import { SVGProps } from 'react';

export const NinjaAgentsOutlinedIcon = ({
  width = 24,
  height = 24,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512 512"
    >
      <path
        d="M255.99,128.01h.01c-53.23,0-100.75,14.9-132.04,44.71-1.41-1.84-2.55-3.41-3.29-4.6-4.72-7.52-10.23-16.34-14.25-25.24-.74-1.65-1.39-3.2-1.95-4.66,3.46.85,7.59,1.46,12.05,1.17,4.24-.27,8.81-1.73,12.88-4.79,3.89-2.92,6.17-6.43,7.48-9.24,2.39-5.12,2.64-10.2,2.61-13.29-.05-6.73-1.66-14.6-3.5-21.75-3.83-14.8-11.43-68.39-16.06-79.65l-33.83,52.15c3.81,9.25,8.68,22.39,12.06,34.09-.79-.16-1.62-.29-2.47-.4-3.14-.39-7.8-.49-12.97,1.38-12.59,4.56-16.71,15.71-17.98,22.6-2.61,14.24,2.28,28.59,7.04,39.14,4.98,11.05,9.96,15.3,9.96,15.3l13.71,15.82,8.28,7.38c-6.56,10.89-11.62,22.97-14.69,36.41-7-3.58-15.18-7.77-22.36-12.5-1.33-.88-2.55-1.72-3.66-2.53,2.83-.54,6-1.46,8.96-3.09,2.81-1.55,5.27-4.01,6.59-7.4,1.26-3.23,1.11-6.35.63-8.68-.87-4.24-3.21-7.77-4.76-9.86-3.36-4.55-8.37-9.37-13.2-13.62-9.98-8.8-41.77-42.7-50.57-48.85l2.26,46.29c7.22,5.05,17.11,12.38,25.25,19.23-.63.15-1.27.32-1.92.53-2.37.75-5.67,2.19-8.34,5.12-6.5,7.15-3.85,16.04-1.33,21.12,5.22,10.5,15.72,18.66,24.25,24.28,8.93,5.89,14.5,7.17,14.5,7.17l17.36,6.31,3.11.77c-.18,3.12-.49,6.15-.49,9.38,0,93.5,76.41,190.46,170.67,190.46s170.67-96.96,170.67-190.46-76.41-140.2-170.67-140.2ZM137.38,378.67s-.05-.07-.07-.1c-25.62-31.88-41.3-72.91-41.3-113.24,0-84.47,68.79-126.66,153.65-126.66,57.78,0,108.11,19.57,134.35,58.72-27.64-32.78-74.28-49.16-127.15-49.16-84.86,0-153.65,42.19-153.65,126.66,0,36.4,12.77,73.38,34.1,103.68.03.03.05.07.08.1h-.01ZM256,394.66c-71.88,0-141.33-60.76-141.33-121.85s69.45-47.53,141.33-47.53,141.33-13.56,141.33,47.53-69.45,121.85-141.33,121.85ZM371.64,276.48c5.99,15.25-6.59,33.43-28.11,40.61-21.51,7.17-43.81.63-49.8-14.63-5.99-15.25,6.06-21.56,27.58-28.74,21.51-7.18,44.34-12.5,50.33,2.76h0ZM140.36,276.48c-5.99,15.25,6.59,33.43,28.11,40.61,21.51,7.17,43.81.63,49.8-14.63,5.99-15.25-6.06-21.56-27.58-28.74-21.51-7.18-44.34-12.5-50.33,2.76h0Z"
        fill="currentColor"
      />
    </svg>
  );
};
