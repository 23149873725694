import classNames from 'classnames';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './TabList.module.scss';
import { ReactNode } from 'react';
import { PuzzlePiece } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { Icon } from 'src/components/Icon';

const ICON_PROPS = {
  size: SVG_SIZE_M,
};

export type Tab = 'ninja' | 'external';

interface TabListProps {
  activeTab: Tab;
  ninjaModelsLength?: number;
  setActiveTab: (tab: Tab) => void;
}

export const TabList = ({ activeTab, setActiveTab }: TabListProps) => {
  const tabs: {
    tabType: Tab;
    title: string;
    icon: ReactNode;
  }[] = [
    {
      tabType: 'ninja',
      title: 'Ninja',
      icon: <Icon type="ninjaOutlined" {...ICON_PROPS} />,
    },
    {
      tabType: 'external',
      title: 'External',
      icon: <PuzzlePiece {...ICON_PROPS} />,
    },
  ];

  return (
    <div className={styles.root}>
      {tabs.map(({ tabType, title, icon }) => (
        <Button
          key={tabType}
          color="transparent"
          className={classNames(styles.tab, {
            [styles.active]: activeTab === tabType,
          })}
          onClick={() => setActiveTab(tabType)}
        >
          {icon}

          <span className={styles.title}>{title}</span>
        </Button>
      ))}
    </div>
  );
};
