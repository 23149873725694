import { ReactNode } from 'react';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './Tab.module.scss';
import classNames from 'classnames';

export interface TabProps<T extends string> {
  tab: T;
  activeTab?: T;
  onTabClick?: (tab: T) => void;
  children: ReactNode;
  className?: string;
  withAnimation?: boolean;
}

export const Tab = <T extends string>({
  tab,
  activeTab,
  onTabClick,
  children,
  className,
  withAnimation,
}: TabProps<T>) => {
  const handleClick = () => {
    if (onTabClick) {
      onTabClick(tab);
    }
  };

  return (
    <Button
      onClick={handleClick}
      color="transparent"
      className={classNames(
        styles.root,
        {
          [styles.active]: tab === activeTab,
          [styles.animated]: withAnimation,
        },
        className,
      )}
    >
      {children}
    </Button>
  );
};

Tab.displayName = 'Tab';
