import { Check } from '@phosphor-icons/react';
import { FormProvider } from 'src/components/FormProvider';
import { useForm, useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import classNames from 'classnames';
import {
  MessageSkill,
  MessageType,
  TaskSkill,
  NegativeFeedbackDetails,
} from 'src/types';
import {
  getSkillFromMessage,
  getSkillFromTask,
  sanitizeOutgoingContent,
} from 'src/utils';
import TextareaAutosize from 'react-textarea-autosize';
import { NegativeFeedbackReasons, reasonOptions } from 'src/constants/feedback';
import { useFeedbackThumbs } from 'src/hooks';

type NegativeFeedbackAdditionalFormProps = {
  messageId?: string;
  onClose: () => void;
  messageType?: MessageType;
  taskSkill?: TaskSkill;
};

enum FormDataFields {
  IS_WRONG_INTENT = 'is_wrong_intent',
  EXPECTED_INTENT = 'expected_intent',
  ADDITIONAL_FEEDBACK = 'additional_feedback',
  REASON = 'reason',
}

interface FormData {
  [FormDataFields.IS_WRONG_INTENT]: boolean;
  [FormDataFields.EXPECTED_INTENT]: MessageSkill | undefined;
  [FormDataFields.ADDITIONAL_FEEDBACK]: string;
  [FormDataFields.REASON]: NegativeFeedbackReasons | undefined;
}

const ICON_SIZE = 20;

export const NegativeFeedbackAdditionalForm = ({
  messageId,
  onClose,
  messageType,
  taskSkill,
}: NegativeFeedbackAdditionalFormProps) => {
  const methods = useForm<FormData>({
    defaultValues: {
      [FormDataFields.IS_WRONG_INTENT]: false,
      [FormDataFields.EXPECTED_INTENT]: undefined,
      [FormDataFields.ADDITIONAL_FEEDBACK]: '',
      [FormDataFields.REASON]: undefined,
    },
  });

  const { handleSubmit, reset, control, setValue, register } = methods;

  const fieldValues = useWatch({ control });

  const { sendNegativeFeedbackForm } = useFeedbackThumbs(messageId);

  const intentsList = useMemo(() => {
    const currentSkill = taskSkill
      ? getSkillFromTask(taskSkill)
      : messageType
        ? getSkillFromMessage(messageType)
        : '';

    return Object.values(MessageSkill).filter(
      (skill) => skill !== currentSkill,
    );
  }, [messageType, taskSkill]);

  const submitForm = async (data: FormData) => {
    const sanitizedData: NegativeFeedbackDetails = {
      ...data,
      [FormDataFields.ADDITIONAL_FEEDBACK]: sanitizeOutgoingContent(
        data[FormDataFields.ADDITIONAL_FEEDBACK],
      ),
    };

    await sendNegativeFeedbackForm(sanitizedData);
    reset();
    onClose();
  };

  const isDisabledSubmit =
    !fieldValues[FormDataFields.IS_WRONG_INTENT] &&
    fieldValues[FormDataFields.REASON] === null &&
    fieldValues[FormDataFields.ADDITIONAL_FEEDBACK]?.length === 0;

  return (
    <FormProvider<FormData> methods={methods}>
      <form onSubmit={handleSubmit(submitForm)}>
        <div
          className={classNames('nj-feedback-negative-form-content', {
            withoutBorder: !!taskSkill,
          })}
        >
          <div>
            <button
              type="button"
              className={classNames(
                'nj-feedback-negative-form-content-button',
                { selected: fieldValues[FormDataFields.IS_WRONG_INTENT] },
              )}
              onClick={() =>
                setValue(
                  FormDataFields.IS_WRONG_INTENT,
                  !fieldValues[FormDataFields.IS_WRONG_INTENT],
                )
              }
            >
              {fieldValues[FormDataFields.IS_WRONG_INTENT] && (
                <Check size={ICON_SIZE} />
              )}
              <span>Wrong intent</span>
            </button>
            {fieldValues[FormDataFields.IS_WRONG_INTENT] && (
              <div className="nj-feedback-negative-form-content-message-intents-list-wrapper">
                <div className="nj-feedback-negative-form-content-message-intents-list-header">
                  <h5 className="nj-feedback-negative-form-content-message-intents-list-header--title">
                    Choose the intent that should be applied
                  </h5>
                  <span className="nj-feedback-negative-form-content-message-intents-list-header--hint">
                    Optional
                  </span>
                </div>
                <div className="nj-feedback-negative-form-content-message-intents-list">
                  {(intentsList || []).map((item) => {
                    const isSelected =
                      fieldValues[FormDataFields.EXPECTED_INTENT] === item;
                    return (
                      <button
                        key={item}
                        type="button"
                        className={classNames(
                          'nj-feedback-negative-form-content-button',
                          {
                            selected: isSelected,
                          },
                        )}
                        onClick={() =>
                          setValue(
                            FormDataFields.EXPECTED_INTENT,
                            isSelected ? undefined : item,
                          )
                        }
                      >
                        {isSelected && <Check size={ICON_SIZE} />}
                        <span>{item}</span>
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="nj-feedback-negative-form-content-message-reasons-list">
            {reasonOptions.map((item) => {
              const isSelected =
                item.value === fieldValues[FormDataFields.REASON];
              return (
                <button
                  key={item.value}
                  type="button"
                  className={classNames(
                    'nj-feedback-negative-form-content-button',
                    {
                      selected: isSelected,
                    },
                  )}
                  onClick={() =>
                    setValue(
                      FormDataFields.REASON,
                      isSelected ? undefined : item.value,
                    )
                  }
                >
                  {isSelected && <Check size={ICON_SIZE} />}
                  <span>{item.label}</span>
                </button>
              );
            })}
          </div>
          <TextareaAutosize
            className="nj-feedback-negative-form-content-additional-feedback"
            minRows={3}
            maxRows={3}
            placeholder="Additional feedback (optional)"
            {...register(FormDataFields.ADDITIONAL_FEEDBACK)}
          />
          <button
            type="submit"
            className="nj-feedback-negative-form-content-submit-button"
            disabled={isDisabledSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
