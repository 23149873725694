/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TextInput } from './TextInput';

export type TextInputCard = {
  version: string;
  link: string;
  executed?: boolean;
  input_id?: string;
  payload_type?: TextInputCard.payload_type;
  description: string;
  questions: Array<TextInput>;
};

export namespace TextInputCard {
  export enum payload_type {
    TEXT_INPUT_CARD = 'text-input-card',
  }
}
