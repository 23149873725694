export enum MetaHumanEventType {
  AVATAR_EVENT = 'avatar-event',
  PLAY_STREAM_STATE = 'playStreamState', // deprecating
  AVATAR_STREAM_STATE = 'avatar-stream-state',
}

export type MetaHumanSpeechEvent = {
  type: MetaHumanEventType.AVATAR_EVENT;
  silence?: boolean;
  beginSpeech?: boolean;
  sentTTSRequest?: boolean;
  text?: string;
  provider?: string;
};

export type MetaHumanStreamingEvent = {
  type: MetaHumanEventType.PLAY_STREAM_STATE;
  state?: boolean;
  message?: string;
};

export type MetaHumanEvent = MetaHumanSpeechEvent | MetaHumanStreamingEvent;
