import { useCallback } from 'react';
import {
  Message,
  ProposalConfirmationCard as ProposalConfirmationCardType,
} from 'src/types';
import {
  ProposalConfirmationFieldsGroup,
  ProposalCardFormData,
} from './components/ProposalConfirmationFieldsGroup';
import { CompletedTimeSlotFieldsList } from './components/CompletedTimeSlotFieldsList';

type ProposalConfirmationCardProps = {
  message: Message;
  proposalConfirmationCard: ProposalConfirmationCardType;
};

export const ProposalConfirmationCard = ({
  message,
  proposalConfirmationCard,
}: ProposalConfirmationCardProps) => {
  const { description, executed = false, proposals } = proposalConfirmationCard;

  const prepareMessageForSubmit = useCallback(
    (data: ProposalCardFormData): Message => {
      return {
        ...message,
        payload: {
          ...proposalConfirmationCard,
          proposals: {
            type: 'datetime_range_list',
            values: data.proposals,
          },
        } as ProposalConfirmationCardType,
      };
    },
    [message, proposalConfirmationCard],
  );

  return (
    <div className="nj-task-scratchpad--proposal-confirmation-card">
      <div className="nj-task-scratchpad--proposal-confirmation-card--content">
        <div className="nj-task-scratchpad--proposal-confirmation-card--description">
          {description && <p>{description}</p>}

          <h5>Provide availability</h5>

          {executed ? (
            <CompletedTimeSlotFieldsList
              fields={proposals.values}
              timestamp={message.timestamp}
            />
          ) : (
            <ProposalConfirmationFieldsGroup
              fields={proposals}
              executed={executed}
              onPreSubmit={prepareMessageForSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};
