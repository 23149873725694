import { sessionState, useAppDispatch, useAppSelector } from 'src/store';
import { BannerType } from 'src/types';
import {
  removeBannerFromSet,
  removeBannersFromSet,
  setCurrentBannerIndex,
  setNewBannerToSet,
  setToBlackListBanner,
} from 'src/store/slices/sessionSlice';

export const useBanner = () => {
  const dispatch = useAppDispatch();

  const { bannerType, bannersSet, currentBannerIndex, blackListBanners } =
    useAppSelector(sessionState);

  const addBanner = (banner: BannerType) => {
    dispatch(setNewBannerToSet(banner));
  };

  const addToBlackListBanner = (banner: BannerType) => {
    dispatch(setToBlackListBanner(banner));
  };

  const removeBanner = (banner: BannerType) => {
    dispatch(removeBannerFromSet(banner));
  };

  const removeBanners = (banners: BannerType[]) => {
    dispatch(removeBannersFromSet(banners));
  };

  const removeAllPaymentBanners = () => {
    dispatch(
      removeBannersFromSet([
        BannerType.INSUFFICIENT_CREDITS,
        BannerType.LOW_CREDITS,
        BannerType.LOW_DAYS,
        BannerType.LOW_TASKS,
      ]),
    );
  };

  const changeCurrentBannerIndex = (currentBannerIndex: number) => {
    dispatch(setCurrentBannerIndex(currentBannerIndex));
  };

  return {
    bannerType,
    bannersSet,
    addBanner,
    removeBanner,
    changeCurrentBannerIndex,
    currentBannerIndex,
    removeBanners,
    removeAllPaymentBanners,
    addToBlackListBanner,
    blackListBanners,
  };
};
