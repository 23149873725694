import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { VideoCamera, VideoCameraSlash } from '@phosphor-icons/react';
import cn from 'classnames';
import { useBanner, useSession } from 'src/hooks';
import { BannerType, ChatMode, GTMEvent } from 'src/types';
import { exitFullscreen, interruptMetahuman, sendGTMEvent } from 'src/utils';
import { SVG_SIZE_M } from 'src/constants';
import styles from './VideoCallButton.module.scss';

interface VideoCallButtonProps {
  size?: 'small' | 'medium' | 'large';
  iconSize?: number;
}

export const VideoCallButton = ({
  size = 'large',
  iconSize = SVG_SIZE_M,
}: VideoCallButtonProps) => {
  const {
    isFullscreen,
    setShowAvatarQueueModal,
    clearShowAvatarQueue,
    stopAvatarSession,
    toggleTranscript,
    toggleFullscreen,
  } = useContext(AvatarModeContext);

  const {
    onToggleUpsellAvatarModal,
    onToggleAvatarSelect,
    isShowAvatarSelect,
  } = useContext(SessionContext);

  const {
    appUser,
    chatMode,
    avatarQueue = {},
    updateAvatarQueue,
    updateChatMode,
    isOpenTier,
  } = useSession();

  const { removeBanner, bannersSet } = useBanner();

  const handleClick = () => {
    if (isShowAvatarSelect) {
      onToggleAvatarSelect(false);
    }
    // if it is guest user and have no access to video call
    if (!appUser?.settings?.features?.video_chat?.is_capable && isOpenTier) {
      onToggleUpsellAvatarModal();

      sendGTMEvent(GTMEvent.START_VIDEO_CLICK);
      return;
    }
    // if user is not in the queue
    if (!avatarQueue?.joinedAvatarQueue) {
      // We do not switch to ChatMode.AVATAR
      // any longer and instead have an intermediate
      // step within the queue modal.
      setShowAvatarQueueModal(true);
      updateAvatarQueue({ joinedAvatarQueue: true, available: false, step: 1 });

      sendGTMEvent(GTMEvent.START_VIDEO_CLICK);
    } else {
      // when user is in the queue & top banner is shown
      // switch to the modal view instead of banner

      if (bannersSet.includes(BannerType.AVATAR_QUEUE)) {
        removeBanner(BannerType.AVATAR_QUEUE);
        setShowAvatarQueueModal(true);
      } else {
        // user quits avatar mode completely
        if (isFullscreen) {
          exitFullscreen();
          toggleTranscript(true);
          toggleFullscreen(false);
        }
        // TODO(olha): add condition only if metahuman is speaking when we have "beginSpeech" event from the Avatar game
        interruptMetahuman(appUser.user_id);
        stopAvatarSession();
        updateAvatarQueue();
        clearShowAvatarQueue();
        updateChatMode(ChatMode.CHAT);
      }
    }
  };

  return (
    <button
      className={cn(styles.root, {
        [styles.active]: chatMode === ChatMode.AVATAR,
        [styles.roundShape]: size !== 'large',
        [styles.reducedPadding]: size === 'small',
      })}
      onClick={handleClick}
    >
      {chatMode === ChatMode.AVATAR ? (
        <>
          <VideoCameraSlash size={iconSize} />
          {size === 'large' && <span>End video Avatar</span>}
        </>
      ) : (
        <>
          <VideoCamera size={iconSize} />
          {size === 'large' && <span>Video Avatar</span>}
        </>
      )}
    </button>
  );
};
