import { FormInput } from 'src/components/FormInput';
import { FormProvider } from 'src/components/FormProvider';

import styles from './EmailSubmitForm.module.scss';
import { useForm } from 'react-hook-form';
import { Button } from 'src/v2/commonComponents/Button';

type EmailSubmitFormProps = {
  buttonText?: string;
  onSubmit: (email: string) => Promise<void>;
  errorMessage?: string | React.ReactNode;
};

enum SignupFields {
  EMAIL = 'email',
}

interface FormData {
  [SignupFields.EMAIL]: string;
}

export const EmailSubmitForm = ({
  onSubmit,
  errorMessage,
  buttonText = 'Sign up with Email',
}: EmailSubmitFormProps) => {
  const methods = useForm<FormData>({
    defaultValues: {
      [SignupFields.EMAIL]: '',
    },
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const submitForm = async (formData: FormData) => {
    await onSubmit(formData[SignupFields.EMAIL].toLowerCase());
  };

  return (
    <FormProvider<FormData> methods={methods}>
      <form onSubmit={handleSubmit(submitForm)} className={styles.root}>
        <FormInput
          name={SignupFields.EMAIL}
          type="email"
          className={styles.email}
          placeholder="Enter Email"
          required={true}
          errorMessage={errorMessage}
        />
        <Button type="submit" disabled={isSubmitting}>
          <span>{buttonText}</span>
        </Button>
      </form>
    </FormProvider>
  );
};
