import { useExternalModels } from 'src/hooks';
import { Icon } from 'src/components/Icon';
import { SVG_SIZE_M, imageGeneratorsModelsSet } from 'src/constants';
import { ExternalModelGroup } from '../ExternalModelGroup';
import { ExternalModelItemProps } from '../ExternalModelItem';
import { NinjaSubscriptionPlan } from 'src/types';
import { ModelSectionHeader } from '../ModelSectionHeader';
import { getDefaultModelIconName } from 'src/utils';

const ICON_PROPS = {
  size: SVG_SIZE_M,
};

interface TextModelListProps {
  isLimitedForPro: boolean;
  isLimitedForUltra: boolean;
  selectedImageModels: string[];
  onClick: (id: string) => void;
}

export const ImageModelList = ({
  isLimitedForPro,
  isLimitedForUltra,
  selectedImageModels,
  onClick,
}: TextModelListProps) => {
  const { imageExternalModels } = useExternalModels();

  const imageModels: ExternalModelItemProps[] = imageExternalModels.map(
    ({ model, is_capable, display_name }) => {
      const icon =
        imageGeneratorsModelsSet[model]?.icon_name ||
        getDefaultModelIconName(model);

      const isNinjaModel = model.includes('ninjatech');

      return {
        id: model,
        icon: icon ? <Icon type={icon} {...ICON_PROPS} /> : <></>,
        title: display_name || '',
        included: isNinjaModel,
        selected: selectedImageModels.includes(model),
        // TODO(olha): add simple disable props (just in case)
        availableIn:
          (isLimitedForPro || isLimitedForUltra) && !is_capable
            ? NinjaSubscriptionPlan.ULTRA
            : undefined,
        onClick: () => (!isNinjaModel ? onClick(model) : undefined),
      };
    },
  );

  const availableAmazonModels = imageModels.filter(
    (item) => !item.availableIn && item.id.includes('bedrock'),
  );

  const disabledAmazonModels = imageModels.filter(
    (item) => !!item.availableIn && item.id.includes('bedrock'),
  );

  const availableOpenAIModels = imageModels.filter(
    (item) => !item.availableIn && item.id.includes('openai'),
  );

  const disabledOpenAIModels = imageModels.filter(
    (item) => !!item.availableIn && item.id.includes('openai'),
  );

  return (
    <>
      <ModelSectionHeader
        title="Image models"
        selectedLength={selectedImageModels.length}
      />

      <ExternalModelGroup
        groupTitle="Amazon Bedrock"
        modelItems={availableAmazonModels}
      />

      <ExternalModelGroup
        groupTitle="OpenAI"
        modelItems={availableOpenAIModels}
      />

      <ExternalModelGroup
        groupTitle="Amazon Bedrock"
        modelItems={disabledAmazonModels}
      />

      <ExternalModelGroup
        groupTitle="OpenAI"
        modelItems={disabledOpenAIModels}
      />
    </>
  );
};
