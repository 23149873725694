import styles from './VerificationPage.module.scss';
import { isEnterprise } from 'src/utils';
import {
  EnterpriseMeetNinjaSection,
  MeetNinjaSection,
} from 'src/v2/pages/auth/components/MeetNinjaSection';
import { EmailVerificationBlock } from 'src/v2/pages/auth/components/EmailVerificationBlock';
import { useQueryParams } from 'src/hooks';
import { BasePublicPage } from 'src/v2/pages/auth/BasePublicPage';
import { AppRoutes } from 'src/types';
import { TextWithLinkSection } from 'src/v2/pages/auth/components/TextWithLinkSection';
import React from 'react';

export const VerificationPage = () => {
  const { searchParams } = useQueryParams();

  const { email } = searchParams;

  return (
    <BasePublicPage>
      {isEnterprise ? (
        <>
          <EnterpriseMeetNinjaSection />
          <div className={styles.mainSection}>
            <EmailVerificationBlock email={email} />
            <TextWithLinkSection
              text="Back to"
              linkText="Login"
              linkRoute={AppRoutes.LOGIN}
            />
          </div>
        </>
      ) : (
        <>
          <MeetNinjaSection isSignup={false} />
          <div className={styles.mainSection}>
            <EmailVerificationBlock email={email} />
          </div>
        </>
      )}
    </BasePublicPage>
  );
};
