export enum NegativeFeedbackReasons {
  DONT_LIKE_TONE_VERBOSITY = 'dont_like_tone_verbosity',
  NOT_FACTUALLY_CORRECT = 'not_factually_correct',
  OFFENSIVE_UNSAFE = 'offensive_unsafe',
  OTHER = 'other',
}

export const reasonOptions = [
  {
    label: "Don't like tone or verbosity",
    value: NegativeFeedbackReasons.DONT_LIKE_TONE_VERBOSITY,
  },
  {
    label: 'Not factually correct',
    value: NegativeFeedbackReasons.NOT_FACTUALLY_CORRECT,
  },
  {
    label: 'Offensive / Unsafe',
    value: NegativeFeedbackReasons.OFFENSIVE_UNSAFE,
  },
  {
    label: 'Other',
    value: NegativeFeedbackReasons.OTHER,
  },
];
