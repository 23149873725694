import { useCallback, useMemo } from 'react';
import { useSession } from 'src/hooks/useSession';
import { useAppDispatch } from 'src/store';
import { replaceConversationMessage } from 'src/store/updateQueries';
import {
  useCreateFeedbackMutation,
  useUpdateFeedbackMutation,
  useDeleteFeedbackMutation,
} from 'src/store/services';
import {
  CreateFeedbackRequest,
  FeedbackType,
  Message,
  Feedback,
  PatchFeedbackRequest,
  NegativeFeedbackDetails,
} from 'src/types';
import { toast } from 'react-toastify';
import { useConversationParams } from '../conversationHooks';
import { useCurrentMessage } from './useCurrentMessage';
import { COMMON_ERROR_TEXT } from 'src/constants';

const SUBMITTED_TEXT = 'Feedback submitted';
const CANCELLED_TEXT = 'Feedback cancelled';

export const useFeedbackThumbs = (messageId?: string) => {
  const dispatch = useAppDispatch();

  const {
    appUser: { user_id },
  } = useSession();
  const { currentConversationId } = useConversationParams();

  const { currentMessage } = useCurrentMessage(messageId);

  const [createFeedback, { isLoading: isCreateLoading }] =
    useCreateFeedbackMutation();
  const [deleteFeedback, { isLoading: isDeleteLoading }] =
    useDeleteFeedbackMutation();
  const [updateFeedback, { isLoading: isUpdateLoading }] =
    useUpdateFeedbackMutation();

  const isLoading = useMemo(
    () => isCreateLoading || isDeleteLoading || isUpdateLoading,
    [isCreateLoading, isDeleteLoading, isUpdateLoading],
  );

  const isThumbUp = useMemo(
    () =>
      currentMessage
        ? currentMessage.feedback?.feedback_v2_data?.thumbs_up_down?.thumbs_up
        : undefined,
    [currentMessage],
  );

  const updateMessageWithFeedback = useCallback(
    ({
      message,
      feedback_id,
      isThumbUp,
      feedback_ids,
      updated_feedback,
    }: {
      message: Message;
      feedback_id?: string;
      isThumbUp?: boolean;
      feedback_ids?: Array<string>;
      updated_feedback?: Feedback;
    }): Message => {
      if (updated_feedback && feedback_ids) {
        const updatedMessage = {
          ...message,
          feedback: updated_feedback,
          feedback_ids,
        };

        return updatedMessage;
      }

      if (isThumbUp === undefined) {
        const updatedMessage: Message = {
          ...message,
          feedback: {
            ...message.feedback,
            feedback_v2_data: {
              ...message.feedback?.feedback_v2_data,
              thumbs_up_down: undefined,
            },
          },
          feedback_ids: message.feedback_ids?.filter(
            (id) =>
              id !==
              message.feedback?.feedback_v2_data?.thumbs_up_down?.feedback_id,
          ),
        };

        return updatedMessage;
      }

      const thumbsFeedback = {
        feedback_id: feedback_id || '',
        thumbs_up: isThumbUp,
      };

      const updatedMessage = {
        ...message,
        feedback: {
          ...message.feedback,
          feedback_v2_data: {
            ...message.feedback?.feedback_v2_data,
            thumbs_up_down: thumbsFeedback,
          },
        },
        feedback_ids: feedback_id
          ? [...(message.feedback_ids || []), feedback_id]
          : message.feedback_ids,
      };

      return updatedMessage;
    },
    [],
  );

  const handleDeleteFeedback = useCallback(
    async (messageId: string) => {
      const feedback_id =
        currentMessage?.feedback?.feedback_v2_data?.thumbs_up_down?.feedback_id;

      if (!feedback_id) {
        return;
      }

      const temporaryReplacedMessage = updateMessageWithFeedback({
        message: currentMessage,
        feedback_id,
      });

      dispatch(replaceConversationMessage(temporaryReplacedMessage));

      try {
        const { feedback_ids, updated_feedback } = await deleteFeedback({
          conversation_id: currentConversationId,
          message_id: messageId,
          feedback_id,
          user_id,
        }).unwrap();

        toast(CANCELLED_TEXT);

        const updatedMessage = updateMessageWithFeedback({
          message: currentMessage,
          feedback_ids,
          updated_feedback,
        });

        dispatch(replaceConversationMessage(updatedMessage));
      } catch (error) {
        toast(COMMON_ERROR_TEXT);
        dispatch(replaceConversationMessage(currentMessage));
      }
    },
    [
      currentMessage,
      currentConversationId,
      user_id,
      deleteFeedback,
      dispatch,
      updateMessageWithFeedback,
    ],
  );

  const handleCreateFeedback = useCallback(
    async (messageId: string, isThumbUp: boolean) => {
      if (!currentMessage) {
        return;
      }

      const body: CreateFeedbackRequest = {
        user_id,
        conversation_id: currentConversationId,
        message_id: messageId,
        feedback_type: isThumbUp
          ? FeedbackType.POSITIVE
          : FeedbackType.NEGATIVE,
      };

      const temporaryReplacedMessage: Message = updateMessageWithFeedback({
        message: currentMessage,
        isThumbUp,
      });

      dispatch(replaceConversationMessage(temporaryReplacedMessage));

      try {
        const { updated_feedback, feedback_ids } =
          await createFeedback(body).unwrap();
        toast(SUBMITTED_TEXT);

        const updatedMessage = updateMessageWithFeedback({
          message: currentMessage,
          feedback_ids,
          updated_feedback,
        });

        dispatch(replaceConversationMessage(updatedMessage));
      } catch (error) {
        toast(COMMON_ERROR_TEXT);
        dispatch(replaceConversationMessage(currentMessage));
      }
    },
    [
      currentConversationId,
      currentMessage,
      user_id,
      dispatch,
      updateMessageWithFeedback,
      createFeedback,
    ],
  );

  const handleUpdateFeedback = useCallback(
    async (messageId: string, isThumbUp: boolean) => {
      const feedback_id =
        currentMessage?.feedback?.feedback_v2_data?.thumbs_up_down?.feedback_id;

      if (!feedback_id || !currentMessage) {
        return;
      }

      const body: PatchFeedbackRequest = {
        user_id,
        conversation_id: currentConversationId,
        message_id: messageId,
        feedback_id,
        feedback_type: isThumbUp
          ? FeedbackType.POSITIVE
          : FeedbackType.NEGATIVE,
      };

      const temporaryReplacedMessage: Message = updateMessageWithFeedback({
        message: currentMessage,
        isThumbUp,
      });

      dispatch(replaceConversationMessage(temporaryReplacedMessage));

      try {
        const { feedback_ids, updated_feedback } =
          await updateFeedback(body).unwrap();
        toast(SUBMITTED_TEXT);

        const updatedMessage = updateMessageWithFeedback({
          message: currentMessage,
          feedback_ids,
          updated_feedback,
        });

        dispatch(replaceConversationMessage(updatedMessage));
      } catch (error) {
        toast(COMMON_ERROR_TEXT);
        dispatch(replaceConversationMessage(currentMessage));
      }
    },
    [
      currentConversationId,
      currentMessage,
      user_id,
      dispatch,
      updateMessageWithFeedback,
      updateFeedback,
    ],
  );

  const onThumbUpClick = useCallback(() => {
    if (!messageId || isLoading) {
      return;
    }

    if (isThumbUp === undefined) {
      handleCreateFeedback(messageId, true);
    }

    if (isThumbUp === false) {
      handleUpdateFeedback(messageId, true);
    }

    if (isThumbUp) {
      handleDeleteFeedback(messageId);
    }
  }, [
    messageId,
    isThumbUp,
    isLoading,
    handleDeleteFeedback,
    handleCreateFeedback,
    handleUpdateFeedback,
  ]);

  const onThumbDownClick = useCallback(() => {
    if (!messageId || isLoading) {
      return;
    }

    if (isThumbUp === undefined) {
      handleCreateFeedback(messageId, false);
    }

    if (isThumbUp) {
      handleUpdateFeedback(messageId, false);
    }

    if (isThumbUp === false) {
      handleDeleteFeedback(messageId);
    }
  }, [
    messageId,
    isThumbUp,
    isLoading,
    handleCreateFeedback,
    handleUpdateFeedback,
    handleDeleteFeedback,
  ]);

  const sendNegativeFeedbackForm = useCallback(
    async (feedback_details: NegativeFeedbackDetails) => {
      const feedback_id =
        currentMessage?.feedback?.feedback_v2_data?.thumbs_up_down?.feedback_id;

      if (!feedback_id || !messageId) {
        return;
      }

      const body: PatchFeedbackRequest = {
        user_id,
        conversation_id: currentConversationId,
        message_id: messageId,
        feedback_id,
        feedback_details,
      };

      try {
        await updateFeedback(body).unwrap();
        toast(SUBMITTED_TEXT);
      } catch (error) {
        toast(COMMON_ERROR_TEXT);
      }
    },
    [user_id, messageId, currentMessage, currentConversationId, updateFeedback],
  );

  return useMemo(
    () => ({
      isThumbUp,
      onThumbUpClick,
      onThumbDownClick,
      sendNegativeFeedbackForm,
    }),
    [isThumbUp, onThumbUpClick, onThumbDownClick, sendNegativeFeedbackForm],
  );
};
