import cn from 'classnames';
import { useColorTheme } from 'src/hooks';
import styles from './AvatarImage.module.scss';
import ninjaLight from 'src/images/icons/ninja-photo-light.svg';
import ninjaDark from 'src/images/icons/ninja-photo-dark.svg';
import { SVG_SIZE_XXL } from 'src/constants';

interface AvatarImageProps {
  size?: number;
  className?: string;
}

export const AvatarImage = ({
  size = SVG_SIZE_XXL,
  className,
}: AvatarImageProps) => {
  const { isDarkTheme } = useColorTheme();

  return (
    <img
      className={cn(styles.root, className)}
      src={isDarkTheme ? ninjaDark : ninjaLight}
      width={size}
      height={size}
      alt="Ninja"
    />
  );
};
