import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Clock, X } from '@phosphor-icons/react';
import { Span } from 'src/types';
import type { MeetingCardFormData } from '../../../../CreateMeetingCard';
import {
  useMeetingWindowDateRange,
  DateRangeErrorType,
} from '../../../../hooks/useMeetingWindowDateRange';
import { useMeetingCardFieldsWatch } from '../../../../hooks/useMeetingCardFieldsWatch';
import { DateTimePickerInput } from '../DateTimePickerInput';
import { useBreakpoint } from 'src/hooks';
import dayjs from 'dayjs';
import { roundToNearestQuarterHour } from 'src/utils';

const SVG_SIZE = 20;

interface MeetingDateRangePickerProps {
  dateRange: Span;
  index: number;
  onRemove?: () => void;
}

export const MeetingDateRangePicker = ({
  dateRange,
  index,
  onRemove,
}: MeetingDateRangePickerProps) => {
  const { isMobile } = useBreakpoint();

  const {
    formState: { errors },
  } = useFormContext<MeetingCardFormData>();

  const { executed, minutes } =
    useMeetingCardFieldsWatch<MeetingCardFormData>();

  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    updateDateRange,
    validateDateRange,
  } = useMeetingWindowDateRange<MeetingCardFormData>({
    ...dateRange,
    index,
    key: 'meeting_windows',
  });

  useEffect(() => {
    validateDateRange({ start: startDate, end: endDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes]);

  const handleStartChange = (date: string) => {
    setStartDate(date);

    const newMeetingWindow: Span = {
      start: date,
      end: endDate,
    };

    const isValidate = validateDateRange(newMeetingWindow);

    if (!isValidate) {
      const newEndDate = dayjs(date)
        .add(minutes || 0, 'minute')
        .utc();

      const newEndDateString = roundToNearestQuarterHour(newEndDate).format();

      const tmp = {
        start: date,
        end: newEndDateString,
      };

      setEndDate(newEndDateString);
      updateDateRange(tmp);
    } else {
      updateDateRange(newMeetingWindow);
    }
  };

  const handleEndChange = (date: string) => {
    setEndDate(date);

    const newMeetingWindow: Span = {
      start: startDate,
      end: date,
    };

    updateDateRange(newMeetingWindow);
  };

  const hasErrorStartInput =
    errors?.meeting_windows &&
    (errors.meeting_windows[index]?.type === DateRangeErrorType.BOTH_PICKERS ||
      errors.meeting_windows[index]?.type === DateRangeErrorType.START_PICKER);

  const hasErrorEndInput =
    errors?.meeting_windows &&
    errors.meeting_windows[index]?.type === DateRangeErrorType.BOTH_PICKERS;

  return (
    <div className="nj-create-meeting-card--meeting-window-picker">
      <Clock size={SVG_SIZE} />

      <div className="nj-create-meeting-card--meeting-window-picker-wrapper">
        <DateTimePickerInput
          date={startDate}
          onDateChange={handleStartChange}
          hasError={hasErrorStartInput}
        />

        {!isMobile && (
          <hr className="nj-create-meeting-card--meeting-window-dash" />
        )}

        <DateTimePickerInput
          date={endDate}
          onDateChange={handleEndChange}
          hasError={hasErrorEndInput}
        />
      </div>

      {!executed && onRemove && (
        <button onClick={onRemove}>
          <X size={16} />
        </button>
      )}

      {!isMobile &&
        errors?.meeting_windows &&
        errors?.meeting_windows[index]?.message && (
          <span className="nj-create-meeting-card--error-message">
            {errors?.meeting_windows[index]?.message}
          </span>
        )}
    </div>
  );
};
