import { WarningCircle } from '@phosphor-icons/react';
import { ReactNode } from 'react';

type ErrorMessageBlockProps = {
  size?: number;
  errorMessage: string | ReactNode;
};

export const ErrorMessageBlock = ({
  size = 24,
  errorMessage,
}: ErrorMessageBlockProps) => {
  return (
    <div className="nj-auth-form--error-block">
      <WarningCircle size={size} />
      <div>{errorMessage}</div>
    </div>
  );
};
