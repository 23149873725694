import { Modal } from 'src/components/Modal';
import { RenewEarlyConfirmationContent } from 'src/components/RenewEarlyButton/components/RenewEarlyConfirmationContent';
import { useSubscriptionInfo } from 'src/hooks';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';

export const UpdatePlanModal = () => {
  const { isVisibleUpdatePlanModal, onChangeIsVisibleUpdatePlanModal } =
    useContext(SessionContext);

  const { subscriptionInfo } = useSubscriptionInfo();

  const handleCloseConfirmationModal = () => {
    onChangeIsVisibleUpdatePlanModal(false);
  };

  return (
    <Modal
      open={isVisibleUpdatePlanModal}
      onClose={handleCloseConfirmationModal}
      className="nj-renew-early-confirmation-modal"
    >
      <RenewEarlyConfirmationContent
        onCloseModal={handleCloseConfirmationModal}
        subscriptionInfo={subscriptionInfo}
      />
    </Modal>
  );
};
