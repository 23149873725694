import { useEffect, useState, useMemo, useContext } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { useGetContactsQuery } from 'src/store/services';
import { useSession, useGoogleResourcesAccess } from 'src/hooks';
import { KeyCodes } from 'src/types';

const DEFAULT_LETTER = 'a';

type ContactsParams = {
  shouldSearch: boolean;
  cursorPosition: number;
};

/**
 * useContacts() loads and filters contacts of the user account.
 */
export const useContacts = ({
  shouldSearch,
  cursorPosition,
}: ContactsParams) => {
  const { threadInputBoxValue } = useContext(ThreadInputBoxContext);

  const { appUser } = useSession();

  const { canAccessContacts } = useGoogleResourcesAccess();

  const [query, setQuery] = useState<string>(DEFAULT_LETTER);

  const {
    data = [],
    isFetching: isFeatchingContacts,
    isLoading: isLoadingContacts,
    error: contactsError,
  } = useGetContactsQuery(
    { query, user_id: appUser.user_id },
    {
      skip: !query || !canAccessContacts,
    },
  );

  const contacts = useMemo(
    () =>
      data
        ?.slice()
        .sort((a, b) => a.first_name.localeCompare(b.first_name))
        .slice(0, 5) || [],
    [data],
  );

  useEffect(() => {
    if (!shouldSearch) {
      return;
    }

    const textUpToCursor = threadInputBoxValue.substring(0, cursorPosition);
    const lastAtBeforeCursor = textUpToCursor.lastIndexOf(KeyCodes.AT);

    if (lastAtBeforeCursor === -1) {
      return;
    }

    const textAfterCursor = threadInputBoxValue.substring(cursorPosition);
    const nextSpaceAfterCursor = textAfterCursor.indexOf(KeyCodes.SPACE);
    const endOfQuery =
      nextSpaceAfterCursor !== -1
        ? cursorPosition + nextSpaceAfterCursor
        : threadInputBoxValue.length;

    const query = threadInputBoxValue.substring(
      lastAtBeforeCursor + 1,
      endOfQuery,
    );

    setQuery(query.trim() || DEFAULT_LETTER);
  }, [shouldSearch, threadInputBoxValue, cursorPosition]);

  return {
    contacts,
    isFeatchingContacts,
    isLoadingContacts,
    contactsError,
  };
};
