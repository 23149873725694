import { UseFieldArrayUpdate } from 'react-hook-form';
import { User } from '@phosphor-icons/react';
import { Attendee } from 'src/types';
import { Icon } from 'src/components/Icon';
import { getInitialsFromName, sanitizeOutgoingContent } from 'src/utils';
import { useVisible } from 'src/hooks';
import { AttendeeMenu } from '../AttendeeMenu';
import { AddAttendeeNameForm } from '../AddAttendeeNameForm';
import type { MeetingCardFormData } from '../../../../CreateMeetingCard';

const SVG_SIZE = 20;
const CONTACT_ICON_SIZE = 32;

interface AttendeeListItemProps {
  attendee: Attendee;
  disabled?: boolean;
  index: number;
  onRemove: () => void;
  onUpdate: UseFieldArrayUpdate<MeetingCardFormData, 'attendees'>;
}

export const AttendeeListItem = ({
  attendee,
  disabled,
  index,
  onRemove,
  onUpdate,
}: AttendeeListItemProps) => {
  const { email, name } = attendee;

  const { isVisible, handleVisibilityRemove, handleVisibilitySet } =
    useVisible();

  const handleAddName = (name: string) => {
    onUpdate(index, { ...attendee, name: sanitizeOutgoingContent(name) });
  };

  return (
    <>
      <div className="nj-create-meeting-card--attendee">
        {!name ? (
          <>
            <span className="nj-create-meeting-card--attendee-icon">
              <User size={SVG_SIZE} />
            </span>
            <p className="nj-create-meeting-card--attendee-email">{email}</p>
          </>
        ) : (
          <>
            <Icon alt={getInitialsFromName(name)} size={CONTACT_ICON_SIZE} />
            <p className="nj-create-meeting-card--attendee-email">
              <span>{name}</span>
              <span className="nj-create-meeting-card--attendee-email-sublabel">
                {email}
              </span>
            </p>
          </>
        )}

        {!disabled && (
          <AttendeeMenu
            onRemove={onRemove}
            onAddNameClick={!name ? handleVisibilitySet : undefined}
          />
        )}
      </div>

      {isVisible && (
        <AddAttendeeNameForm
          onSave={handleAddName}
          onClose={handleVisibilityRemove}
        />
      )}
    </>
  );
};
