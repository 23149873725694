import { Sun, Moon, Circle } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { useThemeActions, useColorTheme } from 'src/hooks';
import styles from './ThemeSelector.module.scss';

const SVG_SIZE_XXS = 8;

export const ThemeSelector = () => {
  const { setIsDarkTheme } = useThemeActions();
  const { isDarkTheme } = useColorTheme();

  const handleClick = (value: boolean) => {
    setIsDarkTheme(value);
  };

  return (
    <>
      <button className={styles.button} onClick={() => handleClick(false)}>
        <span className={styles.container}>
          <Sun size={SVG_SIZE_M} />
          Light
        </span>

        {!isDarkTheme && <Circle weight="fill" size={SVG_SIZE_XXS} />}
      </button>

      <button className={styles.button} onClick={() => handleClick(true)}>
        <span className={styles.container}>
          <Moon size={SVG_SIZE_M} />
          Dark
        </span>

        {isDarkTheme && <Circle weight="fill" size={SVG_SIZE_XXS} />}
      </button>
    </>
  );
};
