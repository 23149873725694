import { useState, createContext } from 'react';

export type DebugContextType = {
  debugMode: boolean;
  toggleDebugMode: () => void;
};

type DebugContextProviderProps = {
  children: React.ReactNode;
};

/**
 * DebugContext manages debug toggle for the application.
 */
const DebugContext = createContext<DebugContextType>({
  debugMode: false,
  toggleDebugMode: () => undefined,
});

/**
 * Theme Provider helps to toggle light or dark theme modes.
 */
const DebugContextProvider = ({ children }: DebugContextProviderProps) => {
  const [debugMode, setDebugMode] = useState(false);

  const toggleDebugMode = () => {
    setDebugMode(!debugMode);
  };

  return (
    <DebugContext.Provider value={{ debugMode, toggleDebugMode }}>
      {children}
    </DebugContext.Provider>
  );
};

export { DebugContextProvider };
export default DebugContext;
