import { FormEvent, useEffect, useState } from 'react';
import {
  ExpressCheckoutElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { datadogLogs } from '@datadog/browser-logs';
import styles from './StripeElements.module.scss';
import cn from 'classnames';
import {
  StripeExpressCheckoutElementClickEvent,
  StripeExpressCheckoutElementConfirmEvent,
  StripePaymentElementChangeEvent,
} from '@stripe/stripe-js';
import { useBanner, useSession, useVisible, useColorTheme } from 'src/hooks';
import { useCreateSubscriptionMutation } from 'src/store/services';
import { env } from 'src/env';
import { toast } from 'react-toastify';
import {
  PAYMENT_DATADOG_ERROR,
  TRANSACTION_ID,
  PAYMENT_ERROR_MESSAGE,
} from 'src/constants';
import { AppRoutes, GTMEvent, SubscriptionPlan } from 'src/types';
import { useNavigate } from 'react-router-dom';
import { sendGTMEvent, sendPaymentGTMEvent } from 'src/utils';

const PAYMENT_PROVIDER = 'stripe';

interface StripeElementsProps {
  selectedPlan?: SubscriptionPlan;
  onReady: () => void;
  onPaymentProcessing: (value: boolean) => void;
  numberOfSeats?: number;
}

export const StripeElements = ({
  selectedPlan,
  onReady,
  onPaymentProcessing,
  numberOfSeats,
}: StripeElementsProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const { removeAllPaymentBanners } = useBanner();
  const { isDarkTheme } = useColorTheme();

  const {
    appUser: { user_id, email },
  } = useSession();

  const [createSubscription] = useCreateSubscriptionMutation();

  const [isCashAppButton, setIsCashAppButton] = useState<boolean>(false);

  const [isReadyExpressCheckout, setReadyExpressCheckout] =
    useState<boolean>(false);
  const [isReadyPaymentElement, setReadyPaymentElement] =
    useState<boolean>(false);

  const {
    isVisible: isSubmitDisabled,
    handleVisibilitySet: setSubmitDisabled,
    handleVisibilityRemove: setSubmitEnabled,
  } = useVisible(true);

  const handleCreateSubscription = async () => {
    if (!selectedPlan) return;
    try {
      const result = await createSubscription({
        user_id,
        seat_count: numberOfSeats,
        // TODO(olha): BE is not ready for getting new lookupKey
        plan_id: selectedPlan?.plan_id,
      }).unwrap();

      return result;
    } catch (error) {
      onPaymentProcessing(false);
      toast(PAYMENT_ERROR_MESSAGE);

      datadogLogs.logger.error(PAYMENT_DATADOG_ERROR, {
        user_id,
        payment_provider: PAYMENT_PROVIDER,
        plan: selectedPlan?.plan_id,
        step: 'create subscription',
        error: JSON.stringify(error),
      });

      return;
    }
  };

  // TODO(olha): includes code-duplication. Needs refactoring
  const handleConfirmPayment = async () => {
    if (!elements || !stripe) {
      return;
    }

    const subscriptionIntent = await handleCreateSubscription();

    if (!subscriptionIntent) {
      return;
    }

    const { client_secret, subscription_id } = subscriptionIntent;

    localStorage.setItem(TRANSACTION_ID, subscription_id);

    const returnUrl = `${env.REACT_APP_NINJA_UI_URL}?subscription_succeeded_plan=${selectedPlan?.plan_tier}&subscription_succeeded_period=${selectedPlan?.period}`;

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      clientSecret: client_secret,
      confirmParams: {
        return_url: returnUrl,
        payment_method_data: {
          billing_details: {
            email,
          },
        },
      },
      redirect: 'if_required',
    });

    onPaymentProcessing(false);

    localStorage.removeItem(TRANSACTION_ID);

    if (paymentIntent && paymentIntent.status === 'succeeded') {
      // !!! (olha): it's an important event we are tracking. We need to make sure it calls properly and only once by the subscription
      sendPaymentGTMEvent({
        transaction_id: subscription_id,
        subscription_plan: selectedPlan?.plan_tier,
        subscription_period: selectedPlan?.period,
      });

      removeAllPaymentBanners();
      navigate(AppRoutes.HOME, { replace: true });
    } else {
      toast(PAYMENT_ERROR_MESSAGE);

      datadogLogs.logger.error(PAYMENT_DATADOG_ERROR, {
        user_id,
        payment_provider: PAYMENT_PROVIDER,
        plan: selectedPlan?.plan_id,
        step: 'confirm payment',
        error: JSON.stringify(error),
      });
    }
  };

  const handleSubmit = async (
    event:
      | StripeExpressCheckoutElementConfirmEvent
      | FormEvent<HTMLFormElement>,
  ) => {
    if ('preventDefault' in event) {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
    }

    if (!elements || !stripe) {
      return;
    }

    onPaymentProcessing(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      onPaymentProcessing(false);

      datadogLogs.logger.error(PAYMENT_DATADOG_ERROR, {
        user_id,
        payment_provider: PAYMENT_PROVIDER,
        plan: selectedPlan?.plan_id,
        step: 'stripe form validation',
        error: JSON.stringify(submitError),
      });

      return;
    }

    await handleConfirmPayment();
  };

  const handlePaymentElementChange = ({
    complete,
    value,
    collapsed,
  }: StripePaymentElementChangeEvent) => {
    if (value.type === 'cashapp') {
      setIsCashAppButton(true);
    } else {
      setIsCashAppButton(false);
    }

    if (complete) {
      setSubmitEnabled();
    } else {
      setSubmitDisabled();
    }

    // !!! (olha): if we change defaultCollapsed to false, GTM event don't work properly
    if (!collapsed) {
      sendGTMEvent(GTMEvent.PAYMENT_METHOD_CLICK, {
        payment_method_type: value.type,
      });
    }
  };

  useEffect(() => {
    if (isReadyExpressCheckout && isReadyPaymentElement) {
      onReady();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyExpressCheckout, isReadyPaymentElement]);

  const handleExpressCheckoutClick = (
    event: StripeExpressCheckoutElementClickEvent,
  ) => {
    sendGTMEvent(GTMEvent.PAYMENT_METHOD_CLICK, {
      payment_method_type: event.expressPaymentType,
    });
    event.resolve();
  };

  const handleSubmitClick = () => {
    sendGTMEvent(GTMEvent.PAYMENT_SUBMIT_BUTTON_CLICK);
  };

  return (
    <form
      className={styles.root}
      onSubmit={handleSubmit}
      data-e2e="stripe-elements-form"
    >
      <ExpressCheckoutElement
        options={{
          paymentMethods: {
            googlePay: 'always',
            link: 'never', // never so it's shown in payment element below
            paypal: 'never',
          },
          paymentMethodOrder: ['apple_pay', 'google_pay', 'amazon_pay'],
          buttonTheme: {
            googlePay: 'white',
            applePay: isDarkTheme ? 'white' : 'black',
          },
          layout: {
            maxColumns: 1,
            maxRows: 5,
          },
          buttonHeight: 53,
        }}
        onReady={() => setReadyExpressCheckout(true)}
        onConfirm={handleSubmit}
        onClick={handleExpressCheckoutClick}
      />
      <PaymentElement
        options={{
          layout: {
            type: 'accordion',
            // !!! (olha): if we change defaultCollapsed to false, GTM event don't work properly
            defaultCollapsed: true,
            radios: false,
            spacedAccordionItems: true,
          },
          fields: {
            billingDetails: {
              email: 'never',
            },
          },
          paymentMethodOrder: ['google_pay', 'apple_pay', 'card', 'cashapp'],
        }}
        onReady={() => setReadyPaymentElement(true)}
        onChange={handlePaymentElementChange}
      />
      <button
        disabled={isSubmitDisabled}
        className={
          isCashAppButton
            ? cn(styles.submitButton, styles.blackSubmitButton)
            : cn(styles.submitButton, styles.defaultSubmitButton)
        }
        type="submit"
        onClick={handleSubmitClick}
      >
        {isCashAppButton ? 'Reveal QR Code' : 'Subscribe'}
      </button>
    </form>
  );
};
