/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum EmailProvider {
  GOOGLE = 'google',
  GOOGLE_WORKSPACE = 'google-workspace',
  UNKNOWN = 'unknown',
}
