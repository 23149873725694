import { RadioButton as RadioButtonIcon, Circle } from '@phosphor-icons/react';

type CheckboxButtonProps = {
  selected?: boolean;
  label?: string;
  size?: number;
  onClick?: () => void;
};

const DEFAULT_SVG_SIZE = 24;

export const RadioChoiceButton = ({
  size = DEFAULT_SVG_SIZE,
  selected = false,
  onClick,
}: CheckboxButtonProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <span onClick={handleClick}>
      {selected ? (
        <RadioButtonIcon size={size} color="currentColor" weight="fill" />
      ) : (
        <Circle size={size} />
      )}
    </span>
  );
};
