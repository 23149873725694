import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { AccordionItem } from './AccordionItem';

export type AccordionItemType = {
  key: string;
  title: string;
  content: string | JSX.Element;
  subtitle?: string | JSX.Element;
  icon?: JSX.Element;
  shouldHideSubtitle?: boolean;
  isDraggableContent?: boolean;
};

interface AccordionProps {
  items: AccordionItemType[];
  defaultActiveKey?: string | null;
  strictActiveKey?: string | null;
  className?: string;
}

export const Accordion = ({
  items,
  defaultActiveKey,
  strictActiveKey,
  className,
}: AccordionProps) => {
  const [activeKey, setActiveKey] = useState<null | string>(
    defaultActiveKey || null,
  );

  useEffect(() => {
    if (strictActiveKey) {
      setActiveKey(strictActiveKey);
    }
  }, [strictActiveKey]);

  const handleKeyChange = (key: string) => {
    if (key === activeKey) {
      setActiveKey(null);
    } else {
      setActiveKey(key);
    }
  };

  return (
    <div className={classNames('nj-accordion', className)}>
      {items.map(
        ({
          key,
          title,
          content,
          icon,
          subtitle,
          shouldHideSubtitle,
          isDraggableContent,
        }) => (
          <AccordionItem
            content={content}
            icon={icon}
            isOpened={key === activeKey}
            onChange={() => handleKeyChange(key)}
            shouldHideSubtitle
            subtitle={subtitle}
            title={title}
            value={key}
            key={key}
            isDraggableContent={isDraggableContent}
          />
        ),
      )}
    </div>
  );
};
