import {
  setIsDarkTheme,
  setIsTasksListExpanded,
  useAppDispatch,
  setIsSettingsPanelExpanded,
  setChatModeSidePanel,
} from 'src/store';
import { ChatModeSidePanel } from 'src/types';

export const useThemeActions = () => {
  const dispatch = useAppDispatch();

  const toggleTasksListExpanded = (value?: boolean) => {
    dispatch(setIsTasksListExpanded(value));
  };

  const toggleSettingsPanelExpanded = (value?: boolean) => {
    dispatch(setIsSettingsPanelExpanded(value));
  };

  const updateIsDarkTheme = (isDark: boolean) => {
    dispatch(setIsDarkTheme(isDark));
  };

  const updateChatModeSidePanel = (panel: ChatModeSidePanel) => {
    dispatch(setChatModeSidePanel(panel));
  };

  return {
    setIsDarkTheme: updateIsDarkTheme,
    toggleTasksListExpanded,
    toggleSettingsPanelExpanded,
    updateChatModeSidePanel,
  };
};
