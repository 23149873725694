import { useExternalModels, useSession } from 'src/hooks';
import { Icon } from 'src/components/Icon';
import {
  SVG_SIZE_M,
  availableForProLLMs,
  externalModelsSet,
} from 'src/constants';
import { ExternalModelGroup } from '../ExternalModelGroup';
import { ExternalModelItemProps } from '../ExternalModelItem';
import { ExternalModelSettingsOption, NinjaSubscriptionPlan } from 'src/types';
import { ModelSectionHeader } from '../ModelSectionHeader';
import { getDefaultModelIconName } from 'src/utils';

const ICON_PROPS = {
  size: SVG_SIZE_M,
};

interface TextModelListProps {
  isLimitedForPro: boolean;
  isLimitedForUltra: boolean;
  selectedTextModels: string[];
  onClick: (id: string) => void;
}

export const TextModelList = ({
  isLimitedForPro,
  isLimitedForUltra,
  selectedTextModels,
  onClick,
}: TextModelListProps) => {
  const { isProTier } = useSession();
  const {
    textExternalModels: { amazon_bedrock, openai, google },
  } = useExternalModels();

  const getAvailability = (model: string, isCapable?: boolean) => {
    if ((!isLimitedForPro && !isLimitedForUltra) || isCapable) {
      return;
    }

    if (isLimitedForPro && availableForProLLMs.includes(model)) {
      return NinjaSubscriptionPlan.PRO;
    }

    return NinjaSubscriptionPlan.ULTRA;
  };

  const getModels = (
    modelGroup?: ExternalModelSettingsOption[],
  ): ExternalModelItemProps[] =>
    modelGroup?.map(({ display_name, model_name, is_capable }) => {
      const icon =
        externalModelsSet[model_name]?.icon_name ||
        getDefaultModelIconName(model_name);

      return {
        id: model_name,
        icon: icon ? <Icon type={icon} {...ICON_PROPS} /> : <></>,
        title: display_name,
        selected: selectedTextModels.includes(model_name),
        availableIn: getAvailability(model_name, is_capable),
        onClick: () => onClick(model_name),
      };
    }) || [];

  const availableAmazonModels = getModels(amazon_bedrock).filter(
    (item) => !item.availableIn,
  );

  const disabledAmazonModels = getModels(amazon_bedrock).filter(
    (item) => !!item.availableIn,
  );

  const availableOpenAIModels = getModels(openai).filter(
    (item) => !item.availableIn,
  );

  const disabledOpenAIModels = getModels(openai).filter(
    (item) => !!item.availableIn,
  );

  const availableGoogleModels = getModels(google).filter(
    (item) => !item.availableIn,
  );

  const disabledGoogleModels = getModels(google).filter(
    (item) => !!item.availableIn,
  );

  return (
    <>
      <ModelSectionHeader
        title="Text models"
        selectedLength={selectedTextModels.length}
      />

      <ExternalModelGroup
        groupTitle={isProTier ? '' : 'Amazon Bedrock'}
        modelItems={availableAmazonModels}
      />

      <ExternalModelGroup
        groupTitle={isProTier ? '' : 'OpenAI'}
        modelItems={availableOpenAIModels}
      />

      <ExternalModelGroup
        groupTitle={isProTier ? '' : 'Google'}
        modelItems={availableGoogleModels}
      />

      <ExternalModelGroup
        groupTitle="Amazon Bedrock"
        modelItems={disabledAmazonModels}
      />

      <ExternalModelGroup
        groupTitle="OpenAI"
        modelItems={disabledOpenAIModels}
      />

      <ExternalModelGroup
        groupTitle="Google"
        modelItems={disabledGoogleModels}
      />
    </>
  );
};
