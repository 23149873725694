import styles from './InputError.module.scss';
import classNames from 'classnames';
import { ReactNode } from 'react';

export type ErrorRenderType = 'tooltip' | 'inline';

interface InputErrorProps {
  title: string | ReactNode;
  renderType?: ErrorRenderType;
}

export const InputError = ({
  title,
  renderType = 'tooltip',
}: InputErrorProps) => {
  return (
    <div
      className={classNames({
        [styles.tooltip]: renderType === 'tooltip',
        [styles.inline]: renderType === 'inline',
      })}
    >
      {title}
    </div>
  );
};
