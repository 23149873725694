import styles from './BasePublicPage.module.scss';
import { Header } from 'src/v2/pages/auth/components/Header';
import { Footer } from 'src/v2/pages/auth/components/Footer';
import { useRandomSignupBackgroundStyle } from 'src/hooks/useRandomSignupBackgroundStyle';
import { isEnterprise } from 'src/utils';
import { EnterpriseFooter } from 'src/v2/pages/auth/components/EnterpriseFooter';
import classNames from 'classnames';

interface BasePublicPageProps {
  seoTitle?: string;
  seoDescription?: string;
  alignContainer?: 'center' | 'top';
  children?: React.ReactNode;
}

export const BasePublicPage = ({
  seoTitle,
  seoDescription,
  children,
  alignContainer = 'top',
}: BasePublicPageProps) => {
  const { backgroundImageStyle } = useRandomSignupBackgroundStyle();

  return (
    <div className={styles.root} style={backgroundImageStyle}>
      <Header seoTitle={seoTitle} seoDescription={seoDescription} />
      <main className={classNames(styles.container, styles[alignContainer])}>
        {children}
      </main>
      {isEnterprise ? <EnterpriseFooter /> : <Footer />}
    </div>
  );
};
