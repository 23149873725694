import { ReactNode, RefObject } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './ImageEffectTransition.module.scss';

interface FilterTransitionProps {
  inProp: boolean;
  nodeRef: RefObject<HTMLElement>;
  children: ReactNode;
  timeout?: number;
  unmountOnExit?: boolean;
}

export const ImageEffectTransition = ({
  inProp,
  nodeRef,
  children,
  timeout = 1000,
  unmountOnExit = true,
}: FilterTransitionProps) => {
  return (
    <CSSTransition
      in={inProp}
      timeout={timeout}
      classNames={{
        enter: styles.filterEnter,
        enterActive: styles.filterEnterActive,
        enterDone: styles.filterEnterDone,
        exit: styles.filterExit,
        exitActive: styles.filterExitActive,
      }}
      nodeRef={nodeRef}
      unmountOnExit={unmountOnExit}
    >
      {children}
    </CSSTransition>
  );
};
