import { useColorTheme, useSession } from 'src/hooks';
import styles from './UpsellCard.module.scss';
import { SignupForFreeButton } from 'src/components/SignupForFreeButton';
import { UpgradeButton } from 'src/components/UpgradeButton';

interface UpsellCardProps {
  darkIcon: string;
  lightIcon: string;
  description: string;
  dataGTM: string;
  signUpTitle?: string;
  shouldShowUpgrade?: boolean;
  upgradeTitle?: string;
  additionalButton?: React.ReactNode;
}

export const UpsellCard = ({
  darkIcon,
  lightIcon,
  description,
  dataGTM,
  signUpTitle = 'Sign Up',
  shouldShowUpgrade = true,
  upgradeTitle = 'Upgrade',
  additionalButton,
}: UpsellCardProps) => {
  const { isDarkTheme } = useColorTheme();

  const { isOpenTier } = useSession();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <img src={isDarkTheme ? darkIcon : lightIcon} alt="Ninja" />

        <p className={styles.description}>{description}</p>
      </div>

      {isOpenTier && (
        <SignupForFreeButton
          dataGTM={`${dataGTM}-signup`}
          title={signUpTitle}
        />
      )}

      {!isOpenTier && shouldShowUpgrade && (
        <UpgradeButton dataGTM={`${dataGTM}-upgrade`} title={upgradeTitle} />
      )}

      {additionalButton}
    </div>
  );
};
