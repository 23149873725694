import { useColorTheme } from 'src/hooks';
import ninjaLight from 'src/images/icons/ninja-photo-light.svg';
import ninjaDark from 'src/images/icons/ninja-photo-dark.svg';

type NinjaLoaderProps = {
  content: string;
};

const IMG_SIZE = 160;

export const NinjaLoader = ({ content }: NinjaLoaderProps) => {
  const { isDarkTheme } = useColorTheme();

  return (
    <div className="nj-ninja-loader">
      <div className="nj-ninja-loader--images">
        <img
          src={isDarkTheme ? ninjaDark : ninjaLight}
          alt="Ninja"
          className="nj-ninja-loader--image"
          width={IMG_SIZE}
          height={IMG_SIZE}
        />

        <div className="nj-ninja-loader--spinner"></div>
      </div>
      <div className="nj-ninja-loader--content">{content}</div>
    </div>
  );
};
