import { SupportTicketRequest } from 'src/types/models/SupportTicketRequest';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseUsersV2Query } from 'src/store/services/config';

export const zendeskApi = createApi({
  reducerPath: 'zendeskApi',
  baseQuery: baseUsersV2Query,
  endpoints: (build) => ({
    sendSupportZendeskForm: build.mutation<
      void,
      { userId: string; supportRequest: SupportTicketRequest }
    >({
      query: ({ userId, supportRequest }) => ({
        url: `/${userId}/support_ticket`,
        method: 'POST',
        body: supportRequest,
      }),
    }),
  }),
});

export const { useSendSupportZendeskFormMutation } = zendeskApi;
