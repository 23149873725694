import { Frequency } from 'rrule';

export const DEFAULT_RECURRENT_INTERVAL = 1;
export const DEFAULT_RECURRENT_FREQUENCY = Frequency.WEEKLY;
export const DEFAULT_RECURRENT_OCCURRENCES = 12;
export const DEFAULT_ORGANIZER_AVAILABILITY = 'busy';

export const NO_VIDEO_CALL_VALUE = 'no_video_call';
export const NO_VIDEO_CALL_LABEL = 'No video call';

export const NO_ATTENDEES = 'No attendees';

export const DATE_RANGE_STRING_FORMAT = 'ddd, MMM D h:mmA';
export const DEFAULT_MINUTES_GAP = 15;
