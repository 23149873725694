import { useContext, useMemo } from 'react';
import AuthContext from 'src/contexts/AuthContext';
import { useSession } from 'src/hooks';
import './GreetingTitle.scss';

export const GreetingTitle = () => {
  const { isGuestAccess } = useContext(AuthContext);
  const { appUser } = useSession();

  const title = useMemo(
    () =>
      !isGuestAccess && appUser.first_name
        ? `Hi, ${appUser.first_name}!`
        : 'Hi! How can I help you?',
    [appUser.first_name, isGuestAccess],
  );

  return <h1 className="nj-greeting-title ellipsis">{title}</h1>;
};
