import { isObject, isString, isBoolean, isNumber } from './general.guard';
import { MatchMakerQueryResponse, AvatarQueue } from '../api.extended';
import {
  MetaHumanEvent,
  MetaHumanSpeechEvent,
  MetaHumanStreamingEvent,
  MetaHumanEventType,
} from '../avatar';
import log from 'src/utils/logger';

/**
 * isMetaHumanEventType() typeguards MetaHumanEventType.
 * @param data unknown
 * @returns boolean
 */
export function isMetaHumanEventType(
  data: unknown,
): data is MetaHumanEventType {
  return Object.values(MetaHumanEventType).includes(data as MetaHumanEventType);
}

/**
 * isMetaHumanStreamingEvent() typeguards MetaHumanStreamingEvent.
 * @param data unknown
 * @returns boolean
 */
export function isMetaHumanStreamingEvent(
  data: unknown,
): data is MetaHumanStreamingEvent {
  const event = data as MetaHumanStreamingEvent;

  if (!isObject(event)) {
    return false;
  }

  if (
    !(
      'type' in event &&
      (event.type === MetaHumanEventType.PLAY_STREAM_STATE ||
        event.type === MetaHumanEventType.AVATAR_STREAM_STATE)
    )
  ) {
    return false;
  }

  if ('state' in event && !isBoolean(event.state)) {
    log.debug(
      `isMetaHumanStreamingEvent > invalid state ${JSON.stringify(event)}`,
    );
    return false;
  }

  if ('message' in event && !isString(event.message)) {
    log.debug(
      `isMetaHumanStreamingEvent > invalid message ${JSON.stringify(event)}`,
    );
    return false;
  }

  return true;
}

/**
 * isMetaHumanSpeechEvent() typeguards MetaHumanSpeechEvent.
 * @param data unknown
 * @returns boolean
 */
export function isMetaHumanSpeechEvent(
  data: unknown,
): data is MetaHumanSpeechEvent {
  const event = data as MetaHumanSpeechEvent;

  if (!isObject(event)) {
    return false;
  }

  if (!('type' in event && event.type === MetaHumanEventType.AVATAR_EVENT)) {
    return false;
  }

  if ('silence' in event && !isBoolean(event.silence)) {
    log.debug(
      `isMetaHumanSpeechEvent > invalid silence ${JSON.stringify(event)}`,
    );
    return false;
  }

  if ('beginSpeech' in event && !isBoolean(event.beginSpeech)) {
    log.debug(
      `isMetaHumanSpeechEvent > invalid beginSpeech ${JSON.stringify(event)}`,
    );
    return false;
  }

  if ('sentTTSRequest' in event && !isBoolean(event.sentTTSRequest)) {
    log.debug(
      `isMetaHumanSpeechEvent > invalid sentTTSRequest ${JSON.stringify(
        event,
      )}`,
    );
    return false;
  }

  if ('text' in event && !isString(event.text)) {
    log.debug(`isMetaHumanSpeechEvent > invalid text ${JSON.stringify(event)}`);
    return false;
  }

  if ('provider' in event && !isString(event.provider)) {
    log.debug(
      `isMetaHumanSpeechEvent > invalid provider ${JSON.stringify(event)}`,
    );
    return false;
  }

  return true;
}

/**
 * isMetaHumanEvent() typeguards MetaHumanEvent.
 * @param data unknown
 * @returns boolean
 */
export function isMetaHumanEvent(data: unknown): data is MetaHumanEvent {
  return isMetaHumanSpeechEvent(data) || isMetaHumanStreamingEvent(data);
}

/**
 * isMatchMakerQueryResponse typeguards matchmaker query response.
 * @param data unknown
 * @returns boolean
 */
export function isMatchMakerQueryResponse(
  data: unknown,
): data is MatchMakerQueryResponse {
  const response = data as MatchMakerQueryResponse;

  if (!isObject(response)) {
    return false;
  }

  if ('signallingServer' in response && !isString(response.signallingServer)) {
    return false;
  }

  if ('userID' in response && !isString(response.userID)) {
    return false;
  }

  if ('available' in response && !isBoolean(response.available)) {
    return false;
  }

  if ('invalidRequest' in response && !isBoolean(response.invalidRequest)) {
    return false;
  }

  if ('positionInQueue' in response && !isNumber(response.positionInQueue)) {
    return false;
  }

  if (
    'expectedWaitingTime' in response &&
    !isNumber(response.expectedWaitingTime)
  ) {
    return false;
  }

  if ('message' in response && !isString(response.message)) {
    return false;
  }

  if ('messageID' in response && !isString(response.messageID)) {
    return false;
  }

  if ('messageType' in response && !isString(response.messageType)) {
    return false;
  }

  return true;
}

/**
 * isAvatarQueue typeguards avatar queue.
 * @param data unknown
 * @returns boolean
 */
export function isAvatarQueue(data: unknown): data is AvatarQueue {
  const queue = data as AvatarQueue;

  if (!isMatchMakerQueryResponse(queue)) {
    return false;
  }

  if ('joinedAvatarQueue' in queue && !isBoolean(queue.joinedAvatarQueue)) {
    return false;
  }

  if ('step' in queue && !isNumber(queue.step)) {
    return false;
  }

  if ('ready' in queue && !isBoolean(queue.ready)) {
    return false;
  }

  return true;
}
