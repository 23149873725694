/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum MessageChannel {
  CE = 'ce',
  EMAIL = 'email',
  SMS = 'sms',
  WEB_APP = 'web_app',
}
