import { useMemo, useContext } from 'react';
import {
  Check,
  XCircle,
  PauseCircle,
  Warning,
  Prohibit,
} from '@phosphor-icons/react';
import SessionContext from 'src/contexts/SessionContext';
import { ChatsFilter } from 'src/types';
import { useTaskById } from 'src/hooks';
import { InProgressIcon } from 'src/components/InProgressIcon';

const ICON_PROPS = {
  size: 16,
  color: 'currentColor',
};

export const FilteringChip = () => {
  const { removeCurrentTaskId } = useTaskById();
  const { chatsFilter, setChatsFilter } = useContext(SessionContext);

  const handleResetConversationsList = () => {
    setChatsFilter(ChatsFilter.ALL);
  };

  // all + all & archived will not have icons
  const chipIcon = useMemo(() => {
    switch (chatsFilter) {
      case ChatsFilter.STOPPED:
        return <Prohibit {...ICON_PROPS} />;
      case ChatsFilter.FAILED:
        return <Warning {...ICON_PROPS} />;
      case ChatsFilter.IN_PROGRESS:
        return <InProgressIcon {...ICON_PROPS} />;
      case ChatsFilter.DONE:
        return <Check {...ICON_PROPS} />;
      case ChatsFilter.BLOCKED:
        return <PauseCircle {...ICON_PROPS} />;
      default:
        return null;
    }
  }, [chatsFilter]);

  return chatsFilter !== ChatsFilter.ALL ? (
    <div className="nj-filtering-chips" onClick={removeCurrentTaskId}>
      <span className="nj-filtering-chip">
        <span>{chipIcon}</span>
        <span>{chatsFilter}</span>
        <span onClick={handleResetConversationsList}>
          <XCircle {...ICON_PROPS} />
        </span>
      </span>
    </div>
  ) : null;
};
