/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ImageGenStyles {
  NONE = 'none',
  CINEMATIC = 'cinematic',
  PHOTOGRAPHIC = 'photographic',
  ANIME = 'anime',
  MANGA = 'manga',
  DIGITAL_ART = 'digital_art',
  PIXEL_ART = 'pixel_art',
  FANTASY_ART = 'fantasy_art',
  NEONPUNK = 'neonpunk',
  MODEL_3D = 'model_3d',
}
