import { DefaultStaticField } from '../DefaultStaticField';
import { SingleSelectOption } from 'src/types';
import { TitleField } from '../TitleField';
import { LocationVideoField } from '../LocationVideoField';
import { EventInfoDefaultVaue } from '../EventInfoDefaultVaue';

interface EventInfoFieldProps {
  conferenceOptions: Array<SingleSelectOption>;
}

export const EventInfoField = ({ conferenceOptions }: EventInfoFieldProps) => {
  return (
    <DefaultStaticField
      label="Event info"
      defaultValue={
        <EventInfoDefaultVaue conferenceOptions={conferenceOptions} />
      }
    >
      <>
        <TitleField />
        <LocationVideoField conferenceOptions={conferenceOptions} />
      </>
    </DefaultStaticField>
  );
};
