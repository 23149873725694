type ProposalTimeSlotLabelProps = {
  label: string;
  sublabel?: string;
};

export const ProposalTimeSlotLabel = ({
  label,
  sublabel,
}: ProposalTimeSlotLabelProps) => {
  return (
    <div className="nj-task-view--form--label-wrapper">
      <label className="nj-task-view--form--label">{label}</label>
      <span className="nj-task-view--form--sublabel">{sublabel}</span>
    </div>
  );
};
