interface LabelTeamsUltraProps {
  labelType?: 'short' | 'full';
}

export const LabelTeamsUltra = ({
  labelType = 'full',
}: LabelTeamsUltraProps) => {
  return (
    <label className="nj-label-ultra">
      {labelType === 'short' ? 'Team Ultra' : 'Team Ultra subscription'}
    </label>
  );
};
