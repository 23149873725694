import styles from './BannerPagination.module.scss';
import {
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
} from '@phosphor-icons/react';
import { useBanner } from 'src/hooks/useBanner';
import { SVG_SIZE_M } from 'src/constants';
import { useBreakpoint } from 'src/hooks';

export const BannerPagination = () => {
  const { isMobile } = useBreakpoint();
  const { currentBannerIndex, bannersSet, changeCurrentBannerIndex } =
    useBanner();
  return (
    <div className={styles.root}>
      {isMobile ? (
        <>
          <button
            type="button"
            className={styles.arrowButton}
            disabled={currentBannerIndex === 0}
            onClick={() => changeCurrentBannerIndex(currentBannerIndex - 1)}
          >
            <CaretUp size={SVG_SIZE_M} />
          </button>
          <button
            type="button"
            className={styles.arrowButton}
            disabled={currentBannerIndex + 1 === bannersSet.length}
            onClick={() => changeCurrentBannerIndex(currentBannerIndex + 1)}
          >
            <CaretDown size={SVG_SIZE_M} />
          </button>
        </>
      ) : (
        <>
          <button
            type="button"
            className={styles.arrowButton}
            disabled={currentBannerIndex === 0}
            onClick={() => changeCurrentBannerIndex(currentBannerIndex - 1)}
          >
            <CaretLeft size={SVG_SIZE_M} />
          </button>
          <span>
            {currentBannerIndex + 1}/{bannersSet.length}
          </span>
          <button
            type="button"
            className={styles.arrowButton}
            disabled={currentBannerIndex + 1 === bannersSet.length}
            onClick={() => changeCurrentBannerIndex(currentBannerIndex + 1)}
          >
            <CaretRight size={SVG_SIZE_M} />
          </button>
        </>
      )}
    </div>
  );
};
