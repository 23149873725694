import { useContext, useImperativeHandle } from 'react';
import AudioContext from 'src/contexts/AudioContext';
import { StopCircle } from '@phosphor-icons/react';
import { ChatMode } from 'src/types';
import { useSession, useThreadActions, useConversationParams } from 'src/hooks';
import { interruptMetahuman } from 'src/utils';

const SVG_SIZE = 24;

export const StopResponseButton = () => {
  const { stopResponseTriggerRef } = useContext(AudioContext);
  const { chatMode, appUser } = useSession();
  const { updateThreadStatus } = useThreadActions();
  const { currentConversationId } = useConversationParams();

  const handleClickStop = () => {
    if (!currentConversationId) {
      return;
    }

    updateThreadStatus({
      threadId: currentConversationId,
      statusRegistry: { ignoreMessages: true, isSubmitHappened: false },
    });

    if (chatMode === ChatMode.AVATAR) {
      interruptMetahuman(appUser.user_id, false);
    }
  };

  useImperativeHandle(stopResponseTriggerRef, () => ({
    stopResponse: handleClickStop,
  }));

  return (
    <button
      data-e2e="stop-response-button"
      onClick={handleClickStop}
      className="nj-chat-form--stop-button"
    >
      <StopCircle size={SVG_SIZE} weight="fill" />
      <div className="nj-chat-form--stop-button-spinner" />
    </button>
  );
};
