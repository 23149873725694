import React, { useEffect, useRef } from 'react';
import styles from './TextList.module.scss';
import cn from 'classnames';
import { Circle } from '@phosphor-icons/react';
import { ANIMATION_TIMEOUT } from 'src/constants';

interface TextListProps {
  values: { [key: string]: string }; // Dictionary of values (id: name)
  onValueSelect: (id: string) => void; // Callback when a language is selected
  selectedLanguage: string; // Currently selected language id
  defaultValue?: string; // Optional default value id for display
}

export const TextList: React.FC<TextListProps> = React.memo(
  ({ values, onValueSelect, selectedLanguage, defaultValue }) => {
    const selectedItemRef = useRef<HTMLLIElement | null>(null);

    const handleClick = (id: string) => {
      if (selectedLanguage !== id) {
        onValueSelect(id);
      }
    };

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (selectedItemRef.current) {
          selectedItemRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }
      }, ANIMATION_TIMEOUT + 50);

      return () => {
        clearInterval(timeout);
      };
    }, [selectedLanguage]);

    return (
      <ul className={styles.list}>
        {Object.entries(values).map(([id, name]) => (
          <li
            key={id}
            ref={selectedLanguage === id ? selectedItemRef : null}
            onClick={() => handleClick(id)}
            className={cn(styles.listItem, {
              [styles.selected]: selectedLanguage === id,
            })}
          >
            <span className={styles.title}>{name}</span>

            {id === defaultValue && (
              <span className={styles.defaultLabel}>Default</span>
            )}

            {selectedLanguage === id && <Circle size={8} weight="fill" />}
          </li>
        ))}
      </ul>
    );
  },
);
