import { useForm } from 'react-hook-form';
import {
  TextInput,
  Message,
  CommonInputTypes,
  TextInputCard,
  isInputTypes,
} from 'src/types';
import { FormProvider } from 'src/components/FormProvider';
import { FormInput } from 'src/components/FormInput';
import { Button } from 'src/components/Button';
import { useUpdateTaskMessageByPDU } from 'src/hooks';

type TextInputFieldsGroupProps = {
  id: string;
  message: Message;
  fields: Array<TextInput>;
};

type TextInputFieldsGroupData = {
  [key: string]: string;
};

enum TextInputCardNotifications {
  SUBMISSION_SUCCESS = 'Email submission was successful.',
  SUBMISSION_FAILED = 'Email submission failed.',
}

export const TextInputFieldsGroup = ({
  id,
  message,
  fields = [],
}: TextInputFieldsGroupProps) => {
  const { onSubmit } = useUpdateTaskMessageByPDU({
    successMessage: TextInputCardNotifications.SUBMISSION_SUCCESS,
    errorMessage: TextInputCardNotifications.SUBMISSION_FAILED,
  });

  const defaultValues = fields.reduce(
    (
      acc: TextInputFieldsGroupData,
      { type = CommonInputTypes.TEXT, value = '' }: TextInput,
      index: number,
    ) => {
      return { ...acc, [`${type}_${index}`]: value };
    },
    {},
  );

  const methods = useForm<TextInputFieldsGroupData>({
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const handleFormSubmit = (data: TextInputFieldsGroupData) => {
    const updatedFields = fields.map(
      (field: TextInput, index: number): TextInput => {
        const { type = CommonInputTypes.TEXT } = field;
        return { ...field, value: data[`${type}_${index}`] };
      },
    );

    const messageToSend: Message = {
      ...message,
      payload: {
        ...message.payload,
        questions: updatedFields,
      } as TextInputCard,
    };

    onSubmit(messageToSend);
  };

  if (fields.length === 0) {
    return null;
  }

  return (
    <FormProvider<TextInputFieldsGroupData> methods={methods}>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="nj-task-view--form"
        autoComplete="off"
      >
        {fields.map(
          (
            { type = CommonInputTypes.TEXT, label, mandatory }: TextInput,
            index: number,
          ) => (
            <div
              key={`text-input-${id}-${index}`}
              className="nj-task-view--form--default-static-field"
            >
              <div className="nj-task-view--form--field">
                <div className="nj-task-view--form--label-wrapper">
                  <label className="nj-task-view--form--label">
                    {label && `${label}'s`} Email
                  </label>
                </div>
                <FormInput
                  type={isInputTypes(type) ? type : 'text'}
                  name={`${type}_${index}`}
                  placeholder={`Add ${type}`}
                  className="nj-task-view--form--input"
                  required={mandatory}
                />
              </div>
            </div>
          ),
        )}
        <div className="nj-task-view--form--field center">
          <Button
            type="submit"
            className="nj-button nj-button--primary"
            disabled={!isValid}
          >
            Submit
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
