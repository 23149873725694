import styles from './MeetNinjaSection.module.scss';

type MeetNinjaSectionProps = {
  isSignup: boolean;
};

export const MeetNinjaSection = ({ isSignup }: MeetNinjaSectionProps) => {
  return (
    <div className={styles.root}>
      <h1 className={styles.meetNinja}>Meet Ninja</h1>
      <h4 className={styles.productivity}>
        Your All-In-One AI Agent for Unlimited Productivity
      </h4>
      {isSignup && (
        <h2 className={styles.join}>
          Join <span className={styles.highlighted}>1.5M</span> users saving
          time and money
        </h2>
      )}
    </div>
  );
};
