import { useRef } from 'react';
import classNames from 'classnames';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { FadeTransition } from 'src/animations/FadeTransition';
import styles from './TabArrow.module.scss';
import { Button } from 'src/v2/commonComponents/Button';

interface TabArrowProps {
  direction: 'prev' | 'next';
  visible: boolean;
  onClick: () => void;
}

export const TabArrow = ({ direction, visible, onClick }: TabArrowProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const isPrev = direction === 'prev';

  return (
    <FadeTransition nodeRef={buttonRef} inProp={visible}>
      <Button
        color="transparent"
        ref={buttonRef}
        className={classNames(styles.root, {
          [styles.prev]: isPrev,
          [styles.next]: !isPrev,
        })}
        onClick={onClick}
      >
        {isPrev ? (
          <CaretLeft size={SVG_SIZE_M} />
        ) : (
          <CaretRight size={SVG_SIZE_M} />
        )}
      </Button>
    </FadeTransition>
  );
};
