import { Modal } from 'src/components/Modal';
import styles from './InviteUsersModal.module.scss';
import { InviteUsersFileInputId } from 'src/constants';
import { ChangeEvent, useContext, useState } from 'react';
import log from 'src/utils/logger';
import { InviteUsersModalStep } from 'src/v2/components/InviteUsersModalStep';
import { FileWithInvitedUsersStep } from 'src/v2/components/FileWithInvitedUsersStep';
import { ConfirmInvitedUsersStep } from 'src/v2/components/ConfirmInvitedUsersStep';
import SessionContext from 'src/contexts/SessionContext';

export const InviteUsersModal = () => {
  const { isOpenInviteUsersModal, onChangeIsOpenInviteUsersModal } =
    useContext(SessionContext);
  const [fileWithEmails, setFileWithEmails] = useState<File | null>(null);
  const [listOfEmails, setListOfEmails] = useState<string[]>([]);
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [isConfirmationStep, setIsConfirmationStep] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const handleUploadClick = () => {
    document.getElementById(InviteUsersFileInputId)?.click();
  };

  const handleDeleteFile = () => {
    if (!!fileWithEmails) {
      setFileWithEmails(null);
      setListOfEmails([]);
    }
    setIsFileLoading(false);
  };

  const handleCancelClick = () => {
    handleDeleteFile();
    setListOfEmails([]);
    setInputValue('');
    onChangeIsOpenInviteUsersModal(false);
  };

  const handleClose = () => {
    setIsConfirmationStep(false);
    handleCancelClick();
  };

  const handleContinueClick = () => {
    setIsConfirmationStep(true);
  };

  const handleChangeInputValue = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.currentTarget.value);
  };

  const handleInviteInputEmails = () => {
    const emails = inputValue.split(',');
    setListOfEmails(emails);
    setIsConfirmationStep(true);
  };

  const handleGoBackClick = () => {
    setListOfEmails([]);
    setIsConfirmationStep(false);
  };

  const handleFileSelect = (event: ChangeEvent) => {
    const files = ((event.target as HTMLInputElement)?.files || []) as FileList;
    const file = files[0];
    if (file) {
      setIsFileLoading(true);
      setFileWithEmails(file);
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>): void => {
        try {
          const text = event.target?.result as string;
          const lines = text.split('\n');
          const parsedEmails = lines
            .map((line) => line.replace(/"/g, '').trim())
            .filter(Boolean);

          setListOfEmails(parsedEmails);

          setIsFileLoading(false);
          const fileInput = document.getElementById(
            InviteUsersFileInputId,
          ) as HTMLInputElement;
          if (fileInput) {
            fileInput.value = '';
            fileInput.files = null;
          }
        } catch (err) {
          log.error(err, 'Error parsing file');
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <Modal
      open={isOpenInviteUsersModal}
      onClose={handleClose}
      className={styles.inviteUsersModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
    >
      {!listOfEmails.length && (
        <InviteUsersModalStep
          onClose={handleClose}
          onFileSelect={handleFileSelect}
          onUploadFile={handleUploadClick}
          inputValue={inputValue}
          onInputChange={handleChangeInputValue}
          onInviteInputEmails={handleInviteInputEmails}
        />
      )}
      {!!fileWithEmails && !isConfirmationStep && (
        <FileWithInvitedUsersStep
          file={fileWithEmails}
          isLoading={isFileLoading}
          onDeleteFile={handleDeleteFile}
          onClose={handleCancelClick}
          onContinue={handleContinueClick}
        />
      )}

      {isConfirmationStep && (
        <ConfirmInvitedUsersStep
          onGoBack={handleGoBackClick}
          onClose={handleClose}
          listOfEmails={listOfEmails}
        />
      )}
    </Modal>
  );
};
