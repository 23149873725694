import { useEffect } from 'react';
import { useCollapse } from 'react-collapsed';
import { MessageContext, ConversationRole } from 'src/types';
import { ContextCardItem } from '../ContextCardItem';

const MAX_ROWS = 5;

interface ContextCardProps {
  messagesContext: MessageContext[];
}

export const ContextCard = ({ messagesContext }: ContextCardProps) => {
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse();

  useEffect(() => {
    if (messagesContext) {
      setExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesContext]);

  if (!messagesContext || messagesContext.length === 0) {
    return null;
  }

  return (
    <div className="nj-task-scratchpad--section nj-task-scratchpad--context">
      <h5 className="nj-task-scratchpad--context-title">Context</h5>

      <ul className="nj-task-scratchpad--context-list">
        {messagesContext
          .slice(0, MAX_ROWS)
          .map(({ message_id, content, role }) => (
            <ContextCardItem
              key={message_id}
              content={content}
              //TODO(olha): waiting fix by BE
              role={role as ConversationRole}
            />
          ))}
      </ul>

      {messagesContext.length > MAX_ROWS && (
        <>
          <ul
            className="nj-task-scratchpad--context-list"
            {...getCollapseProps()}
          >
            {messagesContext
              .slice(MAX_ROWS)
              .map(({ message_id, content, role }) => (
                <ContextCardItem
                  key={message_id}
                  content={content}
                  //TODO(olha): waiting fix by BE
                  role={role as ConversationRole}
                />
              ))}
          </ul>

          <button
            className="nj-task-scratchpad--context-collapse-button"
            {...getToggleProps()}
          >
            {isExpanded ? 'View less' : 'View more'}
          </button>
        </>
      )}
    </div>
  );
};
