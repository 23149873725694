import {
  isMessageWithCreationTaskId,
  isMessageWithImageGenTask,
  Message,
} from 'src/types';
import { MessageWithTaskProgress } from '../MessageWithTaskProgress';
import styles from './MessageProgress.module.scss';

const DEFAULT_TEXT = 'Thinking...';

interface MessageProgressProps {
  message: Message;
}

export const MessageProgress = ({ message }: MessageProgressProps) => {
  if (!message.payload) {
    return <p className={styles.text}>{DEFAULT_TEXT}</p>;
  }

  if (
    !isMessageWithCreationTaskId(message) &&
    !isMessageWithImageGenTask(message)
  ) {
    return null;
  }

  return (
    <MessageWithTaskProgress
      message_type={message.message_type}
      payload={message.payload}
    />
  );
};
