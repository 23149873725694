import { useTimeZone } from 'src/hooks';
import dayjs from 'dayjs';

export const useTimezoneOffsets = () => {
  const { userTimeZone } = useTimeZone();
  const browserTimeZone = dayjs.tz.guess();

  const userTimezoneOffsetMinutes = dayjs().tz(userTimeZone).utcOffset();
  const browserTimezoneOffsetMinutes = dayjs().tz(browserTimeZone).utcOffset();

  const hoursDifferenceToUserTZ =
    (browserTimezoneOffsetMinutes - userTimezoneOffsetMinutes) / 60;

  return { hoursDifferenceToUserTZ };
};
