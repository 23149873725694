import styles from './FileWithInvitedUsersStep.module.scss';
import { Button } from 'src/v2/commonComponents/Button';
import { FileCsv, Trash } from '@phosphor-icons/react';
import { SVG_SIZE_L, SVG_SIZE_XL } from 'src/constants';
import { formatFileSize } from 'src/utils';
import { SpinnerSimple } from 'src/v2/commonComponents/SpinnerSimple';

type FileWithInvitedUsersStepProps = {
  file: File;
  isLoading: boolean;
  onDeleteFile: () => void;
  onClose: () => void;
  onContinue: () => void;
};

export const FileWithInvitedUsersStep = ({
  file,
  isLoading,
  onDeleteFile,
  onClose,
  onContinue,
}: FileWithInvitedUsersStepProps) => {
  return (
    <div className={styles.root}>
      <h4 className={styles.title}>Invite users</h4>
      <div className={styles.fileRow}>
        <div className={styles.fileNameWrapper}>
          {isLoading ? (
            <SpinnerSimple size={SVG_SIZE_XL} />
          ) : (
            <FileCsv size={SVG_SIZE_XL} />
          )}
          <div className={styles.fileInfo}>
            <span className={styles.fileName}>{file.name}</span>
            <span className={styles.fileSize}>{formatFileSize(file.size)}</span>
          </div>
        </div>
        <Button onClick={onDeleteFile} color="transparent">
          <Trash size={SVG_SIZE_L} />
        </Button>
      </div>
      <div className={styles.buttonRow}>
        <Button
          color="transparent"
          onClick={onClose}
          className={styles.cancelButton}
        >
          Cancel
        </Button>
        <Button onClick={onContinue} disabled={isLoading}>
          Continue
        </Button>
      </div>
    </div>
  );
};
