import { get_access_token } from 'src/utils';
import { useEffect, useState } from 'react';
import { useSession } from 'src/hooks';

export const useGetAvatarURL = () => {
  const [avatarURL, setAvatarURL] = useState<string | undefined>();
  const { appUser, avatarQueue } = useSession();

  // attn: only use post message and do not append real-time parameters to the URL
  useEffect(() => {
    const buildIframeURL = async () => {
      const token = await get_access_token();

      const URL = avatarQueue?.signallingServer
        ? `https://${avatarQueue.signallingServer}/` +
          `?hide_ui=true` +
          `&fullscreen=true` +
          `&hideMic=true` +
          `&userID=${appUser.user_id}` +
          `&token=${token}` +
          `&avatar_name=${appUser.avatar}` +
          `&avatar_id=${appUser.persona?.avatar_id}` +
          `&voice_id=${appUser.settings?.video_language_setting?.voice_id}` +
          `&environment=${appUser.settings?.theme_setting?.environment}` +
          `&background_color=${encodeURIComponent(
            appUser.settings?.theme_setting?.background_hex_color || '',
          )}` +
          `&camera_index=${appUser.settings?.camera_angle_setting?.camera_index}` +
          `&cinematic_mode=${appUser.settings?.camera_angle_setting?.cinematic_mode}`
        : '';

      if (URL && avatarURL !== URL) {
        setAvatarURL(URL);
      }
    };

    if (avatarQueue?.available && avatarQueue?.signallingServer) {
      buildIframeURL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarQueue?.available, avatarQueue?.signallingServer]);

  return { avatarURL };
};
