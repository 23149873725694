import { useMemo } from 'react';
import { useMessageTab, useSession, useExternalModels } from 'src/hooks';
import {
  ChitChatCard,
  TaskSkill,
  MessageTabs,
  MessageTabData,
  GTMEvent,
} from 'src/types';
import { prepareExternalModelsTabContent, sendGTMEvent } from 'src/utils';
import { Tabs, TabPanel } from 'src/v2/commonComponents/Tabs';
import { renderMessageTabList } from '../MessageTabList';
import styles from './ThreadChitChatCard.module.scss';

// TODO(olha): needs refactoring
import { ThreadMessageContent } from 'src/components/FlatAppearance/components/ThreadMessageContent';
import { UpgradeButton } from 'src/components/UpgradeButton';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';
import { SuperAgentUpsell } from '../SuperAgentUpsell';
import { SUPER_AGENT_AS_EXTERNAL_MODEL } from 'src/constants';
import { FeedbackRating } from 'src/v2/components/FeedbackRating';

interface ThreadChatCardProps {
  messageId?: string;
  chitChatCard: ChitChatCard;
  isStreaming?: boolean;
}

const TEXT_MAX_SIZE = 150;

export const ThreadChitChatCard = ({
  messageId,
  chitChatCard,
  isStreaming,
}: ThreadChatCardProps) => {
  const {
    isLimitedTier,
    appUser: { settings },
  } = useSession();

  const { textExternalModels } = useExternalModels();

  const { data } = chitChatCard;
  const { ninja_model_output, referenced_model_summary, skill } = data || {};

  const { references: external_models_result } = referenced_model_summary || {};

  const tabContent = useMemo(
    () =>
      external_models_result
        ? prepareExternalModelsTabContent(external_models_result)
        : [],
    [external_models_result],
  );

  const withSuperAgent = useMemo(
    () =>
      tabContent.some(({ tabName }) =>
        tabName.includes(SUPER_AGENT_AS_EXTERNAL_MODEL),
      ),
    [tabContent],
  );

  const tabsData: MessageTabData[] = useMemo(
    () => [
      ...(ninja_model_output
        ? [
            {
              tabName: MessageTabs.SEARCH_RESULT,
              iconName: 'ninjaOutlined',
              tabTitle: 'Ninja 405B',
              content: ninja_model_output || '',
            },
          ]
        : []),
      ...(!withSuperAgent && !settings?.super_agent?.is_capable
        ? [
            {
              tabName: MessageTabs.DEEP_RESEARCH,
              iconName: 'ninjaPixProLogo',
              tabTitle: 'Ninja SuperAgent',
              content: '',
            },
          ]
        : []),
      ...tabContent,
    ],
    [
      ninja_model_output,
      tabContent,
      settings?.super_agent?.is_capable,
      withSuperAgent,
    ],
  );

  const { getTabSRegistry, onTabClick } = useMessageTab({
    messageId,
    tabsData,
  });

  const handleTabClick = (tabName: string) => {
    onTabClick(tabName);

    if (tabName === MessageTabs.DEEP_RESEARCH) {
      sendGTMEvent(GTMEvent.SUPERAGENT_UPSELL_TAB_CLICK);
      return;
    }

    if (withSuperAgent) {
      tabContent[0]?.content
        ? sendGTMEvent(GTMEvent.SUPERAGENT_COMPLETE_CLICK)
        : sendGTMEvent(GTMEvent.SUPERAGENT_IN_PROGRESS_CLICK);
    }
  };

  if (skill === TaskSkill.DOCUMENT_QA && isLimitedTier) {
    if (ninja_model_output) {
      const trimmedContent = ninja_model_output.replace(/^\n+|\n+$/g, '');
      const isLongText = trimmedContent.length > TEXT_MAX_SIZE;
      const slicedContent = `${trimmedContent.slice(0, TEXT_MAX_SIZE).trim()}...`;
      return (
        <div>
          <div className={styles.contentWrapper}>
            <ThreadMessageContent
              content={slicedContent}
              isStreaming={isStreaming}
            />
            {isLongText && <div className={styles.blurOverlay} />}
          </div>
          {isLongText && (
            <div className={styles.upsellBlockWrapper}>
              <span className={styles.upsellBlockText}>
                Upgrade to Pro to reveal the answer and include your file(s) in
                follow-up conversations.
              </span>
              <UpgradeButton dataGTM="File-agent-response-upgrade" />
            </div>
          )}
        </div>
      );
    }

    return null;
  }

  return (
    <div className={styles.root}>
      <Tabs<string>
        defaultActiveTab={MessageTabs.SEARCH_RESULT}
        onTabClick={handleTabClick}
      >
        {renderMessageTabList({
          data: tabsData,
          withSuperAgent,
          tabRegistry: getTabSRegistry(),
          externalModels: textExternalModels,
        })}

        {tabsData.map(({ tabName, content }) => {
          const isSuperAgentTab =
            tabName.includes(SUPER_AGENT_AS_EXTERNAL_MODEL) ||
            tabName === MessageTabs.DEEP_RESEARCH;

          return (
            <TabPanel key={tabName} tab={tabName}>
              {content.length === 0 ? (
                // TODO(olha): create a common component
                <>
                  {(!isSuperAgentTab || withSuperAgent) && (
                    <ProgressBar count={3} />
                  )}

                  {isSuperAgentTab && (
                    <SuperAgentUpsell description="An advanced agent that dives deep into your questions, leveraging relevant sources to deliver more accurate and richer answers." />
                  )}
                </>
              ) : (
                <div className={styles.contentWrapper}>
                  <ThreadMessageContent
                    content={content}
                    isStreaming={isStreaming}
                  />

                  {isSuperAgentTab && !isStreaming && (
                    <FeedbackRating messageId={messageId} />
                  )}
                </div>
              )}
            </TabPanel>
          );
        })}
      </Tabs>
    </div>
  );
};
