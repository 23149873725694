import styles from './EnterpriseFooter.module.scss';

export const EnterpriseFooter = () => {
  return (
    <div className={styles.root}>
      <h4 className={styles.awsHeading}>
        Alexa Fund Portfolio Company | AWS Official Partner
      </h4>
    </div>
  );
};
