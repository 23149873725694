import { createApi } from '@reduxjs/toolkit/query/react';
import { baseTokenQuery } from './config';
import { addSourcesToGTMDataLayer } from 'src/utils';
import { CALENDAR_URL, CONTACTS_URL } from 'src/constants';
import log from 'src/utils/logger';

export enum TokenTags {
  Token = 'Token',
}

export const tokenApi = createApi({
  reducerPath: 'tokenApi',
  tagTypes: [TokenTags.Token],
  baseQuery: baseTokenQuery,
  endpoints: (builder) => ({
    getGoogleResourcesAccess: builder.query<{ scopes: string[] }, string>({
      query: (userId) => `/scopes?user_id=${userId}&provider_id=Google`,
      providesTags: () => [TokenTags.Token],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const calendar_access = data.scopes.includes(CALENDAR_URL);
          const contacts_access = data.scopes.includes(CONTACTS_URL);

          addSourcesToGTMDataLayer({
            calendar_access,
            contacts_access,
          });
        } catch (error) {
          log.error('Failed to fetch Google resources access:', error);
        }
      },
    }),
    revokeGoogleResourcesAccess: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/revoke?user_id=${userId}&provider_id=Google`,
        method: `GET`,
      }),
      invalidatesTags: (result, error, arg) => [TokenTags.Token],
    }),
    getGoogleCalendarToken: builder.query<
      {
        access_token: string;
        expired: string;
      },
      string
    >({
      query: (userId) =>
        `/?user_id=${userId}&provider_id=Google&scopes=calendar`,
      providesTags: () => [TokenTags.Token],
    }),
  }),
});

export const {
  useGetGoogleResourcesAccessQuery,
  useRevokeGoogleResourcesAccessMutation,
  useGetGoogleCalendarTokenQuery,
} = tokenApi;
