import styles from './UpgradeDowngradeSummarySection.module.scss';
import { SubscriptionPlan } from 'src/types';
import { CollapsedItem } from 'src/components/CollapsedItem';
import { SummaryHeader } from '../SummaryHeader';
import { SummaryContent } from '../SummaryContent';

type UpgradeDowngradeSummarySectionProps = {
  selectedPlan?: SubscriptionPlan;
};

export const UpgradeDowngradeSummarySection = ({
  selectedPlan,
}: UpgradeDowngradeSummarySectionProps) => {
  const isDowngrade = selectedPlan?.total_due_amount === 0;
  const header = (
    <SummaryHeader
      selectedPlan={selectedPlan}
      originalPrice={selectedPlan?.total_recurring_amount}
      dueToday={selectedPlan?.total_due_amount}
      additionalDescription=", starting next billing cycle"
    />
  );

  const content = (
    <SummaryContent
      selectedPlan={selectedPlan}
      totalOriginalAmount={selectedPlan?.total_recurring_amount || 0}
      subtotal={selectedPlan?.total_recurring_amount || 0}
      taxes={0}
      dueToday={selectedPlan?.total_due_amount || 0}
      discount={selectedPlan?.prorated_discount_amount}
    />
  );

  return (
    <div className={styles.root}>
      {isDowngrade ? (
        <div className={styles.header}>{header}</div>
      ) : (
        <CollapsedItem
          title=""
          header={header}
          content={content}
          isExpanded={false}
          headerClassName={styles.collapsedItem}
        />
      )}
    </div>
  );
};
