interface LabelTeamsStandardProps {
  labelType?: 'short' | 'full';
}

export const LabelTeamsStandard = ({
  labelType = 'full',
}: LabelTeamsStandardProps) => {
  return (
    <label className="nj-label-standard">
      {labelType === 'short' ? 'Team Standard' : 'Team Standard subscription'}
    </label>
  );
};
