import { useFormContext, useFieldArray } from 'react-hook-form';
import { AddTimeframeButton } from './components/AddTimeframeButton';
import { MeetingCardFormData } from '../../CreateMeetingCard';
import { MeetingCardField } from '../MeetingCardField';
import { MeetingDateRangePicker } from './components/MeetingDateRangePicker';

export const MeetingWindowsField = () => {
  const { control } = useFormContext<MeetingCardFormData>();
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'meeting_windows',
  });

  return (
    <MeetingCardField
      className="nj-create-meeting-card--meeting-window"
      label={
        <>
          <span>Schedule event between</span>
          <span className="nj-create-meeting-card--sublabel">
            Ninja will find a suitable meeting time that accommodates all
            attendees within the specified timeframe
          </span>
        </>
      }
    >
      <div className="nj-create-meeting-card--meeting-window-container">
        <div className="nj-create-meeting-card--windows-wrapper">
          {fields.map((item, index) => (
            <div
              key={item.id}
              className="nj-create-meeting-card--meeting-window-range-field"
            >
              <MeetingDateRangePicker
                dateRange={item}
                index={index}
                onRemove={fields.length > 1 ? () => remove(index) : undefined}
              />
            </div>
          ))}
        </div>

        <AddTimeframeButton<MeetingCardFormData> onAppend={append} />
      </div>
    </MeetingCardField>
  );
};
