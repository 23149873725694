import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useCurrentMessage } from './useCurrentMessage';
import { useAppDispatch } from 'src/store';
import { useSession } from '../useSession';
import { useConversationParams } from '../conversationHooks';
import {
  useCreateFeedbackMutation,
  useDeleteFeedbackMutation,
  useUpdateFeedbackMutation,
} from 'src/store/services';
import { replaceConversationMessage } from 'src/store/updateQueries';
import { COMMON_ERROR_TEXT } from 'src/constants';
import {
  CreateFeedbackRequest,
  Feedback,
  FeedbackType,
  FeedbackType1,
  Message,
  PatchFeedbackRequest,
} from 'src/types';

const SUBMITTED_TEXT = 'Feedback submitted';
const CANCELLED_TEXT = 'Feedback cancelled';

export const useFeedbackRating = (messageId?: string) => {
  const dispatch = useAppDispatch();

  const {
    appUser: { user_id },
  } = useSession();
  const { currentConversationId } = useConversationParams();

  const { currentMessage } = useCurrentMessage(messageId);

  const currentRating = useMemo(
    () =>
      currentMessage
        ? currentMessage.feedback?.feedback_v2_data?.super_agent?.data.rating
            ?.rating
        : undefined,
    [currentMessage],
  );

  const [createFeedback, { isLoading: isCreateLoading }] =
    useCreateFeedbackMutation();
  const [deleteFeedback, { isLoading: isDeleteLoading }] =
    useDeleteFeedbackMutation();
  const [updateFeedback, { isLoading: isUpdateLoading }] =
    useUpdateFeedbackMutation();

  const isLoading = useMemo(
    () => isCreateLoading || isDeleteLoading || isUpdateLoading,
    [isCreateLoading, isDeleteLoading, isUpdateLoading],
  );

  const updateMessageWithFeedback = useCallback(
    ({
      message,
      feedback_id,
      rating,
      feedback_ids,
      updated_feedback,
    }: {
      message: Message;
      feedback_id?: string;
      rating: number | null;
      feedback_ids?: Array<string>;
      updated_feedback?: Feedback;
    }): Message => {
      if (updated_feedback && feedback_ids) {
        const updatedMessage = {
          ...message,
          feedback: updated_feedback,
          feedback_ids,
        };

        return updatedMessage;
      }

      if (rating === null) {
        const updatedMessage = {
          ...message,
          feedback: {
            ...message.feedback,
            feedback_v2_data: {
              ...message.feedback?.feedback_v2_data,
              super_agent: undefined,
            },
          },
          feedback_ids: message.feedback_ids?.filter(
            (id) =>
              id !==
              message.feedback?.feedback_v2_data?.super_agent?.feedback_id,
          ),
        };

        return updatedMessage;
      }

      const superAgentFeedback = {
        feedback_id: feedback_id || '',
        data: {
          feedback_type: FeedbackType1.SUPER_AGENT,
          rating: {
            rating,
          },
        },
      };

      const updatedMessage = {
        ...message,
        feedback: {
          ...message.feedback,
          feedback_v2_data: {
            ...message.feedback?.feedback_v2_data,
            super_agent: superAgentFeedback,
          },
        },
        feedback_ids: feedback_id
          ? [...(message.feedback_ids || []), feedback_id]
          : message.feedback_ids,
      };

      return updatedMessage;
    },
    [],
  );

  const handleDeleteFeedback = useCallback(
    async (messageId: string) => {
      const feedback_id =
        currentMessage?.feedback?.feedback_v2_data?.super_agent?.feedback_id;

      if (!feedback_id) {
        return;
      }

      const temporaryReplacedMessage = updateMessageWithFeedback({
        message: currentMessage,
        feedback_id,
        rating: null,
      });

      dispatch(replaceConversationMessage(temporaryReplacedMessage));

      try {
        const { feedback_ids, updated_feedback } = await deleteFeedback({
          conversation_id: currentConversationId,
          message_id: messageId,
          feedback_id,
          user_id,
        }).unwrap();

        toast(CANCELLED_TEXT);
        const updatedMessage = updateMessageWithFeedback({
          message: currentMessage,
          feedback_id,
          rating: null,
          feedback_ids,
          updated_feedback,
        });

        dispatch(replaceConversationMessage(updatedMessage));
      } catch (error) {
        toast(COMMON_ERROR_TEXT);
        dispatch(replaceConversationMessage(currentMessage));
      }
    },
    [
      currentMessage,
      currentConversationId,
      user_id,
      deleteFeedback,
      dispatch,
      updateMessageWithFeedback,
    ],
  );

  const handleCreateFeedback = useCallback(
    async (messageId: string, rating: number) => {
      if (!currentMessage) {
        return;
      }

      const body: CreateFeedbackRequest = {
        user_id,
        conversation_id: currentConversationId,
        message_id: messageId,
        feedback_type: FeedbackType.FEEDBACK_V2,
        feedback_details_v2: {
          feedback_type: FeedbackType1.SUPER_AGENT,
          rating: {
            rating,
          },
        },
      };

      const temporaryReplacedMessage: Message = updateMessageWithFeedback({
        message: currentMessage,
        rating,
      });

      dispatch(replaceConversationMessage(temporaryReplacedMessage));

      try {
        const { updated_feedback, feedback_ids } =
          await createFeedback(body).unwrap();
        toast(SUBMITTED_TEXT);

        const updatedMessage = updateMessageWithFeedback({
          message: currentMessage,
          feedback_ids,
          updated_feedback,
          rating,
        });

        dispatch(replaceConversationMessage(updatedMessage));
      } catch (error) {
        toast(COMMON_ERROR_TEXT);
        dispatch(replaceConversationMessage(currentMessage));
      }
    },
    [
      currentConversationId,
      currentMessage,
      user_id,
      dispatch,
      updateMessageWithFeedback,
      createFeedback,
    ],
  );

  const handleUpdateFeedback = useCallback(
    async (messageId: string, rating: number) => {
      const feedback_id =
        currentMessage?.feedback?.feedback_v2_data?.super_agent?.feedback_id;

      if (!currentMessage || !feedback_id) {
        return;
      }

      const body: PatchFeedbackRequest = {
        user_id,
        conversation_id: currentConversationId,
        message_id: messageId,
        feedback_id,
        feedback_details_v2: {
          feedback_type: FeedbackType1.SUPER_AGENT,
          rating: {
            rating,
          },
        },
      };

      const temporaryReplacedMessage: Message = updateMessageWithFeedback({
        message: currentMessage,
        rating,
      });

      dispatch(replaceConversationMessage(temporaryReplacedMessage));

      try {
        const { feedback_ids, updated_feedback } =
          await updateFeedback(body).unwrap();
        toast(SUBMITTED_TEXT);

        const updatedMessage: Message = updateMessageWithFeedback({
          message: currentMessage,
          rating,
          feedback_ids,
          updated_feedback,
        });

        dispatch(replaceConversationMessage(updatedMessage));
      } catch (error) {
        toast(COMMON_ERROR_TEXT);
        dispatch(replaceConversationMessage(currentMessage));
      }
    },
    [
      currentConversationId,
      currentMessage,
      user_id,
      dispatch,
      updateMessageWithFeedback,
      updateFeedback,
    ],
  );

  const updateFeedbackRating = useCallback(
    async (rating: number) => {
      if (!messageId || isLoading) {
        return;
      }

      if (currentRating === rating) {
        await handleDeleteFeedback(messageId);
        return;
      }

      const hasSuperAgentFeedback =
        currentMessage?.feedback?.feedback_v2_data?.super_agent;

      if (hasSuperAgentFeedback) {
        await handleUpdateFeedback(messageId, rating);
        return;
      }

      await handleCreateFeedback(messageId, rating);
    },
    [
      messageId,
      currentRating,
      isLoading,
      currentMessage,
      handleDeleteFeedback,
      handleUpdateFeedback,
      handleCreateFeedback,
    ],
  );

  return useMemo(
    () => ({
      currentRating,
      updateFeedbackRating,
    }),
    [currentRating, updateFeedbackRating],
  );
};
