import { useMemo } from 'react';
import { threadState, useAppSelector } from 'src/store';
import { useConversationParams } from 'src/hooks';

export const useThreadRegistry = () => {
  const { shouldAnimate, threadsStatusRegistry } = useAppSelector(threadState);

  const { currentConversationId } = useConversationParams();

  const ignoreMessages = useMemo(
    () => threadsStatusRegistry[currentConversationId]?.ignoreMessages,
    [threadsStatusRegistry, currentConversationId],
  );

  const isSubmitHappened = useMemo(
    () => threadsStatusRegistry[currentConversationId]?.isSubmitHappened,
    [threadsStatusRegistry, currentConversationId],
  );

  return {
    /**
     * @deprecated
     */
    shouldAnimate,
    ignoreMessages,
    isSubmitHappened,
  };
};
