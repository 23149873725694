export function setWithExpiry(
  key: string,
  value: unknown,
  ttl_seconds: number,
) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl_seconds * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string): unknown | null {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
