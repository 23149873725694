import { useFormContext, useFieldArray } from 'react-hook-form';
import { ProposalCardFormData } from '../ProposalConfirmationFieldsGroup';
import { ProposalTimeSlotDateRangePicker } from '../ProposalTimeSlotDateRangePicker';
import { AddTimeframeButton } from '../../../CreateMeetingCard/components/MeetingWindowsField/components/AddTimeframeButton';
import { ProposalTimeSlotLabel } from '../ProposalTimeSlotLabel';

export const ProposalTimeSlotField = () => {
  const { control } = useFormContext<ProposalCardFormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'proposals',
  });

  return (
    <div className="nj-task-view--form--field">
      <ProposalTimeSlotLabel
        label="Time slots"
        sublabel="Ninja will propose the time slot you provided to guests"
      />
      <div className="nj-task-view--form--block">
        <div className="nj-task-view--form--block-column">
          {fields.map((item, index) => (
            <div
              key={item.id}
              className="nj-create-meeting-card--meeting-window-range-field"
            >
              <ProposalTimeSlotDateRangePicker
                dateRange={item}
                index={index}
                onRemove={fields.length > 1 ? () => remove(index) : undefined}
              />
            </div>
          ))}
          <AddTimeframeButton<ProposalCardFormData> onAppend={append} />
        </div>
      </div>
    </div>
  );
};
