import { useMemo } from 'react';
import { useGetUserSubscriptionInfoQuery } from 'src/store/services';
import { useSession } from 'src/hooks';

export const useSubscriptionInfo = () => {
  const { appUser, isSubscribedTier, isEnterpriseTier } = useSession();

  const {
    data: subscriptionInfo,
    isLoading: isSubscriptionInfoLoading,
    isFetching: isSubscriptionInfoFetching,
  } = useGetUserSubscriptionInfoQuery(
    {
      user_id: appUser.user_id,
    },
    { skip: !isSubscribedTier || isEnterpriseTier },
  );

  return useMemo(
    () => ({
      subscriptionInfo,
      isSubscriptionInfoLoading,
      isSubscriptionInfoFetching,
    }),
    [subscriptionInfo, isSubscriptionInfoLoading, isSubscriptionInfoFetching],
  );
};
