import { Link } from 'react-router-dom';
import { AppRoutes } from 'src/types';

export const UpsellChatsMessage = () => {
  return (
    <div className="nj-upsell-message-chats">
      <span>To save your chat history </span>
      <div>
        <Link to={AppRoutes.SIGN_UP}>Sign up</Link> or{' '}
        <Link to={AppRoutes.LOGIN}>Login</Link>
      </div>
    </div>
  );
};
