import { useContext } from 'react';
import classNames from 'classnames';
import { useBreakpoint } from 'src/hooks';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { Timer } from '@phosphor-icons/react';

const SVG_SIZE = 24;

export const AvatarIdleBanner = () => {
  const { isTabletAndUp } = useBreakpoint();

  const { idleTimeLeft } = useContext(AvatarModeContext);

  return (
    <div
      className={classNames('nj-common-banner', 'nj-banner', 'nj-banner--idle')}
    >
      <div className="nj-banner--text-wrapper">
        <div className="nj-banner--icon">
          <Timer size={SVG_SIZE} weight="fill" />
        </div>
        <div className="nj-banner--notification">
          <h5>{idleTimeLeft}</h5>
          <p>
            {isTabletAndUp
              ? "Video chat will end if there's no activities."
              : "Chat'll end if there's no activities."}
          </p>
        </div>
      </div>
    </div>
  );
};
