import {
  useContext,
  useState,
  KeyboardEvent,
  ChangeEvent,
  SyntheticEvent,
  useMemo,
  RefObject,
} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import AudioContext from 'src/contexts/AudioContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import {
  useThreadRegistry,
  useSession,
  useSubmitUserInput,
  useBreakpoint,
  useConversationParams,
  useInputBoxState,
  useConvertTextToFile,
} from 'src/hooks';
import { interruptMetahuman } from 'src/utils';
import { ChatMode, KeyCodes } from 'src/types';
import { MicrophoneTrigger } from 'src/pages/ManageTasksChatPage/components/ChatForm/components/MicrophoneTrigger';
import { SubmitTrigger } from 'src/pages/ManageTasksChatPage/components/ChatForm/components/SubmitTrigger';
import { QuickContactPicker } from './components/QuickContactPicker';
import { QuickCommandPicker } from './components/QuickCommandPicker';
import { ReplaceTooltip } from './components/ReplaceTooltip';
import { AddAttachmentButton } from 'src/v2/components/ThreadInputBox/components/AddAttachmentButton';
import { PromptButton } from 'src/pages/ManageTasksChatPage/components/ChatForm/components/PromptButton';
import MoreActionsButton from './components/MoreActionsButton/MoreActionsButton';
import { TranslatePromptButton } from 'src/pages/ManageTasksChatPage/components/ChatForm/components/TranslatePromptButton';
import { AttachmentContainer } from './components/AttachmentContainer';
import { PromptTemplatesButton } from 'src/components/PromptsButton';
import { PromptInput } from './components/PromptInput';
import styles from './ThreadInputBox.module.scss';
import { ExternalModelSelection } from 'src/v2/components/ExternalModelSelection';
import {
  MAX_NUMBER_OF_PASTED_WORDS,
  MAX_NUMBER_OF_WORDS_OPEN_TIER,
  REG_EXP_FOR_WORD_SEPARATORS,
} from 'src/constants';

const MAX_INPUT_ROWS = 4;
const DEFAULT_PLACEHOLDER = 'Ask anything or use / to choose a specific agent';
const DEFAULT_PLACEHOLDER_MOBILE = 'Ask anything or use /';
const RESPONDING_PLACEHOLDER = 'Ninja responding';
const PROCESSING_PLACEHOLDER = 'Processing request';
const LISTENING_PLACEHOLDER = 'Listening';
const ELLIPSIS_SUFFIX = '...';

const DESKTOP_ERROR_TEXT = `To process your entire input, please upgrade. Otherwise, it will be shortened to ${new Intl.NumberFormat('en-US').format(MAX_NUMBER_OF_WORDS_OPEN_TIER)} words.`;
const MOBILE_ERROR_TEXT = `Upgrade to process your full input; otherwise, it will be limited to ${new Intl.NumberFormat('en-US').format(MAX_NUMBER_OF_WORDS_OPEN_TIER)} words.`;

interface ThreadInputBoxProps {
  startSpeechRecognizing: () => void;
  stopSpeechRecognizing: () => void;
  unMuteMicrophone: () => void;
}

export const ThreadInputBox = ({
  startSpeechRecognizing,
  stopSpeechRecognizing,
  unMuteMicrophone,
}: ThreadInputBoxProps) => {
  const { threadInputBoxRef } = useContext(ForwardRefContext);
  const {
    attachmentLoading,
    threadInputBoxValue,
    setThreadInputBoxValue,
    temporaryInputValue,
    promptLoading,
    setTemporaryInputValue,
    threadInputBoxFiles,
    threadFromPromptTemplate,
    threadInputBoxTextError,
    setThreadInputBoxTextError,
    convertedFromTextFilesLength,
  } = useContext(ThreadInputBoxContext);
  const { microphoneTriggerRef, recordInProgress, metaHumanTalking } =
    useContext(AudioContext);
  const { isSubmitHappened } = useThreadRegistry();
  const { isLandingPage } = useConversationParams();
  const { chatMode, appUser, isOpenTier } = useSession();
  const { onSubmitUserInput } = useSubmitUserInput();
  const { onConvertTextToFile } = useConvertTextToFile();

  const { isMobile, isLaptopAndUp } = useBreakpoint();

  const {
    shouldNotSubmitOnEnter,
    isVisibleContactPicker,
    isVisibleCommandPicker,
    onOpenContactPicker,
    onCloseContactPicker,
    onOpenCommandPicker,
    onCloseCommandPicker,
  } = useInputBoxState();

  const hasError = useMemo(() => {
    if (threadInputBoxFiles) {
      return (
        Object.keys(threadInputBoxFiles).filter(
          (item) => !!threadInputBoxFiles[item]?.errorMessage,
        ).length > 0
      );
    } else return false;
  }, [threadInputBoxFiles]);

  const hasLoadingFiles = useMemo(() => {
    if (threadInputBoxFiles) {
      return (
        Object.keys(threadInputBoxFiles).filter(
          (item) => !!threadInputBoxFiles[item]?.isLoading,
        ).length > 0
      );
    } else return false;
  }, [threadInputBoxFiles]);

  const [cursorPosition, setCursorPosition] = useState<number>(0);

  const openTierErrorText = useMemo(
    () => (isLaptopAndUp ? DESKTOP_ERROR_TEXT : MOBILE_ERROR_TEXT),
    [isLaptopAndUp],
  );

  const handleCloseAllPickers = () => {
    onCloseContactPicker();
    onCloseCommandPicker();
  };

  // TODO(olha): probably deprecated
  const updateCursorPosition = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    const target = e.currentTarget as HTMLTextAreaElement;
    setCursorPosition(target.selectionStart);
  };

  // managing the behavior of opening or closing quick pickers based on keyboard input.
  const handleCheckKeyCode = ({ key }: KeyboardEvent<HTMLElement>) => {
    const beforeCursor = threadInputBoxValue.substring(0, cursorPosition);

    switch (key) {
      case KeyCodes.AT:
        if (!threadInputBoxValue || beforeCursor.at(-1) === KeyCodes.SPACE) {
          onOpenContactPicker();
        }
        break;

      case KeyCodes.SLASH:
        if (!threadInputBoxValue) {
          onOpenCommandPicker();
        }
        break;

      case KeyCodes.BACKSPACE || KeyCodes.DELETE:
        if (!threadInputBoxValue) {
          handleCloseAllPickers();
        }

        if (beforeCursor.at(-1) === KeyCodes.AT) {
          onCloseContactPicker();
        } else if (
          beforeCursor.at(-2) === KeyCodes.AT &&
          (beforeCursor.at(-3) === KeyCodes.SPACE ||
            threadInputBoxValue.length === 2)
        ) {
          onOpenContactPicker();
        }

        if (threadInputBoxValue.at(-1) === KeyCodes.SLASH) {
          onCloseCommandPicker();
        } else if (threadInputBoxValue.at(-2) === KeyCodes.SLASH) {
          onOpenCommandPicker();
        }

        break;

      case KeyCodes.SPACE || KeyCodes.ESCAPE:
        handleCloseAllPickers();
        break;

      default:
        break;
    }
  };

  // Disabling submit if the input box is empty, or if the Stop button is Processing, or if the Avatar is talking, or if Speech-to-text in progress
  const isDisabledSubmitButton =
    (!threadInputBoxValue.trim() && convertedFromTextFilesLength === 0) ||
    !!isSubmitHappened ||
    !!metaHumanTalking ||
    !!recordInProgress ||
    promptLoading ||
    attachmentLoading ||
    hasError ||
    hasLoadingFiles;

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // allows the user to switch to the next line when pressing the Shift and Enter keys
    if (e.key === KeyCodes.ENTER && !e.shiftKey) {
      e.preventDefault();
    }

    // submit input by pressing Enter key if the quick picker is not open and if the input is not disabled
    if (
      e.key === KeyCodes.ENTER &&
      !e.shiftKey &&
      !shouldNotSubmitOnEnter &&
      !isDisabledSubmitButton
    ) {
      onSubmitUserInput(threadInputBoxValue);
      return;
    }

    if (e.key !== KeyCodes.ENTER) {
      handleCheckKeyCode(e);
      return;
    }
  };

  const handleChange = (value: string) => {
    setThreadInputBoxTextError('');

    if (promptLoading) {
      return;
    }

    const valueSplittedToWords = value.split(REG_EXP_FOR_WORD_SEPARATORS);
    if (
      isOpenTier &&
      valueSplittedToWords.length > MAX_NUMBER_OF_WORDS_OPEN_TIER
    ) {
      const newValue = valueSplittedToWords
        .slice(0, MAX_NUMBER_OF_WORDS_OPEN_TIER)
        .join(' ');
      setThreadInputBoxValue(newValue);
      setThreadInputBoxTextError(openTierErrorText);
      return;
    }

    setThreadInputBoxValue(value);
    if (value === '') {
      setTemporaryInputValue('');
    }
  };

  const handleTextAreaChange = ({
    target,
  }: ChangeEvent<HTMLTextAreaElement>) => {
    handleChange(target.value);
  };

  const handleBlur = () => {
    handleCloseAllPickers();
  };

  const handlePaste = <T extends HTMLElement>(e: React.ClipboardEvent<T>) => {
    const pastedText = e.clipboardData.getData('text');
    const valueSplittedToWords = pastedText.split(REG_EXP_FOR_WORD_SEPARATORS);

    if (
      valueSplittedToWords.length > MAX_NUMBER_OF_PASTED_WORDS &&
      !isOpenTier
    ) {
      e.preventDefault();
      onConvertTextToFile(pastedText);
    }
  };

  const stopRecording = (skipInterrupt?: boolean) => {
    microphoneTriggerRef?.current?.stopRecording(skipInterrupt);
  };

  const handleClick = () => {
    handleCloseAllPickers();

    if (recordInProgress) {
      stopRecording();
    } else {
      // TODO(olha): workaround until we implement Mute button with avatar signal
      interruptMetahuman(appUser.user_id);
    }
  };

  const isAvatarMode = chatMode === ChatMode.AVATAR;

  const inputValue =
    recordInProgress && threadInputBoxValue
      ? `${threadInputBoxValue} ${ELLIPSIS_SUFFIX}`
      : threadInputBoxValue;

  const placeholder = useMemo(() => {
    if ((isSubmitHappened && isAvatarMode) || metaHumanTalking) {
      return `${RESPONDING_PLACEHOLDER} ${ELLIPSIS_SUFFIX}`;
    }

    if (isSubmitHappened) {
      return `${PROCESSING_PLACEHOLDER} ${ELLIPSIS_SUFFIX}`;
    }

    if (recordInProgress) {
      return `${LISTENING_PLACEHOLDER} ${ELLIPSIS_SUFFIX}`;
    }

    if (promptLoading) {
      return temporaryInputValue;
    }

    return isMobile ? DEFAULT_PLACEHOLDER_MOBILE : DEFAULT_PLACEHOLDER;
  }, [
    recordInProgress,
    isSubmitHappened,
    metaHumanTalking,
    isAvatarMode,
    isMobile,
    promptLoading,
    temporaryInputValue,
  ]);

  return (
    <div
      className={classNames(styles.root, {
        [styles.inLandingPage]: isLandingPage,
      })}
    >
      {!!threadInputBoxTextError && (
        <span className={styles.error}>{threadInputBoxTextError}</span>
      )}

      <div
        className={classNames(styles.container, {
          [styles.withError]: hasError || !!threadInputBoxTextError,
        })}
      >
        <div className={styles.textAreaWrapper}>
          {threadFromPromptTemplate ? (
            <PromptInput
              value={inputValue}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={handleClick}
              onPaste={handlePaste}
            />
          ) : (
            // TODO(olha): after polishing and testing PromptInput, TextareaAutosize should be removed
            <TextareaAutosize
              data-e2e="thread-input-box"
              // TODO(olha): temporary workaround
              ref={threadInputBoxRef as RefObject<HTMLTextAreaElement>}
              value={inputValue}
              placeholder={placeholder}
              minRows={1}
              maxRows={MAX_INPUT_ROWS}
              className={styles.textArea}
              onKeyDown={handleKeyDown}
              onChange={handleTextAreaChange}
              onSelect={updateCursorPosition}
              onBlur={handleBlur}
              onClick={handleClick}
              onPaste={handlePaste}
            />
          )}

          <MicrophoneTrigger
            startSpeechRecognizing={startSpeechRecognizing}
            stopSpeechRecognizing={stopSpeechRecognizing}
            unMuteMicrophone={unMuteMicrophone}
          />

          <SubmitTrigger isSubmitDisabled={isDisabledSubmitButton} />
        </div>

        {threadInputBoxFiles && (
          <AttachmentContainer files={threadInputBoxFiles} />
        )}

        <div className={styles.containerRowWrapper}>
          <div
            id="input-box-anchor-tooltip"
            className={classNames(styles.actions, styles.actionsLtr)}
          >
            {isLaptopAndUp ? <MoreActionsButton /> : <AddAttachmentButton />}

            <PromptTemplatesButton />

            <PromptButton />

            <TranslatePromptButton />
          </div>

          <ExternalModelSelection />

          <QuickContactPicker
            isOpen={isVisibleContactPicker}
            cursorPosition={cursorPosition}
            onClose={onCloseContactPicker}
            setCursorPosition={setCursorPosition}
          />

          <QuickCommandPicker
            isOpen={isVisibleCommandPicker}
            onClose={onCloseCommandPicker}
            setCursorPosition={setCursorPosition}
          />

          <ReplaceTooltip />
        </div>
      </div>
    </div>
  );
};
