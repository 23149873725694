import { useState } from 'react';
import { TabPanel } from 'react-headless-tabs';
import { Tabs } from 'src/components/Tabs';
import { ListOfPhotosTab } from './components/ListOfPhotosTab';
import { ListOfScreenRecordingsTab } from './components/ListOfScreenRecordingsTab';
import { ListOfVideosTab } from './components/ListOfVideosTab';
import { useCheckIsNinjaEmployee } from 'src/hooks/useCheckIsNinjaEmployee';

export enum ListOfVideoTabs {
  VIDEOS = 'Movies',
  SCREEN_RECORDINGS = 'Screen recordings',
  PHOTOS = 'Screenshots',
}

const Lists = [
  {
    id: ListOfVideoTabs.VIDEOS,
    component: <ListOfVideosTab />,
  },
  {
    id: ListOfVideoTabs.SCREEN_RECORDINGS,
    component: <ListOfScreenRecordingsTab />,
  },
  {
    id: ListOfVideoTabs.PHOTOS,
    component: <ListOfPhotosTab />,
  },
];

export const SavedContentSection = () => {
  const [activeTab, setActiveTab] = useState(ListOfVideoTabs.VIDEOS);
  const isUserNinjaEmployee = useCheckIsNinjaEmployee();

  return (
    <div className="nj-avatar-recording--list-of-scripts">
      {isUserNinjaEmployee && (
        <Tabs
          tabsList={Object.values(ListOfVideoTabs)}
          activeTab={activeTab}
          onTabChange={setActiveTab}
        />
      )}
      <div className="nj-tabbed-layout--panels nj-section--main-container with-tabs">
        {isUserNinjaEmployee ? (
          Lists.map((list, index) => (
            <TabPanel
              hidden={activeTab !== list.id}
              key={`panel-${list.id}-${index}`}
            >
              {list.component}
            </TabPanel>
          ))
        ) : (
          <ListOfVideosTab />
        )}
      </div>
    </div>
  );
};
