import { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import { CaretDown, CaretUp } from '@phosphor-icons/react';

const SVG_SIZE = 20;

type AccordionItemProps = {
  onChange: (value: string) => void;
  isOpened: boolean;
  value: string;
  icon?: JSX.Element;
  title: string;
  shouldHideSubtitle?: boolean;
  subtitle?: string | JSX.Element;
  content: string | JSX.Element;
  isDraggableContent?: boolean;
};

const DEFAULT_HEIGHT = 1000;

export const AccordionItem = ({
  onChange,
  isOpened,
  value,
  icon,
  title,
  shouldHideSubtitle,
  subtitle,
  content,
  isDraggableContent,
}: AccordionItemProps) => {
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const panelContainerRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateMaxHeight = () => {
      if (panelContainerRef.current && panelRef.current) {
        const { height } = panelContainerRef.current.getBoundingClientRect();
        const panelHeight =
          height || panelContainerRef.current.offsetHeight || DEFAULT_HEIGHT;
        setMaxHeight(panelHeight);
      }
    };

    updateMaxHeight();

    const resizeObserver = new ResizeObserver(updateMaxHeight);
    if (panelContainerRef.current) {
      resizeObserver.observe(panelContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleClick = (value: string) => {
    onChange(value);
  };

  return (
    <div
      className={classNames('nj-accordion--panel', { open: isOpened })}
      role="tab"
      tabIndex={0}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={() => handleClick(value)}
        className="nj-accordion--header"
      >
        <div className="nj-accordion--label">
          {icon}
          <div className="nj-accordion--label-content">
            <p className="nj-accordion--title">{title}</p>
            <p
              className={classNames('nj-accordion--subtitle', {
                hide: shouldHideSubtitle && isOpened,
              })}
            >
              {subtitle}
            </p>
          </div>
        </div>
        {isOpened ? <CaretUp size={SVG_SIZE} /> : <CaretDown size={SVG_SIZE} />}
      </div>

      <div
        ref={panelRef}
        className={classNames('nj-accordion--content', {
          isDraggableContent: isDraggableContent,
        })}
        style={{ maxHeight: isOpened ? maxHeight : 0 }}
      >
        <div
          className="nj-accordion--content-container"
          ref={panelContainerRef}
        >
          {content}
        </div>
      </div>
    </div>
  );
};
