import type { SelectOption } from 'src/components/Select';
import { TimeSavingFilter, AvatarVideoPresetName } from 'src/types';
import { CommunicationToneID } from 'src/types/models/CommunicationToneID';
import { env } from 'src/env';

export const OFFLINE_RENDER_S3_BUCKET = 'avatar-metahuman/renders';

export const AVATARS_JSON =
  env.REACT_APP_AVATARS_S3_BUCKET ||
  'https://ninja-avatar-assets-beta.s3.us-west-2.amazonaws.com/avatars.json';

export const DEFAULT_AVATAR_ID = 'BP_Atlas_00';

export const DEFAULT_COMMUNICATION_TONE_ID =
  CommunicationToneID.PROFESSIONAL_AND_FORMAL;

export const DEFAULT_AVATAR = {
  id: DEFAULT_AVATAR_ID,
  url: 'https://ninja-avatar-assets-beta.s3.us-west-2.amazonaws.com/Jesse.png',
};

export const DEFAULT_SELECTED_GENDER = 'female';

export const DEFAULT_AVATAR_ENVIRONMENT = 'Color_Void';

export const DEFAULT_AVATAR_VOICE_ID = 'en-US-JennyNeural';
export const DEFAULT_AVATAR_MALE_VOICE_ID = 'en-US-SteffanNeural';
export const DEFAULT_LANGUAGE = 'en-US';
export const DEFAULT_CAMERA_INDEX = 0;
export const DEFAULT_BACKGROUND_COLOR = '#060809';

export const DEFAULT_DURATION = '30';

export const DEFAULT_RATE = '30';
export const DEFAULT_HOURS_PER_DAY = '4';

export const DEFAULT_NEGOTIATION_DURATION = '24';
export const DEFAULT_REQUEST_WINDOW_EXPANSION = '2';
export const DEFAULT_MINIMUM_AGREEMENT_PERCENTAGE = '0.5';
export const DEFAULT_DURATION_BETWEEN_REMINDER_EMAILS = '8';

export const DEFAULT_SAVINGS_DURATION = TimeSavingFilter.Week;

export const avatarCameraOptions: SelectOption[] = [
  {
    label: 'Front normal',
    value: '0',
  },
  {
    label: 'Front Close-Up',
    value: '1',
  },
  {
    label: 'Top Close-Up',
    value: '4',
  },
  {
    label: 'Left Close-Up',
    value: '3',
  },
  {
    label: 'Right Close-Up',
    value: '2',
    customConfig: {
      hasBorderBottom: true,
    },
  },
  {
    label: 'Cinematic',
    value: '5',
    customConfig: {
      hasBorderBottom: true,
    },
  },
  {
    label: 'Auto',
    value: '6',
  },
];

export const avatarEnvironmentOptions: SelectOption[] = [
  {
    label: 'None',
    value: '0',
    customConfig: {
      hasBorderBottom: true,
    },
  },
  {
    label: 'Office',
    value: '1',
  },
  {
    label: 'Meeting Room',
    value: '2',
  },
  {
    label: 'News Station',
    value: '3',
  },
  {
    label: 'Lobby',
    value: '4',
    customConfig: {
      hasBorderBottom: true,
    },
  },
  {
    label: 'Rooftop',
    value: '5',
  },
  {
    label: 'Street',
    value: '6',
  },
];

export const avatarEmotionOptions: SelectOption[] = [
  {
    label: 'Neutral',
    value: 'neutral',
  },
  {
    label: 'Happy',
    value: 'happy',
  },
  {
    label: 'Sad',
    value: 'sad',
  },
  {
    label: 'Apologetic',
    value: 'apologetic',
  },
];

export const AvailableVideoFormats = [
  {
    label: 'Wide Landscape 16 : 9 4K',
    subTitle: 'YouTube',
    icon: 'landscape',
    value: AvatarVideoPresetName._4K_RAW,
  },
  {
    label: 'Tall Portrait 9 : 16',
    subTitle: 'YouTube Short, TikTok, Instagram Reels',
    icon: 'portrait',
    value: AvatarVideoPresetName.HD_VERTICAL,
  },
  {
    label: 'Square 1 : 1',
    subTitle: 'LInkedIn, Twitter, Facebook Post, Instagram Post',
    icon: 'square',
    value: AvatarVideoPresetName.SQUARE,
  },
];

export const RecordingParamName = 'mode';

export const FLOATING_PADDING = 16;
export const DEFAULT_FLOATING_WIDTH = 320;
export const DEFAULT_FLOATING_HEIGHT = 180;
export const SETTINGS_PANEL_EXPANDED_WIDTH = 388;
export const SETTINGS_PANEL_WIDTH = 72;
export const LEFT_PANEL_WIDTH = 320;

export const AUTOCLOSE_TIMER_DURATION = 3000;
export const QUEUE_HOLDING_DURATION = 120000;

export const AVATAR_SESSION_DURATION_DEFAULT = 300000; // 5 minutes

export const AVATAR_SESSION_NOTICE_SHOW = 120000; // 2 minutes
export const AVATAR_SESSION_NOTICE_WARNING = 60000; // 1 minute
