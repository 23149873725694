interface LabelUltraProps {
  labelType?: 'short' | 'full';
}

export const LabelUltra = ({ labelType = 'short' }: LabelUltraProps) => {
  return (
    <label className="nj-label-free nj-label-ultra">
      {labelType === 'short' ? 'Ultra' : 'Ultra subscription'}
    </label>
  );
};
