import { useTaskById } from 'src/hooks';

export const useReadMessage = () => {
  const { updateTaskField } = useTaskById();

  const readMessage = async (task_id: string) => {
    await updateTaskField({
      task_id,
      task: {
        task_id,
        requires_attention: false,
      },
    });
  };

  return { readMessage };
};
