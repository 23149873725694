import { Star } from '@phosphor-icons/react';
import cn from 'classnames';
import { SVG_SIZE_L } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import { useFeedbackRating } from 'src/hooks';
import styles from './FeedbackRating.module.scss';

const RATING_LENGTH = 5;

interface FeedbackRatingProps {
  messageId?: string;
}

export const FeedbackRating = ({ messageId }: FeedbackRatingProps) => {
  const { currentRating, updateFeedbackRating } = useFeedbackRating(messageId);

  return (
    <div className={styles.root}>
      <p className={styles.content}>
        <span className={styles.title}>Help us improve Ninja</span>
        <span className={styles.description}>
          How satisfied are you with the quality of the SuperAgent answer?
        </span>
      </p>

      <div className={styles.ratingWrapper}>
        {Array.from({ length: RATING_LENGTH }, (_, index) => {
          const rating = index + 1;
          const isSelected =
            currentRating !== undefined && currentRating >= rating;

          return (
            <Button
              className={cn(styles.ratingButton, {
                [styles.selected]: isSelected,
              })}
              key={rating}
              color="transparent"
              onClick={() => updateFeedbackRating(rating)}
            >
              <Star
                size={SVG_SIZE_L}
                weight={isSelected ? 'fill' : 'regular'}
              />
            </Button>
          );
        })}
      </div>
    </div>
  );
};
