/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CommunicationToneID {
  PROFESSIONAL_AND_FORMAL = 'Professional_And_Formal',
  FRIENDLY_AND_INFORMAL = 'Friendly_And_Informal',
  PERSUASIVE = 'Persuasive',
  EMPATHETIC = 'Empathetic',
  INQUISITIV_AND_CURIOUS = 'Inquisitiv_And_Curious',
  ASSERTIVE_BUT_POLITE = 'Assertive_But_Polite',
  INSPIRATIONAL_AND_UPLIFTING = 'Inspirational_And_Uplifting',
  APOLOGETIC = 'Apologetic',
  INSTRUCTIONAL_OR_EXPLANATORY = 'Instructional_Or_Explanatory',
  GRATEFUL_AND_APPRECIATIVE = 'Grateful_And_Appreciative',
}
