import { useMemo } from 'react';
import { useCollapse } from 'react-collapsed';
import classNames from 'classnames';
import type { Icon } from '@phosphor-icons/react';
import { Citation } from 'src/types';
import { ThreadMessageContent } from 'src/components/FlatAppearance/components/ThreadMessageContent';
import {
  ProgressBar,
  ColorScheme,
} from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';
import { ShowMoreLessButton } from 'src/components/FlatAppearance/components/ShowMoreLessButton';

import styles from './ThreadSummary.module.scss';

const SVG_SIZE = 24;

type ColorPalette = 'plain' | 'blue' | 'light-blue';

interface ThreadSummaryProps {
  title: string;
  content?: string;
  IconComponent?: Icon;
  references?: Citation[];
  colorPalette?: ColorPalette;
  isStreaming?: boolean;
  isCollapsed?: boolean;
}

/**
 * @deprecated
 */
export const ThreadSummary = ({
  title,
  content = '',
  IconComponent,
  references = [],
  colorPalette = 'plain',
  isStreaming,
  isCollapsed,
}: ThreadSummaryProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const progressColors: ColorScheme | null = useMemo(() => {
    switch (colorPalette) {
      case 'blue':
        return {
          baseColor: { darkTheme: '#021733', lightTheme: '#cee3ff' },
          highlightColor: { darkTheme: '#0880a6', lightTheme: '#e7f9ff' },
        };

      case 'light-blue':
        return {
          baseColor: { darkTheme: '#2d4b59', lightTheme: '#d2e6f1' },
          highlightColor: { darkTheme: '#7cc0e1', lightTheme: '#ffffff' },
        };

      default:
        return null;
    }
  }, [colorPalette]);

  return (
    <div className={classNames(styles.root, styles[colorPalette])}>
      <h5 className={styles.title}>
        {IconComponent && <IconComponent size={SVG_SIZE} />}
        <span>{title}</span>
      </h5>

      {content.length === 0 && (
        <ProgressBar
          count={3}
          baseColor={progressColors?.baseColor}
          highlightColor={progressColors?.highlightColor}
        />
      )}

      {content.length > 0 && !isCollapsed && (
        <ThreadMessageContent
          content={content}
          isStreaming={isStreaming}
          references={references}
        />
      )}

      {content.length > 0 && isCollapsed && (
        <>
          {!isExpanded && (
            <div className={styles.collapsedContentBlock}>
              <ThreadMessageContent
                content={content}
                isStreaming={isStreaming}
                references={references}
              />
              <div className={styles.collapsedContentCoverage} />
            </div>
          )}

          <div {...getCollapseProps()} className={styles.expandedContentBlock}>
            <ThreadMessageContent
              content={content}
              isStreaming={isStreaming}
              references={references}
            />
          </div>

          <ShowMoreLessButton
            getToggleProps={getToggleProps}
            isExpanded={isExpanded}
          />
        </>
      )}
    </div>
  );
};
