import { useContext, useRef } from 'react';
import { Notebook } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { useVisible, useBreakpoint } from 'src/hooks';
import styles from './PromptTemplatesButton.module.scss';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { PromptsModal } from './components/PromptsModal';
import { useGetPromptTemplatesQuery } from 'src/store/services';

export const PromptTemplatesButton = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { isMobile } = useBreakpoint();
  const {
    isVisible: isModalVisible,
    handleVisibilityRemove: onModalClose,
    handleVisibilitySet: onModalOpen,
  } = useVisible();

  const {
    promptLoading,
    temporaryInputValue,
    setThreadFromPromptTemplate,
    setThreadInputBoxValue,
    setTemporaryInputValue,
  } = useContext(ThreadInputBoxContext);

  const { data: promptTemplates, isLoading } = useGetPromptTemplatesQuery();

  const handleButtonClick = () => {
    buttonRef.current?.blur();
    onModalOpen();
  };

  const setPrompt = (prompt: string) => {
    if (temporaryInputValue) {
      setTemporaryInputValue('');
    }
    setThreadFromPromptTemplate(prompt);
    setThreadInputBoxValue(prompt);
  };

  return (
    <div>
      <button
        ref={buttonRef}
        className={styles.triggerButton}
        onClick={handleButtonClick}
        data-tooltip-id="prompt-template-tooltip"
        data-tooltip-place={isMobile ? 'bottom-start' : 'top-start'}
        disabled={isLoading || promptLoading}
      >
        <Notebook size={SVG_SIZE_M} />
        {!isMobile && <span>Prompt Library</span>}
      </button>

      <PromptsModal
        isOpen={isModalVisible}
        onClose={onModalClose}
        data={promptTemplates}
        setPrompt={setPrompt}
      />
    </div>
  );
};
