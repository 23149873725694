import { SeatStatus } from 'src/types/models/SeatStatus';

export enum TeamsUsersTabs {
  ACTIVE = 'Active',
  PENDING = 'Pending',
}

export type SeatsParams = {
  user_id: string;
  team_id: string;
  statuses: SeatStatus;
  page_token?: string;
};
