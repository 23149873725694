import { useMemo } from 'react';
import classNames from 'classnames';
import { useSession } from 'src/hooks';
import { DefaultStaticField } from '../DefaultStaticField';
import { GuestsNotesDefaultValue } from '../GuestsNotesDefaultValue';
import { AttendeeListField } from '../AttendeeListField';
import { GuestAvailabilityField } from '../GuestAvailabilityField';
import { DescriptionField } from '../DescriptionField';
import { useMeetingCardFieldsWatch } from '../../hooks/useMeetingCardFieldsWatch';
import type { MeetingCardFormData } from '../../CreateMeetingCard';
import { UpgradeButton } from 'src/components/UpgradeButton';

interface GuestsNotesFieldProps {
  maxAttendeesLength: number;
}

export const GuestsNotesField = ({
  maxAttendeesLength,
}: GuestsNotesFieldProps) => {
  const { isFreeTier } = useSession();

  const { attendees } = useMeetingCardFieldsWatch<MeetingCardFormData>();

  const isErrorInAttendeeList = useMemo(
    () => attendees && attendees.length > maxAttendeesLength,
    [attendees, maxAttendeesLength],
  );

  const label = useMemo(() => {
    if (isFreeTier) {
      return (
        <div className="nj-create-meeting-card--guest-notes-label">
          <p className="nj-create-meeting-card--guest-notes-label-text">
            <span>Guests & notes</span>

            <span className="nj-create-meeting-card--guest-notes-label-description">
              Only 1:1 meetings available. Upgrade to Pro plan for multiparty
              scheduling.
            </span>
          </p>

          <UpgradeButton dataGTM="Create-meeting-card-upgrade" />
        </div>
      );
    }

    return 'Guests & notes';
  }, [isFreeTier]);

  return (
    <DefaultStaticField
      label={label}
      defaultValue={<GuestsNotesDefaultValue />}
      className={classNames({ warning: isErrorInAttendeeList })}
    >
      <>
        <AttendeeListField maxAttendeesLength={maxAttendeesLength} />
        <GuestAvailabilityField />
        <DescriptionField />
      </>
    </DefaultStaticField>
  );
};
