import { useState } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { colord, Colord } from 'colord';
import { useDebouncedCallback } from 'use-debounce';
import classNames from 'classnames';
import { ColorTypeSelector, ColorType } from './components/ColorTypeSelector';

const DEFAULT_HEX_COLOR = '#FFFFFF';
const DEBOUNCE_DELAY = 500;

interface ColorPickerProps {
  hexColor?: string;
  onChange: (hexColor: string) => void;
  className?: string;
}

export const ColorPicker = ({
  hexColor,
  onChange,
  className,
}: ColorPickerProps) => {
  const [color, setColor] = useState<Colord>(
    colord(hexColor || DEFAULT_HEX_COLOR),
  );
  const [colorType, setColorType] = useState<ColorType>(ColorType.RGB);

  const debouncedOnChange = useDebouncedCallback((colord: Colord) => {
    onChange(colord.toHex());
  }, DEBOUNCE_DELAY);

  const handleHexChange = (newHex: string) => {
    const updatedColor = colord(newHex);
    setColor(updatedColor);
    debouncedOnChange(updatedColor);
  };

  const handleRgbChange = (
    part: keyof ReturnType<Colord['toRgb']>,
    { target }: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedRGBColor = {
      ...color.toRgb(),
      [part]: parseInt(target.value, 10),
    };
    const updatedColor = colord(updatedRGBColor);

    setColor(updatedColor);
    debouncedOnChange(updatedColor);
  };

  return (
    <div className={classNames('nj-color-picker', className)}>
      <div className="nj-color-picker--control">
        <HexColorPicker color={color.toHex()} onChange={handleHexChange} />

        <span className="nj-color-picker--caption hue">Hue</span>
      </div>

      <div className="nj-color-picker--selector-wrapper">
        <ColorTypeSelector selectedValue={colorType} onChange={setColorType} />

        {colorType === ColorType.HEX && (
          <HexColorInput
            alpha
            prefixed
            color={color.toHex()}
            onChange={handleHexChange}
          />
        )}

        {colorType === ColorType.RGB && (
          <div className="nj-color-picker--rgb-inputs">
            <label className="nj-color-picker--rgb-inputs-label">
              <span className="nj-color-picker--caption">R</span>

              <input
                type="number"
                value={color.toRgb().r}
                onChange={(e) => handleRgbChange('r', e)}
              />
            </label>

            <label className="nj-color-picker--rgb-inputs-label">
              <span className="nj-color-picker--caption">G</span>

              <input
                type="number"
                value={color.toRgb().g}
                onChange={(e) => handleRgbChange('g', e)}
              />
            </label>

            <label className="nj-color-picker--rgb-inputs-label">
              <span className="nj-color-picker--caption">B</span>

              <input
                type="number"
                value={color.toRgb().b}
                onChange={(e) => handleRgbChange('b', e)}
              />
            </label>
          </div>
        )}
      </div>
    </div>
  );
};
