import { ReactNode } from 'react';
import { DotsThreeOutlineVertical } from '@phosphor-icons/react';
import { Menu, MenuButton } from '@szhsin/react-menu';

const SVG_SIZE = 20;

interface ConversationsControlMenuProps {
  children: ReactNode;
}

export const ConversationsControlMenu = ({
  children,
}: ConversationsControlMenuProps) => {
  return (
    <Menu
      gap={8}
      menuClassName="nj-multi-menu"
      menuButton={
        <MenuButton id="onboarding-element-2">
          <DotsThreeOutlineVertical size={SVG_SIZE} alt="Tasks menu" />
        </MenuButton>
      }
      transition
    >
      {children}
    </Menu>
  );
};
