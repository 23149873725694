import {
  Conversation,
  ApiTask,
  ConversationUpdateRequest,
  ConversationState,
} from 'src/types';
import { conversationApi } from '../services/conversationApi';

// getConversations

export const updateQueryGetConversations = (
  args: ConversationUpdateRequest,
) => {
  const { user_id, conversation_id, ...patch } = args;

  return conversationApi.util.updateQueryData(
    'getConversations',
    { user_id, pageToken: '', includeArchived: false },
    (draft) => ({
      ...draft,
      data: draft?.data
        ?.map((item) => {
          if (item.conversation_id === conversation_id) {
            return {
              ...item,
              ...patch,
            };
          } else {
            return item;
          }
        })
        .filter((item) => item.state !== ConversationState.ARCHIVED),
    }),
  );
};

export const updateQueryGetConversationsWithArchived = (
  args: ConversationUpdateRequest,
) => {
  const { user_id, conversation_id, ...patch } = args;

  return conversationApi.util.updateQueryData(
    'getConversations',
    { user_id, pageToken: '', includeArchived: true },
    (draft) => {
      draft?.data?.forEach((item) => {
        if (item.conversation_id === conversation_id) {
          Object.assign(item, patch);
        }
      });
    },
  );
};

export const addConversationToList = (conversation: Conversation) => {
  const { user_id, conversation_id = '' } = conversation;

  return conversationApi.util.updateQueryData(
    'getConversations',
    { user_id, pageToken: '', includeArchived: false },
    (draft) => {
      const hasCurrentConversation = draft?.data?.find(
        (item) => item.conversation_id === conversation_id,
      );

      return hasCurrentConversation
        ? { ...draft }
        : {
            ...draft,
            data: [conversation, ...(draft?.data || [])],
          };
    },
  );
};

export const updateConversationInConversationsList = ({
  user_id,
  conversation,
}: {
  user_id: string;
  conversation: Partial<Conversation>;
}) => {
  const { conversation_id = '' } = conversation;

  return conversationApi.util.updateQueryData(
    'getConversations',
    { user_id, pageToken: '', includeArchived: false },
    (draft) => ({
      ...draft,
      data: draft?.data?.map((item) =>
        item.conversation_id === conversation_id
          ? { ...item, ...conversation }
          : item,
      ),
    }),
  );
};

export const addTaskToConversationsList = (newTask: ApiTask) => {
  const { parent_conversation_id = '', user_id, task_id } = newTask;

  return conversationApi.util.updateQueryData(
    'getConversations',
    { user_id, pageToken: '', includeArchived: false },
    (draft) => ({
      ...draft,
      data: draft?.data?.map((item) => {
        if (item.conversation_id === parent_conversation_id) {
          return {
            ...item,
            tasks: item.tasks?.some((task) => task.task_id === task_id)
              ? item.tasks
              : [...(item.tasks || []), newTask],
          };
        } else {
          return item;
        }
      }),
    }),
  );
};

export const updateTaskInConversationsList = ({
  user_id,
  task: updatedTask,
}: {
  user_id: string;
  task: Partial<ApiTask>;
}) => {
  const { parent_conversation_id = '', task_id } = updatedTask;

  return conversationApi.util.updateQueryData(
    'getConversations',
    { user_id, pageToken: '', includeArchived: false },
    (draft) => ({
      ...draft,
      data: draft?.data?.map((conversation) => {
        if (conversation.conversation_id === parent_conversation_id) {
          return {
            ...conversation,
            tasks: conversation.tasks?.map((task) =>
              task.task_id === task_id ? { ...task, ...updatedTask } : task,
            ),
          };
        } else {
          return conversation;
        }
      }),
    }),
  );
};

// getConversationById

export const updateQueryGetConversationById = (
  args: ConversationUpdateRequest,
) => {
  const { user_id, conversation_id, ...patch } = args;
  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      // TODO(olha): check why draft can be undefined
      if (draft) {
        Object.assign(draft, patch);
      }
    },
  );
};

export const addConversation = (conversation: Conversation) => {
  const { user_id, conversation_id = '' } = conversation;

  return conversationApi.util.upsertQueryData(
    'getConversationById',
    { user_id, conversation_id },
    conversation,
  );
};

export const updateConversationState = ({
  user_id,
  conversation,
}: {
  user_id: string;
  conversation: Partial<Conversation>;
}) => {
  const { conversation_id = '' } = conversation;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      // TODO(olha): check why draft can be undefined
      if (draft) {
        Object.assign(draft, conversation);
      }
    },
  );
};

export const addTaskToConversation = (newTask: ApiTask) => {
  const { parent_conversation_id = '', user_id } = newTask;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id: parent_conversation_id },
    (draft) => {
      // TODO(olha): check why draft can be undefined
      if (draft) {
        draft.tasks ? draft.tasks.push(newTask) : (draft.tasks = [newTask]);
      }
    },
  );
};

export const updateTaskInConversation = ({
  user_id,
  task: updatedTask,
}: {
  user_id: string;
  task: Partial<ApiTask>;
}) => {
  const { parent_conversation_id = '', task_id } = updatedTask;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id: parent_conversation_id },
    (draft) => {
      const focusTask = draft?.tasks?.find((task) => task.task_id === task_id);

      if (!focusTask) {
        return;
      }

      Object.assign(focusTask, updatedTask);
    },
  );
};
