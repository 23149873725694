import { useState, useMemo } from 'react';
import classNames from 'classnames';
import { Button } from 'src/components/Button';
import { PlayCircle, Trash, WarningCircle } from '@phosphor-icons/react';
import { DownloadVideoButton } from '../DownloadVideoButton';
import { ShareButton } from 'src/components/ShareButton';
import { ModalWithVideo } from '../ModalWithVideo';
import { ProgressBar } from 'src/components/ProgressBar';
import { AvatarVideos } from 'src/types/models/AvatarVideos';
import { AvatarVideosStatus } from 'src/types/models/AvatarVideosStatus';
import { offlineRecordingApi } from 'src/store/services';

const SVG_SIZE = 24;

type VideoItemProps = {
  videoData: AvatarVideos;
};

export const VideoItem = ({ videoData }: VideoItemProps) => {
  const [isOpenPlayerModal, setIsOpenPlayerModal] = useState(false);

  const [deleteVideo] = offlineRecordingApi.useDeleteVideoByIdMutation();

  const handleDeleteVideo = () => {
    if (videoData.video_id) {
      deleteVideo(videoData.video_id);
    }
  };

  const hasResolution = useMemo(
    () => !!videoData.urls && videoData.urls.length > 0,
    [videoData],
  );

  return (
    <div
      className={classNames('nj-view-video-tab-content--video-item', {
        'direction-column': videoData.status === AvatarVideosStatus.PROCESSING,
      })}
    >
      <div className="nj-view-video-tab-content--video-item-name">
        <span className="nj-section--main-container-subtitle">
          {videoData.created_at
            ? new Date(videoData.created_at).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            : ''}
        </span>
        <h5 className="nj-view-video-tab-content--video-item-name-label ellipsis">
          {videoData.title}
        </h5>
      </div>

      {videoData.status === AvatarVideosStatus.AVAILABLE && hasResolution && (
        <div className="nj-view-video-tab-content--video-item-actions">
          <Button
            type="button"
            className="nj-view-video-tab-content--video-item-button"
            onClick={() => setIsOpenPlayerModal(true)}
          >
            <PlayCircle size={SVG_SIZE} />
          </Button>
          <DownloadVideoButton
            videoInfo={videoData}
            buttonIconSize={SVG_SIZE}
          />
          <ShareButton videoData={videoData} />
        </div>
      )}

      {videoData.status === AvatarVideosStatus.PROCESSING && (
        <div className="nj-view-video-tab-content--video-item-actions with-progress">
          <ProgressBar progress={videoData.progress || 0} />
          <Button
            type="button"
            className="nj-view-video-tab-content--video-item-button"
            onClick={handleDeleteVideo}
          >
            <Trash size={20} />
          </Button>
        </div>
      )}

      {(videoData.status === AvatarVideosStatus.FAILURE ||
        (videoData.status === AvatarVideosStatus.AVAILABLE &&
          !hasResolution)) && (
        <div className="nj-view-video-tab-content--video-item-actions">
          <WarningCircle size={SVG_SIZE} />
          <Button
            type="button"
            className="nj-view-video-tab-content--video-item-button"
            onClick={handleDeleteVideo}
          >
            <Trash size={20} />
          </Button>
        </div>
      )}
      <ModalWithVideo
        isOpen={isOpenPlayerModal}
        onClose={() => setIsOpenPlayerModal(false)}
        videoData={videoData}
      />
    </div>
  );
};
