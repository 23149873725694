import { useMemo } from 'react';
import classNames from 'classnames';
import { useSession, useThemeActions } from 'src/hooks';
import { ChatModeSidePanel, type DeepResearchResult } from 'src/types';
import type { ReferencedModelSummary } from 'src/types/models/ReferencedModelSummary';

interface SettingsMessageResearchCodeCardProps {
  cardType: 'researcher' | 'chit-chat' | 'chit-chat-card';
  deepResearchData?: DeepResearchResult;
  referencedModelSummary?: ReferencedModelSummary;
  isStreaming?: boolean;
}

/**
 * @deprecated
 */
export const SettingsMessageResearchCodeCard = ({
  cardType,
  deepResearchData,
  referencedModelSummary,
  isStreaming,
}: SettingsMessageResearchCodeCardProps) => {
  const {
    appUser: { settings },
  } = useSession();
  const { toggleSettingsPanelExpanded, updateChatModeSidePanel } =
    useThemeActions();

  const joinStringWithOr = (arr: string[]): string => {
    switch (arr.length) {
      case 0:
        return '';

      case 1:
        return arr[0];

      default:
        return `${arr.slice(0, -1).join(', ')} or ${arr[arr.length - 1]}`;
    }
  };

  const shouldSuggestDeepResearch = useMemo(
    () => !deepResearchData && !!settings?.research?.deep_research?.is_capable,
    [deepResearchData, settings],
  );

  const settingsOptions: string[] = useMemo(() => {
    const options: string[] = [];

    if (shouldSuggestDeepResearch && cardType === 'researcher') {
      options.push('SuperAgent');
    }

    return options;
  }, [shouldSuggestDeepResearch, cardType]);

  const title = useMemo(
    () => `Enable ${joinStringWithOr(settingsOptions)} in the `,
    [settingsOptions],
  );

  const handleNinjaAgentsClick = () => {
    updateChatModeSidePanel(ChatModeSidePanel.NINJA_AGENTS);
    toggleSettingsPanelExpanded(true);
  };

  if (settingsOptions.length === 0 || isStreaming || cardType === 'chit-chat') {
    return null;
  }

  return (
    <div
      className={classNames(
        'nj-upsell-message-research-code-card blue-gradient',
      )}
    >
      <span>
        {title}
        {shouldSuggestDeepResearch && cardType === 'researcher' && (
          <button
            type="button"
            onClick={handleNinjaAgentsClick}
            className="nj-upsell-message-research-code-card--settings-button"
          >
            Ninja agents.
          </button>
        )}
      </span>
    </div>
  );
};
