import { Check } from '@phosphor-icons/react';
// import { Button } from 'src/components/Button';

const SVG_SIZE = 24;

interface SuccessNotificationProps {
  videoTitle: string;
}

export const SuccessNotification = ({
  videoTitle,
}: SuccessNotificationProps) => {
  return (
    <div className="nj-avatar-recording--success-notification">
      <div className="nj-avatar-recording--success-notification-wrapper">
        <Check size={SVG_SIZE} />

        <p className="nj-avatar-recording--success-notification-content">
          <span className="title">Generating Video</span>
          <span className="subtitle">{videoTitle}</span>
        </p>
      </div>
    </div>
  );
};
