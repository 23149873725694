import { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { FormProvider } from 'src/components/FormProvider';
import { Modal } from 'react-responsive-modal';
import { Select } from 'src/components/Select';
import TextareaAutosize from 'react-textarea-autosize';
import { X } from '@phosphor-icons/react';
import { Checkbox } from 'src/components/Checkbox';
import styles from 'src/pages/ManageAccountPage/components/General/General.module.scss';
import { useBreakpoint } from 'src/hooks';
import { useMobileOrientation } from 'react-device-detect';
import { useCollapse } from 'react-collapsed';
import { DeleteAccountReason, DeleteAccountFormData } from 'src/types/api.extended';

type DeleteAccountOption = {
  label: string;
  value: DeleteAccountReason;
};

const subjectOptions: DeleteAccountOption[] = [
  { label: 'Privacy concerns', value: DeleteAccountReason.PRIVACY },
  { label: 'No longer using the service', value: DeleteAccountReason.UNUSED },
  { label: 'Unwanted communication', value: DeleteAccountReason.UNWANTED_COMMUNICATION },
  { label: 'Account security issues', value: DeleteAccountReason.SECURITY },
  { label: 'Financial reasons', value: DeleteAccountReason.FINANCIAL },
  { label: 'Dissatisfaction with the service', value: DeleteAccountReason.DISSATISFACTION },
  { label: 'Other', value: DeleteAccountReason.OTHER },
];

const requiredFieldErrorMessage = 'This field is required';
const MENU_HEIGHT = 150;

type AutoresizeRow = {
  minRows: number;
  maxRows: number;
};

type AutoresizeRows = {
  [key: string]: AutoresizeRow;
};

const AUTORESIZE_ROWS: AutoresizeRows = {
  defaults: { minRows: 8, maxRows: 12 },
};

interface DeleteAccountModalProps {
  open: boolean;
  title: string;
  submitTitle: string;
  onClose: () => void;
  onSubmit: (value: DeleteAccountFormData) => void;
  children: React.ReactNode;
}

const SVG_SIZE = 24;

export const DeleteAccountModal = ({
  open,
  title,
  submitTitle,
  onClose,
  onSubmit,
  children,
}: DeleteAccountModalProps) => {
  const { rootContainerRef } = useContext(ForwardRefContext);
  const methods = useForm<DeleteAccountFormData>();
  const { handleSubmit, watch, formState: { errors }, control, register } = methods;

  // State to track selected reason
  const [selectedReason, setSelectedReason] = useState<DeleteAccountReason | undefined>(undefined);

  const handleDeleteReasonChange = (value: DeleteAccountReason) => {
    setSelectedReason(value); // Track the selected reason
  };

  // Watch the checkbox value, selected reason, and textarea content
  const checkboxChecked = watch('delete-account-checkbox', false);
  const selectedReasonWatch = watch('selectedReason', undefined); // Use undefined as the default value
  const reasonTextWatch = watch('reasonText', '');

  const handleFormSubmit = (value: DeleteAccountFormData) => {
    onSubmit(value);
    onClose();
  };

  const { isMobile } = useBreakpoint();
  const { isPortrait } = useMobileOrientation();
  const [rows, setRows] = useState<AutoresizeRow>(AUTORESIZE_ROWS.defaults);

  useEffect(() => {
    const handleOrientationChange = () => {
      if (isMobile) {
        const rows = Math.floor((window.innerHeight - 300) / 25);
        setRows({ minRows: rows, maxRows: isPortrait ? rows + 4 : rows });
        return;
      }
      setRows(AUTORESIZE_ROWS.defaults);
    };

    handleOrientationChange();

    window.addEventListener('resize', handleOrientationChange);
    return () => window.removeEventListener('resize', handleOrientationChange);
  }, [isPortrait, isMobile]);


  // Conditions for showing the warning
  const shouldAllowSubmit = checkboxChecked && selectedReasonWatch && (selectedReasonWatch !== DeleteAccountReason.OTHER || reasonTextWatch.trim() !== '');

  const shouldShowWarning = checkboxChecked && (!selectedReasonWatch || (selectedReasonWatch === DeleteAccountReason.OTHER && reasonTextWatch.trim() === ''));

  // Collapse hook for the "Other" textarea
  const { getCollapseProps: getTextareaCollapseProps } = useCollapse({
    isExpanded: selectedReason === DeleteAccountReason.OTHER,
  });

  // Collapse hook for the warning message
  const { getCollapseProps: getWarningCollapseProps } = useCollapse({
    isExpanded: shouldShowWarning,
  });


  return (
    <Modal
      open={open}
      onClose={onClose}
      container={rootContainerRef?.current || document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: 'nj-modal--access',
      }}
      closeIcon={<X size={SVG_SIZE} />}
      center
    >
      <FormProvider<DeleteAccountFormData> methods={methods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="nj-modal--access--wrapper">
            <p className="nj-modal--access--title">{title}</p>

            {children}

            {/* Wrap the Select and Textarea in a container */}
            <div className="input-container" style={{ width: '100%' }}>
              <legend className="nj-settings-tab-content--delete-account-form-subtitle">Reason for deletion</legend>
              <Select
                control={control}
                name="selectedReason"
                options={subjectOptions}
                required={requiredFieldErrorMessage}
                errors={errors}
                menuPlacement="bottom"
                menuShouldScrollIntoView={false}
                maxMenuHeight={MENU_HEIGHT}
                defaultValues
                onChangeHandler={handleDeleteReasonChange}
              />

              <div {...getTextareaCollapseProps()}>
                <legend className="nj-settings-tab-content--delete-account-form-subtitle">Please specify your reason</legend>
                <TextareaAutosize
                  className={styles.deleteAccountTextArea}
                  placeholder="Type your reason here..."
                  minRows={rows.minRows}
                  maxRows={rows.maxRows}
                  {...register('reasonText')}
                />
              </div>
            </div>

            <table>
              <tbody>
                <tr>
                  <td>
                    <Checkbox label="" name="delete-account-checkbox" />
                  </td>
                  <td>
                    I confirm that I understand my account will be permanently deleted and cannot be recovered.
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div {...getWarningCollapseProps()}>
                      <div className={styles.dataControlContentRed} id="missingCloseReasonWarning" style={{ padding: '10px', width: '100%', borderWidth: '2px' }}>
                        * You must provide a reason to close your account
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="nj-modal--access--action-wrapper">
              <button
                type="button"
                className={styles.deleteAccountCancelButton}
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={
                  shouldAllowSubmit
                    ? `${styles.deleteAccountAcceptButton} ${styles.enabledButton}`
                    : `${styles.deleteAccountAcceptButton} ${styles.disabledButton}`
                }
                disabled={!shouldAllowSubmit}
              >
                {submitTitle}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
