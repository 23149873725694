import styles from 'src/v2/components/ManageUsers/ManageUsers.module.scss';
import { ManageAccountContentPage } from 'src/pages/ManageAccountPage/components/ManageAccountContentPage';
import { useContext, useEffect, useState } from 'react';
import { Button } from 'src/v2/commonComponents/Button';
import { Plus } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { AppRoutes, TeamsUsersTabs } from 'src/types';
import cn from 'classnames';
import { useBreakpoint, useTeamInfo, useSeats } from 'src/hooks';
import { TeamsUsersTable } from 'src/v2/components/TeamsUsersTable';
import { SeatStatus } from 'src/types/models/SeatStatus';
import { useNavigate } from 'react-router-dom';
import SessionContext from 'src/contexts/SessionContext';

export const ManageUsers = () => {
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();
  const [currentTable, setCurrentTable] = useState(TeamsUsersTabs.ACTIVE);
  const [nextPageActiveUsers, setNextPageActiveUsers] = useState('');
  const [nextPagePendingUsers, setNextPagePendingUsers] = useState('');

  const { teamInfo, isTeamInfoLoading, isOwner, isAdmin } = useTeamInfo();

  const { onChangeIsOpenInviteUsersModal } = useContext(SessionContext);

  const {
    filteredSeats: activeUsers,
    isSeatsListLoading: isActiveUsersLoading,
    isSeatsListFetching: isActiveUsersFetching,
    newPageToken: nextPageActiveUsersToken,
  } = useSeats({
    team_id: teamInfo?.team_id,
    statuses: SeatStatus.ASSIGNED,
    page_token: nextPageActiveUsers,
  });

  const {
    filteredSeats: pendingUsers,
    isSeatsListLoading: isPendingUsersLoading,
    isSeatsListFetching: isPendingUsersFetching,
    newPageToken: nextPagePendingUsersToken,
  } = useSeats({
    team_id: teamInfo?.team_id,
    statuses: SeatStatus.INVITE_PENDING,
    page_token: nextPagePendingUsers,
  });

  const isActiveUsersTable = currentTable === TeamsUsersTabs.ACTIVE;

  const currentUsersList = isActiveUsersTable ? activeUsers : pendingUsers;

  const hasMorePages = isActiveUsersTable
    ? !!nextPageActiveUsersToken
    : !!nextPagePendingUsersToken;

  const isLoading = isActiveUsersTable
    ? isActiveUsersLoading
    : isPendingUsersLoading;

  const isFetching = isActiveUsersTable
    ? isActiveUsersFetching
    : isPendingUsersFetching;

  const TablesList = [
    { value: TeamsUsersTabs.ACTIVE, count: teamInfo?.num_seats_assigned || 0 },
    {
      value: TeamsUsersTabs.PENDING,
      count: 0, // todo replace with real value from BE
    },
  ];

  const numberOfSeats = teamInfo ? teamInfo.num_seats_total : 0;

  const handleInviteUsersClick = () => {
    onChangeIsOpenInviteUsersModal(true);
  };

  const handleLoadMore = () => {
    if (currentTable === TeamsUsersTabs.ACTIVE) {
      setNextPageActiveUsers(nextPageActiveUsersToken);
    } else {
      setNextPagePendingUsers(nextPagePendingUsersToken);
    }
  };

  useEffect(() => {
    if (!isTeamInfoLoading && !(isOwner || isAdmin)) {
      navigate(AppRoutes.HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTeamInfoLoading, teamInfo]);

  return (
    <ManageAccountContentPage
      title="Manage Users"
      subtitle={`Seats available in workspace: ${numberOfSeats}`}
    >
      <>
        <div className={styles.root}>
          <div className={styles.buttonRow}>
            <div className={styles.tableButtonsWrapper}>
              {TablesList.map((item) => (
                <Button
                  key={item.value}
                  color="secondary"
                  onClick={() => setCurrentTable(item.value)}
                  className={cn(styles.tableSelectButton, {
                    [styles.active]: item.value === currentTable,
                  })}
                >
                  {item.value} users ({item.count})
                </Button>
              ))}
            </div>
            <Button onClick={handleInviteUsersClick}>
              <Plus size={SVG_SIZE_M} />
              {isMobile ? '' : 'Invite users'}
            </Button>
          </div>
          <TeamsUsersTable
            usersList={currentUsersList}
            isLoading={isLoading || isTeamInfoLoading}
            onLoadMore={handleLoadMore}
            hasMorePages={hasMorePages}
            isFetching={isFetching}
            statuses={
              isActiveUsersTable
                ? SeatStatus.ASSIGNED
                : SeatStatus.INVITE_PENDING
            }
            isActiveUsersTable={isActiveUsersTable}
          />
        </div>
      </>
    </ManageAccountContentPage>
  );
};
