import { useSession } from 'src/hooks';
import { UpsellMessage } from 'src/components/UpsellMessage';
import { RemainingTasksSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/RemainingTasksSection';
import styles from './WalletSection.module.scss';
import { useMemo } from 'react';
import { TrialDaysSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/TrialDaysSection';

export const WalletSection = () => {
  const { isOpenTier, isFreeTier, isProTrialTier, isEnterpriseTrialTier } =
    useSession();

  // TODO(olha): needs refactoring
  const description = useMemo(() => {
    if (isFreeTier || isOpenTier || isProTrialTier) {
      return 'Enjoy unlimited daily tasks, custom image settings, and access to the best AI models';
    }
    return '';
  }, [isFreeTier, isOpenTier, isProTrialTier]);

  return (
    <div className={styles.root}>
      {(isOpenTier || isFreeTier) && <RemainingTasksSection />}

      {(isProTrialTier || isEnterpriseTrialTier) && (
        <>
          <TrialDaysSection />
        </>
      )}

      {(isOpenTier || isFreeTier || isProTrialTier) && (
        <>
          <hr className="divider" />

          <UpsellMessage
            title={
              isOpenTier || isFreeTier || isProTrialTier
                ? 'Unlimited Tasks, Starting at $5/mo'
                : ''
            }
            description={description}
            className={styles.upsellMessage}
            dataGTMNinjaTier="Settings-upgrade"
            dataGTMOpenTier="Settings-wallet-section-signup"
          />
        </>
      )}
    </div>
  );
};
