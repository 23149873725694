import { useCallback } from 'react';
import {
  avatarState,
  clearAvatarQueue,
  clearNotification,
  sessionState,
  setAppUser,
  setAvatarQueue,
  setChatMode,
  setFirstUserQuery,
  setIsAccessModalShown,
  setOnboardingHintStep,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { AppUser, AvatarQueue, ChatMode } from 'src/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserTier } from 'src/types/models/UserTier';
import { updateRenewEarlyStatus } from 'src/store/slices/sessionSlice';

export const useSession = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    agent,
    appUser,
    notification,
    isSocketConnected,
    onboardingHintsStep,
    isAccessModalShown,
    isFirstSchedulerTask,
    isFirstUserQuery,
  } = useAppSelector(sessionState);
  const { chatMode, avatarQueue } = useAppSelector(avatarState);

  const dispatch = useAppDispatch();

  const updateChatMode = (mode: ChatMode) => {
    dispatch(setChatMode(mode));

    if (pathname !== '/' && mode !== ChatMode.CALENDAR) {
      navigate('/');
    }
  };

  const removeNotification = () => {
    dispatch(clearNotification());
  };

  const changeOnboardingHintStep = (value: number) => {
    dispatch(setOnboardingHintStep(value));
  };

  const updateAppUser = (user: AppUser) => {
    dispatch(setAppUser(user));
  };

  const updateAvatarQueue = (value?: AvatarQueue) => {
    if (!value) {
      dispatch(clearAvatarQueue());
    } else {
      dispatch(setAvatarQueue(value));
    }
  };

  const setRenewEarlyPending = () => {
    dispatch(
      updateRenewEarlyStatus({
        renew_early_pending_subscription: {
          subscription_id: '',
          period_start: '',
          period_end: '',
        },
      }),
    );
  };

  const updateIsAccessModalShown = (value: boolean) => {
    dispatch(setIsAccessModalShown(value));
  };

  const updateFirstUserQuery = useCallback(() => {
    dispatch(setFirstUserQuery());
  }, [dispatch]);

  return {
    notification,
    removeNotification,
    agent,
    chatMode,
    updateChatMode,
    appUser,
    updateAppUser,
    isSocketConnected,
    onboardingHintsStep,
    changeOnboardingHintStep,
    avatarQueue,
    updateAvatarQueue,
    isAccessModalShown,
    isFirstSchedulerTask,
    updateIsAccessModalShown,
    isFirstUserQuery,
    updateFirstUserQuery,
    isOpenTier:
      appUser?.tier_id === UserTier.OPEN || appUser?.tier_id === undefined,
    isFreeTier: appUser?.tier_id === UserTier.FREE,
    isStandardTier: appUser?.tier_id === UserTier.STANDARD,
    isProTier:
      appUser?.tier_id === UserTier.PRO ||
      // TODO(olha): PAID should be deprecated
      appUser?.tier_id === UserTier.PAID ||
      appUser?.tier_id === UserTier.TESTING,
    isUltraTier: appUser?.tier_id === UserTier.ULTRA,
    isSubscribedTier:
      // TODO(olha): PAID should be deprecated
      appUser?.tier_id === UserTier.PAID ||
      appUser?.tier_id === UserTier.STANDARD ||
      appUser?.tier_id === UserTier.PRO ||
      appUser?.tier_id === UserTier.ULTRA ||
      appUser?.tier_id === UserTier.TESTING ||
      appUser?.tier_id === UserTier.ENTERPRISE ||
      appUser?.tier_id === UserTier.TEAMS_STANDARD ||
      appUser?.tier_id === UserTier.TEAMS_PRO ||
      appUser?.tier_id === UserTier.TEAMS_ULTRA,
    // TODO(olha): experimental variable.
    isLimitedTier:
      appUser?.tier_id === UserTier.STANDARD ||
      appUser?.tier_id === UserTier.FREE ||
      appUser?.tier_id === UserTier.OPEN,
    isProTrialTier: appUser?.tier_id === UserTier.PRO_TRIAL,
    isTestTier: appUser?.tier_id === UserTier.TESTING,
    isEnterpriseTrialTier: appUser?.tier_id === UserTier.ENTERPRISE_TRIAL,
    isEnterpriseTier: appUser?.tier_id === UserTier.ENTERPRISE,
    isTeamsTier:
      appUser?.tier_id === UserTier.TEAMS_PRO ||
      appUser?.tier_id === UserTier.TEAMS_ULTRA ||
      appUser?.tier_id === UserTier.TEAMS_STANDARD,
    isTeamsProTier: appUser.tier_id === UserTier.TEAMS_PRO,
    isTeamsStandardTier: appUser?.user_id === UserTier.TEAMS_STANDARD,
    isTeamsUltraTier: appUser?.user_id === UserTier.TEAMS_ULTRA,
    tierId: appUser?.tier_id || UserTier.OPEN,
    setRenewEarlyPending,
  };
};
