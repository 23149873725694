import { useSession } from 'src/hooks/useSession';
import { useMemo } from 'react';
import { defineIfUserNinjaEmployee } from 'src/utils';

export const useCheckIsNinjaEmployee = () => {
  const { appUser } = useSession();

  const { email } = appUser;

  const isUserNinjaEmployee = useMemo(() => {
    return defineIfUserNinjaEmployee(email);
  }, [email]);

  return isUserNinjaEmployee;
};
