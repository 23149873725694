import { Users } from '@phosphor-icons/react';
import { NO_ATTENDEES } from '../../constants/defaultValues';
import { useMeetingCardFieldsWatch } from '../../hooks/useMeetingCardFieldsWatch';
import { Icon } from 'src/components/Icon';
import type { MeetingCardFormData } from '../../CreateMeetingCard';

const SVG_SIZE = 20;
const CONTACT_ICON_SIZE = 20;

export const GuestsNotesDefaultValue = () => {
  const { event_description, confirm_guest_availability, attendees } =
    useMeetingCardFieldsWatch<MeetingCardFormData>();

  return (
    <>
      {attendees && attendees.length > 0 ? (
        <div className="nj-create-meeting-card--attendees-default-list">
          {attendees.map(({ name, email }, index) => (
            <div
              key={index}
              className="nj-create-meeting-card--attendees-default-item"
            >
              {!name ? (
                <Users size={SVG_SIZE} />
              ) : (
                <Icon
                  alt={name.charAt(0).toUpperCase()}
                  size={CONTACT_ICON_SIZE}
                />
              )}
              <span>{name || email || ''}</span>
            </div>
          ))}
        </div>
      ) : (
        <span>{NO_ATTENDEES}</span>
      )}

      {confirm_guest_availability && (
        <span>Confirm with guest for availability</span>
      )}

      <span>{event_description || 'No description'}</span>
    </>
  );
};
