import './UpgradeForm.scss';
import {
  GTMEvent,
  PaymentPlanInterval,
  PlanSectionType,
  SubscriptionPlan,
} from 'src/types';
import {
  PRO_FEATURES,
  PRO_MODELS,
  SVG_SIZE_M,
  ULTRA_FEATURES,
  ULTRA_MODELS,
  STANDARD_MODELS,
  STANDARD_FEATURES,
  TEAMS_FEATURES,
} from 'src/constants';
import { Check, CheckCircle, Image } from '@phosphor-icons/react';
import { EXTERNAL_MODELS_LINK } from 'src/constants/externalLinks';
import { useCallback, useMemo } from 'react';
import { sendGTMEvent } from 'src/utils';
import { useSession } from 'src/hooks';

type UpgradeFormProps = {
  onOpenCheckout: (type: PlanSectionType, period: PaymentPlanInterval) => void;
  period: PaymentPlanInterval;
  type: PlanSectionType;
  isSelected: boolean;
  selectedPlan?: SubscriptionPlan;
  isTeams?: boolean;
};

export const UpgradeForm = ({
  onOpenCheckout,
  period,
  type,
  isSelected,
  selectedPlan,
  isTeams,
}: UpgradeFormProps) => {
  const { isProTier, isUltraTier, isStandardTier, isSubscribedTier } =
    useSession();
  const isMonthlyPeriod = period === PaymentPlanInterval.MONTHLY;

  const isProType = type === 'Pro' || type === 'Teams_Pro';
  const isStandardType = type === 'Standard' || type === 'Teams_Standard';
  const isUltraType = type === 'Ultra' || type === 'Teams_Ultra';

  const roundAmount = useCallback((value?: number) => {
    if (value !== 0 && !value) {
      return value;
    }
    return Math.ceil(value / 100);
  }, []);

  const originalPriceLabel = useMemo(() => {
    if (!selectedPlan?.original_price) {
      return '-';
    }
    return roundAmount(
      isMonthlyPeriod
        ? selectedPlan?.original_price
        : selectedPlan?.original_price / 12,
    );
  }, [selectedPlan?.original_price, isMonthlyPeriod, roundAmount]);

  const discountedPriceLabel = useMemo(() => {
    if (!selectedPlan?.discounted_price) {
      return '-';
    }
    return roundAmount(
      isMonthlyPeriod
        ? selectedPlan?.discounted_price
        : selectedPlan?.discounted_price / 12,
    );
  }, [selectedPlan?.discounted_price, isMonthlyPeriod, roundAmount]);

  const isDowngrade = useMemo(
    () =>
      ((isUltraTier && type === 'Pro') || type === 'Standard') &&
      isSubscribedTier,
    [type, isUltraTier, isSubscribedTier],
  );

  const isCurrentPlan = useMemo(() => {
    return (
      (isStandardTier && type === 'Standard') ||
      (isProTier && type === 'Pro') ||
      (isUltraTier && type === 'Ultra')
    );
  }, [isStandardTier, isProTier, isUltraTier, type]);

  const buttonLabel = useMemo(() => {
    if (isCurrentPlan) {
      return 'Current plan';
    }

    if (type === 'Standard' && isSubscribedTier) {
      return 'Downgrade to Standard';
    }

    if (type === 'Ultra' && isSubscribedTier) {
      return 'Upgrade to Ultra';
    }

    if (type === 'Pro' && isUltraTier) {
      return 'Downgrade to Pro';
    }

    if (type === 'Pro' && isStandardTier) {
      return 'Upgrade to Pro';
    }

    return `Get ${isTeams ? 'Team ' : ''}${isTeams ? type.split('_')[1] : type}`;
  }, [
    isStandardTier,
    isUltraTier,
    isSubscribedTier,
    type,
    isCurrentPlan,
    isTeams,
  ]);

  const features = useMemo(() => {
    if (isStandardType) {
      return STANDARD_FEATURES;
    }
    if (isProType) {
      return PRO_FEATURES;
    }
    return ULTRA_FEATURES;
  }, [isStandardType, isProType]);

  const models = useMemo(() => {
    if (isStandardType) {
      return STANDARD_MODELS;
    }
    if (isProType) {
      return PRO_MODELS;
    }
    return ULTRA_MODELS;
  }, [isStandardType, isProType]);

  const handleViewAllModelsClick = () => {
    sendGTMEvent(GTMEvent.PAYMENT_VIEW_ALL_MODELS_CLICK);
  };

  return (
    <div className="nj-upgrade-form">
      <div className="nj-plan-section--current-plan">
        <div className="nj-plan-section--header-wrapper">
          <h5 className="nj-plan-section--title-wrapper">
            <span className="nj-plan-section--title">
              {isTeams ? 'Team ' : ''}
              {isTeams ? type.split('_')[1] : type}
            </span>

            {isMonthlyPeriod &&
              !!selectedPlan?.one_time_discount_percentage && (
                <p className="nj-plan-section--current-plan-sales-description">
                  {`${selectedPlan?.one_time_discount_percentage}% off first month`}
                </p>
              )}

            {period === PaymentPlanInterval.ANNUALLY && (
              <p className="nj-plan-section--current-plan-sales-description">
                Billed annually
              </p>
            )}
          </h5>

          <p className="nj-plan-section--current-plan-price">
            {selectedPlan?.discounted_price !== 0 && (
              <span className="nj-plan-section--current-plan-original-price">
                <s>${originalPriceLabel}</s>
                &nbsp;
              </span>
            )}
            <span className="nj-plan-section--current-plan-title">
              $
              {selectedPlan?.discounted_price !== 0
                ? discountedPriceLabel
                : originalPriceLabel}
              &nbsp;
            </span>
            <span className="nj-plan-section--current-plan-subtitle">
              {isTeams ? 'per seat/month' : '/mo'}
            </span>
          </p>

          <span className="nj-plan-section--current-plan-description colored">
            Unlimited tasks
          </span>
        </div>
      </div>

      <div>
        {isSelected ? (
          <button type="button" className="nj-upgrade-form--selected" disabled>
            <CheckCircle size={SVG_SIZE_M} weight="fill" />
            <span>Selected</span>
          </button>
        ) : (
          <button
            type="button"
            className={`nj-button nj-upgrade-form--submit ${isDowngrade ? 'nj-downgrade-button' : ''}`}
            onClick={() => onOpenCheckout(type, period)}
            id="Plan-card-upgrade"
            disabled={isCurrentPlan}
            data-e2e={`upgrade-to-${type}-button`}
          >
            {buttonLabel}
          </button>
        )}

        <p className="nj-upgrade-form--caption">
          Cancel anytime, effective next billing cycle
        </p>
      </div>

      <div className="nj-plan-section--current-plan-features-list">
        <h5 className="nj-plan-section--current-plan-features-list-title">
          {isStandardType && 'Includes'}
          {isProType && `Everything in ${isTeams ? 'Team ' : ''}Standard, plus`}
          {isUltraType && `Everything in ${isTeams ? 'Team ' : ''}Pro, plus`}
        </h5>
        {features.map((item) => (
          <div
            className="nj-plan-section--current-plan-feature-row"
            key={item.id}
          >
            <Check size={SVG_SIZE_M} />
            <span>{item.title}</span>
          </div>
        ))}
        {isTeams &&
          TEAMS_FEATURES.map((item) => (
            <div
              className="nj-plan-section--current-plan-feature-row"
              key={item.id}
            >
              <Check size={SVG_SIZE_M} />
              <span>{item.title}</span>
            </div>
          ))}
        <hr className="divider" />
        <div className="nj-plan-section--current-plan-models-list">
          <h5 className="nj-plan-section--current-plan-models-list-title">
            <Check size={SVG_SIZE_M} />
            <span>
              <span className="colored">Unlimited </span>
              <span>
                {isStandardType && 'access to 3 AI models'}
                {isProType && 'access to 8 AI models'}
                {isUltraType && 'access to 20+ premium AI models'}
              </span>
            </span>
          </h5>
          <div className="nj-plan-section--current-plan-models-list-content">
            {models.map((item) => (
              <div
                className="nj-plan-section--current-plan-models-list-item"
                key={item.id}
              >
                {item.icon}
                <div>{item.text}</div>
                {item.isShowImageIcon && (
                  <div className="nj-plan-section--current-plan-models-list-item-image">
                    <Image size={SVG_SIZE_M} weight="fill" />
                  </div>
                )}
              </div>
            ))}
            {isUltraType && (
              <a
                href={EXTERNAL_MODELS_LINK}
                target="_blank"
                rel="noreferrer"
                className="nj-plan-section--current-plan-models-list-view-all-link"
                onClick={handleViewAllModelsClick}
              >
                View all models
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
