import { avatarEmotionOptions } from 'src/constants/metahuman';

export const convertArrayToString = (array: (string | undefined)[]) => {
  return array.filter((item) => !!item).join(', ');
};

export const getVideoScriptSubtitle = (
  script?: { text?: string; imageURL?: string }[],
) => {
  if (!script || script.length === 0) {
    return 'Add content for Avatar to play';
  }
  const scriptLength = script.filter((item) => !!item.text).length;
  return scriptLength > 0
    ? `${scriptLength} dialogue(s)`
    : 'Add content for Avatar to play';
};

export const getAvatarSettingsSubtitle = (emotion?: string) => {
  return (
    avatarEmotionOptions.find((item) => item.value === emotion)?.label || ''
  );
};

export const getVideoFormatSubtitle = (
  _4k?: boolean,
  portrait?: boolean,
  square?: boolean,
) => {
  return convertArrayToString([
    _4k ? '16:9 4K' : '',
    portrait ? '9:16' : '',
    square ? '1:1' : '',
  ]);
};
