import { MagicWand } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { useAttachFile } from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';

type EditImageButtonProps = {
  url: string;
  isDisabled?: boolean;
};

// TODO(olga): to avoid logic duplication would be nice to have a common component for Edit Image
export const EditImageButton = ({ url, isDisabled }: EditImageButtonProps) => {
  const { sendReceivedFile } = useAttachFile();

  const handleClick = async () => {
    await sendReceivedFile(url);
  };

  return (
    <Button
      type="button"
      onClick={handleClick}
      disabled={isDisabled}
      color="secondary"
    >
      <MagicWand size={SVG_SIZE_M} />
      Edit image
    </Button>
  );
};
