import dayjs from 'dayjs';
import { PAYMENTS_DATE_FORMAT, TIER_DATA } from 'src/constants';
import {
  useBreakpoint,
  useSession,
  useSubscriptionInfo,
  useSubscriptionPlans,
  useTeamInfo,
} from 'src/hooks';
import { LabelPro } from 'src/components/LabelPro';
import './SubscriptionPlansForm.scss';
import { LabelFree } from 'src/components/LabelFree';
import { LabelEnterpriseTrial } from 'src/components/LabelEnterpriseTrial';
import { LabelUltra } from 'src/components/LabelUltra';
import { ChangePlanButton } from 'src/components/ChangePlanButton';
import { UpgradeButton } from 'src/components/UpgradeButton';
import LabelStandard from 'src/components/LabelStandard/LabelStandard';
import { PaymentPlanInterval } from 'src/types';
import { Button } from 'src/v2/commonComponents/Button';
import { LabelAnnual } from 'src/components/LabelAnnual';
import { LabelTeamsPro } from 'src/components/LabelTeamsPro';
import { LabelTeamsUltra } from 'src/components/LabelTeamsUltra';
import { LabelTeamsStandard } from 'src/components/LabelTeamsStandard';
import { UserTier } from 'src/types/models/UserTier';

type SubscriptionPlansFormProps = {
  onOpenCheckout: () => void;
};

// TODO(olha): divide to small components
export const SubscriptionPlansForm = ({
  onOpenCheckout,
}: SubscriptionPlansFormProps) => {
  const {
    appUser,
    isProTrialTier,
    isFreeTier,
    isEnterpriseTrialTier,
    isSubscribedTier,
    isStandardTier,
    isTeamsTier,
    tierId,
  } = useSession();

  const { teamInfo, isMember } = useTeamInfo();

  const { isMobile } = useBreakpoint();

  const {
    subscriptionInfo,
    isSubscriptionInfoLoading,
    isSubscriptionInfoFetching,
  } = useSubscriptionInfo();

  const { plans, isPlansLoading } = useSubscriptionPlans({
    group: isTeamsTier ? 'teams' : 'individual',
  });

  if (isSubscriptionInfoLoading) {
    return null;
  }

  const annuallyPlanInfo = plans
    ? plans.filter(
        (item) =>
          item.plan_tier === (appUser.tier_id as string) &&
          item.period === PaymentPlanInterval.ANNUALLY,
      )[0]
    : null;

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: subscriptionInfo?.price_info?.currency || 'USD',
  });

  const amountInfo = moneyFormatter.format(
    subscriptionInfo?.price_info?.amount || 0,
  );

  const cancelledDate = dayjs(
    subscriptionInfo?.curr_period_end_timestamp,
  ).format(PAYMENTS_DATE_FORMAT);

  const upcomingPayment = subscriptionInfo?.is_cancelled
    ? 'None'
    : `${amountInfo} on ${cancelledDate}`;

  const handleChangeBillingCycle = () => {
    onOpenCheckout();
  };

  const isShowUpdateToAnnually =
    subscriptionInfo?.interval === PaymentPlanInterval.MONTHLY &&
    !isPlansLoading &&
    !subscriptionInfo?.is_cancelled &&
    !subscriptionInfo?.has_scheduled_plan_change &&
    !subscriptionInfo?.has_scheduled_plan_change &&
    !subscriptionInfo?.processing_plan_change &&
    !isSubscriptionInfoFetching &&
    !isSubscriptionInfoLoading;

  const renderUpcomingLabel = () => {
    if (subscriptionInfo?.scheduled_plan_name === appUser.tier_id) {
      return <LabelAnnual />;
    }

    switch (subscriptionInfo?.scheduled_plan_name) {
      case UserTier.PRO:
      case UserTier.PAID:
        return <LabelPro />;
      case UserTier.ULTRA:
        return <LabelUltra />;
      case UserTier.STANDARD:
        return <LabelStandard />;
      case UserTier.TEAMS_PRO:
        return <LabelTeamsPro />;
      case UserTier.TEAMS_ULTRA:
        return <LabelTeamsUltra />;
      case UserTier.TEAMS_STANDARD:
        return <LabelTeamsStandard />;
      default:
        return null;
    }
  };

  return (
    <div className="nj-subscription-plans-form">
      <div className="nj-subscription-plans-form--container">
        <div className="nj-subscription-plans-form--header-wrapper">
          <p className="nj-subscription-plans-form--header">
            <span className="nj-subscription-plans-form--title">
              Your subscription
            </span>

            <span className="nj-subscription-plans-form--description">
              {/*todo temporary hided till upgrade plan is implemented*/}
              {/*{isSubscribedTier &&*/}
              {/*  'Edit or cancel your subscription. Choose between monthly and yearly billing. All yearly billing plans will receive a 30% discount.'}*/}
              {isFreeTier &&
                'Get unlimited access to Ninja’s AI Assistant and all Pro features. Cancel anytime.'}
              {isProTrialTier &&
                'Continue enjoying Pro features - Upgrade now!'}
              {isEnterpriseTrialTier &&
                'Continue enjoying Enterprise features - Upgrade now!'}
            </span>
          </p>
        </div>

        {isTeamsTier && (
          <div className="nj-subscription-plans-form--field">
            <p className="nj-subscription-plans-form--label">Workspace name</p>
            <div className="nj-subscription-plans-form--value">
              {teamInfo?.team_name}
            </div>
          </div>
        )}

        <div className="nj-subscription-plans-form--field">
          <p className="nj-subscription-plans-form--label">Plan</p>

          {isEnterpriseTrialTier && (
            <p className="nj-subscription-plans-form--value">
              <LabelEnterpriseTrial />
            </p>
          )}
          {(isProTrialTier || isSubscribedTier) && (
            <div className="nj-subscription-plans-form--value-wrapper">
              {TIER_DATA[tierId].labelLong}
              {subscriptionInfo && subscriptionInfo?.is_cancelled && (
                <div className="nj-subscription-plans-form--cancelled-label">
                  Plan will be canceled on &nbsp;
                  <span className="nj-subscription-plans-form--cancelled-date">
                    {cancelledDate}
                  </span>
                </div>
              )}
            </div>
          )}
          {isFreeTier && (
            <p className="nj-subscription-plans-form--value">
              <LabelFree />
            </p>
          )}
        </div>

        {isSubscribedTier && !isMember && (
          <>
            <div className="nj-subscription-plans-form--field">
              <p className="nj-subscription-plans-form--label">Billing cycle</p>
              <div className="nj-subscription-plans-form--billing-cycle-row">
                <p className="nj-subscription-plans-form--value capitalize">
                  {subscriptionInfo?.interval}
                </p>
                {isShowUpdateToAnnually && (
                  <>
                    {!isMobile && <span>|</span>}
                    <Button
                      color="transparent"
                      onClick={handleChangeBillingCycle}
                      className="nj-subscription-plans-form--change-billing-cycle-button"
                    >
                      Get annual subscription{' '}
                      {!isStandardTier &&
                        `for ${annuallyPlanInfo?.recurring_discount_percentage}% off`}
                    </Button>
                  </>
                )}
              </div>
            </div>

            {isTeamsTier && !isMember && (
              <div className="nj-subscription-plans-form--field">
                <p className="nj-subscription-plans-form--label">Seats</p>
                <div className="nj-subscription-plans-form--value">
                  {teamInfo?.num_seats_total}
                </div>
              </div>
            )}

            <div className="nj-subscription-plans-form--field">
              <p className="nj-subscription-plans-form--label">
                Upcoming Payment
              </p>
              <p className="nj-subscription-plans-form--value">
                {upcomingPayment}
              </p>

              {(subscriptionInfo?.has_scheduled_downgrade ||
                subscriptionInfo?.has_scheduled_plan_change ||
                subscriptionInfo?.processing_plan_change) &&
                !subscriptionInfo?.is_cancelled && <>{renderUpcomingLabel()}</>}
            </div>
          </>
        )}
      </div>

      {(isFreeTier || isProTrialTier) && (
        <UpgradeButton dataGTM="Subscription-upgrade" />
      )}

      {!isFreeTier && !isProTrialTier && <ChangePlanButton />}
    </div>
  );
};
