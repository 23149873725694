import { useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import { RadioButton as RadioButtonIcon, Circle } from '@phosphor-icons/react';
import { ChangeEvent } from 'react';

const SVG_SIZE = 24;

interface RadioButtonProps<T extends string> {
  name: string;
  label: string | JSX.Element;
  value: T;
  onChangeHandler?: (value: T) => void;
  required?: boolean;
  className?: string;
  labelClassName?: string;
}

export const RadioButton = <T extends string>({
  name,
  value,
  label,
  onChangeHandler,
  required,
  className,
  labelClassName,
}: RadioButtonProps<T>) => {
  const { register, control } = useFormContext();
  const fieldValues = useWatch({ control });

  return (
    <div className={classNames('nj-radio-button', className)}>
      <input
        type="radio"
        id={value}
        value={value}
        className="nj-radio-button--input"
        {...register(name, { required })}
        onChange={(data: ChangeEvent<HTMLInputElement>) => {
          register(name).onChange(data);
          if (onChangeHandler) {
            onChangeHandler(data.target.value as T);
          }
        }}
      />

      <label
        htmlFor={value}
        className={classNames('nj-radio-button--label', labelClassName)}
      >
        <span>{label}</span>

        {!!fieldValues[name] && fieldValues[name] === value ? (
          <span className="nj-radio-button--label-icon">
            <RadioButtonIcon
              weight="fill"
              size={SVG_SIZE}
              color="currentColor"
            />
          </span>
        ) : (
          <div className="nj-radio-button--label-icon-not-checked">
            <Circle color="currentColor" size={SVG_SIZE} />
          </div>
        )}
      </label>
    </div>
  );
};
