import { ReactNode, RefObject, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useBreakpoint } from 'src/hooks';

interface AppearingFromRightProps {
  inProp: boolean;
  nodeRef: RefObject<HTMLElement>;
  children: ReactNode;
  unmountOnExit?: boolean;
  timeout?: number;
  onExited?: () => void;
}

export const AppearingFromRight = ({
  inProp,
  nodeRef,
  children,
  timeout = 450,
  unmountOnExit = true,
  onExited,
}: AppearingFromRightProps) => {
  const { isMobile, isTablet } = useBreakpoint();

  // TODO(olha): there is a bug, animation doesn't work in Desktop. Will be fixed later
  const animationClassNames = useMemo(() => {
    if (isMobile) {
      return 'nj-animate-fade';
    }
    if (isTablet) {
      return 'nj-animate-horizontal-slow-appearance';
    }
    return 'nj-animate-right-panel-width-reduce';
  }, [isMobile, isTablet]);

  return (
    <CSSTransition
      in={inProp}
      timeout={timeout}
      classNames={animationClassNames}
      nodeRef={nodeRef}
      unmountOnExit={unmountOnExit}
      onExited={onExited}
    >
      {children}
    </CSSTransition>
  );
};
