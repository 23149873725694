import { useMemo, forwardRef } from 'react';
import { ActionStatue, Action } from 'src/types';
import styles from './LatestProgressAction.module.scss';

interface LatestProgressActionProps {
  actions?: Action[];
}
export const LatestProgressAction = forwardRef<
  HTMLParagraphElement,
  LatestProgressActionProps
>(({ actions = [] }, ref) => {
  const allCompleted = useMemo(
    () => actions.every((action) => action.status === ActionStatue.COMPLETED),
    [actions],
  );

  if (allCompleted) {
    return <p ref={ref} className={styles.root} />;
  }

  const firstInprogressAction =
    actions.find((item) => item.status !== ActionStatue.COMPLETED)?.action ||
    'Thinking...';

  return (
    <p ref={ref} className={styles.root}>
      {firstInprogressAction}
    </p>
  );
});
