import { createApi } from '@reduxjs/toolkit/query/react';
import { baseTasksQuery } from './config';
import { CreateFeedbackRequest } from 'src/types/models/CreateFeedbackRequest';
import { PatchFeedbackRequest } from 'src/types/models/PatchFeedbackRequest';
import { FeedbackResponse } from 'src/types/models/FeedbackResponse';
import { DeleteFeedbackRequest } from 'src/types/models/DeleteFeedbackRequest';

export const feedbackApi = createApi({
  reducerPath: 'feedbackApi',
  baseQuery: baseTasksQuery,
  endpoints: (build) => ({
    createFeedback: build.mutation<FeedbackResponse, CreateFeedbackRequest>({
      query: (body) => ({
        url: '/feedback',
        method: 'POST',
        body,
      }),
    }),
    updateFeedback: build.mutation<FeedbackResponse, PatchFeedbackRequest>({
      query: (body) => ({
        url: `/feedback/${body.feedback_id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteFeedback: build.mutation<FeedbackResponse, DeleteFeedbackRequest>({
      query: (body) => ({
        url: `/feedback/${body.feedback_id}`,
        method: 'DELETE',
        body,
      }),
    }),
  }),
});

export const {
  useCreateFeedbackMutation,
  useUpdateFeedbackMutation,
  useDeleteFeedbackMutation,
} = feedbackApi;
