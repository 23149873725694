import styles from './SummaryRow.module.scss';

interface SummaryRowProps {
  label: string;
  value: string;
}

export const SummaryRow = ({ label, value }: SummaryRowProps) => {
  return (
    <div className={styles.root}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
};
