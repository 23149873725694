import { useGetSeatsListQuery } from 'src/store/services';
import { useMemo } from 'react';
import { sessionState, useAppSelector } from 'src/store';
import { SeatStatus } from 'src/types/models/SeatStatus';

type SeatsParams = {
  team_id?: string;
  statuses: SeatStatus;
  page_token?: string;
};

export const useSeats = ({ team_id, statuses, page_token }: SeatsParams) => {
  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);

  const {
    data: seatsListData,
    isLoading: isSeatsListLoading,
    isFetching: isSeatsListFetching,
  } = useGetSeatsListQuery(
    {
      user_id: user_id || '',
      team_id: team_id || '',
      statuses: statuses,
      page_token,
    },
    { skip: !team_id || !user_id },
  );

  return useMemo(
    () => ({
      filteredSeats: seatsListData?.seats || [],
      isSeatsListLoading,
      isSeatsListFetching,
      newPageToken: seatsListData?.b64_serialized_last_evaluated_key || '',
    }),
    [seatsListData, isSeatsListLoading, isSeatsListFetching],
  );
};
