import { usersApi } from 'src/store/services';
import { AppUser, UserSettings } from 'src/types';
import { useSession } from 'src/hooks';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import { setPartialAppUser, useAppDispatch } from 'src/store';

const SAVED_TEXT = 'Saved';
const TOAST_ID = 'updateUserDataToast';

export const useUserData = () => {
  const { appUser } = useSession();
  const dispatch = useAppDispatch();

  const [updateUserMutation] = usersApi.useUpdateUserMutation();

  const updateUserData = useCallback(
    async (
      data: Partial<AppUser>,
      showNotificationToast = true,
      notificationText?: string,
    ) => {
      const request = {
        id: appUser.user_id,
        body: {
          ...data,
        },
      };

      dispatch(setPartialAppUser(data));

      await updateUserMutation(request).unwrap();

      // display toast
      if (showNotificationToast) {
        if (!toast.isActive(TOAST_ID)) {
          toast(notificationText || SAVED_TEXT, { toastId: TOAST_ID });
        }
      }
    },
    [updateUserMutation, dispatch, appUser],
  );

  const updateUserSettings = useCallback(
    async (data: Partial<UserSettings>, notificationText?: string) => {
      await updateUserData(
        {
          settings: {
            ...appUser.settings,
            ...data,
          },
        },
        true,
        notificationText,
      );
    },
    [updateUserData, appUser],
  );

  return {
    updateUserData,
    updateUserSettings,
  };
};
