import classNames from 'classnames';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { CaretDown } from '@phosphor-icons/react';

const SVG_SIZE = 20;

export enum ColorType {
  HEX = 'HEX',
  RGB = 'RGB',
}

interface ColorTypeSelectorProps {
  selectedValue: ColorType;
  onChange: (value: ColorType) => void;
}

export const ColorTypeSelector = ({
  selectedValue,
  onChange,
}: ColorTypeSelectorProps) => {
  return (
    <Menu
      menuClassName="nj-multi-menu"
      menuButton={
        <MenuButton className="nj-color-picker--type-selector-button">
          <span>{selectedValue}</span>
          <CaretDown size={SVG_SIZE} />
        </MenuButton>
      }
      gap={-70}
    >
      {Object.values(ColorType).map((item) => (
        <MenuItem
          key={item}
          className={classNames({ selected: selectedValue === item })}
          onClick={() => onChange(item)}
        >
          <div className="nj-multi-menu--menu-item">{item}</div>
        </MenuItem>
      ))}
    </Menu>
  );
};
