import { Message } from 'src/types';

type DebugInfoProps = {
  message: Message;
};

export const DebugInfo = ({ message }: DebugInfoProps) => {
  return (
    <p className="nj-debug bold">
      <span>
        message: <b>{JSON.stringify(message)}</b>
      </span>
    </p>
  );
};
