import { useMemo } from 'react';
import { Message, TaskState } from 'src/types';
import { formatTimestampToDateAndTime } from 'src/utils';
import { useTimeZone } from 'src/hooks';

interface ConfirmButtonProps {
  message: Message;
  isProcessed?: boolean;
  taskState?: TaskState;
}

export const ConfirmButton = ({
  message,
  taskState,
  isProcessed,
}: ConfirmButtonProps) => {
  const { userTimeZone } = useTimeZone();

  const submissionTime = useMemo(
    () => formatTimestampToDateAndTime(message.timestamp, userTimeZone),
    [message.timestamp, userTimeZone],
  );

  const isCancelled =
    taskState === TaskState.STOPPED || taskState === TaskState.STOPPING;

  if (isCancelled) {
    return null;
  }

  if (!isProcessed) {
    return (
      <button
        type="submit"
        className="nj-button nj-thread-combined-card--action-button active"
      >
        Confirm & submit
      </button>
    );
  }

  return (
    <i
      className="nj-task-view--form--submitted"
      data-e2e="create-meeting-card-submited-time"
    >
      Confirmed on {submissionTime}
    </i>
  );
};
