import { useMemo } from 'react';
import { Check } from '@phosphor-icons/react';
import { ImageGenerationModels, SVG_SIZE_M } from 'src/constants';
import { SwitchSimple } from 'src/v2/commonComponents/SwitchSimple';
import { useSession, useUserData } from 'src/hooks';
import { ImageGenSettings } from 'src/types';
import log from 'src/utils/logger';
import styles from './InternalSection.module.scss';

// TODO(olha): create for item common component
const InternalSection = () => {
  const {
    appUser: { settings },
  } = useSession();
  const { updateUserSettings } = useUserData();

  const pixArtData = useMemo(
    () =>
      settings?.image_gen_settings?.models?.find(
        (item) => item.model === ImageGenerationModels.PIX,
      ),
    [settings?.image_gen_settings?.models],
  );

  const handleChange = async (checked: boolean) => {
    const updatedModels = (settings?.image_gen_settings?.models || []).map(
      (item) => {
        return item.model === ImageGenerationModels.PIX
          ? {
              ...item,
              enabled: checked,
            }
          : item;
      },
    );

    const image_gen_settings: ImageGenSettings = {
      ...(settings?.image_gen_settings || {}),
      models: updatedModels,
    };

    try {
      await updateUserSettings({ image_gen_settings });
    } catch (e) {
      log.error(e);
    }
  };

  return (
    <div>
      <div className={styles.item}>
        <div className={styles.content}>
          <p className={styles.title}>Ninja 405B</p>
          <p className={styles.description}>Agentic Llama 3.1 405B</p>
        </div>

        <Check size={SVG_SIZE_M} />
      </div>

      <div className={styles.item}>
        <div className={styles.content}>
          <p className={styles.title}>Ninja FLUX</p>
          <p className={styles.description}>Images by FLUX.1</p>
        </div>

        <Check size={SVG_SIZE_M} />
      </div>

      <div className={styles.item}>
        <div className={styles.content}>
          <p className={styles.title}>Ninja PixArt</p>
          <p className={styles.description}>Images by PixArt-Sigma</p>
        </div>

        <SwitchSimple
          isDisabled={!pixArtData?.is_capable}
          isChecked={!!pixArtData?.enabled}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default InternalSection;
