import { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { usePortal } from 'src/hooks';

const DEFAULT_PORTAL_ID = 'portal-root';

interface PortalWrapperProps {
  children: ReactNode;
  rootId?: string;
}

export const PortalWrapper = ({
  children,
  rootId = DEFAULT_PORTAL_ID,
}: PortalWrapperProps) => {
  const portalContainer = usePortal(rootId);

  if (!portalContainer) {
    return null;
  }

  return ReactDOM.createPortal(children, portalContainer);
};
