import { isString, isBoolean, isObject } from './general.guard';
import {
  TaskState,
  TaskSkill,
  TaskChannel,
  FeedbackType,
  ResearchTaskPayload,
  ResearchTaskPayloadKind,
  ApiTask,
} from '../api';
import { ApiTaskSelectable } from '../api.extended';
import { isSchedulingPayload } from './api.scheduling.guard';
import { isReservationDetails } from './api.reservation.guard';
import { isConversation } from './api.conversation.guard';
import log from 'src/utils/logger';

/**
 * isTaskStatus() tests data against TaskState data type.
 * @param data unknown
 * @returns boolean
 */
export function isTaskStatus(data: unknown): data is TaskState {
  const state = data as TaskState;

  if (!Object.values(TaskState).includes(state)) {
    log.debug(
      `isTaskStatus > invalid task state ${state} in ${JSON.stringify(data)}`,
    );
    return false;
  }

  return true;
}

/**
 * isResearchTaskPayload() tests data to be of ResearchTaskPayload type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchTaskPayload(
  data: unknown,
): data is ResearchTaskPayload {
  const payload = data as ResearchTaskPayload;

  if ('payload_type' in payload && payload.payload_type !== 'research-task') {
    return false;
  }

  if ('query' in payload && !isString(payload.query)) {
    log.debug(
      `isResearchTaskPayload > invalid query ${JSON.stringify(payload)}`,
    );
    return false;
  }

  if (
    'kind' in payload &&
    !Object.values(ResearchTaskPayloadKind).includes(payload.kind)
  ) {
    log.debug(`isResearchTaskPayload > inalid kind ${JSON.stringify(payload)}`);
    return false;
  }

  return true;
}

/**
 * isApiTaskSelectable() guards tasks against ApiTaskSelectable data type.
 * @param data unknown
 * @returns boolean
 */
export function isApiTaskSelectable(data: unknown): data is ApiTaskSelectable {
  const task = data as ApiTaskSelectable;

  if (!isApiTask(task)) {
    log.debug(`isApiTaskSelectable > not an api task ${JSON.stringify(task)}`);
    return false;
  }

  if ('selected' in task && !isBoolean(task.selected)) {
    log.debug(`isApiTaskSelectable > invalid selected ${JSON.stringify(task)}`);
    return false;
  }

  return true;
}

/**
 * isPartialApiTaskList() determines whether the list is an array of tasks.
 * @param data unknown
 * @returns boolean
 */
export function isPartialApiTaskList(
  data: unknown,
): data is Partial<ApiTaskSelectable>[] {
  if (!Array.isArray(data)) {
    log.debug(`isPartialApiTaskList > not an array ${JSON.stringify(data)}`);
    return false;
  }

  for (const item of data) {
    if (!isPartialApiTask(item)) {
      log.debug(
        `isPartialApiTaskList > not a partial task ${JSON.stringify(item)}`,
      );
      return false;
    }
  }

  return true;
}

/**
 * isPartialApiTask() tests data to be a partial task with optional data.
 * @param data unknown
 * @returns boolean
 */
export function isPartialApiTask(data: unknown): data is Partial<ApiTask> {
  const task = data as ApiTask;

  if (!isObject(task)) {
    log.debug(`isPartialApiTask > not an object ${JSON.stringify(task)}`);
    return false;
  }

  if ('task_id' in task && !isString(task.task_id)) {
    log.debug(`isPartialApiTask > invalid task_id ${JSON.stringify(task)}`);
    return false;
  }

  if ('user_id' in task && !isString(task.user_id)) {
    log.debug(`isPartialApiTask > invalid user_id ${JSON.stringify(task)}`);
    return false;
  }

  if ('agent_id' in task && !isString(task.agent_id)) {
    log.debug(`isPartialApiTask > invalid agent_id ${JSON.stringify(task)}`);
    return false;
  }

  if ('task_subject' in task && !isString(task.task_subject)) {
    log.debug(
      `isPartialApiTask > invalid task_subject ${JSON.stringify(task)}`,
    );
    return false;
  }

  if ('task_hash' in task && !isString(task.task_hash)) {
    log.debug(`isPartialApiTask > invalid task_hash ${JSON.stringify(task)}`);
    return false;
  }

  if (
    'channel' in task &&
    !(
      isString(task.channel) &&
      Object.values(TaskChannel).includes(task.channel)
    )
  ) {
    log.debug(`isPartialApiTask > invalid channel ${JSON.stringify(task)}`);
    return false;
  }

  if (
    'state' in task &&
    !(isString(task.state) && Object.values(TaskState).includes(task.state))
  ) {
    log.debug(`isPartialApiTask > invalid state ${JSON.stringify(task)}`);
    return false;
  }

  if (
    'skill' in task &&
    !(isString(task.skill) && Object.values(TaskSkill).includes(task.skill))
  ) {
    log.debug(`isPartialApiTask > invalid skill ${JSON.stringify(task)}`);
    return false;
  }

  if ('requires_attention' in task && !isBoolean(task.requires_attention)) {
    log.debug(
      `isPartialApiTask > invalid requires_attention ${JSON.stringify(task)}`,
    );
    return false;
  }

  if ('conversation_id' in task && !isString(task.conversation_id)) {
    log.debug(
      `isPartialApiTask > invalid conversation_id ${JSON.stringify(task)}`,
    );
    return false;
  }

  if (
    'payload' in task &&
    !(
      isSchedulingPayload(task.payload) ||
      isReservationDetails(task.payload) ||
      isResearchTaskPayload(task.payload)
    )
  ) {
    log.debug(
      `isPartialApiTask > not known payload type in ${JSON.stringify(task)}`,
    );
    return false;
  }

  if (
    'conversation' in task &&
    !(task.conversation === undefined || isConversation(task.conversation))
  ) {
    log.debug(
      `isPartialApiTask > invalid conversation ${JSON.stringify(task)}`,
    );
    return false;
  }

  if ('created_at' in task && !isString(task.created_at)) {
    log.debug(`isPartialApiTask > invalid created_at ${JSON.stringify(task)}`);
    return false;
  }

  if ('updated_at' in task && !isString(task.updated_at)) {
    log.debug(`isPartialApiTask > invalid updated_at ${JSON.stringify(task)}`);
    return false;
  }

  if (
    'feedback_type' in task &&
    !(
      task.feedback_type === undefined ||
      Object.values(FeedbackType).includes(task.feedback_type)
    )
  ) {
    log.debug(
      `isPartialApiTask > invalid feedback_type ${JSON.stringify(task)}`,
    );
    return false;
  }

  return true;
}

/**
 * isApiTask() guards data to be of chat task type.
 * @param data unknown
 * @returns boolean
 */
export function isApiTask(data: unknown): data is ApiTask {
  const task = data as ApiTask;

  if (!isPartialApiTask(task)) {
    log.debug(`isApiTask > not a partial task ${JSON.stringify(task)}`);
    return false;
  }

  if (
    !(
      'user_id' in task &&
      'channel' in task &&
      'skill' in task &&
      'agent_id' in task
    )
  ) {
    log.debug(
      `isApiTask > mandatory fields (user_id, channel, skill, agent_id) missing in ${JSON.stringify(
        task,
      )}`,
    );
    return false;
  }

  return true;
}
