import { useFormContext } from 'react-hook-form';
import { Message, TaskState } from 'src/types';
import type { MeetingCardFormData } from '../../CreateMeetingCard';
import { useMeetingCardFieldsWatch } from '../../hooks/useMeetingCardFieldsWatch';
import { useTaskById } from 'src/hooks';
import { StopTaskButton } from 'src/components/StopTaskButton';
import { ConfirmButton } from '../ConfirmButton';

interface CMCActionButtonsProps {
  message: Message;
  isSubmitted?: boolean;
}

export const CMCActionButtons = ({
  message,
  isSubmitted,
}: CMCActionButtonsProps) => {
  const { currentTaskData } = useTaskById();
  const { executed } = useMeetingCardFieldsWatch<MeetingCardFormData>();

  const { setValue } = useFormContext<MeetingCardFormData>();

  if (!currentTaskData || !currentTaskData.task_id) {
    return null;
  }

  const { task_id, state, task_hash } = currentTaskData;

  const handleStop = () => {
    setValue('executed', true);
  };

  const isProcessed = executed || isSubmitted;

  return (
    <div className="nj-create-meeting-card--actions">
      {!isProcessed && (
        <StopTaskButton
          taskId={task_id}
          taskState={state || TaskState.IN_PROGRESS}
          taskHash={task_hash}
          buttonName="Cancel request"
          onStop={handleStop}
        />
      )}

      <ConfirmButton
        message={message}
        taskState={state}
        isProcessed={isProcessed}
      />
    </div>
  );
};
