import { useEffect, RefObject } from 'react';
import { isMobile } from 'react-device-detect';

type EventListener = (event: MouseEvent | TouchEvent) => void;

export const useOutsideClick = (
  ref: RefObject<HTMLElement> | null,
  callback: EventListener,
  ignoreRef?: RefObject<HTMLElement> | null,
  optionalIgnoreRef?: RefObject<HTMLElement> | null,
): void => {
  useEffect(() => {
    const handleClickOutside: EventListener = (event) => {
      const modalElement = document.querySelector(
        '.react-responsive-modal-root',
      );
      if (modalElement) {
        return;
      }

      if (ref?.current && !ref.current.contains(event.target as Node)) {
        if (
          ignoreRef &&
          ignoreRef.current &&
          ignoreRef.current.contains(event.target as Node)
        ) {
          return;
        }
        if (
          optionalIgnoreRef &&
          optionalIgnoreRef.current &&
          optionalIgnoreRef.current.contains(event.target as Node)
        ) {
          return;
        }

        callback(event);
      }
    };

    document.addEventListener(
      isMobile ? 'touchstart' : 'mousedown',
      handleClickOutside,
    );

    return () => {
      document.removeEventListener(
        isMobile ? 'touchstart' : 'mousedown',
        handleClickOutside,
      );
    };
  }, [ref, callback, ignoreRef, optionalIgnoreRef]);
};
