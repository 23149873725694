import { useForm } from 'react-hook-form';
import { FormProvider } from 'src/components/FormProvider';
import { useUpdateTaskMessageByPDU } from 'src/hooks';
import { DatetimeRangeList, Message, Span } from 'src/types';
import { Button } from 'src/components/Button';
import { ProposalTimeSlotField } from '../ProposalTimeSlotField';

type ProposalConfirmationFieldsGroupProps = {
  fields: DatetimeRangeList;
  executed: boolean;
  onPreSubmit: (data: ProposalCardFormData) => Message;
};

enum ProposalConfirmationsNotifications {
  SUBMISSION_SUCCESS = 'Time slot submission was successful.',
  SUBMISSION_FAILED = 'Time slot submission failed.',
}

export type ProposalCardFormData = {
  executed?: boolean;
  minutes?: number;
  proposals: Array<Span>;
};

export const ProposalConfirmationFieldsGroup = ({
  fields,
  executed,
  onPreSubmit,
}: ProposalConfirmationFieldsGroupProps) => {
  const { onSubmit } = useUpdateTaskMessageByPDU({
    successMessage: ProposalConfirmationsNotifications.SUBMISSION_SUCCESS,
    errorMessage: ProposalConfirmationsNotifications.SUBMISSION_FAILED,
  });

  const defaultValues: ProposalCardFormData = {
    proposals: fields.values,
    minutes: 0,
    executed,
  };

  const methods = useForm<ProposalCardFormData>({
    defaultValues,
  });

  const { handleSubmit } = methods;

  const handleFormSubmit = (data: ProposalCardFormData) => {
    const updatedMessage = onPreSubmit(data);
    onSubmit(updatedMessage);
  };

  return (
    <FormProvider<ProposalCardFormData> methods={methods}>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="nj-task-view--form"
        autoComplete="off"
      >
        <div className="nj-task-view--form--block-wrapper">
          <ProposalTimeSlotField />
        </div>
        <div className="nj-task-view--form--footer center">
          <Button
            type="submit"
            className="nj-button nj-button--primary"
            disabled={executed}
          >
            Submit
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
