import connectCalendarHeaderImg from 'src/images/backgrounds/connect-calendar-header.png';
import { Icon } from 'src/components/Icon';
import React, { useEffect, useState } from 'react';
import { useGoogleResourcesAccess, useSession } from 'src/hooks';
import { CALENDAR_URL, CONTACTS_URL, DIRECTORY_URL } from 'src/constants';
import {
  useGoogleLogin,
  UseGoogleLoginOptionsAuthCodeFlow,
} from '@react-oauth/google';
import GoogleLogo from 'src/images/logos/google-logo.svg';
import { useLogout } from 'src/hooks/useLogout';
import { useCanConnectToGoogle } from 'src/hooks/useCanConnectToGoogle';
import { env } from 'src/env';

const TOOLTIP_ANCHOR_ID = 'modal-connect-trigger';

type AccessModalContentProps = {
  onCloseModal: () => void;
};

export const AccessModalContent = ({
  onCloseModal,
}: AccessModalContentProps) => {
  const [scope, setScope] = useState<string[]>([]);
  const {
    appUser: { email },
    isEnterpriseTier,
  } = useSession();
  const { logoutWithRedirectToSignUp } = useLogout();

  const canConnectToGoogle = useCanConnectToGoogle();

  const { canAccessDirectory, canAccessContacts, canAccessCalendar } =
    useGoogleResourcesAccess();

  const googleLoginParams: UseGoogleLoginOptionsAuthCodeFlow = {
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: env.REACT_APP_GOOGLE_AUTHORIZATION_REDIRECT_URI,
    hint: email,
    state: window.location.href,
  };

  useEffect(() => {
    const tempScopeArr = [];
    if (!canAccessCalendar) {
      tempScopeArr.push(CALENDAR_URL);
    }
    if (!canAccessContacts) {
      tempScopeArr.push(CONTACTS_URL);
    }
    if (!canAccessDirectory) {
      tempScopeArr.push(DIRECTORY_URL);
    }
    setScope([...tempScopeArr]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAccessDirectory, canAccessContacts, canAccessCalendar]);

  const connectGoogle = useGoogleLogin({
    ...googleLoginParams,
    scope: scope.join(' '),
  });

  const handleContinueClick = () => {
    connectGoogle();
  };

  return (
    <div className="nj-auth-access-modal-content">
      <header className="nj-auth-access-modal-content-header">
        <img
          className="nj-auth-access-modal-background-image"
          src={connectCalendarHeaderImg}
          alt="Ninja"
        />
      </header>
      <div className="nj-auth-access-modal-content-main">
        <h2 className="nj-auth-access-modal-content-main-title">
          Connect your calendar & contacts
        </h2>
        <p className="nj-auth-access-modal-content-main-description">
          {canConnectToGoogle
            ? 'Connect your calendar and contacts to allow Ninja to quickly find guest contact details, access your availability and schedule meetings.'
            : "Ninja can automatically suggest better times with calendar access, but it seems you're using non-gmail account. Please sign up using Google Single Sign-On (SSO) to connect your calendar."}
        </p>
        {canConnectToGoogle && (
          <div className="nj-auth-access-modal-content-main-form">
            <div className="nj-auth-access-modal-content-main-field">
              <span className="nj-auth-access-modal-content-main-label-wrapper">
                <Icon type="googleCalendarLogo" size={40} />

                <span className="nj-auth-access-modal-content-main-label">
                  <span className="nj-auth-access-modal-content-main-label-title">
                    Google Calendar & Contacts
                  </span>
                  <span className="nj-auth-access-modal-content-main-label-subtitle">
                    Gmail, G Workspace account
                  </span>
                </span>
              </span>
            </div>
          </div>
        )}
        <footer className="nj-auth-access-modal-content-footer">
          {canConnectToGoogle || isEnterpriseTier ? (
            <>
              <button
                type="button"
                className="nj-auth-access-modal-content-footer-button-cancel"
                onClick={onCloseModal}
              >
                Cancel
              </button>
              <button
                id={TOOLTIP_ANCHOR_ID}
                type="submit"
                className="nj-auth-access-modal-content-footer-button"
                onClick={handleContinueClick}
                disabled={scope.length === 0}
              >
                Connect
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="nj-auth-access-modal-content-footer-button-cancel"
                onClick={onCloseModal}
              >
                Continue without calendar access
              </button>
              <button
                className="nj-auth-sign-in-up-button nj-google-button"
                onClick={logoutWithRedirectToSignUp}
              >
                <div className="nj-google-button-state"></div>
                <div className="nj-google-button-content-wrapper">
                  <div>
                    <img
                      className="nj-auth-sign-in-up-button-logo"
                      src={GoogleLogo}
                      alt="Google"
                    />
                  </div>
                  <span className="nj-google-button-contents">
                    Sign up with Google
                  </span>
                  <span style={{ display: 'none' }}>Sign up with Google</span>
                </div>
              </button>
            </>
          )}
        </footer>
      </div>
    </div>
  );
};
