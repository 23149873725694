import { useMemo } from 'react';
import classNames from 'classnames';
import { Check, Warning } from '@phosphor-icons/react';
import { ActionStatue } from 'src/types';
import { InProgressIcon } from 'src/components/InProgressIcon';

interface ProgressStatusIconProps {
  status?: ActionStatue;
  size?: number;
  className?: string;
}

export const ProgressStatusIcon = ({
  status,
  size = 20,
  className,
}: ProgressStatusIconProps) => {
  const statusIcon = useMemo(() => {
    switch (status) {
      case ActionStatue.FAILED:
        return <Warning size={size} />;

      case ActionStatue.IN_PROGRESS:
        return <InProgressIcon size={size} />;

      default:
        return <Check size={size} />;
    }
  }, [status, size]);

  if (!status) {
    return null;
  }

  return (
    <span
      className={classNames(
        'nj-task-scratchpad--progress-card-status-icon',
        className,
        {
          warning: status === ActionStatue.FAILED,
        },
      )}
    >
      {statusIcon}
    </span>
  );
};
