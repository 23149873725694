import { Fragment } from 'react';
import { useCollapse } from 'react-collapsed';
import styles from './UserMessageContent.module.scss';
import cn from 'classnames';

interface UserMessageContentProps {
  content: string;
  isBlinking?: boolean;
}

const TEXT_MAX_SIZE = 148;

export const UserMessageContent = ({
  content,
  isBlinking,
}: UserMessageContentProps) => {
  const { getToggleProps, isExpanded } = useCollapse();

  const trimmedContent = content.replace(/^\n+|\n+$/g, '');

  const isLongContent = trimmedContent.length > TEXT_MAX_SIZE;
  const slicedContent = `${trimmedContent.slice(0, TEXT_MAX_SIZE).trim()}...`;

  const lines = (
    isLongContent && !isExpanded ? slicedContent : trimmedContent
  ).split('\n');

  return (
    <div className={styles.root}>
      <span className={cn(styles.content, { [styles.blinking]: isBlinking })}>
        {lines.map((line, index) => (
          <Fragment key={index}>
            {line}
            {index < lines.length - 1 && <br />}
          </Fragment>
        ))}
      </span>

      {isLongContent && (
        <button
          type="button"
          className={styles.lessMoreButton}
          {...getToggleProps()}
        >
          {isExpanded ? 'View less' : 'View more'}
        </button>
      )}
    </div>
  );
};
