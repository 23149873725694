import { DefaultStaticField } from '../DefaultStaticField';
import { ScheduleBetweenDefaultVaue } from '../ScheduleBetweenDefaultVaue';
import { DurationField } from '../DurationField';
import { MeetingWindowsField } from '../MeetingWindowsField';
import { RecurrenceField } from '../RecurrenceField';

export const ScheduleBetweenField = () => {
  return (
    <DefaultStaticField
      label="Find a suitable time between"
      defaultValue={<ScheduleBetweenDefaultVaue />}
    >
      <>
        <DurationField />
        <MeetingWindowsField />
        <RecurrenceField />
      </>
    </DefaultStaticField>
  );
};
