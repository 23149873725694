import {
  FormProvider as ReactHookFormProvider,
  FieldValues,
  FormProviderProps as ReactHookFormProviderProps,
  UseFormReturn,
} from 'react-hook-form';

interface FormProviderProps<T extends FieldValues> {
  children: React.ReactNode;
  methods: UseFormReturn<T>;
}

/**
 * Custom FormProvider component for React Hook Form
 * Workaround to fix TS issue in React Hook Form https://github.com/react-hook-form/react-hook-form/issues/6679
 */
export const FormProvider = <T extends FieldValues>({
  methods,
  children,
}: FormProviderProps<T>) => {
  const FormProviderComponent: (
    props: ReactHookFormProviderProps<T>,
  ) => JSX.Element = ReactHookFormProvider;

  return <FormProviderComponent {...methods}>{children}</FormProviderComponent>;
};
