import { SVGProps } from 'react';

export const VentureBeatLogo = ({
  width = 79,
  height = 11,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 79 11"
      fill="none"
    >
      <g clipPath="url(#clip0_8491_32418)">
        <path
          d="M55.3013 0.5C56.2892 0.527333 57.486 0.570285 58.2084 1.31999C58.635 1.82532 58.867 2.46653 58.8624 3.12788C58.8749 3.41659 58.8269 3.70473 58.7213 3.97374C58.6157 4.24275 58.4549 4.48666 58.2494 4.68977C57.9575 4.94856 57.6242 5.15645 57.2634 5.30476C57.7063 5.406 58.117 5.61602 58.4583 5.91585C58.9093 6.42277 59.1502 7.08255 59.1318 7.76084C59.1399 8.07893 59.0837 8.39538 58.9666 8.69125C58.8495 8.98713 58.674 9.25635 58.4505 9.48282C57.6695 10.2774 56.6426 10.2911 55.5219 10.3184H51.0178V8.31531L51.8944 8.32897V2.48946L51.0178 2.50312V0.5H55.3013ZM54.4462 4.44963C55.2388 4.46329 55.3501 4.46329 55.5454 4.4223C55.766 4.37525 55.9626 4.25115 56.1 4.07226C56.2374 3.89337 56.3065 3.67138 56.2951 3.44611C56.306 3.26588 56.2667 3.08614 56.1815 2.92691C56.0964 2.76767 55.9688 2.63516 55.8128 2.54412C55.5903 2.43284 55.4516 2.43284 54.4637 2.41917L54.4462 4.44963ZM54.4462 8.31531H55.2271C55.7679 8.31531 56.5332 8.30164 56.5332 7.37036C56.5447 7.17021 56.4933 6.97148 56.3862 6.80202C56.279 6.63257 56.1216 6.50088 55.9358 6.42542C55.6996 6.3278 55.5883 6.3278 54.4618 6.3278L54.4462 8.31531Z"
          fill="currentColor"
        />
        <path
          d="M66.4841 7.84282C66.3865 8.35824 66.2596 9.03962 65.6212 9.6507C64.9828 10.2618 64.0593 10.4863 63.1593 10.4863C61.2401 10.4863 59.5571 9.55504 59.5571 6.74559C59.548 6.25622 59.6138 5.76833 59.7524 5.29889C60.2405 3.64329 61.5896 2.95605 63.0714 2.95605C64.1842 2.95605 65.0882 3.34653 65.6095 3.87366C66.6677 4.90256 66.613 6.57183 66.5974 7.2122H62.1987C62.1987 7.76863 62.2143 8.75457 63.1163 8.75457C63.868 8.75457 63.9656 8.11615 64.0085 7.85063L66.4841 7.84282ZM64.0124 5.83969C64.0229 5.647 63.9991 5.45398 63.9422 5.2696C63.8819 5.10702 63.771 4.96807 63.6258 4.87318C63.4807 4.7783 63.3089 4.73248 63.1358 4.74247C62.3002 4.74247 62.2729 5.54879 62.2729 5.83969H64.0124Z"
          fill="currentColor"
        />
        <path
          d="M73.952 8.31577V10.3189H70.9473L70.8927 9.48523C70.6757 9.80971 70.3785 10.0725 70.0298 10.248C69.6812 10.4235 69.2931 10.5058 68.9032 10.4868C67.6088 10.4868 66.8025 9.72147 66.8025 8.32943C66.7871 7.95297 66.8688 7.57888 67.0397 7.24312C67.2107 6.90736 67.4652 6.62122 67.7786 6.41221C68.6963 5.78551 70.1215 5.84017 70.8243 5.85384C70.8426 5.59914 70.8334 5.34321 70.797 5.09047C70.7767 4.94026 70.7014 4.80293 70.5856 4.70516C70.4698 4.60739 70.3218 4.55614 70.1703 4.56137C70.0824 4.56019 69.9952 4.57688 69.914 4.61045C69.8328 4.64401 69.7592 4.69374 69.6978 4.75661C69.6167 4.85194 69.5556 4.96257 69.518 5.08196C69.4804 5.20135 69.4672 5.32706 69.4791 5.45165H67.0524C67.0524 5.04795 67.1375 4.64879 67.3023 4.28023C67.8177 3.23767 69.1258 2.93115 70.1273 2.93115C71.0723 2.93115 72.1578 3.20839 72.7552 3.82143C73.298 4.36223 73.3526 4.99285 73.3526 5.51803V8.31577H73.952ZM70.8282 7.12092C70.5667 7.08935 70.3015 7.10857 70.0473 7.17754C69.8544 7.22898 69.6853 7.34554 69.5686 7.50745C69.4519 7.66935 69.3948 7.86664 69.4069 8.06587C69.4069 8.552 69.6588 8.81753 70.0336 8.81753C70.159 8.81917 70.2827 8.78796 70.3923 8.72699C70.5019 8.66602 70.5936 8.57742 70.6584 8.47C70.7714 8.27076 70.8294 8.04501 70.8263 7.81596L70.8282 7.12092Z"
          fill="currentColor"
        />
        <path
          d="M77.4567 0.861328V3.11435H78.6106V5.10967H77.4567V7.70436C77.4704 7.96793 77.4977 8.51068 78.1654 8.51068C78.2936 8.51168 78.4207 8.48849 78.5403 8.44235V10.2776C78.0418 10.3834 77.5347 10.4442 77.0253 10.4591C76.2189 10.4591 75.6059 10.2346 75.2994 9.93005C74.9089 9.53958 74.8972 9.02611 74.8972 8.62197V5.10967H74.2705V3.11435H75.0924V1.40018L77.4567 0.861328Z"
          fill="currentColor"
        />
        <path
          d="M0.410583 0.5H4.62573V2.50312L3.76279 2.48946L5.19387 8.10836L6.73819 2.48946L5.8772 2.50312V0.5H9.74288V2.50312L9.08883 2.48946L6.65424 10.3184H3.4836L1.02167 2.48946L0.410583 2.50312V0.5Z"
          fill="currentColor"
        />
        <path
          d="M15.7777 7.84282C15.6801 8.35824 15.5552 9.03962 14.9167 9.6507C14.2783 10.2618 13.3549 10.4863 12.4548 10.4863C10.5356 10.4863 8.85271 9.55504 8.85271 6.74559C8.8427 6.25617 8.90856 5.76815 9.04794 5.29889C9.53408 3.64329 10.8832 2.95605 12.367 2.95605C13.4798 2.95605 14.3837 3.34653 14.905 3.87366C15.9632 4.90256 15.9066 6.57183 15.8929 7.2122H11.4943C11.4943 7.76863 11.5079 8.75457 12.4119 8.75457C13.1635 8.75457 13.2611 8.11615 13.3021 7.85063L15.7777 7.84282ZM13.3021 5.83969C13.3123 5.64715 13.2892 5.45429 13.2338 5.2696C13.1734 5.10669 13.0622 4.9675 12.9167 4.87258C12.7712 4.77767 12.599 4.73205 12.4255 4.74247C11.5919 4.74247 11.5645 5.54879 11.5645 5.83969H13.3021Z"
          fill="currentColor"
        />
        <path
          d="M23.5657 8.31568H24.1651V10.3188H20.9788V6.08608C20.9874 5.90862 20.9689 5.73088 20.9242 5.55895C20.8754 5.44206 20.7915 5.3432 20.6842 5.27605C20.5768 5.2089 20.4512 5.17676 20.3248 5.18409C20.2265 5.18412 20.1292 5.20383 20.0387 5.24206C19.9482 5.28028 19.8662 5.33625 19.7976 5.40666C19.5477 5.65656 19.5321 5.9748 19.5185 6.38284V8.33521H20.0065V10.3188H16.3615V8.31568H16.9472V5.1099H16.3615V3.11459H19.2139L19.2959 4.21377C19.4056 3.99556 19.5515 3.79751 19.7274 3.62806C19.9683 3.39416 20.2535 3.21079 20.5664 3.08875C20.8792 2.9667 21.2132 2.90844 21.5489 2.9174C22.3006 2.9174 22.8023 3.19659 23.0659 3.47578C23.5384 3.94825 23.552 4.532 23.5657 5.03767V8.31568Z"
          fill="currentColor"
        />
        <path
          d="M27.6129 0.861328V3.11435H28.7667V5.10967H27.6129V7.70436C27.6265 7.96793 27.6539 8.51068 28.3216 8.51068C28.4504 8.51183 28.5782 8.48864 28.6984 8.44235V10.2776C28.1992 10.3834 27.6915 10.4442 27.1814 10.4591C26.3751 10.4591 25.764 10.2346 25.4575 9.93005C25.067 9.53958 25.0533 9.02611 25.0533 8.62197V5.10967H24.4266V3.11435H25.2485V1.40018L27.6129 0.861328Z"
          fill="currentColor"
        />
        <path
          d="M32.3554 3.11426V7.32941C32.3554 7.62031 32.3827 8.28802 33.0368 8.28802C33.6908 8.28802 33.7181 7.56564 33.7181 7.19079V5.10957L33.191 5.12324V3.11426H36.3148V8.32902L36.9122 8.31535V10.3185H33.9466V9.51215C33.7067 9.86432 33.3666 10.1364 32.9704 10.2931C32.6204 10.4313 32.2475 10.5022 31.8712 10.502C31.5919 10.5157 31.3126 10.4735 31.0498 10.378C30.787 10.2825 30.5459 10.1354 30.3405 9.94557C29.7978 9.41844 29.7705 8.72144 29.7548 8.05569V5.10957L29.1691 5.12324V3.11426H32.3554Z"
          fill="currentColor"
        />
        <path
          d="M40.3522 4.36555C40.4635 3.97508 40.727 3.03209 42.1093 3.03209C42.3397 3.02827 42.5696 3.05585 42.7926 3.11409V5.42373C42.5918 5.37613 42.386 5.35253 42.1796 5.35345C41.7336 5.33415 41.2966 5.48329 40.9555 5.77125C40.6216 6.1051 40.6079 6.47996 40.6079 7.1379L40.5806 8.30932H41.4279V10.3183H37.4724V8.31518H38.0699V5.1094H37.4724V3.11409H40.2682L40.3522 4.36555Z"
          fill="currentColor"
        />
        <path
          d="M50.2527 7.84282C50.157 8.35824 50.0321 9.03962 49.3917 9.6507C48.7513 10.2618 47.8298 10.4863 46.9298 10.4863C45.0106 10.4863 43.3277 9.55504 43.3277 6.74559C43.3176 6.25617 43.3835 5.76815 43.5229 5.29889C44.009 3.64329 45.3581 2.95605 46.8321 2.95605C47.945 2.95605 48.8489 3.34653 49.3702 3.87366C50.4264 4.90256 50.3718 6.57183 50.3581 7.2122H45.9711C45.9711 7.76863 45.9848 8.75457 46.8868 8.75457C47.6385 8.75457 47.7361 8.11615 47.779 7.85063L50.2527 7.84282ZM47.779 5.83969C47.7895 5.647 47.7657 5.45398 47.7088 5.2696C47.649 5.10666 47.5382 4.96735 47.393 4.87238C47.2477 4.77741 47.0757 4.73185 46.9024 4.74247C46.0668 4.74247 46.0395 5.54879 46.0395 5.83969H47.779Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_8491_32418">
          <rect
            width={width}
            height={height}
            fill="currentColor"
            transform="translate(0.410583 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
