import { useFormContext, useWatch, FieldValues } from 'react-hook-form';

export const useMeetingCardFieldsWatch = <T extends FieldValues>() => {
  const { control } = useFormContext<T>();
  const fieldValues = useWatch<T>({ control });

  return {
    ...fieldValues,
  };
};
