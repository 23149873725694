import styles from './TextWithLinkSection.module.scss';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'src/types';

export interface BackToSigninSectionProps {
  text?: string;
  linkText: string;
  linkRoute: AppRoutes;
}

export const TextWithLinkSection = ({
  text,
  linkText,
  linkRoute,
}: BackToSigninSectionProps) => {
  return (
    <div className={styles.root}>
      {text && <span className={styles.text}>{text}&nbsp;</span>}
      <Link to={linkRoute} className={styles.link}>
        {linkText}
      </Link>
    </div>
  );
};
