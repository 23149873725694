import { Check, Copy } from '@phosphor-icons/react';
import { toast } from 'react-toastify';
import { useExternalModels } from 'src/hooks';
import { Message, MessageType } from 'src/types';
import {
  getResearchCopyTemplate,
  getSchedulerCopyTemplate,
  getChatCardCopyTemplate,
} from 'src/utils';

type CopyMessageButtonProps = {
  item: Message;
  withTitle?: boolean;
  disabled?: boolean;
};

const SVG_SIZE = 20;

export const CopyMessageButton = ({
  item,
  withTitle,
  disabled,
}: CopyMessageButtonProps) => {
  const { textExternalModels } = useExternalModels();

  const getCopyString = (item: Message) => {
    switch (item.message_type) {
      case MessageType.CONVERSATION:
      case MessageType.TASK_CREATED:
        return item.content.trim();
      case MessageType.CODE_TASK_CREATION_CARD:
        return getChatCardCopyTemplate(item, textExternalModels);
      case MessageType.RESEARCH_TASK_CREATION_CARD:
        return getResearchCopyTemplate(item, textExternalModels);
      case MessageType.SCHEDULER_TASK_CREATION_CARD:
        return getSchedulerCopyTemplate(item);
      case MessageType.CHAT_CARD:
        return getChatCardCopyTemplate(item, textExternalModels);

      default:
        // since all cards will have content at least
        // providing a default case, otherwise, each
        // copyable card type should have a template.
        return item.content.trim();
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(getCopyString(item));
    toast(
      <div className="nj-copy-snackbar-content">
        <Check size={24} />
        <span>Copied</span>
      </div>,
      {
        className: 'nj-copy-snackbar',
      },
    );
  };

  return (
    <button onClick={handleCopy} disabled={disabled} data-e2e="copy-button">
      <Copy size={SVG_SIZE} color="currentColor" />
      {withTitle && <span>Copy</span>}
    </button>
  );
};
