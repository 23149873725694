import React, { useEffect } from 'react';
import { useSession, useUserData } from 'src/hooks';
import styles from './ContentPane.module.scss';

type ContentPaneProps = {
  children?: React.ReactNode;
};

/**
 * ContentPane displays pages per chosen route.
 */
export const ContentPane = ({ children }: ContentPaneProps) => {
  const { appUser } = useSession();
  const { updateUserData } = useUserData();

  useEffect(() => {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (
      appUser &&
      appUser.user_id &&
      appUser.settings?.timezone_setting?.is_automatically_timezone &&
      appUser.settings?.timezone_setting?.timezone &&
      appUser.settings?.timezone_setting?.timezone !== currentTimezone
    ) {
      updateUserData(
        {
          settings: {
            ...appUser.settings,
            timezone_setting: {
              timezone: currentTimezone,
              is_automatically_timezone: true,
            },
          },
        },
        false,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUser]);

  return <div className={styles.root}>{children}</div>;
};
