import { useFormContext, useFieldArray } from 'react-hook-form';
import { useMeetingCardFieldsWatch } from '../../hooks/useMeetingCardFieldsWatch';
import { MeetingCardFormData } from '../../CreateMeetingCard';
import { AttendeeListItem } from './components/AttendeeListItem';
import { AddNewAttendeeForm } from './components/AddNewAttendeeForm';
import { NO_ATTENDEES } from '../../constants/defaultValues';

interface AttendeeListFieldProps {
  maxAttendeesLength: number;
}

export const AttendeeListField = ({
  maxAttendeesLength,
}: AttendeeListFieldProps) => {
  const { control } = useFormContext<MeetingCardFormData>();
  const { executed } = useMeetingCardFieldsWatch<MeetingCardFormData>();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'attendees',
    rules: { maxLength: maxAttendeesLength },
  });

  return (
    <div className="nj-create-meeting-card--field nj-create-meeting-card--attendee-field-list">
      <div className="nj-create-meeting-card--guest-availability-label">
        <p className="nj-create-meeting-card--label">Guests</p>

        {fields.length <= maxAttendeesLength ? (
          <span className="nj-create-meeting-card--sublabel">
            {`Maximum ${maxAttendeesLength} guests`}
          </span>
        ) : (
          <span className="nj-create-meeting-card--sublabel warning">
            {`You've reached the maximum of ${maxAttendeesLength} guests. Please adjust the guest
            count to proceed.`}
          </span>
        )}
      </div>

      <div className="nj-create-meeting-card--attendee-wrapper">
        {fields.length > 0 ? (
          fields.map((item, index) => (
            <AttendeeListItem
              disabled={executed}
              key={item.id}
              attendee={item}
              index={index}
              onRemove={() => remove(index)}
              onUpdate={update}
            />
          ))
        ) : (
          <span className="nj-create-meeting-card--attendee-description">
            {NO_ATTENDEES}
          </span>
        )}

        <AddNewAttendeeForm append={append} />
      </div>
    </div>
  );
};
