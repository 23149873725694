import { lazy, Suspense, ReactNode } from 'react';
import { BaseReactPlayerProps } from 'react-player/base';
const ReactPlayer = lazy(() => import('react-player'));

interface LazyReactPlayerProps extends BaseReactPlayerProps {
  fallbackElement?: ReactNode;
}

export const LazyReactPlayer = ({
  width = '100%',
  height = '100%',
  fallbackElement,
  ...rest
}: LazyReactPlayerProps) => {
  return (
    <Suspense fallback={fallbackElement}>
      <ReactPlayer width={width} height={height} {...rest} />
    </Suspense>
  );
};
