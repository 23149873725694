import { useContext } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { FormProvider } from 'src/components/FormProvider';
import { Modal } from 'react-responsive-modal';
import { X } from '@phosphor-icons/react';
import { Button } from 'src/components/Button';

interface AccessModalProps<T extends FieldValues> {
  open: boolean;
  title: string;
  submitTitle: string;
  disabledSubmit?: boolean;
  onClose: () => void;
  onSubmit: (value?: T) => void;
  children: React.ReactNode;
}

const SVG_SIZE = 24;

export const AccessModal = <T extends FieldValues>({
  open,
  title,
  submitTitle,
  disabledSubmit,
  onClose,
  onSubmit,
  children,
}: AccessModalProps<T>) => {
  const { rootContainerRef } = useContext(ForwardRefContext);
  const methods = useForm<T>();

  const { handleSubmit } = methods;

  const handleFormSubmit = (value: T) => {
    onSubmit(value);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      container={rootContainerRef?.current || document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: 'nj-modal--access',
      }}
      closeIcon={<X size={SVG_SIZE} />}
      center
    >
      <FormProvider<T> methods={methods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="nj-modal--access--wrapper">
            <p className="nj-modal--access--title">{title}</p>

            {children}

            <div className="nj-modal--access--action-wrapper">
              <Button
                type="reset"
                onClick={onClose}
                className="nj-settings-tab-content--access-button outlined active"
              >
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={disabledSubmit}
                className="nj-settings-tab-content--access-button fill"
              >
                {submitTitle}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
