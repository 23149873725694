import { useRef, useEffect, useCallback } from 'react';

/**
 * Custom hook that provides a setInterval functionality
 * with a start and stop mechanism.
 *
 * @param callback - Function to be called at each interval.
 * @param delay - Delay in milliseconds for the interval.
 * @returns A function to start and stop the interval.
 */
export const useConditionalInterval = (callback: () => void, delay = 0) => {
  const savedCallback = useRef(callback);
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  // Remember the latest callback if it changes
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const startInterval = useCallback(() => {
    // Prevent multiple intervals
    if (intervalId.current !== null) {
      return;
    }

    // Immediately call the callback
    savedCallback.current();

    intervalId.current = setInterval(() => {
      savedCallback.current();
    }, delay);
  }, [delay]);

  const stopInterval = useCallback(() => {
    if (intervalId.current !== null) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stopInterval();
    };
  }, [stopInterval]);

  return { startInterval, stopInterval };
};
