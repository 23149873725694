import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import { DotsThreeOutlineVertical } from '@phosphor-icons/react';

const SVG_SIZE = 20;

interface AttendeeMenuProps {
  onRemove: () => void;
  onAddNameClick?: () => void;
}

export const AttendeeMenu = ({
  onAddNameClick,
  onRemove,
}: AttendeeMenuProps) => {
  return (
    <Menu
      gap={-8}
      menuClassName="nj-multi-menu"
      direction="left"
      menuButton={
        <MenuButton className="nj-create-meeting-card--attendee-menu">
          <DotsThreeOutlineVertical size={SVG_SIZE} alt="Conversation menu" />
        </MenuButton>
      }
      transition
    >
      <MenuItem onClick={onRemove}>Remove</MenuItem>

      {onAddNameClick && <MenuItem onClick={onAddNameClick}>Add name</MenuItem>}
    </Menu>
  );
};
