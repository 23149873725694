import { StylesConfig, MultiValue, GroupBase } from 'react-select';

export const getCustomStyles = <T>(): StylesConfig<
  T | MultiValue<T>,
  boolean,
  GroupBase<T>
> => {
  const dot = () => ({
    alignItems: 'center',
    display: 'flex',

    ':after': {
      borderRadius: 10,
      content: '" "',
      display: 'inline-block',
      height: 8,
      width: 8,
    },
  });

  return {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      borderRadius: '20px',
      ':hover': {
        cursor: 'pointer',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '10px 16px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided, { isDisabled }) => ({
      ...provided,
      display: isDisabled ? 'none' : 'flex',
      paddingRight: '8px',
    }),
    input: (provided) => ({
      ...provided,
      color: '#e8eaea',
      visibility: 'visible',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      backdropFilter: 'blur(12px)',
    }),
    option: (provided, { isSelected, data }) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '10px 16px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.028px',
      borderBottom: (data as { customConfig: { hasBorderBottom: boolean } })
        ?.customConfig?.hasBorderBottom
        ? '1px solid #343E42'
        : 'none',
      '&:hover': {
        cursor: 'pointer',
      },
      ...(isSelected ? dot() : {}),
    }),
  };
};
