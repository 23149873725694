import { useEffect, useState, useContext } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { KeyCodes } from 'src/types';

type QuickPickerControlsParams<T> = {
  options: T[];
  isOpen: boolean;
  shouldFocusInputBox?: boolean;
  onClose: () => void;
  onItemClick: (index: number) => void;
};
export const useQuickPickerControls = <T>({
  options,
  isOpen,
  shouldFocusInputBox,
  onClose,
  onItemClick,
}: QuickPickerControlsParams<T>) => {
  const { threadInputBoxRef } = useContext(ForwardRefContext);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    setSelectedIndex(0);
  }, [options]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isOpen) {
        return;
      }

      if (e.key === KeyCodes.ARROW_DOWN) {
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          Math.min(options.length - 1, prevIndex + 1),
        );
      } else if (e.key === KeyCodes.ARROW_UP) {
        e.preventDefault();
        setSelectedIndex((prevIndex) => Math.max(0, prevIndex - 1));
      } else if (e.key === KeyCodes.ENTER && options[selectedIndex]) {
        e.preventDefault();
        onItemClick(selectedIndex);
        onClose();
        setSelectedIndex(0);
        if (shouldFocusInputBox) {
          threadInputBoxRef?.current?.focus();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    isOpen,
    options,
    selectedIndex,
    onItemClick,
    onClose,
    threadInputBoxRef,
    shouldFocusInputBox,
  ]);

  return { selectedIndex };
};
