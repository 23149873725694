import { ChangeEvent, ReactNode, useId } from 'react';
import classNames from 'classnames';
import { CheckSquare, Square } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import styles from './CheckboxSimple.module.scss';

interface CheckboxSimpleProps {
  isChecked: boolean;
  isDisabled?: boolean;
  label?: ReactNode;
  className?: string;
  onChange: (value: boolean) => void;
}

export const CheckboxSimple = ({
  isChecked,
  isDisabled,
  label,
  className,
  onChange,
}: CheckboxSimpleProps) => {
  const uniqueId = useId();

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!isDisabled) {
      onChange(target.checked);
    }
  };

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.checked]: isChecked,
        [styles.disabled]: isDisabled,
      })}
    >
      <input
        checked={isChecked}
        disabled={isDisabled}
        className={styles.input}
        id={uniqueId}
        type="checkbox"
        onChange={handleChange}
      />
      <label className={styles.label} htmlFor={uniqueId}>
        {label}

        <span className={styles.icon}>
          {isChecked ? (
            <CheckSquare weight="fill" size={SVG_SIZE_M} />
          ) : (
            <Square size={SVG_SIZE_M} />
          )}
        </span>
      </label>
    </div>
  );
};
