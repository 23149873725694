import styles from './VerificationFailedPage.module.scss';
import { EmailVerificationFailedBlock } from 'src/v2/pages/auth/components/EmailVerificationFailedBlock';
import { BasePublicPage } from 'src/v2/pages/auth/BasePublicPage';
import { AppRoutes } from 'src/types';
import { TextWithLinkSection } from 'src/v2/pages/auth/components/TextWithLinkSection';
import React from 'react';

export const VerificationFailedPage = () => {
  return (
    <BasePublicPage alignContainer="center">
      <div className={styles.mainSection}>
        <EmailVerificationFailedBlock />
        <TextWithLinkSection
          text="Back to"
          linkText="Login"
          linkRoute={AppRoutes.LOGIN}
        />
      </div>
    </BasePublicPage>
  );
};
