import { Frequency } from 'rrule';
import { useFormContext } from 'react-hook-form';
import type { MeetingCardFormData } from '../../../../CreateMeetingCard';
import { FormInput } from 'src/components/FormInput';
import { Select } from 'src/components/Select';

export const frequencyOptions = [
  { label: 'Day(s)', value: Frequency.DAILY },
  { label: 'Week(s)', value: Frequency.WEEKLY },
  { label: 'Month(s)', value: Frequency.MONTHLY },
  { label: 'Year(s)', value: Frequency.YEARLY },
];

export const RecurrenceSelector = () => {
  const { setValue } = useFormContext<MeetingCardFormData>();

  const handleRecurrenceChange = (freq: Frequency) => {
    setValue('recurrent_frequency', freq);
  };

  return (
    <div className="nj-create-meeting-card--recurrence-selector">
      <div className="nj-create-meeting-card--recurrence-selector-field">
        <p className="nj-create-meeting-card--recurrence-selector-label">
          Every:
        </p>

        <FormInput
          required
          className="nj-create-meeting-card--input nj-create-meeting-card--recurrence-input"
          name="recurrent_interval"
          type="number"
          min={1}
        />

        <Select<Frequency>
          name="recurrent_frequency"
          isShort
          options={frequencyOptions}
          onChangeHandler={(value) => handleRecurrenceChange(value)}
        />
      </div>

      <div className="nj-create-meeting-card--recurrence-selector-field">
        <p className="nj-create-meeting-card--recurrence-selector-label">
          Total:
        </p>

        <FormInput
          required
          className="nj-create-meeting-card--input nj-create-meeting-card--recurrence-input"
          name="recurrent_total_occurrences"
          type="number"
          min={1}
        />

        <p className="nj-create-meeting-card--recurrence-selector-tip">
          Occurrences
        </p>
      </div>
    </div>
  );
};
