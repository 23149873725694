import { useMemo, useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { useGetFilteredTasksQuery } from 'src/store/services';
import { useAppSelector, sessionState } from 'src/store';
import { getTasksFilterFromChatsFilter } from 'src/utils';
import { ChatsFilter } from 'src/types';

export const useTasks = () => {
  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);

  const { chatsFilter, filteredTasksPageToken } = useContext(SessionContext);

  const isFilteredTasksList = useMemo(
    () =>
      !!chatsFilter &&
      chatsFilter !== ChatsFilter.ALL &&
      chatsFilter !== ChatsFilter.ALL_AND_ARCHIVED,
    [chatsFilter],
  );

  const { data: filteredTasksListData, isLoading: isFilteredTasksLoading } =
    useGetFilteredTasksQuery(
      {
        user_id,
        pageToken: filteredTasksPageToken,
        filterState: chatsFilter && getTasksFilterFromChatsFilter(chatsFilter),
      },
      { skip: !user_id || !isFilteredTasksList },
    );

  return useMemo(
    () => ({
      filteredTasks: filteredTasksListData?.data || [],
      isFilteredTasksLoading,
      isFilteredTasksList,
      newFilteredTaskPageToken: filteredTasksListData?.page_token || '',
    }),
    [filteredTasksListData, isFilteredTasksLoading, isFilteredTasksList],
  );
};
