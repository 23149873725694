import { Modal } from 'react-responsive-modal';
import React from 'react';
import logoMobile from 'src/images/login/atlas_plain_mobile.png';
import logoDesktop from 'src/images/login/atlas_plain_desktop.png';
import mobileBackground from 'src/images/backgrounds/maintenance-mobile-bg.png';
import desktopBackground from 'src/images/backgrounds/maintenance-desktop-bg.png';
import { useBreakpoint } from 'src/hooks';

export const MaintenanceScreen = () => {
  const { isMobile } = useBreakpoint();
  return (
    <div
      className="nj-maintenance-screen"
      style={{
        backgroundImage: isMobile
          ? `url(${mobileBackground})`
          : `url(${desktopBackground})`,
        backgroundSize: 'cover',
      }}
    >
      <Modal
        open={true} // todo replace it with flag from BE when BE is ready
        onClose={
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {}
        }
        container={document.body}
        classNames={{
          root: 'nj-modal--root',
          overlay: 'nj-modal--overlay nj-modal-maintenance-overlay',
          modal: 'nj-modal--access nj-maintenance-modal',
        }}
        center
        closeOnOverlayClick={false}
        showCloseIcon={false}
      >
        <div className="nj-maintenance-modal-content">
          <header className="nj-maintenance-modal-content-header">
            <img
              className="nj-maintenance-modal-background-image"
              src={isMobile ? logoMobile : logoDesktop}
              alt="Ninja"
            />
          </header>
          <div className="nj-maintenance-modal-content-main">
            <h2 className="nj-maintenance-modal-content-main-title">
              Ninja is currently down for maintenance
            </h2>
            <div className="nj-maintenance-modal-content-main-text">
              {isMobile ? (
                <p>
                  Our site is currently down for scheduled maintenance as we
                  work diligently to enhance your overall experience.
                </p>
              ) : (
                <p>Our site is currently down for scheduled maintenance.</p>
              )}
              {isMobile ? (
                <p>
                  We apologize for any inconvenience this may cause and
                  appreciate your patience. Our team is implementing
                  improvements to make the platform more efficient, and we
                  anticipate being back online shortly.
                </p>
              ) : (
                <p>
                  Thank you for your patience and cooperation as we strive to
                  provide you with an even better user experience.
                </p>
              )}
              {isMobile && (
                <p>
                  Thank you for your patience and cooperation as we strive to
                  provide you with an even better user experience.
                </p>
              )}
              <p>
                Best regards,
                <br /> The NinjaTech AI Team
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
