import { UpsellCard } from 'src/v2/commonComponents/UpsellCard';
import lightIcon from 'src/images/icons/art-edit-image.svg';
import darkIcon from 'src/images/icons/art-edit-image-dark.svg';

export const EditImageUpsell = () => {
  return (
    <UpsellCard
      darkIcon={darkIcon}
      lightIcon={lightIcon}
      description="Upgrade to the Pro or Ultra plan to unlock full access to images and remove watermarks."
      dataGTM="Edit-message"
      upgradeTitle="Upgrade"
    />
  );
};
