import { useRef, KeyboardEvent, useContext, RefObject } from 'react';
import HighlightWithinTextarea from 'react-highlight-within-textarea';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import styles from './PromptInput.module.scss';

type EditableInputProps = {
  value: string;
  onChange: (value: string) => void;
  onKeyDown: (e: KeyboardEvent<HTMLElement>) => void;
  onBlur: () => void;
  onClick: () => void;
  onPaste?: (e: React.ClipboardEvent<HTMLDivElement>) => void;
};

export const PromptInput = ({
  value,
  onChange,
  onKeyDown,
  onBlur,
  onClick,
  onPaste,
}: EditableInputProps) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const { setThreadFromPromptTemplate } = useContext(ThreadInputBoxContext);
  const { threadInputBoxRef } = useContext(ForwardRefContext);

  const handleInputChange = (value: string) => {
    onChange(value);
    if (!value) {
      setThreadFromPromptTemplate('');
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const selection = window.getSelection();
    const range = document.createRange();
    const target = e.target as HTMLElement;
    range.selectNodeContents(target);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const highlightedComponent = (props: { children: React.ReactNode }) => {
    return (
      <mark
        className={styles.mark}
        onMouseDown={handleClick}
        aria-disabled={true}
      >
        {props.children}
      </mark>
    );
  };

  return (
    <div
      className={styles.root}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onClick={onClick}
      onPaste={onPaste}
      // TODO(olha): temporary workaround
      ref={threadInputBoxRef as RefObject<HTMLDivElement>}
    >
      <HighlightWithinTextarea
        placeholder="..."
        value={value}
        highlight={Array.from(value.matchAll(/\[.*?\]/g)).map((match) => ({
          highlight: match[0],
          component: highlightedComponent,
        }))}
        onChange={handleInputChange}
        ref={(el) => (textareaRef.current = el)}
      />
    </div>
  );
};
