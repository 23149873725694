import { ModalGalleryType } from 'src/types';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { useSession } from 'src/hooks';
import { useGetFileURLQuery } from 'src/store/services';
import { SVG_SIZE_M } from 'src/constants';
import { File, Warning, X } from '@phosphor-icons/react';
import log from 'src/utils/logger';
import { Spinner } from 'src/components/Loading';
import styles from 'src/v2/components/ThreadInputBox/components/AttachedFileItem/AttachedFileItem.module.scss';

type ConvertedTextFileProps = {
  filename: string;
  convertedFilename: string;
  isLoading: boolean;
  hasError: boolean;
  onDelete?: () => void;
};

export const ConvertedTextFile = ({
  filename,
  convertedFilename,
  isLoading,
  hasError,
  onDelete,
}: ConvertedTextFileProps) => {
  const { onChangeModalGalleryData } = useContext(SessionContext);
  const { appUser } = useSession();
  const { data, isError } = useGetFileURLQuery(
    { user_id: appUser.user_id, file_name: convertedFilename },
    {
      skip: !appUser.user_id || convertedFilename.length === 0,
    },
  );

  const handleDeleteAttachment = async () => {
    if (onDelete) {
      onDelete();
    }
  };

  const handleFileClick = async () => {
    if (data) {
      const url = data?.url || '';
      await fetch(url)
        .then((response) => response.text())
        .then((text) =>
          onChangeModalGalleryData(
            true,
            ModalGalleryType.TEXT_FILE,
            [
              {
                url,
                title: filename,
                content: text,
              },
            ],
            0,
          ),
        )
        .catch((error) => log.error('Error:', error));
    }
  };
  if (isError) {
    return <p>Error processing the request</p>;
  }
  return (
    <div
      className={
        !!onDelete ? 'nj-thread-message--content-with-file-wrapper--file' : ''
      }
    >
      <button
        type="button"
        onClick={handleFileClick}
        className="nj-thread-message--content-with-file-wrapper--file-label"
        key={filename}
      >
        <div className="nj-thread-message--content-with-file-wrapper--file-label-text">
          {isLoading ? (
            <Spinner size={SVG_SIZE_M} inline />
          ) : hasError ? (
            <Warning
              size={SVG_SIZE_M}
              weight="fill"
              className={styles.errorIcon}
            />
          ) : (
            <File size={SVG_SIZE_M} />
          )}
          <span className="nj-thread-message--content-with-file-wrapper--file-name ellipsis">
            {filename}
          </span>
        </div>
      </button>
      {!!onDelete && (
        <button
          type="button"
          onClick={handleDeleteAttachment}
          className={styles.fileLabelClose}
        >
          <X size={12} weight="bold" />
        </button>
      )}
    </div>
  );
};
