import { SVGProps } from 'react';

export const WSJLogo = ({
  width = 27,
  height = 15,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27 15"
      fill="none"
    >
      <path
        d="M5.03208 0.547706V0.829005L5.29323 0.889049C5.69461 0.989395 5.75507 1.23039 5.91546 1.99327L6.3333 4.13798L5.16862 10.4026L3.5824 2.5312C3.40145 1.66756 3.3414 1.02517 3.9842 0.884525L4.28524 0.824481V0.547706H0.534576V0.829005L0.916223 0.929763C1.29787 1.03011 1.45826 1.21065 1.71941 2.39548L4.2692 14.463H4.92721L6.63392 5.34707L8.52571 14.463H9.048L11.4979 2.76849C11.7792 1.42368 11.8997 1.04121 12.3821 0.917837L12.7633 0.817079V0.547706H10.0079V0.829005L10.4294 0.9092C11.0319 1.0297 11.1721 1.41093 10.8908 2.95726L9.38893 10.4026L7.86728 2.5312C7.70278 1.74816 7.60614 1.02517 8.26908 0.904677L8.71077 0.824481V0.543182L5.03208 0.547706ZM22.8987 0.585541V0.866841L23.4004 0.987339C23.8417 1.08769 24.0227 1.24849 24.0227 1.81067V11.5492C24.0227 12.6333 24.0227 14.0591 22.8979 14.0591C22.2555 14.0591 21.7546 13.4969 21.7941 13.1153C21.834 12.4931 22.9185 12.533 22.9785 11.5291C23.039 10.8299 22.5969 10.5009 22.1552 10.4446C21.5329 10.3644 20.7701 10.8863 20.7495 11.9305C20.7297 13.2753 21.6131 14.5 23.1183 14.5C24.7839 14.5 25.8737 13.3764 25.8737 11.2276L25.8742 1.83042C25.8742 1.26823 26.0148 1.08727 26.4162 0.987339L26.855 0.862317V0.581017L22.8987 0.585541ZM16.1335 0.5C14.4268 0.5 13.4633 1.92583 13.4633 3.79293C13.4633 7.26682 17.941 8.61204 17.941 11.7446C17.941 13.2304 17.3184 14.0534 16.4194 14.0534C15.0746 14.0534 14.1102 12.4277 13.8696 9.83717H13.5685V14.4153H13.8095C14.0102 13.7573 14.2714 13.6927 14.4922 13.6927C14.8739 13.6927 15.2748 14.4947 16.4395 14.4947C18.2264 14.4947 19.3907 12.9492 19.3915 10.8197C19.3915 6.64335 14.9138 5.61932 14.9138 2.62743C14.9138 1.94474 15.1145 0.941278 16.1187 0.941278C17.5647 0.941278 18.1874 2.84869 18.4279 5.15789H18.729V0.580606H18.488C18.3876 1.0223 18.2071 1.22299 17.946 1.22299C17.4841 1.22299 17.2468 0.500411 16.1389 0.500411L16.1335 0.5ZM19.366 4.43778V4.48713L19.3755 4.48138L19.3705 4.44025L19.366 4.43778Z"
        fill="currentColor"
      />
    </svg>
  );
};
