import { useMemo } from 'react';
import { useGetMyContactPhotoQuery } from 'src/store/services';
import { useGoogleResourcesAccess, useSession } from 'src/hooks';
import { IDP } from 'src/types';

export const useGetUserPhoto = () => {
  const { appUser, isOpenTier } = useSession();
  const { canAccessContacts } = useGoogleResourcesAccess();

  const { data: photoUrl } = useGetMyContactPhotoQuery(
    {
      user_id: appUser.user_id,
    },
    { skip: isOpenTier || appUser.idp === IDP.EMAIL || !canAccessContacts },
  );

  return useMemo(() => ({ photoUrl }), [photoUrl]);
};
