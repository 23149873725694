import { useRef } from 'react';
import { Plus } from '@phosphor-icons/react';
import { Button } from 'src/v2/commonComponents/Button';
import { ReactTooltip } from 'src/v2/commonComponents/ReactTooltip';
import { useOutsideClick, useVisible } from 'src/hooks';
import { SVG_SIZE_M } from 'src/constants';
import { AddAttachmentButton } from 'src/v2/components/ThreadInputBox/components/AddAttachmentButton';
import { CapturePhoto } from 'src/components/CapturePhoto';
import styles from './MoreActionsButton.module.scss';

const MORE_ACTIONS_ANCHOR_ID = 'more-actions-input-trigger';

const MoreActionsButton = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { isVisible, handleVisibilityRemove, handleVisibilitySet } =
    useVisible();

  useOutsideClick(containerRef, handleVisibilityRemove);

  return (
    <div ref={containerRef} className={styles.root}>
      <Button
        id={MORE_ACTIONS_ANCHOR_ID}
        className={styles.trigger}
        shape="round"
        color="transparent"
        onClick={handleVisibilitySet}
      >
        <Plus size={SVG_SIZE_M} />
      </Button>

      <ReactTooltip
        place="top-start"
        id={MORE_ACTIONS_ANCHOR_ID}
        isOpen={isVisible}
        offset={-38}
        className={styles.tooltip}
      >
        <AddAttachmentButton withTitle onClose={handleVisibilityRemove} />

        <CapturePhoto withTitle onClose={handleVisibilityRemove} />
      </ReactTooltip>
    </div>
  );
};

export default MoreActionsButton;
