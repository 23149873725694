import { useContext } from 'react';
import { Modal } from 'react-responsive-modal';
import { X } from '@phosphor-icons/react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { useTaskById } from 'src/hooks';
import log from 'loglevel';

type QuickTourModalProps = {
  isOpen: boolean;
  onClose: () => void;
  taskName: string;
  taskId: string;
  onStop?: () => void;
};

const SVG_SIZE = 24;

export const StopTaskConfirmationModal = ({
  isOpen,
  onClose,
  taskName,
  taskId,
  onStop,
}: QuickTourModalProps) => {
  const { stopTask } = useTaskById();
  const { rootContainerRef } = useContext(ForwardRefContext);

  const handleStopTask = async () => {
    try {
      if (onStop) {
        onStop();
      }

      stopTask(taskId);

      onClose();
    } catch (e) {
      log.error(e);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      container={rootContainerRef?.current || document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: 'nj-modal--access nj-stop-task-confirmation-modal',
        closeIcon: 'nj-video-avatar-modal-closeButton',
      }}
      closeIcon={<X size={SVG_SIZE} />}
      center
    >
      <div className="nj-stop-task-confirmation-modal-content">
        <h3 className="nj-stop-task-confirmation-modal-title">Stop task?</h3>
        <p className="nj-stop-task-confirmation-modal-text">
          Stop <b>{taskName}</b>? Please be aware that once a task is stopped,
          it cannot be restarted.
        </p>
        <div className="nj-stop-task-confirmation-modal-buttons-wrapper">
          <button
            className="nj-stop-task-confirmation-modal-button nj-stop-task-confirmation-modal-cancel-button"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="nj-stop-task-confirmation-modal-button nj-stop-task-confirmation-modal-submit-button"
            onClick={handleStopTask}
          >
            Stop task
          </button>
        </div>
      </div>
    </Modal>
  );
};
