import { useMemo, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useSession } from 'src/hooks';
import { getTimeZoneShortName } from 'src/utils';

dayjs.extend(utc);
dayjs.extend(timezone);

export const useTimeZone = () => {
  const { appUser } = useSession();

  const { settings } = appUser;

  const userTimeZone = useMemo(
    () => settings?.timezone_setting?.timezone || dayjs.tz.guess(),
    [settings?.timezone_setting?.timezone],
  );

  const convertUTCtoUserTimeZone = useCallback(
    (date: Dayjs | string) => {
      return dayjs(date).tz(userTimeZone);
    },
    [userTimeZone],
  );

  const convertUserTimeZoneToUTC = useCallback((date: Dayjs | string) => {
    return dayjs(date).utc();
  }, []);

  return {
    convertUTCtoUserTimeZone,
    convertUserTimeZoneToUTC,
    userTimeZone,
    userTimeZoneShortName: getTimeZoneShortName(userTimeZone),
  };
};
