import { UserTier } from 'src/types/models/UserTier';
import { LabelFree } from 'src/components/LabelFree';
import LabelStandard from 'src/components/LabelStandard/LabelStandard';
import { LabelPro } from 'src/components/LabelPro';
import { LabelProTrial } from 'src/components/FlatAppearance/components/LabelProTrial';
import { LabelUltra } from 'src/components/LabelUltra';
import { LabelEnterpriseTrial } from 'src/components/LabelEnterpriseTrial';
import { LabelEnterprise } from 'src/components/LabelEnterprise';
import { LabelTeamsStandard } from 'src/components/LabelTeamsStandard';
import { LabelTeamsPro } from 'src/components/LabelTeamsPro';
import { LabelTeamsUltra } from 'src/components/LabelTeamsUltra';

export type TierDataType = Record<
  UserTier,
  {
    labelShort: React.ReactNode | null;
    labelLong: React.ReactNode | null;
  }
>;

export const TIER_DATA: TierDataType = {
  [UserTier.OPEN]: {
    labelShort: null,
    labelLong: null,
  },
  [UserTier.FREE]: {
    labelShort: <LabelFree />,
    labelLong: <LabelFree />,
  },
  [UserTier.STANDARD]: {
    labelShort: <LabelStandard labelType="short" />,
    labelLong: <LabelStandard labelType="full" />,
  },
  [UserTier.PRO]: {
    labelShort: <LabelPro labelType="short" />,
    labelLong: <LabelPro labelType="full" />,
  },
  [UserTier.PRO_TRIAL]: {
    labelShort: <LabelProTrial />,
    labelLong: <LabelProTrial />,
  },
  [UserTier.ULTRA]: {
    labelShort: <LabelUltra labelType="short" />,
    labelLong: <LabelUltra labelType="full" />,
  },
  [UserTier.ENTERPRISE_TRIAL]: {
    labelShort: <LabelEnterpriseTrial />,
    labelLong: <LabelEnterpriseTrial />,
  },
  [UserTier.ENTERPRISE]: {
    labelShort: <LabelEnterprise />,
    labelLong: <LabelEnterprise />,
  },
  [UserTier.TEAMS_STANDARD]: {
    labelShort: <LabelTeamsStandard labelType="short" />,
    labelLong: <LabelTeamsStandard labelType="full" />,
  },
  [UserTier.TEAMS_PRO]: {
    labelShort: <LabelTeamsPro labelType="short" />,
    labelLong: <LabelTeamsPro labelType="full" />,
  },
  [UserTier.TEAMS_ULTRA]: {
    labelShort: <LabelTeamsUltra labelType="short" />,
    labelLong: <LabelTeamsUltra labelType="full" />,
  },
  [UserTier.TESTING]: {
    labelShort: null,
    labelLong: null,
  },
  [UserTier.PAID]: {
    labelShort: <LabelPro labelType="short" />,
    labelLong: <LabelPro labelType="full" />,
  },
  [UserTier.TEAM]: {
    labelShort: null,
    labelLong: null,
  },
};
