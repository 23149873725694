import cn from 'classnames';
import { ChatMode } from 'src/types';
import { useSession, usePictureInPicture, useBreakpoint } from 'src/hooks';
import { UnreadCountButton } from 'src/components/UnreadCountButton';
import { ThreadView } from 'src/components/FlatAppearance/components/ThreadView';
import styles from './MultiConversationsPanel.module.scss';

interface MultiConversationsPanelProps {
  startSpeechRecognizing: () => void;
  stopSpeechRecognizing: () => void;
  unMuteMicrophone: () => void;
}

/**
 * Composes conversation panel with header, footer and AI scenario.
 */
export const MultiConversationsPanel = ({
  startSpeechRecognizing,
  stopSpeechRecognizing,
  unMuteMicrophone,
}: MultiConversationsPanelProps) => {
  const { isPictureInPicture } = usePictureInPicture();
  const { isDesktopAndUp } = useBreakpoint();

  const { chatMode } = useSession();

  return (
    <div
      className={cn(styles.root, {
        [styles.fixedWidth]:
          !isPictureInPicture && chatMode === ChatMode.AVATAR,
      })}
    >
      {chatMode === ChatMode.CHAT && isDesktopAndUp && <UnreadCountButton />}

      <ThreadView
        startSpeechRecognizing={startSpeechRecognizing}
        stopSpeechRecognizing={stopSpeechRecognizing}
        unMuteMicrophone={unMuteMicrophone}
      />
    </div>
  );
};
