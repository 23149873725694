import { useCallback, useContext } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import log from 'src/utils/logger';
import { useSession } from 'src/hooks';
import { useDeleteFileMutation } from 'src/store/services';

export const useDeleteAttachment = () => {
  const { appUser } = useSession();

  const [deleteFileFromS3] = useDeleteFileMutation();

  const { deleteAttachment, onClearAttachments, threadInputBoxFiles } =
    useContext(ThreadInputBoxContext);

  const handleDeleteAttachment = useCallback(
    async (fileId: string, convertedFileName?: string) => {
      deleteAttachment(fileId);
      if (!!convertedFileName) {
        try {
          await deleteFileFromS3({
            user_id: appUser.user_id,
            file_name: convertedFileName,
          });
        } catch (error) {
          log.error(error);
        }
      }
    },
    [deleteAttachment, appUser, deleteFileFromS3],
  );

  const handleDeleteAllAttachments = useCallback(async () => {
    onClearAttachments();
    if (!!threadInputBoxFiles) {
      for (const key of Object.keys(threadInputBoxFiles)) {
        if (!!threadInputBoxFiles[key]?.convertedFileName) {
          try {
            await deleteFileFromS3({
              user_id: appUser.user_id,
              file_name: threadInputBoxFiles[key]?.convertedFileName || '',
            });
          } catch (error) {
            log.error(error);
          }
        }
      }
    }
  }, [onClearAttachments, appUser, deleteFileFromS3, threadInputBoxFiles]);

  return {
    onDeleteAttachment: handleDeleteAttachment,
    onDeleteAllAttachments: handleDeleteAllAttachments,
  };
};
