import { useMemo } from 'react';
import { useAppSelector, interfaceControlState } from 'src/store';

export const useLeftPanelState = () => {
  const { isTasksListExpanded } = useAppSelector(interfaceControlState);

  return useMemo(
    () => ({
      isTasksListExpanded,
    }),
    [isTasksListExpanded],
  );
};
