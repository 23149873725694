import { useRef } from 'react';
import { ResearchCard } from 'src/types';
import { Atom, ListMagnifyingGlass, Stack } from '@phosphor-icons/react';
import { ThreadResearchReferences } from './components/ThreadResearchReferences';
import { ThreadResearchMedia } from './components/ThreadResearchMedia';
import { useSession } from 'src/hooks';
import { ThreadSummary } from '../ThreadSummary';
import { ExternalModelReferencesList } from '../ExternalModelReferencesList';
import { prepareExternalModelsReferences } from 'src/utils';
import { SettingsMessageResearchCodeCard } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/SettingsMessageResearchCodeCard';

interface ThreadResearchCardProps {
  researchCard?: ResearchCard;
  isStreaming?: boolean;
  originalQuery?: string;
  taskId?: string;
}

/**
 * @deprecated
 */
export const ThreadResearchCard = ({
  researchCard,
  isStreaming,
  originalQuery,
  taskId,
}: ThreadResearchCardProps) => {
  const { isOpenTier } = useSession();
  const containerRef = useRef<HTMLDivElement>(null);

  if (!researchCard) {
    return null;
  }

  const { search_result, deep_research_result, generative_ai_result } =
    researchCard.data || {};

  const { referenced_model_summary } = generative_ai_result || {};
  const { references, summary } = referenced_model_summary || {};
  const { web_page_references, image_references, video_references } =
    search_result || {};

  return (
    <div className="nj-thread-research-card" ref={containerRef}>
      {search_result && (
        <ThreadSummary
          title="Fast Research"
          content={search_result.summary}
          IconComponent={ListMagnifyingGlass}
          references={search_result.web_page_references}
          isStreaming={isStreaming}
        />
      )}

      <ThreadResearchReferences researchReferences={web_page_references} />

      {deep_research_result && (
        <>
          <ThreadSummary
            title="SuperAgent"
            content={deep_research_result.summary}
            IconComponent={Atom}
            references={deep_research_result.web_page_references}
            colorPalette="blue"
            isStreaming={isStreaming}
          />

          <ThreadResearchReferences
            researchReferences={deep_research_result.web_page_references}
          />
        </>
      )}

      <ThreadResearchMedia
        images={image_references || []}
        videos={video_references || []}
      />

      {generative_ai_result && (
        <>
          <ThreadSummary
            title="Summary from external models"
            content={summary}
            IconComponent={Stack}
            isStreaming={isStreaming}
            isCollapsed
            colorPalette="light-blue"
            references={prepareExternalModelsReferences(
              references || {},
              originalQuery,
            )}
          />

          <ExternalModelReferencesList
            references={references}
            originalQuery={originalQuery}
            taskId={taskId}
          />
        </>
      )}

      {!isOpenTier && (
        <SettingsMessageResearchCodeCard
          cardType="researcher"
          deepResearchData={researchCard.data?.deep_research_result}
          referencedModelSummary={
            researchCard.data?.generative_ai_result?.referenced_model_summary
          }
          isStreaming={isStreaming}
        />
      )}
    </div>
  );
};
