/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CodeCard } from './CodeCard';
import type { ProgressUpdate } from './ProgressUpdate';
import type { ProgressUpdateV2 } from './ProgressUpdateV2';

export type CodeTaskCreationCard = {
  task_query: string;
  task_hash: string;
  task_id: string;
  progress?: ProgressUpdate;
  progress_v2?: ProgressUpdateV2;
  payload_type?: CodeTaskCreationCard.payload_type;
  code_card?: CodeCard;
};

export namespace CodeTaskCreationCard {
  export enum payload_type {
    CODE_TASK_CREATION_CARD = 'code-task-creation-card',
  }
}
