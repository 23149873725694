import styles from './GoogleSignupSuggestionSection.module.scss';
import { AppRoutes, SSOProvider } from 'src/types';
import { useContext, useMemo } from 'react';
import AuthContext from 'src/contexts/AuthContext';
import { Link } from 'react-router-dom';
import { SSOButton } from 'src/v2/pages/auth/components/SSOButton';
import { TermsSection } from 'src/v2/pages/auth/components/TermsSection/TermsSection';

type GoogleSignupSuggestionSectionProps = {
  email: string;
};

export const GoogleSignupSuggestionSection = ({
  email,
}: GoogleSignupSuggestionSectionProps) => {
  const { setAuthLoading } = useContext(AuthContext);

  const googlePromptRedirectLink = useMemo(() => {
    const encodedEmail = encodeURIComponent(email);
    return `${AppRoutes.SIGN_UP_WITH_EMAIL}?email=${encodedEmail}`;
  }, [email]);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div>
          <h2 className={styles.signUpHeader}>Set up your account</h2>
        </div>

        <h5 className={styles.greetings}>Hi {email}</h5>
        <p className={styles.googlePrompt}>
          The easiest way for you to sign up is with Google. By doing so, you
          can connect your calendar, enabling Ninja to access your availability
          and schedule meetings.
        </p>
        <SSOButton
          ssoProvider={SSOProvider.GOOGLE}
          showFullButton
          setAuthLoading={setAuthLoading}
        />
        <div>
          <p className={styles.googlePrompt}>
            Prefer to create an account with a password?
          </p>
          <Link className={styles.loginLink} to={googlePromptRedirectLink}>
            Click here
          </Link>
        </div>

        <TermsSection />
      </div>
    </div>
  );
};
