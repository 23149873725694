import { useMemo } from 'react';
import {
  useGoogleLogin,
  UseGoogleLoginOptionsAuthCodeFlow,
} from '@react-oauth/google';
import { useSession } from 'src/hooks';
import {
  useGetGoogleResourcesAccessQuery,
  useRevokeGoogleResourcesAccessMutation,
} from 'src/store/services';
import { CALENDAR_URL, CONTACTS_URL, DIRECTORY_URL } from 'src/constants';
import { env } from 'src/env';

export const useGoogleResourcesAccess = () => {
  const { appUser, isOpenTier } = useSession();

  const googleLoginParams: UseGoogleLoginOptionsAuthCodeFlow = {
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: env.REACT_APP_GOOGLE_AUTHORIZATION_REDIRECT_URI,
    hint: appUser?.email,
    state: window.location.href,
  };

  const [revokeGoogleAccess] = useRevokeGoogleResourcesAccessMutation();

  const { data, isLoading, isFetching } = useGetGoogleResourcesAccessQuery(
    appUser.user_id,
    { skip: !appUser?.user_id || isOpenTier },
  );

  const canAccessCalendar = useMemo(
    () => !!data?.scopes.find((item) => item === CALENDAR_URL),
    [data],
  );
  const canAccessContacts = useMemo(
    () => !!data?.scopes.find((item) => item === CONTACTS_URL),
    [data],
  );

  const canAccessDirectory = useMemo(
    () => !!data?.scopes.find((item) => item === DIRECTORY_URL),
    [data],
  );

  const connectAll = useGoogleLogin({
    ...googleLoginParams,
    scope: `${CALENDAR_URL} ${CONTACTS_URL} ${DIRECTORY_URL}`,
  });

  const connectCalendar = useGoogleLogin({
    ...googleLoginParams,
    scope: CALENDAR_URL,
  });

  const connectContacts = useGoogleLogin({
    ...googleLoginParams,
    scope: CONTACTS_URL,
  });

  const connectDirectory = useGoogleLogin({
    ...googleLoginParams,
    scope: DIRECTORY_URL,
  });

  return {
    canAccessCalendar,
    canAccessContacts,
    canAccessDirectory,
    connectAll,
    connectCalendar,
    connectContacts,
    connectDirectory,
    revokeGoogleAccess,
    isLoadingConnectionCheck: isLoading,
    isFetchingConnectionCheck: isFetching,
  };
};
