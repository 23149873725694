/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FileRequestPayload = {
  payload_type?: FileRequestPayload.payload_type;
  original_filename: string;
  converted_filename: string;
};

export namespace FileRequestPayload {
  export enum payload_type {
    FILE = 'file',
  }
}
