import { useMemo, useState } from 'react';
import { useChangePlanMutation } from 'src/store/services';
import log from 'src/utils/logger';
import { useSession } from 'src/hooks';
import { AppRoutes, NinjaSubscriptionPlan, SubscriptionPlan } from 'src/types';
import styles from './UpgradeDowngradeButton.module.scss';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { defineIsUpgrade } from 'src/utils';
import { UserTier } from 'src/types/models/UserTier';

type UpgradeDowngradeButtonProps = {
  selectedPlan?: SubscriptionPlan;
};

export const UpgradeDowngradeButton = ({
  selectedPlan,
}: UpgradeDowngradeButtonProps) => {
  const { appUser } = useSession();
  const navigate = useNavigate();
  const [changePlan] = useChangePlanMutation();

  const [isDisabledSubmit, setDisabledSubmit] = useState<boolean>(false);

  const newTierName = useMemo(
    () =>
      selectedPlan?.plan_tier
        ? selectedPlan.plan_tier[0].toUpperCase() +
          selectedPlan.plan_tier.slice(1)
        : '',
    [selectedPlan],
  );

  const isUpgrade = defineIsUpgrade(
    appUser?.tier_id as UserTier,
    selectedPlan?.plan_tier as NinjaSubscriptionPlan,
  );

  const handleConfirmAndPayClick = async () => {
    setDisabledSubmit(true);

    try {
      if (!!selectedPlan) {
        const data = await changePlan({
          user_id: appUser.user_id,
          product_key: selectedPlan.metadata?.stripe?.lookup_key || '',
          price_timestamp: selectedPlan.timestamp,
        }).unwrap();

        const redirectUrl = data?.approve_url;

        if (!!redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          navigate(AppRoutes.HOME);
          toast.success(
            isUpgrade
              ? `Upgrade to ${newTierName} successful`
              : 'Account downgrade started',
          );
        }
      }
    } catch (e) {
      toast.error(
        isUpgrade
          ? `Upgrade to ${newTierName} failed`
          : 'Account downgrade failed',
      );
      log.error();
    }
  };

  return (
    <button
      type="button"
      className={styles.confirmAndPayButton}
      onClick={handleConfirmAndPayClick}
      disabled={isDisabledSubmit}
    >
      Confirm & pay
    </button>
  );
};
