import { BasePublicPage } from 'src/v2/pages/auth/BasePublicPage';
import { ForgotPasswordSection } from 'src/v2/pages/auth/ForgotPasswordPage/components/ForgotPasswordSection';

export const ForgotPasswordPage = () => {
  return (
    <BasePublicPage alignContainer="center">
      <ForgotPasswordSection />
    </BasePublicPage>
  );
};
