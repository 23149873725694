import { SVGProps } from 'react';

export const WhatsappIcon = ({
  width = 24,
  height = 24,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M12.0001 0C5.38338 0 7.74383e-05 5.3833 7.74383e-05 12C7.74383e-05 14.0661 0.533817 16.0977 1.54599 17.8904L0.0193818 23.3374C-0.0307052 23.5163 0.0178166 23.7083 0.147208 23.8414C0.24686 23.9442 0.382512 24 0.521817 24C0.563556 24 0.605817 23.9948 0.647034 23.9849L6.3319 22.5767C8.06773 23.5085 10.0227 24 12.0001 24C18.6168 24 24.0001 18.6167 24.0001 12C24.0001 5.3833 18.6168 0 12.0001 0ZM18.0366 16.2344C17.7799 16.945 16.5486 17.5936 15.9569 17.6807C15.4258 17.7584 14.7538 17.7918 14.0161 17.5602C13.5689 17.4193 12.995 17.2325 12.2599 16.919C9.16964 15.601 7.15156 12.5285 6.99712 12.3256C6.84321 12.1226 5.73921 10.6763 5.73921 9.17948C5.73921 7.68261 6.53486 6.94643 6.81764 6.64174C7.10043 6.33704 7.43382 6.26087 7.63938 6.26087C7.84495 6.26087 8.04999 6.26348 8.22999 6.27183C8.41938 6.28122 8.67347 6.20035 8.92338 6.79409C9.18008 7.40348 9.79625 8.90035 9.87243 9.05322C9.94964 9.20556 10.0008 9.38348 9.89851 9.58644C9.79625 9.78939 9.74512 9.91617 9.59069 10.0941C9.43625 10.272 9.26721 10.4906 9.12843 10.6273C8.97399 10.7791 8.81382 10.9435 8.99329 11.2482C9.17277 11.5529 9.79103 12.5489 10.7072 13.3555C11.8837 14.3917 12.8766 14.713 13.1844 14.8654C13.4923 15.0177 13.6723 14.9922 13.8517 14.7892C14.0312 14.5857 14.6218 13.9007 14.8269 13.5965C15.0319 13.2923 15.2375 13.3424 15.5203 13.4442C15.803 13.5454 17.3176 14.281 17.6255 14.4334C17.9333 14.5857 18.1389 14.6619 18.2161 14.7887C18.2933 14.915 18.2933 15.5243 18.0366 16.2344Z"
        fill="currentColor"
      />
    </svg>
  );
};
