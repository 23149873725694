import { useMemo } from 'react';
import { TextInput } from 'src/types';
import { useTimeZone } from 'src/hooks';
import { User } from '@phosphor-icons/react';
import { formatTimestampToDateAndTime } from 'src/utils';

type CompletedTextInputFieldsListProps = {
  fields: TextInput[];
  timestamp?: string;
};

const SVG_SIZE = 20;

export const CompletedTextInputFieldsList = ({
  timestamp,
  fields,
}: CompletedTextInputFieldsListProps) => {
  const { userTimeZone } = useTimeZone();

  const submissionTime = useMemo(
    () => formatTimestampToDateAndTime(timestamp, userTimeZone),
    [timestamp, userTimeZone],
  );

  return (
    <div className="nj-task-view--form completed">
      <h5 className="nj-task-scratchpad--context-title">
        Provide guest information
      </h5>
      {fields.map(({ label, value }: TextInput, index: number) => (
        <div key={index} className="nj-task-view--form--field">
          <label className="nj-task-view--form--label">{label}</label>
          <div className="nj-task-view--form--value">
            <User size={SVG_SIZE} />
            <span className="nj-task-view--form--input--value">{value}</span>
          </div>
        </div>
      ))}
      <i
        data-e2e="text-input-card-submission-time"
        className="nj-task-view--form--submitted"
      >
        Submitted on {submissionTime}
      </i>
    </div>
  );
};
