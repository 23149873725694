import { useState, useCallback } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useFormContext, FieldValues, Path, PathValue } from 'react-hook-form';
import { Span } from 'src/types';
import { useMeetingCardFieldsWatch } from './useMeetingCardFieldsWatch';

dayjs.extend(isSameOrBefore);

export enum DateRangeErrorType {
  BOTH_PICKERS = 'both_pickers',
  START_PICKER = 'start_picker',
}

interface UseMeetingWindowDateRangeArgs extends Span {
  index: number;
  key: string;
}

export const useMeetingWindowDateRange = <T extends FieldValues>({
  start,
  end,
  index,
  key,
}: UseMeetingWindowDateRangeArgs) => {
  const { setError, setValue, clearErrors } = useFormContext<T>();

  const { minutes } = useMeetingCardFieldsWatch<T>();

  const [startDate, setStartDate] = useState<string>(start);
  const [endDate, setEndDate] = useState<string>(end);

  const validateDateRange = useCallback(
    ({ start, end }: Span) => {
      const modifiedStart = dayjs(start).add(minutes || 0, 'minute');
      const isStartBeforeEnd = dayjs(modifiedStart).isSameOrBefore(dayjs(end));

      if (!isStartBeforeEnd) {
        setError(`${key}.${index}` as Path<T>, {
          message:
            'Adjust the start or end time to have a range greater than selected duration.',
          type: DateRangeErrorType.BOTH_PICKERS,
        });
        return false;
      }

      clearErrors(`${key}.${index}` as Path<T>);

      return true;
    },
    [index, key, minutes, setError, clearErrors],
  );

  const updateDateRange = useCallback(
    ({ start, end }: Span) => {
      if (!validateDateRange({ start, end })) {
        return;
      }

      setValue(
        `${key}.${index}` as Path<T>,
        {
          start: dayjs(start).utc().format(),
          end: dayjs(end).utc().format(),
        } as PathValue<T, Path<T>>,
      );
    },
    [validateDateRange, setValue, index, key],
  );

  return {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    updateDateRange,
    validateDateRange,
  };
};
