import { isString, isObject } from './general.guard';
import { DebugMetadata, MessageDebugData } from '../api';
import log from 'src/utils/logger';

/**
 * isDebugMetadata() test data against DebugMetadata type.
 * @param data unknown
 * @returns boolean
 */
export function isDebugMetadata(data: unknown): data is DebugMetadata {
  const metadata = data as DebugMetadata;

  if (
    'payload_type' in metadata &&
    metadata.payload_type !== 'debug-metadata'
  ) {
    return false;
  }

  if (!('intent_label' in metadata && isString(metadata.intent_label))) {
    log.debug(
      `isDebugMetadata > invalid intent_label ${JSON.stringify(metadata)}`,
    );
    return false;
  }

  return true;
}

/**
 * isMessageDebugData() guards against debug data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageDebugData(data: unknown): data is MessageDebugData {
  const debug = data as MessageDebugData;

  if (!isObject(debug)) {
    log.debug(`isMessageDebugData > invalid debug ${JSON.stringify(debug)}`);
    return false;
  }

  if (
    'x_trace_id' in debug &&
    !(isString(debug.x_trace_id) || debug.x_trace_id === undefined)
  ) {
    log.debug(
      `isMessageDebugData > invalid x_trace_id ${JSON.stringify(debug)}`,
    );
    return false;
  }

  return true;
}
