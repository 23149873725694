import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { TabProps } from '../Tab';
import { TabArrow } from '../TabArrow';
import styles from './TabList.module.scss';

export interface TabListProps<T extends string> {
  activeTab?: T;
  onTabClick?: (tab: T) => void;
  children: ReactNode;
  className?: string;
}

export const TabList = <T extends string>({
  activeTab,
  children,
  onTabClick,
  className,
}: TabListProps<T>) => {
  const [isBeginning, setIsBeginning] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean | null>(null);

  const swiperRef = useRef<SwiperClass | null>(null);

  const handleArrowStateChange = (swiper: SwiperClass) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const handleReachBeginning = (swiper: SwiperClass) => {
    setIsBeginning(true);
    setIsEnd(swiper.isEnd);
  };

  const handleReachEnd = (swiper: SwiperClass) => {
    setIsEnd(true);
    setIsBeginning(swiper.isBeginning);
  };

  const handleInit = (swiper: SwiperClass) => {
    swiperRef.current = swiper;

    if (!swiper.isLocked) {
      setIsEnd(false);
    } else {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  };

  return (
    <div className={classNames(styles.root, className)}>
      <TabArrow
        direction="prev"
        visible={!isBeginning}
        onClick={() => swiperRef.current?.slidePrev()}
      />

      <Swiper
        init={false}
        spaceBetween={4}
        slidesPerView="auto"
        onFromEdge={handleArrowStateChange}
        onInit={handleInit}
        onReachEnd={handleReachEnd}
        onReachBeginning={handleReachBeginning}
      >
        {Children.map(children, (child, index) => {
          if (!isValidElement(child)) {
            return null;
          }

          const childType = (child.type as React.FC).displayName;

          if (childType === 'Tab') {
            const clonedChild = cloneElement(
              child as ReactElement<TabProps<T>>,
              {
                activeTab,
                onTabClick,
              },
            );

            return <SwiperSlide key={index}>{clonedChild}</SwiperSlide>;
          }

          return <SwiperSlide key={index}>{child}</SwiperSlide>;
        })}
      </Swiper>

      <TabArrow
        direction="next"
        visible={isEnd === false}
        onClick={() => swiperRef.current?.slideNext()}
      />
    </div>
  );
};

TabList.displayName = 'TabList';
