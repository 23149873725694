import styles from './CheckoutSeatsSection.module.scss';
import { useDebouncedCallback } from 'use-debounce';
import { useState } from 'react';

type CheckoutSeatsSectionProps = {
  numberOfSeats: number;
  onChangeNumberOfSeats: (value: number) => void;
};

export const CheckoutSeatsSection = ({
  numberOfSeats,
  onChangeNumberOfSeats,
}: CheckoutSeatsSectionProps) => {
  const [value, changeValue] = useState(numberOfSeats);

  const handleDebouncedOnChange = useDebouncedCallback((query: number) => {
    onChangeNumberOfSeats(query);
  }, 500);

  const handleChangeValue = (val: number) => {
    if (val <= 0) {
      changeValue(1);
      handleDebouncedOnChange(1);
    } else {
      changeValue(val);
      handleDebouncedOnChange(val);
    }
  };

  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.labelWrapper}>
        <label className={styles.label}>Seats</label>
        <span className={styles.description}>
          Choose the number of seats included in your workspace.
        </span>
      </div>
      <input
        className={styles.input}
        type="number"
        step={1}
        min={1}
        value={value}
        onChange={(e) => handleChangeValue(Number(e.target.value))}
      />
    </div>
  );
};
