interface LabelTeamsProProps {
  labelType?: 'short' | 'full';
}

export const LabelTeamsPro = ({ labelType = 'full' }: LabelTeamsProProps) => {
  return (
    <label className="nj-label-pro">
      {labelType === 'short' ? 'Team Pro' : 'Team Pro subscription'}
    </label>
  );
};
