import { useCallback, useMemo } from 'react';
import { useGetConversationByIdQuery } from 'src/store/services';
import { useAppSelector, sessionState } from 'src/store';
import { useConversationParams } from './useConversationParams';

export const useCurrentConversationTasks = () => {
  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);

  const { currentConversationId, shouldSkipGetConversationById } =
    useConversationParams();

  const { tasks: currentConversationTasks } = useGetConversationByIdQuery(
    {
      user_id,
      conversation_id: currentConversationId,
    },
    {
      skip: shouldSkipGetConversationById,
      selectFromResult: (result) => {
        return { tasks: result.data?.tasks };
      },
    },
  );

  const getTaskFromConversationById = useCallback(
    (task_id?: string) => {
      if (!task_id) {
        return;
      }

      return currentConversationTasks?.find((item) => item.task_id === task_id);
    },
    [currentConversationTasks],
  );

  return useMemo(
    () => ({
      conversationTasks: currentConversationTasks || [],
      getTaskFromConversationById,
    }),
    [currentConversationTasks, getTaskFromConversationById],
  );
};
