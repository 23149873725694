import { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useSession } from 'src/hooks';
import DebugContext from 'src/contexts/DebugContext';
import AudioContext from 'src/contexts/AudioContext';
import { letMetaHumanTalk, sanitizeOutgoingContent } from 'src/utils';
import log from 'src/utils/logger';
import { CITATION_REGEX } from 'src/constants';

export const useAvatarSpeech = () => {
  const {
    appUser: { user_id },
  } = useSession();
  const { debugMode } = useContext(DebugContext);
  const { metaHumanTalking } = useContext(AudioContext);

  const [avatarLocale, setAvatarLocale] = useState<string>('');
  const [avatarVoice, setAvatarVoice] = useState<string>('');
  const [timestamp, setTimestamp] = useState<null | number>(null);

  const queueContent = useRef<string>('');

  const textRef = useRef<string>('');
  const isStreamingRef = useRef<boolean>(false);
  const sentencesQueueRef = useRef<string[]>([]);

  const removeSubstring = (str: string, subStr: string): string => {
    return str.replace(subStr, '');
  };

  // place newly arrived text into sentences queue
  const enqueueAvatarSpeech = (
    speech: string,
    newLocale: string,
    voiceID: string,
  ) => {
    setAvatarLocale(newLocale);
    setAvatarVoice(voiceID);
    const incomingSpeech = sanitizeOutgoingContent(speech);
    const newText = removeSubstring(incomingSpeech, queueContent.current);

    queueContent.current = incomingSpeech;

    const combinedText = `${textRef.current || ''}${newText}`;
    const newSentences = combinedText.match(/[^.!?]+[.!?]+/g) || [];
    const notASentence = combinedText.replace(newSentences.join(''), '');

    textRef.current = notASentence;

    const newSentencesQueue = [...sentencesQueueRef.current, ...newSentences];
    sentencesQueueRef.current = newSentencesQueue;

    if (newSentencesQueue.length > 0) {
      // (olha): workaround making effect for calling sendSpeechToAvatar
      setTimestamp(Date.now());
    }

    if (!isStreamingRef.current && newSentencesQueue.length > 0) {
      isStreamingRef.current = true;
    }

    if (debugMode) {
      log.debug(
        'DEBUG > AVATAR > enqueueAvatarSpeech > ',
        sentencesQueueRef.current,
      );
    }
  };

  const clearAvatarSpeechQueue = useCallback(() => {
    setTimestamp(null);
    isStreamingRef.current = false;
    textRef.current = '';
    sentencesQueueRef.current = [];
    queueContent.current = '';
  }, []);

  // delegate speech to iframed avatar application
  const sendSpeechToAvatar = useCallback(
    (newText: string) => {
      if (!newText) return;

      if (debugMode) {
        log.debug(
          'DEBUG > AVATAR > streaming sentence: ',
          newText,
          avatarLocale,
          avatarVoice,
        );
      }
      const clearedFromCitationsText = newText
        .split(' ')
        .filter((word) => !CITATION_REGEX.test(word))
        .join(' ');

      letMetaHumanTalk(
        clearedFromCitationsText,
        user_id,
        avatarVoice,
        avatarLocale,
      );
    },
    [user_id, avatarVoice, avatarLocale, debugMode],
  );

  // create streaming effect for avatar speech
  useEffect(() => {
    // TODO(olha): we need to skip sending signals to Avatar if we consider Avatar doesn't speak
    if (!metaHumanTalking) {
      clearAvatarSpeechQueue();
      return;
    }

    if (isStreamingRef.current) {
      if (sentencesQueueRef.current.length > 0) {
        const [sentence, ...rest] = sentencesQueueRef.current;
        sendSpeechToAvatar(sentence);
        sentencesQueueRef.current = rest;
        // (olha): since we have to use useRef (sentencesQueueRef) instead of useState,
        // timestamp is a workaround making effect for calling sendSpeechToAvatar properly;
        setTimestamp(Date.now());
      } else {
        setTimestamp(null);
        isStreamingRef.current = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp, metaHumanTalking]);

  return {
    enqueueAvatarSpeech,
  };
};
