import { DropAttachmentIcon } from 'src/images/icons/dropAttachment';
import styles from './DnDOverlay.module.scss';

export const DnDOverlay = () => {
  return (
    <div className={styles.root}>
      <DropAttachmentIcon />
      <span className={styles.textOverlay}>Drop to attach any file</span>
    </div>
  );
};
