import researcherIcon from 'src/images/icons/art-researcher.svg';
import researcherDarkIcon from 'src/images/icons/art-researcher-dark.svg';
import { useSession } from 'src/hooks';
import { UpsellCard } from 'src/v2/commonComponents/UpsellCard';

interface SuperAgentUpsellProps {
  description: string;
}

export const SuperAgentUpsell = ({ description }: SuperAgentUpsellProps) => {
  const {
    appUser: { settings },
  } = useSession();

  if (settings?.super_agent?.is_capable) {
    return null;
  }

  return (
    <UpsellCard
      darkIcon={researcherDarkIcon}
      lightIcon={researcherIcon}
      description={description}
      dataGTM="SuperAgent-message"
      shouldShowUpgrade
      upgradeTitle="Upgrade"
    />
  );
};
