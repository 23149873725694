import { Fragment, useMemo } from 'react';
import { DownloadSimple, MonitorPlay } from '@phosphor-icons/react';
import { Menu, MenuButton, MenuDivider, MenuItem } from '@szhsin/react-menu';
import { AvatarVideoPresetName } from 'src/types';
import { Icon } from 'src/components/Icon';
import { AvailableVideoFormats } from 'src/constants';
import { AvatarVideos } from 'src/types/models/AvatarVideos';
import log from 'src/utils/logger';

type DownloadVideoButtonProps = {
  videoInfo: AvatarVideos;
  buttonIconSize?: number;
};

const ICON_SIZE = 20;

export const DownloadVideoButton = ({
  videoInfo,
  buttonIconSize,
}: DownloadVideoButtonProps) => {
  const videoFormats = useMemo(
    () =>
      AvailableVideoFormats.filter(({ value }) =>
        videoInfo.urls?.find(({ name }) => value === name),
      ),
    [videoInfo],
  );

  const handleDownload = (value: AvatarVideoPresetName) => {
    const videoUrl =
      videoInfo.urls?.find(({ name }) => name === value)?.url || '';

    fetch(videoUrl)
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        } else {
          throw new Error('Error');
        }
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = videoInfo.title || 'avatar video';
        a.style.display = 'none';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
      })
      .catch((error) => {
        log.error(error);
      });
  };

  return (
    <Menu
      gap={16}
      menuClassName="nj-multi-menu"
      menuButton={
        <MenuButton className="nj-view-video-tab-content--video-item-button">
          <DownloadSimple size={buttonIconSize} />
        </MenuButton>
      }
      onItemClick={(e) => handleDownload(e.value)}
      transition
    >
      <MenuItem
        value={AvatarVideoPresetName.ALL}
        className="nj-download-video-tooltip-content-video-item"
      >
        <MonitorPlay size={ICON_SIZE} />
        <div className="nj-download-video-tooltip-content-video-item-label-wrapper">
          <p className="nj-download-video-tooltip-content-label-title">
            Download all formats
          </p>
        </div>
      </MenuItem>
      <MenuDivider />
      {videoFormats.map((videoFormat, index) => (
        <Fragment key={`${videoFormat.label}_${index}`}>
          <MenuItem
            value={videoFormat.value}
            className="nj-download-video-tooltip-content-video-item"
          >
            <Icon type={videoFormat.icon} size={ICON_SIZE} />
            <div className="nj-download-video-tooltip-content-video-item-label-wrapper">
              <p className="nj-download-video-tooltip-content-label-title">
                {videoFormat.label}
              </p>
              <span className="nj-download-video-tooltip-content-label-subtitle">
                {videoFormat.subTitle}
              </span>
            </div>
          </MenuItem>
          {videoFormats.length !== index + 1 && <MenuDivider />}
        </Fragment>
      ))}
    </Menu>
  );
};
