import { useState, useEffect, useRef } from 'react';
import { getSessionWorker } from '../webworkers/sessionWorkerFactory';

export enum SessionEventType {
  SESSION_ON = 'session-on',
  SESSION_OFF = 'session-off',
}

export const useSessionTimer = (initialTime: number) => {
  const [remainingTime, setRemainingTime] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  const workerRef = useRef<Worker | null>(null);

  useEffect(() => {
    workerRef.current = getSessionWorker();

    workerRef.current.onmessage = (e: MessageEvent) => {
      const { type, timeLeft } = e.data;
      switch (type) {
        case SessionEventType.SESSION_ON:
          setRemainingTime(timeLeft);
          break;
        case SessionEventType.SESSION_OFF:
          setRemainingTime(0);
          break;
        default:
          break;
      }
    };

    return () => {
      workerRef.current?.terminate();
    };
  }, []);

  const startSession = (duration: number) => {
    setRemainingTime(duration);
    setIsRunning(true);
    workerRef.current?.postMessage({ type: 'start', payload: duration });
  };

  const stopSession = () => {
    setIsRunning(false);
    workerRef.current?.postMessage({ type: 'stop' });
  };

  return { remainingTime, isRunning, startSession, stopSession };
};
