import { ConversationRole } from 'src/types';
import { Markdown } from 'src/components/Markdown';

interface ContextCardItemProps {
  role?: ConversationRole;
  content: string;
}

export const ContextCardItem = ({ role, content }: ContextCardItemProps) => {
  return (
    <div
      data-e2e="task-scratchpad-context-item"
      className="nj-task-scratchpad--context-item"
    >
      {role && (
        <span>
          {role === ConversationRole.USER ? 'User input: ' : 'Ninja: '}
        </span>
      )}

      <Markdown>{content}</Markdown>
    </div>
  );
};
