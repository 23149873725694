import { StylesConfig, MultiValue, GroupBase } from 'react-select';

type StylesProps = {
  isShort?: boolean;
  isDarkTheme?: boolean;
};

export const getCustomStyles = <T>({
  isShort,
  isDarkTheme,
}: StylesProps): StylesConfig<T | MultiValue<T>, boolean, GroupBase<T>> => {
  const dot = () => ({
    alignItems: 'center',
    display: 'flex',

    ':after': {
      borderRadius: 10,
      content: '" "',
      display: 'inline-block',
      height: 8,
      width: 8,
    },
  });

  return {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided, { isDisabled }) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      borderRadius: '20px',
      ':hover': {
        cursor: 'pointer',
      },
      opacity: isDisabled ? 0.68 : 1,
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: '14px',
      padding: isShort ? '0px 0px 0px 10px' : '0px 16px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '14px',
      fontWeight: 500,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided, { isDisabled }) => ({
      ...provided,
      display: isDisabled ? 'none' : 'flex',
      paddingRight: '8px',
    }),
    input: (provided, { isDisabled }) => ({
      ...provided,
      fontSize: '14px',
      visibility: isDisabled ? 'hidden' : 'visible',
      margin: 0,
      '& > input': {
        minWidth: '0 !important',
        gridArea: 'initial !important',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '14px',
      marginLeft: '5px',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      backdropFilter: 'blur(12px)',
      position: 'absolute',
      zIndex: 9999,
    }),
    option: (provided, { isSelected, data, isDisabled }) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '10px 16px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.028px',
      color: isSelected
        ? isDarkTheme
          ? '#0CC5FF'
          : '#0A74FF'
        : isDarkTheme
          ? '#bbbfc0'
          : '#1d282d',
      borderBottom: (
        data as {
          customConfig: { hasBorderBottom: boolean };
        }
      )?.customConfig?.hasBorderBottom
        ? '1px solid #343E42'
        : 'none',
      '&:hover': {
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      },
      ...(isSelected ? dot() : {}),
    }),
  };
};
