import className from 'classnames';
import { PaymentPlanOption } from 'src/types';
import { roundPriceAmount } from '../../utils/prepareData';
import './PlanOption.scss';

interface PlanOptionProps {
  isActive?: boolean;
  option: PaymentPlanOption;
  onClick: (option: PaymentPlanOption) => void;
}

export const PlanOption = ({ isActive, option, onClick }: PlanOptionProps) => {
  const handleClick = () => {
    onClick(option);
  };

  return (
    <button
      onClick={handleClick}
      className={className('nj-plan-option', { active: isActive })}
    >
      <div>
        <span className="nj-plan-option--title">
          ${roundPriceAmount(option.amount)}
        </span>
        <span className="nj-plan-option--subtitle">
          /{option.isAnnual ? 'yr' : 'mo'}
        </span>
      </div>
    </button>
  );
};
