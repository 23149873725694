import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Span } from 'src/types';
import { Clock } from '@phosphor-icons/react';
import { useTimeZone } from 'src/hooks';

type ProposalTimeSlotStaticValueProps = {
  proposal: Span;
};

const SVG_SIZE = 20;

// Format: Tue, Apr 9, 2024
const TIME_SLOT_DAY_FORMAT = 'ddd, MMM D, YYYY';

// Format: 9:00 AM
const TIME_SLOT_TIME_FORMAT = 'h:mm A';

export const ProposalTimeSlotStaticValue = ({
  proposal,
}: ProposalTimeSlotStaticValueProps) => {
  const { userTimeZone } = useTimeZone();
  const { start, end } = proposal;

  const timeSlotDay = useMemo(
    () => dayjs(start).tz(userTimeZone).format(TIME_SLOT_DAY_FORMAT),
    [start, userTimeZone],
  );

  const timeSlotStart = useMemo(
    () => dayjs(start).tz(userTimeZone).format(TIME_SLOT_TIME_FORMAT),
    [start, userTimeZone],
  );
  const timeSlotEnd = useMemo(
    () => dayjs(end).tz(userTimeZone).format(TIME_SLOT_TIME_FORMAT),
    [end, userTimeZone],
  );

  return (
    <div className="nj-task-view--form--block-row">
      <Clock size={SVG_SIZE} />
      <span>{timeSlotDay}</span>
      <span>{timeSlotStart}</span>
      <span>{timeSlotEnd}</span>
    </div>
  );
};
