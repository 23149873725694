import { useMemo } from 'react';
import { Message } from 'src/types';
import { TextInputCard as TextInputCardType } from 'src/types';
import { TextInputFieldsGroup } from './components/TextInputFieldsGroup';
import { CompletedTextInputFieldsList } from './components/CompletedTextInputFieldsList';
import { v4 as uuidv4 } from 'uuid';

type TextInputCardProps = {
  message: Message;
  textInputCard: TextInputCardType;
};

export const TextInputCard = ({
  message,
  textInputCard,
}: TextInputCardProps) => {
  const { input_id, description, questions, executed } = textInputCard;

  const uniqueId = useMemo(() => {
    return input_id || uuidv4();
  }, [input_id]);

  return (
    <div className="nj-text-input-card">
      {description && (
        <p className="nj-text-input-card--description">{description}</p>
      )}
      {executed ? (
        <CompletedTextInputFieldsList
          fields={questions}
          timestamp={message.timestamp}
        />
      ) : (
        <TextInputFieldsGroup
          id={uniqueId}
          message={message}
          fields={questions}
        />
      )}
    </div>
  );
};
