import classNames from 'classnames';
import styles from './Counter.module.scss';

interface CounterProps {
  number: number;
  color?: 'black' | 'active' | 'regular';
  withPlus?: boolean;
  className?: string;
}

export const Counter = ({
  className,
  number,
  color = 'regular',
  withPlus,
}: CounterProps) => {
  return (
    <span
      className={classNames(styles.root, styles[color], className)}
      data-testid="counter"
    >
      {withPlus && '+ '}
      {number}
    </span>
  );
};
