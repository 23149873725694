import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { WelcomeUpsellModalType } from 'src/types';

interface ModalsControl {
  isVisibleQuickTourModal: boolean;
  isVisibleWelcomeModal: boolean;
  welcomeUpsellModalType: WelcomeUpsellModalType | null;
}

const initialState: ModalsControl = {
  isVisibleQuickTourModal: false,
  isVisibleWelcomeModal: false,
  welcomeUpsellModalType: null,
};

export const modalsControlSlice = createSlice({
  name: 'modalsControl',
  initialState,
  reducers: {
    toggleQuickTourModal: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      state.isVisibleQuickTourModal = payload ?? !state.isVisibleQuickTourModal;
    },
    toggleWelcomeModal: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      state.isVisibleWelcomeModal = payload ?? !state.isVisibleWelcomeModal;
    },
    setWelcomeUpsellModalType: (
      state,
      { payload }: PayloadAction<WelcomeUpsellModalType | null>,
    ) => {
      state.welcomeUpsellModalType = payload;
    },
  },
});

// TODO(olha): divide into separate selectors
export const modalsControlState = (state: RootState) =>
  state.modalsControl as ModalsControl;

export const {
  toggleWelcomeModal,
  setWelcomeUpsellModalType,
  toggleQuickTourModal,
} = modalsControlSlice.actions;

export default modalsControlSlice.reducer;
