import { useState } from 'react';
import { Message } from 'src/types';
import { useSession, useTaskById } from 'src/hooks';
import { useSendMessagePDUMutation } from 'src/store/services';
import { toPDUMessage } from 'src/utils';
import { toast } from 'react-toastify';
import log from 'src/utils/logger';

type UpdateTaskMessageByPDUArgs = {
  successMessage: string;
  errorMessage: string;
};

export const useUpdateTaskMessageByPDU = ({
  successMessage,
  errorMessage,
}: UpdateTaskMessageByPDUArgs) => {
  const { appUser } = useSession();
  const { currentTaskId } = useTaskById();
  const [sendPDUMessage] = useSendMessagePDUMutation();

  const [isSubmitted, setSubmitted] = useState<boolean>(false);

  const onSubmit = async (message: Message) => {
    if (!currentTaskId) {
      return;
    }

    try {
      const envelope = toPDUMessage(JSON.stringify(message), appUser.user_id);
      await sendPDUMessage({
        message: envelope,
      }).unwrap();

      toast(successMessage);
      setSubmitted(true);
    } catch (e: unknown) {
      log.error(e);
      setSubmitted(false);
      toast(errorMessage);
    }
  };

  return { isSubmitted, onSubmit };
};
