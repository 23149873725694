import { deleteUser, signOut } from 'aws-amplify/auth';
import log from 'src/utils/logger';
import { useContext } from 'react';
import AuthContext from 'src/contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useResetAllStoredData } from 'src/hooks';
import { AppRoutes } from 'src/types';
import { isEnterprise } from 'src/utils';

export interface Props {
  logout: () => void;
  logoutWithRedirectToSignUp: () => void;
  deleteCognitoUser: () => Promise<void>;
}

export const useLogout = (): Props => {
  const { setAuthLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  const { onResetAllStoredData } = useResetAllStoredData();

  const deleteCognitoUser = async () => {
    try {
      await onResetAllStoredData();
      await deleteUser();
    } catch (error) {
      log.error(error);
    }
  };

  const logout = async () => {
    try {
      setAuthLoading(true);

      await onResetAllStoredData();
      await signOut();
      if (isEnterprise) {
        // TODO: check why we are getting forever loading for enterprise on logout
        window.location.reload();
      }
    } catch (error) {
      log.error(error);
      setAuthLoading(false);
    }
  };

  const logoutWithRedirectToSignUp = async () => {
    await logout();
    navigate(AppRoutes.SIGN_UP);
  };

  return {
    logout,
    deleteCognitoUser,
    logoutWithRedirectToSignUp,
  };
};
