import { useContext } from 'react';
import {
  ModalGalleryType,
  SearchImageReference,
  SearchVideoReference,
} from 'src/types';
import { ResearchMediaCarousel } from '../ResearchMediaCarousel';
import SessionContext from 'src/contexts/SessionContext';

interface ThreadResearchMediaProps {
  videos: SearchVideoReference[];
  images: SearchImageReference[];
}

export const ThreadResearchMedia = ({
  videos,
  images,
}: ThreadResearchMediaProps) => {
  const { onChangeModalGalleryData } = useContext(SessionContext);

  const handleSetCurrentMedia = (index: number) => {
    onChangeModalGalleryData(
      true,
      ModalGalleryType.IMAGES_VIDEOS,
      [...videos, ...images],
      index,
    );
  };

  if (images.length === 0 && videos.length === 0) {
    return null;
  }

  return (
    <>
      <div className="nj-thread-research-media">
        <h6 className="nj-thread-research-card--summary-title">
          Videos & Images
        </h6>

        <ResearchMediaCarousel
          videos={videos}
          images={images}
          setCurrentMedia={handleSetCurrentMedia}
        />
      </div>
    </>
  );
};
