import { useContext } from 'react';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import { toast } from 'react-toastify';
import {
  DotsThreeOutlineVertical,
  PencilSimple,
  Archive,
  Bug,
} from '@phosphor-icons/react';
import { useConversationActions } from 'src/hooks';
import { Conversation, ConversationState } from 'src/types';
import { useCheckIsNinjaEmployee } from 'src/hooks';
import { useHotkeys } from 'react-hotkeys-hook';
import DebugContext from 'src/contexts/DebugContext';

type ConversationThreadMenuProps = {
  conversation: Conversation;
  shouldOpenRenameModal?: (val: boolean) => void;
};

const ICON_PROPS = {
  size: 20,
  color: 'currentColor',
};

export const ConversationThreadMenu = ({
  conversation,
  shouldOpenRenameModal,
}: ConversationThreadMenuProps) => {
  const { state: conversationState, conversation_id } = conversation;
  const { toggleDebugMode } = useContext(DebugContext);
  const isUserNinjaEmployee = useCheckIsNinjaEmployee();

  const { updateConversationById } = useConversationActions();

  useHotkeys('ctrl+d', () => {
    toggleDebugMode();
  });

  const handleOpenRenameModal = () => {
    if (shouldOpenRenameModal) {
      shouldOpenRenameModal(true);
    }
  };

  const handleArchiveThread = async (conversationId?: string) => {
    if (!conversationId) {
      return;
    }

    await updateConversationById({
      conversation_id: conversationId,
      state: ConversationState.ARCHIVED,
    });

    toast('Chat archived');
  };

  return (
    <Menu
      gap={8}
      menuClassName="nj-multi-menu"
      menuButton={
        <MenuButton data-testid="snowman-menu">
          <DotsThreeOutlineVertical {...ICON_PROPS} alt="Conversation menu" />
        </MenuButton>
      }
      transition
    >
      <MenuItem data-testid="rename-hash-btn" onClick={handleOpenRenameModal}>
        <PencilSimple {...ICON_PROPS} /> Rename
      </MenuItem>

      {conversationState === ConversationState.ARCHIVABLE && (
        <>
          <MenuItem
            data-testid="archive-btn"
            onClick={() => handleArchiveThread(conversation_id)}
          >
            <Archive {...ICON_PROPS} /> Archive
          </MenuItem>
        </>
      )}

      {isUserNinjaEmployee && (
        <MenuItem data-testid="rename-hash-btn" onClick={toggleDebugMode}>
          <Bug {...ICON_PROPS} /> Debug
        </MenuItem>
      )}
    </Menu>
  );
};
