import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { PencilSimple } from '@phosphor-icons/react';
import { useBreakpoint } from 'src/hooks';
import { MeetingCardFormData } from '../../CreateMeetingCard';
import { useMeetingCardFieldsWatch } from '../../hooks/useMeetingCardFieldsWatch';

const SVG_SIZE = 20;

interface DefaultStaticFieldProps {
  label: string | JSX.Element;
  defaultValue: JSX.Element;
  children: JSX.Element;
  className?: string;
}

export const DefaultStaticField = ({
  label,
  defaultValue,
  children,
  className,
}: DefaultStaticFieldProps) => {
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const { executed } = useMeetingCardFieldsWatch<MeetingCardFormData>();

  const { isMobile } = useBreakpoint();

  useEffect(() => {
    if (executed) {
      setIsEdited(false);
    }
  }, [executed]);

  const handleClick = () => {
    if (executed) {
      return;
    }
    setIsEdited(true);
  };

  return (
    <div
      className={classNames(
        'nj-create-meeting-card--default-static-field',
        className,
        {
          active: isEdited,
        },
      )}
    >
      {isEdited ? (
        children
      ) : (
        <>
          <div className="nj-create-meeting-card--label-wraper">
            <div className="nj-create-meeting-card--label nj-create-meeting-card--default-static-field-label">
              {label}
            </div>

            {isMobile && (
              <button
                type="button"
                onClick={handleClick}
                className="nj-create-meeting-card--edit-button"
              >
                <PencilSimple size={SVG_SIZE} />
              </button>
            )}
          </div>

          <div
            onClick={handleClick}
            className="nj-create-meeting-card--container"
          >
            <div className="nj-create-meeting-card--default-static-field-content">
              {defaultValue}
            </div>

            {!isMobile && (
              <button
                type="button"
                className="nj-create-meeting-card--edit-button"
              >
                <PencilSimple size={SVG_SIZE} />
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
