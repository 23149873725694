import { useEffect } from 'react';
import { useSession } from 'src/hooks';
import { useLazyGetQueueQuery } from 'src/store/services/matchmakerApi';
import { QueueMessageType } from 'src/types';

export const useAvatarQueue = () => {
  const { appUser, avatarQueue, updateAvatarQueue } = useSession();
  const [getQueue, results] = useLazyGetQueueQuery();

  // do the 1st fetch on clicking the video-call button
  useEffect(() => {
    if (appUser?.user_id && avatarQueue?.step === 1) {
      getQueue({
        userId: appUser.user_id,
        avatar_id: appUser.persona?.avatar_id,
        environment: appUser.settings?.theme_setting?.environment,
        camera_index: appUser.settings?.camera_angle_setting?.camera_index,
      });
    }
  }, [
    appUser?.user_id,
    appUser.persona?.avatar_id,
    appUser.settings?.theme_setting?.environment,
    appUser.settings?.camera_angle_setting?.camera_index,
    avatarQueue?.step,
    getQueue,
  ]);

  // if we run into waiting room, keep polling
  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;

    if (results && results.data) {
      const { data } = results;

      // available, exceeded quota, invalid request, or unavailable
      if (data) {
        updateAvatarQueue({ ...data, step: (avatarQueue?.step || 1) + 1 });
        if (timerId) {
          clearTimeout(timerId);
        }

        // waiting room with countdown
        if (data.messageType === QueueMessageType.WAITING_ROOM) {
          timerId = setTimeout(
            () =>
              getQueue({
                userId: appUser?.user_id,
                avatar_id: appUser.persona?.avatar_id,
                environment: appUser.settings?.theme_setting?.environment,
                camera_index:
                  appUser.settings?.camera_angle_setting?.camera_index,
              }),
            1000,
          );
        }
      }
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);
};
