import { SkillLabel } from 'src/components/SkillLabel';
import styles from './PromptCard.module.scss';

interface PromptCardProps {
  title: string;
  description: string;
  tag: string;
  setPrompt: (prompt: string) => void;
}

export const PromptCard = ({
  title,
  description,
  tag,
  setPrompt,
}: PromptCardProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.promptHeader}>
        <h3>{title}</h3>
        <SkillLabel skill={tag} />
      </div>

      <p className={styles.description}>{description}</p>

      <div className={styles.actions}>
        <button
          className={styles.useButton}
          onClick={() => setPrompt(description)}
        >
          Use Prompt
        </button>
      </div>
    </div>
  );
};
