import classNames from 'classnames';

interface MeetingCardFieldProps {
  label: string | JSX.Element;
  children: JSX.Element;
  className?: string;
}

export const MeetingCardField = ({
  label,
  children,
  className,
}: MeetingCardFieldProps) => {
  return (
    <div className={classNames('nj-create-meeting-card--field', className)}>
      <p className="nj-create-meeting-card--label">{label}</p>

      {children}
    </div>
  );
};
