interface TimerProps {
  minutes: number;
  seconds: number;
}

/**
 * Timer component shows animated countdown.
 */
export const Timer = ({ minutes, seconds }: TimerProps) => {
  const getLeftDigit = (value: number) => {
    return value >= 10 ? value.toString()[0] : '0';
  };

  const getRightDigit = (value: number) => {
    return value >= 10 ? value.toString()[1] : value.toString();
  };

  const getDigit = (value: number) => {
    return `${getLeftDigit(value)}${getRightDigit(value)}`;
  };

  return (
    <span>
      <span>{getDigit(minutes)}</span>
      <span>:</span>
      <span>{getDigit(seconds)}</span>
    </span>
  );
};
