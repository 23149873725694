import type {
  BaseQueryApi,
  BaseQueryArg,
  BaseQueryExtraOptions,
  BaseQueryFn,
} from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { RetryOptions } from '@reduxjs/toolkit/src/query/retry';
import { isNumber } from 'src/types';
import {
  retry,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryMeta,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { retryable_http_codes } from 'src/constants/api';
import { pause } from 'src/utils';
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

// not exported in rtk query :(
type RetryConditionFunction = (
  error: FetchBaseQueryError,
  args: BaseQueryArg<BaseQueryFn>,
  extraArgs: {
    attempt: number;
    baseQueryApi: BaseQueryApi;
    extraOptions: BaseQueryExtraOptions<BaseQueryFn> & RetryOptions;
  },
) => boolean;

// will be retried 3 times
const MAX_RETRIES = 4;
async function apiBackoff(attempt = 0, maxRetries: number = MAX_RETRIES) {
  const attempts = Math.min(attempt, maxRetries);

  const timeout = ~~((Math.random() + 0.5) * (500 << attempts)); // Taken from rtq query keeping this bitwise tricks for fun
  await pause(timeout);
}

const retryFunction: RetryConditionFunction = (error, args, extraArgs) => {
  const possibleMaxRetries: number[] = [
    MAX_RETRIES,
    (args.defaultOptions || {}).maxRetries,
    (extraArgs.extraOptions || {}).maxRetries,
  ].filter((x) => x !== undefined);
  const [maxRetries] = possibleMaxRetries.slice(-1);

  return (
    isNumber(error.status) &&
    retryable_http_codes.includes(error.status) &&
    extraArgs.attempt < maxRetries
  );
};

export const retryableFetchBaseQuery = (
  args: FetchBaseQueryArgs,
): BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  unknown & RetryOptions,
  FetchBaseQueryMeta
> => {
  return retry(fetchBaseQuery(args), {
    retryCondition: retryFunction,
    backoff: apiBackoff,
  });
};
