import { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { OverlayBackground } from 'src/components/OverlayBackground';
import { ANIMATION_TIMEOUT } from 'src/constants';
import { PortalWrapper } from 'src/components/PortalWrapper';
import styles from './BottomDrawer.module.scss';
import classNames from 'classnames';

interface BottomDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
}

export const BottomDrawer = ({
  isOpen,
  onClose,
  children,
  className,
}: BottomDrawerProps) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <PortalWrapper>
      <OverlayBackground
        showOverlay={isOpen}
        zIndex={4500}
        onClick={onClose}
        ref={overlayRef}
      />

      <CSSTransition
        in={isOpen}
        timeout={ANIMATION_TIMEOUT}
        // TODO(olha): create AppearingFromBottom animation component
        classNames="nj-animate-vertical-appearance"
        unmountOnExit
        nodeRef={containerRef}
      >
        <div ref={containerRef} className={classNames(styles.root, className)}>
          <hr onClick={onClose} className={styles.topMarker} />

          {children}
        </div>
      </CSSTransition>
    </PortalWrapper>
  );
};
