/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useMemo } from 'react';
import ForwardRefContext, {
  ForwardRefContextType,
} from 'src/contexts/ForwardRefContext';
import { animateMetahuman } from 'src/utils';
import { AvatarAnimations } from 'src/types';
import { isSafari, isIE } from 'react-device-detect';

interface IFrameProps {
  userId?: string;
  url: string;
  allowFullScreen?: boolean;
  isFloating?: boolean;
  debug?: boolean;
}

/**
 * IFrame component shows iframe based on URL.
 */
export const IFrame = ({
  userId,
  url,
  allowFullScreen = true,
  isFloating = false,
  debug = false,
  ...rest
}: IFrameProps) => {
  const { iFrameRef } = useContext<ForwardRefContextType>(ForwardRefContext);

  useEffect(() => {
    if (userId) {
      animateMetahuman(userId, AvatarAnimations.IDLE, debug);
    }
  }, [userId]);

  const browserProps = useMemo(() => {
    const fullscreenProps =
      (isSafari && { webkitallowfullscreen: allowFullScreen.toString() }) ||
      (isIE && { mozallowfullscreen: allowFullScreen.toString() }) ||
      {};

    return {
      ...fullscreenProps,
      allowFullScreen,
    };
  }, [isFloating, isSafari, isIE]);

  return (
    <iframe
      ref={iFrameRef}
      className="nj-iframe"
      src={url}
      width="100%"
      height="100%"
      title="Streaming Avatar"
      allow="autoplay *; fullscreen *"
      {...browserProps}
      {...rest}
    />
  );
};
