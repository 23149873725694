import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { Citation } from 'src/types';
import { LinkMarkdown } from './components/LinkMarkdown';
import { CodeMarkdown } from './components/CodeMarkdown';
import {
  CITATION_REGEX,
  CODE_LANGUAGE_REGEX,
  CODE_WITHOUT_NEWLINE_REGEX,
} from 'src/constants';
import styles from './Markdown.module.scss';
import { preprocessLaTeX } from 'src/utils/latex';

interface MarkdownProps {
  children: string;
  references?: Citation[];
  isStreaming?: boolean;
}

/**
 * Markdown handles markdown for the following:
 * - Headings (H1, H2, H3), use hash (# Heading 1,  ## Heading 2,  ### Heading 3)
 * - Bold, use double asterisks (**Bold**)
 * - Italics, use single asterisks (*Italics*)
 * - Tables, use pipe (|) and hyphen (-)
 * - Lists, use hyphen (-) or asterisk (*)
 * - Links, use square brackets and parentheses ([Link](https://www.example.com))
 * - Images, use exclamation mark and square brackets (![Alt text](https://www.example.com/image.jpg))
 * - Blockquotes, use greater than sign (>)
 * - Code, use backticks (`) and triple backticks (```)
 * - Strikethrough, use double tilde (~~Strikethrough~~)
 * - Newlines, use double space and newline (  \n)
 * - Horizontal rules, use three hyphens (---)
 * - Task lists, use hyphen (-) and square brackets ([x] or [ ])
 * - Footnotes, use caret and square brackets (^[Footnote])
 * - Superscript, use caret and parentheses (^Superscript)
 * - Subscript, use tilde and parentheses (~Subscript)
 * - Abbreviations, use square brackets and parentheses ([HTML](https://www.example.com) or HTML)
 * - Definition lists, use colon (:) and hyphen (-)
 * - Math, use dollar sign ($) and backticks (`)
 * - Emoji, use colon (:) and hyphen (-)
 * - HTML, use angle brackets (<HTML>)
 * @param children
 * @returns
 */
export const Markdown = ({
  children,
  references,
  isStreaming,
}: MarkdownProps) => {
  const updatedContent = useMemo(() => {
    const partiallyCleared = children
      .replace(/\n/gi, '  \n')
      .replace(CODE_WITHOUT_NEWLINE_REGEX, '$1\n```')
      .replace(CODE_LANGUAGE_REGEX, '```$1\n')
      // (olha): it's a workaround. The library parses several consecutive spaces as code.
      .replace(/ {3}/g, ' \u00a0');

    const cleared = isStreaming
      ? partiallyCleared.replace(CITATION_REGEX, '')
      : partiallyCleared.replace(CITATION_REGEX, (match) => `${match}(#)`);

    // Added for latex, when $ in the end of the string, regExp can't determinate it
    return cleared + ' ';
  }, [children, isStreaming]);

  const CustomLinkMarkdown = useMemo(
    () =>
      ({ ...props }: ReactMarkdownProps) =>
        <LinkMarkdown references={references} {...props} />,
    [references],
  );

  const dataForRender = useMemo(
    () => preprocessLaTeX(updatedContent).slice(0, -1),
    [updatedContent],
  );

  return (
    <div className={styles.root}>
      <ReactMarkdown
        children={dataForRender}
        unwrapDisallowed
        remarkPlugins={[
          breaks,
          gfm,
          [remarkMath, { singleDollarTextMath: false }],
        ]}
        rehypePlugins={[
          [rehypeKatex, { output: 'html', errorColor: 'var(--nj-foreground)' }],
        ]}
        components={{
          // TODO(olha): rendering custom components inside Markdown causes a performance bug (the app is freezing). Using "isStreaming" is a temporary, quick solution to fix freezing
          ...(isStreaming
            ? {}
            : {
                a: CustomLinkMarkdown,
              }),
          code: CodeMarkdown,
        }}
      />
    </div>
  );
};
