import './MonthlyAnnuallySwitcher.scss';
import classNames from 'classnames';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

type MonthlyAnnuallySwitcherProps = {
  isAnnually: boolean;
  onChangeValue: (value: boolean) => void;
};

export const MonthlyAnnuallySwitcher = ({
  isAnnually,
  onChangeValue,
}: MonthlyAnnuallySwitcherProps) => {
  const uniqueId = useMemo(() => uuidv4(), []);

  return (
    <div
      className={classNames('nj-monthly-annually-switcher', {
        switchOn: isAnnually,
        disabled: false,
      })}
    >
      <input
        id={uniqueId}
        className="nj-monthly-annually-switcher--input"
        type="checkbox"
        onChange={() => {
          onChangeValue(!isAnnually);
        }}
        disabled={false}
      />
      <label htmlFor={uniqueId} className="nj-monthly-annually-switcher--label">
        <>
          <span
            className={
              isAnnually
                ? 'nj-monthly-annually-switcher--slider'
                : 'nj-monthly-annually-switcher--not-active-slider-monthly'
            }
          >
            <span>
              Annually&nbsp;
              <span className="nj-monthly-annually-switcher--label-percentage">
                -30%
              </span>
            </span>
          </span>
          <span
            className={
              !isAnnually
                ? 'nj-monthly-annually-switcher--slider'
                : 'nj-monthly-annually-switcher--not-active-slider'
            }
          >
            Monthly
          </span>
        </>
      </label>
    </div>
  );
};
