import styles from './MessageModal.module.scss';
import { SVG_SIZE_M } from 'src/constants';
import { XCircle } from '@phosphor-icons/react';

interface MessageModalProps {
  title: string;
  children: React.ReactNode;
  onClick: () => void;
}

export const MessageModal = ({
  title,
  children,
  onClick,
}: MessageModalProps) => {
  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <h5 className={styles.title}>{title}</h5>
        <button onClick={onClick}>
          <XCircle size={SVG_SIZE_M} />
        </button>
      </header>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
