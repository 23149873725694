import styles from './ModelSectionHeader.module.scss';

interface ModelSectionHeaderProps {
  title: string;
  selectedLength: number;
}

export const LLM_SELECTED_MAX_NUMBER = 2;

export const ModelSectionHeader = ({
  title,
  selectedLength,
}: ModelSectionHeaderProps) => {
  return (
    <p className={styles.root}>
      <span className={styles.title}>{title}</span>
      <span
        className={styles.caption}
      >{`${selectedLength} of ${LLM_SELECTED_MAX_NUMBER} selected`}</span>
    </p>
  );
};
