import { useFormContext } from 'react-hook-form';
import { MeetingCardField } from '../MeetingCardField';
import type { MeetingCardFormData } from '../../CreateMeetingCard';
import TextareaAutosize from 'react-textarea-autosize';

export const DescriptionField = () => {
  const { register } = useFormContext<MeetingCardFormData>();

  return (
    <MeetingCardField label="Description">
      <div className="nj-create-meeting-card--form-field-wrapper">
        <TextareaAutosize
          minRows={1}
          className="nj-create-meeting-card--input"
          {...register('event_description')}
        />
      </div>
    </MeetingCardField>
  );
};
