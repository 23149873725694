import styles from './Footer.module.scss';
import AwsCustomIcon from 'src/images/icons/authFooter/aws-custom.svg';
import ForbesIcon from 'src/images/icons/authFooter/forbes.svg';
import WSJIcon from 'src/images/icons/authFooter/wsj.svg';
import VentureBeatIcon from 'src/images/icons/authFooter/venture-beat.svg';
import { useBreakpoint } from 'src/hooks';
import { StreetLogo } from 'src/images/logos/street-logo';

export const Footer = () => {
  const { isTabletAndUp } = useBreakpoint();
  return (
    <div className={styles.root}>
      <div className={styles.firstRow}>As Seen On</div>
      <div className={styles.secondRow}>
        <img src={AwsCustomIcon} alt={'Aws'} />
        <img src={ForbesIcon} alt={'Forbes'} />
        <img src={WSJIcon} alt={'WSJ'} />
        <StreetLogo width={70} height={20} />
        {isTabletAndUp && <img src={VentureBeatIcon} alt={'Venture Beat'} />}
      </div>
      {!isTabletAndUp && (
        <div className={styles.thirdRow}>
          <img src={VentureBeatIcon} alt={'Venture Beat'} />
        </div>
      )}
    </div>
  );
};
