import React from 'react';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { useCollapse } from 'react-collapsed';
import classNames from 'classnames';
import styles from './SidebarSection.module.scss';
import { SVG_SIZE_S } from 'src/constants';

interface SidebarSectionProps {
  items: string[];
  category: string;
  icon: React.ReactNode;
  isExpanded: boolean;
  isActive: boolean;
  activeSubcategory: string | null;
  setExpandedCategory: (value: string | null) => void;
  setActiveCategory: (value: string | null) => void;
  setActiveSubcategory: (value: string) => void;
}

export const SidebarSection = ({
  items,
  icon,
  category,
  isActive,
  isExpanded,
  activeSubcategory,
  setExpandedCategory,
  setActiveCategory,
  setActiveSubcategory,
}: SidebarSectionProps) => {
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });
  const { onClick: toggleClick, ...toggleProps } = getToggleProps();

  const handleCategoryClick = (event: React.MouseEvent) => {
    toggleClick(event);

    isExpanded ? setExpandedCategory(null) : setExpandedCategory(category);
  };

  const handleItemClick = (subcategory: string) => {
    setActiveCategory(category);
    setActiveSubcategory(subcategory);
  };

  return (
    <div>
      <div
        className={classNames(styles.sectionHeader, {
          [styles.active]:
            isActive && activeSubcategory && items.includes(activeSubcategory),
        })}
        onClick={handleCategoryClick}
        {...toggleProps}
      >
        <div className={styles.titleHeader}>
          {icon}

          <span className={styles.sectionTitle}>{category}</span>
        </div>

        {isExpanded ? (
          <CaretUp size={SVG_SIZE_S} />
        ) : (
          <CaretDown size={SVG_SIZE_S} />
        )}
      </div>

      <div {...getCollapseProps()}>
        <div className={styles.sectionItems}>
          {items.map((item) => (
            <div
              key={item}
              className={classNames(styles.sectionItem, {
                [styles.active]: activeSubcategory === item,
              })}
              onClick={() => handleItemClick(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
