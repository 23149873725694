/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  GUEST = 'guest',
  TESTING = 'testing',
  AGENT = 'agent',
}
