import { DotsThree } from '@phosphor-icons/react';
import classNames from 'classnames';

type ShowMoreLessButtonProps = {
  // eslint-disable-next-line
  getToggleProps: any; // type getToggleProps comes from library react-collapsed
  isExpanded: boolean;
  showLessLabel?: string;
  showMoreLabel?: string;
  withoutIcon?: boolean;
};

const SVG_SIZE = 20;

export const ShowMoreLessButton = ({
  isExpanded,
  getToggleProps,
  showLessLabel,
  showMoreLabel,
  withoutIcon = false,
}: ShowMoreLessButtonProps) => {
  return (
    <button
      className={classNames('nj-thread-research-card--show-more-button', {
        'with-margin': withoutIcon,
      })}
      type="button"
      {...getToggleProps()}
    >
      <DotsThree size={SVG_SIZE} />
      {isExpanded ? (
        <span>{showLessLabel ? showLessLabel : 'View less'}</span>
      ) : (
        <span>{showMoreLabel ? showMoreLabel : 'View more'}</span>
      )}
    </button>
  );
};
