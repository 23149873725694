import { ReactNode, useMemo } from 'react';
import { Message } from 'src/types';
import { DebugMessageWrapper } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/DebugMessageWrapper';
import { formatTimestampToHoursMinutes } from 'src/utils';
import { useTimeZone } from 'src/hooks';

interface MessageWrapperProps {
  message: Message;
  children: ReactNode;
  isTimeVisible?: boolean;
}

export const MessageWrapper = ({
  message,
  children,
  isTimeVisible = true,
}: MessageWrapperProps) => {
  const { userTimeZone } = useTimeZone();

  const messageTime = useMemo(
    () =>
      isTimeVisible &&
      formatTimestampToHoursMinutes(message.timestamp, userTimeZone),
    [message.timestamp, isTimeVisible, userTimeZone],
  );

  return (
    <DebugMessageWrapper message={message}>
      <div className="nj-task-scratchpad--message--header">
        {isTimeVisible && (
          <div className="nj-task-scratchpad--message--time">{messageTime}</div>
        )}
      </div>
      {children}
    </DebugMessageWrapper>
  );
};
