import { RefObject, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FadeTransition } from 'src/animations/FadeTransition';
import classNames from 'classnames';
import styles from './ScrollToEdgeButton.module.scss';
import { ArrowUp, ArrowDown } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { useVisible } from 'src/hooks';

interface ScrollToEdgeButtonProps {
  scrollableContainerRef: RefObject<HTMLElement> | null;
  direction: 'up' | 'down';
  isVisible: boolean;
  className?: string;
}

export const ScrollToEdgeButton = ({
  scrollableContainerRef,
  direction,
  className,
  isVisible,
}: ScrollToEdgeButtonProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  // (olha): there are some conflicts between ScrollToEdgeButton appearing and transition to left/right button container. It's why we need this complex state handling
  const {
    isVisible: isContainerVisible,
    handleVisibilityRemove: onContainerHide,
    handleVisibilitySet: onContainerShow,
  } = useVisible(isVisible);

  const {
    isVisible: isButtonVisible,
    handleVisibilityRemove: onButtonHide,
    handleVisibilitySet: onButtonShow,
  } = useVisible(isVisible);

  useEffect(() => {
    if (isVisible) {
      onContainerShow();
    } else {
      onButtonHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleScroll = () => {
    if (!scrollableContainerRef?.current) {
      return;
    }

    if (direction === 'up') {
      scrollableContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      const { scrollHeight, clientHeight } = scrollableContainerRef.current;

      scrollableContainerRef.current.scrollTo({
        top: scrollHeight - clientHeight,
        behavior: 'smooth',
      });
    }
  };

  return (
    <CSSTransition
      in={isContainerVisible}
      timeout={0}
      nodeRef={containerRef}
      onEnter={onButtonShow}
      unmountOnExit
    >
      <div ref={containerRef} className={classNames(styles.root, className)}>
        <FadeTransition
          inProp={isButtonVisible}
          nodeRef={buttonRef}
          onExited={onContainerHide}
        >
          <button
            ref={buttonRef}
            className={styles.button}
            onClick={handleScroll}
          >
            {direction === 'up' ? (
              <ArrowUp size={SVG_SIZE_M} />
            ) : (
              <ArrowDown size={SVG_SIZE_M} />
            )}
          </button>
        </FadeTransition>
      </div>
    </CSSTransition>
  );
};
