import { useMemo } from 'react';
import {
  Icon,
  Calendar,
  ListMagnifyingGlass,
  Code,
  ChatCircleText,
  Image,
} from '@phosphor-icons/react';
import classNames from 'classnames';
import { TaskSkill } from 'src/types';

const SVG_SIZE = 16;

interface SkillLabelIconProps {
  skill: TaskSkill | string;
}

interface Label {
  SkillIcon: Icon;
  className: string;
}

export const SkillLabelIcon = ({ skill }: SkillLabelIconProps) => {
  const label: Label | null = useMemo(() => {
    switch (skill) {
      case TaskSkill.RESEARCH:
        return {
          SkillIcon: ListMagnifyingGlass,
          className: 'research',
        };

      case TaskSkill.SCHEDULING:
        return {
          SkillIcon: Calendar,
          className: 'scheduling',
        };

      case TaskSkill.CODING:
        return {
          SkillIcon: Code,
          className: 'coding',
        };

      case TaskSkill.CHITCHAT:
        return {
          SkillIcon: ChatCircleText,
          className: 'chitchat',
        };
      case TaskSkill.IMAGE:
        return {
          SkillIcon: Image,
          className: 'image',
        };

      default:
        return null;
    }
  }, [skill]);

  if (!label) {
    return null;
  }

  const { className, SkillIcon } = label;

  return (
    <div className={classNames('nj-skill-label-icon', className)}>
      <SkillIcon size={SVG_SIZE} />
    </div>
  );
};
