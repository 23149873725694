import { useBreakpoint, useSession } from 'src/hooks';
import mobileBackground from 'src/images/backgrounds/maintenance-mobile-bg.png';
import desktopBackground from 'src/images/backgrounds/maintenance-desktop-bg.png';
import { Modal } from 'react-responsive-modal';
import React from 'react';
import atlasWithSkills from 'src/images/login/atlas_with_skills.png';

export const QueueScreen = () => {
  const { isMobile } = useBreakpoint();
  const {
    appUser: { first_name },
  } = useSession();

  return (
    <div
      className="nj-maintenance-screen"
      style={{
        backgroundImage: isMobile
          ? `url(${mobileBackground})`
          : `url(${desktopBackground})`,
        backgroundSize: 'cover',
      }}
    >
      <Modal
        open={true}
        onClose={
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {}
        }
        container={document.body}
        classNames={{
          root: 'nj-modal--root',
          overlay: 'nj-modal--overlay nj-modal-maintenance-overlay',
          modal: 'nj-modal--access nj-queue-modal',
        }}
        center
        closeOnOverlayClick={false}
        showCloseIcon={false}
      >
        <div className="nj-queue-modal-content">
          <div className="nj-queue-modal-content-background-image-wrapper">
            <img
              className="nj-queue-modal-content-header-background-image"
              src={atlasWithSkills}
              alt="Ninja"
            />
          </div>
          <div className="nj-queue-modal-content-text-wrapper">
            <h2 className="nj-queue-modal-content-title">
              Welcome {first_name}!
            </h2>
            <p className="nj-queue-modal-content-description">
              Thank you for signing up and joining our community! We're thrilled
              to have you on board. Currently, access to Ninja is being rolled
              out gradually to ensure the best experience for everyone. You are
              now in the queue, and we're working hard to grant access as
              quickly as possible.
            </p>
            <p className="nj-queue-modal-content-description">
              You will receive a notification from us as soon as your access is
              granted. We appreciate your patience and are excited to welcome
              you to the full Ninja experience soon!
            </p>
            <p className="nj-queue-modal-content-description">
              Warm regards, <br /> The NinjaTech AI Team
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};
