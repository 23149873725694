import { useAppDispatch } from '../index';
import { useCallback, useMemo } from 'react';
import { apiServices } from './index';

export const useResetAllApiServices = () => {
  const dispatch = useAppDispatch();

  const handleClearAllApiCaches = useCallback(() => {
    apiServices.forEach((api) => {
      dispatch(api.util.resetApiState());
    });
  }, [dispatch]);

  return useMemo(
    () => ({ onClearAllApiCaches: handleClearAllApiCaches }),
    [handleClearAllApiCaches],
  );
};
