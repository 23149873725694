import {
  ImageGenSettingsDimensionGroups,
  ImageGenSettingsResolutions,
} from 'src/types';
import { DimensionPrimaryItem } from '../DimensionPrimaryItem';
import styles from './DimensionToggle.module.scss';
import {
  ImageGenSettingsSelectedDimension,
  ImageGenSettingsDimensionGroupTypes,
  ImageGenSettingsDimensionOptionTypes,
  ImageGenSettingsDimensionResolutionTypes,
} from 'src/types';
import { DimensionMoreSelector } from '../DimensionMoreSelector';

interface DimensionToggleProps {
  selectedData: ImageGenSettingsSelectedDimension;
  dimensionGroups: ImageGenSettingsDimensionGroups;
  onChange: (value: Partial<ImageGenSettingsSelectedDimension>) => void;
}

export const DimensionToggle = ({
  dimensionGroups,
  selectedData,
  onChange,
}: DimensionToggleProps) => {
  const handlePrimaryDimensionSelect = (value: string) => {
    onChange({
      group: ImageGenSettingsDimensionGroupTypes.PRIMARY,
      option: value as ImageGenSettingsDimensionOptionTypes,
      resolution: ImageGenSettingsDimensionResolutionTypes._720P,
    });
  };

  const handleSecondaryDimensionSelect = ({
    group,
    option,
  }: {
    group: ImageGenSettingsDimensionGroupTypes;
    option: ImageGenSettingsDimensionOptionTypes;
  }) => {
    onChange({
      group,
      option,
      resolution: ImageGenSettingsDimensionResolutionTypes.SMALL,
    });
  };

  // TODO(olha): use the value from BE
  const handleDisablingCheck = (data: ImageGenSettingsResolutions) => {
    return data.resolutions && Object.values(data.resolutions).length > 0
      ? Object.values(data.resolutions || {}).every(
          ({ is_capable }) => !is_capable,
        )
      : true;
  };

  return (
    <div className={styles.root}>
      {dimensionGroups.primary &&
        Object.entries(dimensionGroups.primary).map(([key, data]) => (
          <DimensionPrimaryItem
            key={key}
            isSelected={key === selectedData.option}
            isDisabled={handleDisablingCheck(data)}
            data={data}
            onClick={() => handlePrimaryDimensionSelect(key)}
          />
        ))}

      <DimensionMoreSelector
        onSelect={handleSecondaryDimensionSelect}
        isSelected={
          selectedData.group !== ImageGenSettingsDimensionGroupTypes.PRIMARY
        }
        selectedData={selectedData}
        dimensionGroups={dimensionGroups}
      />
    </div>
  );
};
