import { MeetNinjaSection } from 'src/v2/pages/auth/components/MeetNinjaSection/MeetNinjaSection';
import { MainSignupSection } from 'src/v2/pages/auth/components/MainSignupSection/MainSignupSection';
import { isEnterprise } from 'src/utils';
import { MainEmailSignupSection } from 'src/v2/pages/auth/components/MainEmailSignupSection';
import { EnterpriseMeetNinjaSection } from 'src/v2/pages/auth/components/MeetNinjaSection';
import { BasePublicPage } from 'src/v2/pages/auth/BasePublicPage/BasePublicPage';
import { SEO_SIGN_UP_DESCRIPTION, SEO_SIGN_UP_TITLE } from 'src/constants';

export const SignupPage = () => {
  return (
    <BasePublicPage
      seoTitle={SEO_SIGN_UP_TITLE}
      seoDescription={SEO_SIGN_UP_DESCRIPTION}
    >
      {isEnterprise ? (
        <>
          <EnterpriseMeetNinjaSection />
          <MainEmailSignupSection />
        </>
      ) : (
        <>
          <MeetNinjaSection isSignup />
          <MainSignupSection />
        </>
      )}
    </BasePublicPage>
  );
};
