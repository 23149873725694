import { useState, ChangeEvent, KeyboardEvent } from 'react';
import { PlusCircle, X } from '@phosphor-icons/react';
import { KeyCodes } from 'src/types';

const SVG_SIZE = 24;

interface AddAttendeeNameFormProps {
  onSave: (name: string) => void;
  onClose: () => void;
}

export const AddAttendeeNameForm = ({
  onSave,
  onClose,
}: AddAttendeeNameFormProps) => {
  const [name, setName] = useState<string>('');

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setName(target.value);
  };

  const handleSave = () => {
    onSave(name);
    onClose();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== KeyCodes.ENTER) {
      return;
    }
    handleSave();
  };

  return (
    <div className="nj-create-meeting-card--attendee-name">
      <div className="nj-create-meeting-card--attendee-name-input-wrapper">
        <input
          value={name}
          placeholder="Add name"
          className="nj-create-meeting-card--input"
          onKeyDown={handleKeyDown}
          onChange={handleChange}
        />

        <button
          type="button"
          onClick={handleSave}
          className="nj-button nj-create-meeting-card--attendee-add-button"
        >
          <PlusCircle weight="fill" size={SVG_SIZE} />
        </button>
      </div>

      <button onClick={onClose}>
        <X size={16} />
      </button>
    </div>
  );
};
