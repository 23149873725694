import { useContext } from 'react';
import classNames from 'classnames';
import { Check } from '@phosphor-icons/react';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { CameraAngleOption } from 'src/types';
import { useAvatarPreferences, useSession } from 'src/hooks';
import { changeMetahumanCameraAngle } from 'src/utils';
import { Button } from 'src/components/Button';

const SVG_SIZE = 40;

export const CameraAngleButton = ({ id, url }: CameraAngleOption) => {
  const { appUser } = useSession();
  const { avatarCameraIndex } = useAvatarPreferences();
  const { toggleCameraAngle } = useContext(AvatarModeContext);

  const handleSendCameraSignalToMetahuman = async (cameraIndex: number) => {
    changeMetahumanCameraAngle(appUser.user_id, cameraIndex);
    toggleCameraAngle(cameraIndex);
  };

  const cameraIndex = Number(id);
  const isActive = avatarCameraIndex === cameraIndex;

  return (
    <Button
      className="nj-section--thumbnail-item"
      onClick={() => handleSendCameraSignalToMetahuman(cameraIndex)}
    >
      <img
        className={classNames('nj-section--thumbnail-image', {
          active: isActive,
        })}
        src={url}
        alt="Ninja Camera angle"
      />

      {isActive && (
        <span className="nj-section--thumbnail-mask">
          <Check size={SVG_SIZE} />
        </span>
      )}
    </Button>
  );
};
