import log from 'loglevel';
import { APP_ENV } from 'src/constants';

// attn: requires testing on beta & prod
if (APP_ENV === 'production') {
  log.disableAll();
} else if (APP_ENV === 'beta' || APP_ENV === 'gamma') {
  // todo: fine-tune after push
  log.setLevel(log.levels.DEBUG);
} else {
  log.enableAll();
}

export default log;
