import { ArrowCounterClockwise } from '@phosphor-icons/react';
import { useBreakpoint } from 'src/hooks';

const SVG_SIZE = 20;
const SVG_SIZE_MOBILE = 24;

type RefreshButtonProps = {
  label?: string;
  onClick?: () => void;
};

export const RefreshButton = ({
  label = 'Refresh',
  onClick,
}: RefreshButtonProps) => {
  const { isMobileOrTablet } = useBreakpoint();

  const handleClick = () => {
    onClick && onClick();
  };
  return (
    <button
      className="nj-random-pillars--blend--footer-button"
      onClick={handleClick}
    >
      <ArrowCounterClockwise
        size={isMobileOrTablet ? SVG_SIZE_MOBILE : SVG_SIZE}
      />
    </button>
  );
};
