export const TEAM_ACTIVE_MEMBERS_COLUMNS: { header: string; key: string }[] = [
  {
    header: 'Name',
    key: 'name',
  },
  {
    header: 'Email',
    key: 'email',
  },
  {
    header: 'Role',
    key: 'role',
  },
  {
    header: '',
    key: 'actions',
  },
];

export const TEAM_PENDING_MEMBERS_COLUMNS: { header: string; key: string }[] = [
  {
    header: 'Email',
    key: 'email',
  },
  {
    header: '',
    key: 'actions',
  },
];

export const SEATS_PAGE_LIMIT = 15;

export const InviteUsersFileInputId = 'inviteEmailsFile';
