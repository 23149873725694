import { useMemo } from 'react';
import { ActionStatue, Action, TaskState } from 'src/types';

interface ProgressStatusProps {
  taskState: TaskState;
  actions: Action[];
}

export const ProgressStatus = ({ actions, taskState }: ProgressStatusProps) => {
  const completedActionsLength = useMemo(
    () =>
      actions.filter((item) => item.status === ActionStatue.COMPLETED).length,
    [actions],
  );

  const currentTaskState = taskState.replace('_', ' ');

  const statusCounter = useMemo(() => {
    return `(${completedActionsLength}/${actions.length})`;
  }, [completedActionsLength, actions]);

  return (
    <span className="nj-task-view-header--progress-card--status">
      <span
        className="progress-status"
        data-e2e={`header-task-view-status-${currentTaskState}`}
      >
        {currentTaskState}
      </span>

      <span className="progress-counter">&nbsp;{statusCounter}</span>
    </span>
  );
};
