import { useMemo } from 'react';
import { useAppSelector, interfaceControlState } from 'src/store';

export const useModeSidePanel = () => {
  const { chatModeSidePanel } = useAppSelector(interfaceControlState);

  return useMemo(
    () => ({
      chatModeSidePanel,
    }),
    [chatModeSidePanel],
  );
};
