import { ExtendedMessage, Message } from 'src/types';
import { UserMessageContent } from '../UserMessageContent';
import { EDIT_FILE_REGEX, FILE_REGEX } from 'src/constants';
import { UserContentWithEditFile } from '../../../../../components/UserMessage/components/UserContentWithEditFile';
import { UserContentWithFile } from '../../../../../components/UserMessage/components/UserContentWithFile';
import { FileRequestPayload } from 'src/types/models/FileRequestPayload';

interface UserMessageBodyProps {
  message: Message;
}

export const UserMessageBody = ({ message }: UserMessageBodyProps) => {
  const { content } = message;

  const isContentWithFile =
    (message.content.length > 0 &&
      !!message.content.match(FILE_REGEX) &&
      (message.content.match(FILE_REGEX) || []).length >= 3) ||
    (message.payload_list || []).filter(
      (item) => item.payload_type === FileRequestPayload.payload_type.FILE,
    ).length > 0;

  const isContentWithEditFile =
    message.content.length > 0 &&
    !!message.content.match(EDIT_FILE_REGEX) &&
    (message.content.match(EDIT_FILE_REGEX) || []).length >= 2;

  if (isContentWithFile) {
    return <UserContentWithFile message={message} />;
  }

  if (isContentWithEditFile) {
    return <UserContentWithEditFile message={message} />;
  }

  return (
    <UserMessageContent
      content={content}
      isBlinking={(message as ExtendedMessage).isBlinking}
    />
  );
};
