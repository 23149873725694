import { useCallback } from 'react';
import { resetPassword } from 'aws-amplify/auth';
import { toast } from 'react-toastify';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './ForgotPasswordEmailSent.module.scss';

interface ForgotPasswordEmailSentProps {
  email?: string;
}

export const ForgotPasswordEmailSent = ({
  email,
}: ForgotPasswordEmailSentProps) => {
  const resentEmail = useCallback(async () => {
    await resetPassword({
      username: email as string,
    });
    toast('Password reset email was resent');
  }, [email]);
  return (
    <div>
      <p className={styles.title}>
        We have sent a password reset link to <span>{email}</span>
      </p>
      <div className={styles.flexRow}>
        <span>Didn't receive an email?</span>
        <Button
          color="transparent"
          shape="regular"
          className={styles.resendButton}
          onClick={resentEmail}
        >
          Send a new link
        </Button>
      </div>
    </div>
  );
};
