import { useRef, useEffect } from 'react';
import { useColorTheme } from './themeHooks/useColorTheme';

const ROOT_CLASS_NAME = 'nj-portal';
const DARK_MODE_CLASS_NAME = 'dark-mode';

export const usePortal = (rootId: string) => {
  const { isDarkTheme } = useColorTheme();

  const portalRoot = document.getElementById(rootId);
  const elRef = useRef<HTMLDivElement | null>(null);

  if (!elRef.current) {
    elRef.current = document.createElement('div');

    elRef.current.classList.add(ROOT_CLASS_NAME);
  }

  useEffect(() => {
    if (portalRoot && elRef.current) {
      portalRoot.appendChild(elRef.current);
    }
    return () => {
      if (portalRoot && elRef.current) {
        portalRoot.removeChild(elRef.current);
      }
    };
  }, [portalRoot]);

  useEffect(() => {
    if (!elRef.current) {
      return;
    }

    if (isDarkTheme) {
      elRef.current.classList.add(DARK_MODE_CLASS_NAME);
    } else {
      elRef.current.classList.remove(DARK_MODE_CLASS_NAME);
    }
  }, [isDarkTheme]);

  if (!portalRoot) {
    return null;
  }

  return elRef.current;
};
