import { avatarEmotionOptions } from 'src/constants/metahuman';
import { Select } from 'src/components/Select';

export const AvatarSettings = () => {
  return (
    <div className="nj-avatar-recording--section-wrapper">
      <div className="nj-avatar-recording--field">
        <label className="nj-avatar-recording--field-label">
          Avatar Emotion
        </label>

        <div className="nj-avatar-recording--field-select">
          <Select name="emotion" options={avatarEmotionOptions} />
        </div>
      </div>
    </div>
  );
};
