import styles from './ChangeSeatsSection.module.scss';
import { useTeamInfo } from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';

type ChangeSeatsSectionProps = {
  onOpenChangeSeatsCheckout: () => void;
};

export const ChangeSeatsSection = ({
  onOpenChangeSeatsCheckout,
}: ChangeSeatsSectionProps) => {
  const { teamInfo, isOwner } = useTeamInfo();

  const availableNumberOfSeats =
    (teamInfo?.num_seats_total || 0) - (teamInfo?.num_seats_assigned || 0);

  const handleChangeSeatsClick = () => {
    onOpenChangeSeatsCheckout();
  };

  return (
    <div className={styles.root}>
      <h4 className={styles.title}>Seats</h4>
      <span className={styles.description}>
        Add or remove seats to match your teams current needs.
      </span>
      <div className={styles.valueRow}>
        <span className={styles.label}>Available seats</span>
        <span className={styles.value}>{availableNumberOfSeats}</span>
      </div>
      <Button
        className={styles.button}
        onClick={handleChangeSeatsClick}
        color="secondary"
        title={!isOwner ? 'Available for workspace owner' : ''}
        disabled={!isOwner}
      >
        Change seats
      </Button>
    </div>
  );
};
