import { useState, useCallback } from 'react';

export const useVisible = (value?: boolean) => {
  const [isVisible, setIsVisible] = useState<boolean>(value ?? false);

  const handleVisibilitySet = useCallback(() => setIsVisible(true), []);
  const handleVisibilityRemove = useCallback(() => setIsVisible(false), []);
  const handleVisibilityToggle = useCallback(
    () => setIsVisible((state) => !state),
    [],
  );

  return {
    isVisible,
    handleVisibilitySet,
    handleVisibilityRemove,
    handleVisibilityToggle,
  };
};
