import { useState, useCallback } from 'react';
import classNames from 'classnames';
import { useFormContext, useWatch } from 'react-hook-form';
import { useStopwatch } from 'react-timer-hook';
import { Play, StopCircle } from '@phosphor-icons/react';
import { interruptMetahuman, playOfflinePreview } from 'src/utils';
import { useSession, useMetaHumanEvent, useAvatarPreferences } from 'src/hooks';
import { isMetaHumanSpeechEvent, type MetaHumanEvent } from 'src/types';
import type { MovieStudioFormData } from 'src/types';
import { Timer } from 'src/components/Timer';

const SVG_SIZE = 20;

export const PreviewPlayButton = () => {
  const { appUser } = useSession();
  const { control } = useFormContext<MovieStudioFormData>();
  const fieldsValue = useWatch<MovieStudioFormData>({ control });

  const {
    avatarCameraIndex,
    avatarVoiceID,
    avatarAudioLocale,
    avatarBackgroundHexColor,
    avatarEnvironment,
    selectedAvatarName,
  } = useAvatarPreferences();

  const [inProgress, setInProgress] = useState<boolean>(false);

  const { seconds, minutes, reset, isRunning, pause } = useStopwatch();

  const isDisabled = !fieldsValue.script || !fieldsValue.script[0].text;

  const stopTimer = useCallback(() => {
    pause();
  }, [pause]);

  useMetaHumanEvent(
    useCallback(
      (metaHumanEvent: MetaHumanEvent) => {
        if (isMetaHumanSpeechEvent(metaHumanEvent) && metaHumanEvent.silence) {
          if (!inProgress) {
            return;
          }
          setInProgress(false);
          stopTimer();
        }
      },
      [inProgress, stopTimer],
    ),
  );

  const handlePlayPreview = async () => {
    if (fieldsValue.script && fieldsValue.script[0].text) {
      await playOfflinePreview(
        {
          ...fieldsValue,
          cameraIndex: avatarCameraIndex,
          voiceID: avatarVoiceID,
          AudioLang: avatarAudioLocale,
          backgroundColor: avatarBackgroundHexColor,
          environment: avatarEnvironment,
          avatar: selectedAvatarName,
        },
        appUser.email,
        appUser.user_id,
      );
    }
  };

  const handleClick = () => {
    if (isDisabled) {
      return;
    }

    if (!inProgress) {
      handlePlayPreview();
      reset();
    } else {
      interruptMetahuman(appUser.user_id);
      stopTimer();
    }

    setInProgress((state) => !state);
  };

  return (
    <div
      onClick={handleClick}
      role="button"
      tabIndex={0}
      className={classNames('nj-avatar-recording--preview-button', {
        disabled: isDisabled,
      })}
    >
      {inProgress ? (
        <StopCircle size={SVG_SIZE} weight="fill" />
      ) : (
        <Play size={SVG_SIZE} />
      )}
      <span
        className={classNames('nj-avatar-recording--preview-button-timer', {
          active: inProgress,
        })}
      >
        {isRunning ? <Timer minutes={minutes} seconds={seconds} /> : 'Preview'}
      </span>
    </div>
  );
};
