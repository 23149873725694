import { useSession } from 'src/hooks';
import { useGetUserTaskQuotaInfoQuery } from 'src/store/services';
import styles from './RemainingTasksSection.module.scss';

export const RemainingTasksSection = () => {
  const { appUser } = useSession();
  const { data: quotaData } = useGetUserTaskQuotaInfoQuery(
    {
      user_id: appUser.user_id,
    },
    {
      skip: !appUser.user_id,
    },
  );

  return (
    <div className={styles.root}>
      <div>
        <h4 className={styles.title}>Remaining tasks</h4>
        <p className={styles.description}>Resets every 24 hours</p>
      </div>
      <span className={styles.value}>
        {quotaData && quotaData?.count !== null
          ? quotaData?.count
          : appUser?.settings?.task_quota?.value}
      </span>
    </div>
  );
};
