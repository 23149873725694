import { sanitize } from 'dompurify';
import { decodeHTML, encodeHTML } from 'entities';

/**
 * @deprecated
 */
export const removeReferencesInBrackets = (content: string) => {
  return content.replace(/\[\d\]/g, '').replace(/,{2,}/gi, ',');
};

export const sanitizeOutgoingContent = (content?: string) => {
  if (!content) {
    return '';
  }
  return sanitize(content);
};

export const sanitizeIncomingContent = (content: string) => {
  return decodeHTML(content);
};

export const encodeOutgoingContent = (content: string) => {
  return encodeHTML(content);
};
