import { CameraRotate } from '@phosphor-icons/react';
import { ChatModeSidePanel } from 'src/types';
import { CommonNavigationButton } from '../CommonNavigationButton';

export const CameraAngleButton = () => {
  return (
    <CommonNavigationButton
      title="Camera Angle"
      sidePanelType={ChatModeSidePanel.CAMERA_ANGLE}
      IconComponent={CameraRotate}
    />
  );
};
