import { ExternalModelReference as ExternalModelReferenceType } from 'src/types';
import { Icon } from 'src/components/Icon';
import styles from './ExternalModelReference.module.scss';

const SVG_SIZE = 16;

interface ExternalModelReferenceProps extends ExternalModelReferenceType {
  disabled?: boolean;
  onClick: (key: string) => void;
}

/**
 * @deprecated
 */
export const ExternalModelReference = ({
  modelKey,
  title,
  icon_name,
  disabled,
  onClick,
}: ExternalModelReferenceProps) => {
  const handleClick = () => {
    onClick(modelKey);
  };

  return (
    <button disabled={disabled} onClick={handleClick} className={styles.root}>
      <span className={styles.index}>{modelKey}</span>

      {icon_name ? <Icon size={SVG_SIZE} type={icon_name} /> : null}

      <p className={styles.title}>{title}</p>
    </button>
  );
};
