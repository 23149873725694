import { useState } from 'react';
import { ArrowsClockwise } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useMeetingCardFieldsWatch } from '../../hooks/useMeetingCardFieldsWatch';
import { FormSwitch } from 'src/components/FormSwitch';
import { RecurrenceSelector } from './components/RecurrenceSelector';
import { MeetingCardFormData } from '../../CreateMeetingCard';

export const RecurrenceField = () => {
  const { executed, is_recurrent } =
    useMeetingCardFieldsWatch<MeetingCardFormData>();

  const [isEdited, setIsEdited] = useState<boolean>(!!is_recurrent);

  const onSwitchChange = (value: boolean) => {
    if (value && !isEdited) {
      setIsEdited(true);
    }
  };

  const isActive = isEdited && is_recurrent && !executed;

  return (
    <div
      className={classNames(
        'nj-create-meeting-card--field',
        'nj-create-meeting-card--recurrence-field',
        {
          active: isActive,
        },
      )}
    >
      <p className="nj-create-meeting-card--label nj-create-meeting-card--recurrence-label">
        Recurrence
      </p>

      <div className="nj-create-meeting-card--recurrence">
        <FormSwitch
          isDisabled={executed}
          name="is_recurrent"
          Icon={ArrowsClockwise}
          onChangeHandler={onSwitchChange}
          className="nj-create-meeting-card--recurrence-switch"
        />

        {isActive && <RecurrenceSelector />}
      </div>
    </div>
  );
};
