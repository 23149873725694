import { MouseEvent, useEffect, useState } from 'react';
// import log from 'src/utils/logger';
import { ArrowSquareOut, EyeSlash } from '@phosphor-icons/react';
import styles from './ReferenceIframe.module.scss';
import { NinjaLoader } from 'src/components/Loading';

type ReferenceIframeProps = {
  url: string;
  onClick?: (e: MouseEvent) => void;
};

export const ReferenceIframe = ({ url, onClick }: ReferenceIframeProps) => {
  const [
    canShowIframe,
    // setCanShowIframe
  ] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    // fetch(url, { method: 'HEAD', mode: 'no-cors' })
    //   .then((response) => response.headers)
    //   .then((headers) => {
    //     const xFrameOptions = headers.get('X-Frame-Options');
    //     if (xFrameOptions === 'DENY' || xFrameOptions === 'SAMEORIGIN') {
    //       setCanShowIframe(false);
    //     } else {
    //       setCanShowIframe(true);
    //     }
    //   })
    //   .catch((error) => {
    //     log.error('Error checking site headers:', error);
    //   });
  }, [url]);

  return (
    <div className="nj-modal-gallery-reference-wrapper" onClick={onClick}>
      {canShowIframe ? (
        <>
          {loading && <NinjaLoader content="" />}
          <iframe
            src={url}
            width="100%"
            height="100%"
            title={url}
            onLoad={() => setLoading(false)}
            style={{ display: loading ? 'none' : 'block' }}
          />
        </>
      ) : (
        <div className={styles.emptyContentWrapper}>
          <EyeSlash size={45} />
          <h3 className={styles.emptyContentTitle}>Preview not available</h3>
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className={styles.emptyContentBrowserLink}
          >
            <span>Click here to view the content in a browser tab</span>
            <ArrowSquareOut size={20} />
          </a>
        </div>
      )}
    </div>
  );
};
