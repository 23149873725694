// TODO(olha): add all routes
export enum AppRoutes {
  HOME = '/',
  MANAGE_ACCOUNT = '/manage-account',
  SIGN_UP = '/sign-up',
  SIGN_UP_WITH_EMAIL = `/sign-up-with-email`,
  LOGIN = '/login',
  LOGIN_WITH_EMAIL = '/login-with-email',
  LOGIN_ERROR = '/login-error',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  VERIFICATION_FAILED = '/verification-failed',
  VERIFIED = '/verified',
  PASSWORD_RESET_FAILED = '/password-reset-failed',
  PAYMENT = '/payment',
  PAYMENT_BETA = '/payment-beta',
  WELCOME = '/welcome',
  WILDCARD = '*',
}

export enum UserSettingsNestedRoutes {
  PROFILE = 'profile',
  INTEGRATIONS = 'integrations',
  GENERAL = 'general',
  ACTIVITY = 'activity',
}

export enum BillingSettingsNestedRoutes {
  SUBSCRIPTION = 'subscription',
  BILLING = 'billing',
}

export enum ManageUsersNestedRoutes {
  MANAGE_USERS = 'users',
}

export enum DownloadNestedRoutes {
  DOWNLOAD = 'download-app',
}

export interface QueryParams {
  session_id?: string;
  query?: string;
  conv?: string;
  // temporary until our App is verified - Meta needs a prod url to test login functionality
  meta_verification?: string;
  username?: string;
  code?: string;
  state?: string;
  expired_at?: string;
  email?: string;
  recommendation?: string;
  error?: string;
  error_description?: string;
  signup_error?: string;
  subscription_succeeded_plan?: string;
  subscription_succeeded_period?: string;
  payment_intent_client_secret?: string;
  ['password-changed']?: string;
  operation_type?: string;
  status?: string;
  to_tier?: string;
  email_verified?: string;
}

type Links = {
  route:
    | UserSettingsNestedRoutes
    | BillingSettingsNestedRoutes
    | DownloadNestedRoutes
    | ManageUsersNestedRoutes;
  icon: JSX.Element;
  title: string;
  defaultRoute: boolean;
  disabled: boolean;
};

export type Navigation = {
  category: string;
  links: Array<Links>;
};
