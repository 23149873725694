import { CommunicationStyle } from 'src/constants';
import { ReactNode, useEffect, useState, lazy, Suspense } from 'react';
import { useSession, useUserData } from 'src/hooks';
import { Persona } from 'src/types/models/Persona';

const LazySlider = lazy(() =>
  import('src/components/Slider').then((module) => ({
    default: module.Slider,
  })),
);

type ConversationalStyleSectionProps = {
  onChange?: (value: number | number[]) => void;
};

export const ConversationalStyleSection = ({
  onChange,
}: ConversationalStyleSectionProps) => {
  const { appUser } = useSession();
  const { updateUserData } = useUserData();
  const [currentConversationalStyle, setCurrentConversationalStyle] = useState<
    number | number[]
  >(3);

  useEffect(() => {
    if (!!appUser.persona?.conversational_style) {
      setCurrentConversationalStyle(appUser.persona?.conversational_style);
    }
  }, [appUser.persona?.conversational_style]);

  const communicationStyleLabels: Record<number, ReactNode> = Object.keys(
    CommunicationStyle,
  ).reduce(
    (acc, key) => {
      const numericKey = parseInt(key);
      acc[numericKey] = (
        <label key={numericKey} className="nj-slider-label-wrapper">
          <span>{numericKey}</span>
          <span>{CommunicationStyle[numericKey]}</span>
        </label>
      );
      return acc;
    },
    {} as Record<number, ReactNode>,
  );

  const handleChangeConversationStyle = (
    conversationalStyleNumber: number | number[],
  ) => {
    updateUserData(
      {
        persona: {
          ...appUser.persona,
          conversational_style: conversationalStyleNumber,
        } as Persona,
      },
      true,
    );
  };

  return (
    <div className="nj-avatar-select-tooltip-style-slider-wrapper">
      <Suspense>
        <LazySlider
          step={1}
          max={5}
          min={1}
          marks={communicationStyleLabels}
          value={currentConversationalStyle}
          onChange={setCurrentConversationalStyle}
          onChangeComplete={onChange ? onChange : handleChangeConversationStyle}
        />
      </Suspense>
    </div>
  );
};
