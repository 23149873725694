import { rrulestr, RRule, Frequency } from 'rrule';
import { uppercaseFirstLetter } from './general';

interface RRuleOptions {
  freq: Frequency;
  interval: number;
  count: number;
}

/**
 * Convert RRULE strings to readable text.
 * @param rruleString - RRULE string.
 * @return {string} A readable description of repetition rules.
 */
export const convertRruleStringToReadableText = (
  rruleString: string,
): string => {
  const rule = rrulestr(rruleString);

  return uppercaseFirstLetter(rule.toText());
};

/**
 * Convert RRULE strings to RRule object.
 * @param rruleString - RRULE string.
 * @return {RRule} A RRule object.
 */
export const convertRruleStringToObject = (rruleString: string): RRule => {
  return rrulestr(rruleString);
};

/**
 * Convert RRULE object to RRule string.
 * @param rrule - RRULE object.
 * @return {string} A RRule string.
 */
export const convertRruleOptionsToRruleString = ({
  freq,
  interval,
  count,
}: RRuleOptions): string => {
  const rrule = new RRule({ freq, interval, count });
  return rrule.toString();
};
