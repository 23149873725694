import { FormInput } from 'src/components/FormInput';
import { FormProvider } from 'src/components/FormProvider';

import styles from './EmailSigninForm.module.scss';
import { useForm } from 'react-hook-form';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { signIn } from 'aws-amplify/auth';
import { isEnterprise, sendGTMEvent } from 'src/utils';
import { GTMEvent } from 'src/types';
import { ServiceError } from '@aws-amplify/core/src/types/errors';
import { ErrorMessageBlock } from 'src/pages/AuthPages/components/ErrorMessageBlock';
import { useQueryParams } from 'src/hooks';
import { useLazyCheckEmailInfoQuery } from 'src/store/services';
import { CheckCircle } from '@phosphor-icons/react';
import { EmailVerificationBlock } from 'src/v2/pages/auth/components/EmailVerificationBlock';
import { COGNITO_SIGN_IN_ERROR_INCORRECT_PASSWORD } from 'src/constants/cognito';
import {
  ENTERPRISE_INVALID_DOMAIN_MESSAGE,
  ENTERPRISE_VALID_EMAIL_DOMAINS,
} from 'src/constants/enterprise';

enum SigninFields {
  EMAIL = 'email',
  PASSWORD = 'password',
}

interface FormData {
  [SigninFields.EMAIL]: string;
  [SigninFields.PASSWORD]: string;
}

export const EmailSigninForm = () => {
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | null>(
    null,
  );
  const [errorFields, setErrorFields] = useState<SigninFields[]>([]);
  const [isShowSuccessMessage, setIsShowSuccessMessage] = useState(false);
  const { searchParams } = useQueryParams();
  const [checkEmailInfo] = useLazyCheckEmailInfoQuery();
  const isPasswordChanged = searchParams['password-changed'];

  useEffect(() => {
    if (isPasswordChanged) {
      setIsShowSuccessMessage(true);
    }
  }, [isPasswordChanged]);

  const methods = useForm<FormData>({
    defaultValues: {
      [SigninFields.EMAIL]: '',
      [SigninFields.PASSWORD]: '',
    },
  });
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { isValid, isSubmitting },
  } = methods;

  const submitForm = async (data: FormData) => {
    const formEmail = data[SigninFields.EMAIL].toLowerCase();
    try {
      setErrorMessage('');
      setErrorFields([]);
      await signIn({
        username: formEmail,
        password: data[SigninFields.PASSWORD],
      });

      sendGTMEvent(GTMEvent.EMAIL_LOGIN_SUCCESS);
    } catch (error) {
      sendGTMEvent(GTMEvent.EMAIL_LOGIN_FAILURE);

      setErrorFields([SigninFields.EMAIL, SigninFields.PASSWORD]);
      setIsShowSuccessMessage(false);
      switch ((error as ServiceError).name) {
        case 'TooManyRequestsException':
          setErrorMessage('Too many attempts. Please try again in x minutes');
          return;
        case 'NotAuthorizedException':
          if (
            (error as ServiceError).message ===
            COGNITO_SIGN_IN_ERROR_INCORRECT_PASSWORD
          ) {
            setErrorMessage(
              'Oops! The email or password is incorrect. Try again.',
            );
          } else {
            setErrorMessage(
              'Unable to sign-in at this time. Please try again later.',
            );
          }
          return;
        case 'UserNotFoundException': {
          const { data: emailInfoData } = await checkEmailInfo(formEmail);
          if (
            emailInfoData?.user_exists &&
            !emailInfoData?.user_email_verified
          ) {
            setErrorMessage(<EmailVerificationBlock email={formEmail} />);
          } else {
            setErrorMessage(
              'Oops! The email or password is incorrect. Try again.',
            );
          }
          return;
        }

        default:
          setErrorMessage(error ? (error as ServiceError).message : 'error');
          return;
      }
    }
  };

  const emailValidator = useCallback(
    async (data: string) => {
      if (!isEnterprise) return;
      const emailDomain = data.split('@')[1];
      if (!ENTERPRISE_VALID_EMAIL_DOMAINS.includes(emailDomain)) {
        setError(SigninFields.EMAIL, {
          message: ENTERPRISE_INVALID_DOMAIN_MESSAGE,
        });
      } else {
        clearErrors([SigninFields.EMAIL]);
      }
    },
    [setError, clearErrors],
  );

  return (
    <FormProvider<FormData> methods={methods}>
      <form onSubmit={handleSubmit(submitForm)} className={styles.root}>
        <div className={styles.formRow}>
          <legend className={styles.legend}>Email</legend>
          <FormInput
            name={SigninFields.EMAIL}
            type="email"
            autoComplete="username"
            className={styles.inputField}
            placeholder={
              isEnterprise ? 'username@amazon.com' : 'username@example.com'
            }
            required={true}
            hasErrorBorder={
              !!errorMessage && errorFields.includes(SigninFields.EMAIL)
            }
            onBlurHandler={emailValidator}
            errorRenderType="inline"
          />
        </div>
        <div className={styles.formRow}>
          <legend className={styles.legend}>Password</legend>
          <FormInput
            name={SigninFields.PASSWORD}
            type="password"
            className={styles.inputField}
            placeholder="Enter password"
            autoComplete="current-password"
            required={true}
            hasErrorBorder={
              !!errorMessage && errorFields.includes(SigninFields.PASSWORD)
            }
          />
        </div>
        <button
          className={styles.submitButton}
          type="submit"
          disabled={!isValid || isSubmitting}
          data-e2e="email-login-submit-button"
        >
          <span>Login</span>
        </button>
        {!!errorMessage && <ErrorMessageBlock errorMessage={errorMessage} />}
        {isShowSuccessMessage && (
          <div className={styles.successBlock}>
            <CheckCircle size={24} />
            <span>Your password has been successfully reset.</span>
          </div>
        )}
      </form>
    </FormProvider>
  );
};
