/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AvatarVideoPresetName {
  _4K_RAW = '4K_raw',
  SQUARE = 'Square',
  HD = 'HD',
  HD_LOW = 'HD_low',
  HD_VERTICAL = 'HD_vertical',
  ALL = 'All',
}
