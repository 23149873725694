import { useFormContext } from 'react-hook-form';
import { MeetingCardField } from '../MeetingCardField';
import { FormInput } from 'src/components/FormInput';
import type { MeetingCardFormData } from '../../CreateMeetingCard';

export const DurationField = () => {
  const {
    formState: { errors },
  } = useFormContext<MeetingCardFormData>();

  return (
    <MeetingCardField label="Duration">
      <div className="nj-create-meeting-card--duration-wrapper">
        <div className="nj-create-meeting-card--form-field-wrapper nj-create-meeting-card--duration-field-wrapper">
          <FormInput
            required
            className="nj-create-meeting-card--input nj-create-meeting-card--duration-input"
            name="minutes"
            type="number"
            hasErrorBorder={!!errors.minutes}
            min={1}
          />

          <span className="nj-create-meeting-card--duration-input-label">
            minutes
          </span>
        </div>

        {/* TODO(olha): will be implemented later, BE is not ready */}
        {/* <DurationAllDayCheckbox /> */}
      </div>
    </MeetingCardField>
  );
};
