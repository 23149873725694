import styles from './ChangeSeatsCheckout.module.scss';
import { useRef, useState } from 'react';
import { AppearingFromRight } from 'src/animations/AppearingFromRight';
import { X } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { getRoundedPriceWithCurrency } from 'src/utils';
import { SummaryRow } from 'src/pages/PaymentBetaPage/components/SummaryRow';
import { Button } from 'src/v2/commonComponents/Button';

type ChangeSeatsCheckoutProps = {
  isOpen: boolean;
  onClose: () => void;
};

// todo component is not completed, need to complete and refactor
export const ChangeSeatsCheckout = ({
  isOpen,
  onClose,
}: ChangeSeatsCheckoutProps) => {
  const checkoutContentRef = useRef<HTMLDivElement>(null);
  const [numberOfSeats, changeNumberOfSeats] = useState<number>(0);

  const handleConfirmAndPayClick = () => {
    // todo when BE is ready
  };

  return (
    <AppearingFromRight inProp={isOpen} nodeRef={checkoutContentRef}>
      <div className={styles.root} ref={checkoutContentRef}>
        <div className={styles.backButtonRow}>
          <button type="button" onClick={onClose} className={styles.backButton}>
            <span>Change seats</span>
            <X size={SVG_SIZE_M} />
          </button>
        </div>

        <div className={styles.mainContainer}>
          <div className={styles.inputRow}>
            <input
              className={styles.seatsInput}
              type="number"
              step={1}
              min={1}
              value={numberOfSeats}
              onChange={(e) => changeNumberOfSeats(Number(e.target.value))}
            />
          </div>
          <hr className={styles.divider} />
          <div className={styles.paymentContainer}>
            <SummaryRow
              label="Subtotal"
              value={getRoundedPriceWithCurrency(0)}
            />
            <SummaryRow
              label="Prorated discount"
              value={getRoundedPriceWithCurrency(-0)}
            />
            <SummaryRow
              label="Tax & fees"
              value={getRoundedPriceWithCurrency(0)}
            />
            <div className={styles.checkoutResultSectionRow}>
              <span>Due Today (USD)</span>

              <span>{getRoundedPriceWithCurrency(0)}</span>
            </div>
          </div>
          <hr className={styles.divider} />
          <div className={styles.paymentButtonWrapper}>
            <Button
              className={styles.paymentButton}
              disabled={numberOfSeats === 0}
              onClick={handleConfirmAndPayClick}
            >
              Confirm & pay
            </Button>
          </div>
        </div>
      </div>
    </AppearingFromRight>
  );
};
