import { fetchAuthSession } from 'aws-amplify/auth';
import { GUEST_ACCESS_TOKEN } from 'src/constants';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { guestUsersBaseUrl } from 'src/store/constants';
import { X_NINJA_EVENT_ID_HEADER } from 'src/constants/api';
import { v4 as uuid_v4 } from 'uuid';
import { resetAllStoredData } from 'src/utils';

export const IS_SIGN_UP_REDIRECT = 'redirect-to-signup';

export const get_access_token = async () => {
  const authSession = await fetchAuthSession();

  if (authSession?.tokens) {
    return authSession.tokens.accessToken.toString();
  } else {
    const guestAuthToken = localStorage.getItem(GUEST_ACCESS_TOKEN);
    if (guestAuthToken) {
      const decodedToken = jwtDecode(guestAuthToken);
      if (dayjs.unix(Number(decodedToken.exp)).isBefore(dayjs())) {
        await resetAllStoredData();

        const fingerprint = await getFingerprint();

        const response = await fetch(guestUsersBaseUrl, {
          method: 'POST',
          headers: new Headers({
            [X_NINJA_EVENT_ID_HEADER]: uuid_v4(),
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            fingerprint,
          }),
        });

        const parsedResponse = await response.json();

        if (parsedResponse.access_token) {
          localStorage.setItem(GUEST_ACCESS_TOKEN, parsedResponse.access_token);
        }
        // todo change it with adding state update to config
        window.location.reload();
        return parsedResponse.access_token;
      }
      return guestAuthToken;
    } else {
      return;
    }
  }
};
