import { useMemo } from 'react';
import { UIColors } from 'src/constants';
import { useAppSelector, sessionColorThemeState } from 'src/store';

export const useColorTheme = () => {
  const isDarkTheme = useAppSelector(sessionColorThemeState);

  const skeletonColors = useMemo(() => {
    const colors = isDarkTheme ? UIColors.darkMode : UIColors.lightMode;
    return {
      baseColor: colors.SKELETON_BACKGROUND,
      highlightColor: colors.SKELETON_HIGHLIGHT,
    };
  }, [isDarkTheme]);

  return useMemo(
    () => ({
      isDarkTheme,
      skeletonColors,
    }),
    [isDarkTheme, skeletonColors],
  );
};
