import { useContext, useMemo } from 'react';
import { CornersOut, CornersIn } from '@phosphor-icons/react';
import classNames from 'classnames';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { exitFullscreen, goFullScreen, isFullScreenEnabled } from 'src/utils';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { useBreakpoint, useThemeActions } from 'src/hooks';
import { Button } from 'src/components/Button';

interface FullScreenButtonProps {
  isVideoFloating?: boolean;
  iconSize: number;
  onFullscreen: (val: boolean) => void;
}

export const FullScreenButton = ({
  isVideoFloating,
  iconSize,
  onFullscreen,
}: FullScreenButtonProps) => {
  const { isMobileOrTablet } = useBreakpoint();

  const { isFullscreen, toggleFullscreen, toggleTranscript } =
    useContext(AvatarModeContext);

  const { rootContainerRef, avatarFrameRef } = useContext(ForwardRefContext);

  const { toggleTasksListExpanded, toggleSettingsPanelExpanded } =
    useThemeActions();

  const FullScreenIcon = isFullscreen ? CornersIn : CornersOut;

  const enabledFullScreen = useMemo(() => isFullScreenEnabled(), []);

  const handleAvatarFullscreen = () => {
    if (isFullscreen) {
      const exitFullscreenAction = enabledFullScreen
        ? exitFullscreen
        : () => onFullscreen(false);
      exitFullscreenAction();
      toggleTranscript(true); // TODO(olha): This appears to be a bug and does not meet the requirements
      toggleFullscreen(false);
      return;
    }

    const element =
      isVideoFloating && isMobileOrTablet
        ? avatarFrameRef?.current
        : rootContainerRef?.current;
    if (element instanceof HTMLDivElement) {
      const goFullScreenAction = enabledFullScreen
        ? () => goFullScreen(element)
        : () => onFullscreen?.(true);
      goFullScreenAction();
    }

    toggleTasksListExpanded(false);
    toggleSettingsPanelExpanded(false);
    toggleTranscript(false);
    toggleFullscreen(true);
  };

  return (
    <Button
      className={classNames('nj-meta-human--button-control', 'fullscreen', {
        selected: isFullscreen,
      })}
      onClick={handleAvatarFullscreen}
    >
      <FullScreenIcon size={iconSize} />
    </Button>
  );
};
