import { useFormContext } from 'react-hook-form';
import { MeetingCardField } from '../MeetingCardField';
import { FormInput } from 'src/components/FormInput';
import type { MeetingCardFormData } from '../../CreateMeetingCard';

export const TitleField = () => {
  const {
    formState: { errors },
  } = useFormContext<MeetingCardFormData>();

  return (
    <MeetingCardField
      className="nj-create-meeting-card--title-field"
      label="Title"
    >
      <div className="nj-create-meeting-card--form-field-wrapper nj-create-meeting-card--title-input">
        <FormInput
          required
          className="nj-create-meeting-card--input"
          name="event_title"
          hasErrorBorder={!!errors.event_title}
        />
      </div>
    </MeetingCardField>
  );
};
