import classNames from 'classnames';
import styles from './SpinnerSimple.module.scss';
import { SVG_SIZE_XXL } from 'src/constants';

interface SpinnerSimpleProps {
  size?: number;
  className?: string;
}

export const SpinnerSimple = ({
  size = SVG_SIZE_XXL,
  className,
}: SpinnerSimpleProps) => {
  const style = {
    width: size,
    height: size,
  };

  return (
    <div style={style} className={classNames(styles.root, className)}>
      <span style={style} className={styles.spinner} />
    </div>
  );
};
