import { useCallback, useMemo } from 'react';
import { useSearchParams, NavigateOptions } from 'react-router-dom';
import { QueryParams } from 'src/types';
import { sanitizeOutgoingContent } from 'src/utils';

// (olha): attn: https://github.com/remix-run/react-router/issues/9757
// useSearchParams has one issue. So, we use new URLSearchParams(window.location.search);

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const typedSearchParams = useMemo(() => {
    const parsedParams: Partial<QueryParams> = {};
    searchParams.forEach((value, key) => {
      parsedParams[key as keyof QueryParams] = sanitizeOutgoingContent(value);
    });
    return parsedParams;
  }, [searchParams]);

  const setTypedSearchParams = useCallback(
    (newParams: Partial<QueryParams>, navigateOpts?: NavigateOptions) => {
      const updatedParams = new URLSearchParams(window.location.search);

      Object.keys(newParams).forEach((key) => {
        const value = newParams[key as keyof QueryParams];
        if (value !== undefined) {
          updatedParams.set(key, sanitizeOutgoingContent(value));
        }
      });

      setSearchParams(updatedParams, navigateOpts);
    },
    [setSearchParams],
  );

  const removeSearchParam = useCallback(
    (paramName: keyof QueryParams) => {
      const newSearchParams = new URLSearchParams(window.location.search);
      newSearchParams.delete(paramName);
      setSearchParams(newSearchParams);
    },
    [setSearchParams],
  );

  const removeAllSearchParams = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  return {
    searchParams: typedSearchParams,
    setSearchParams: setTypedSearchParams,
    removeSearchParam,
    removeAllSearchParams,
  };
};
