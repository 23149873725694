import { useMemo } from 'react';
import { useGetSubscriptionPlansQuery } from 'src/store/services';
import { useSession } from 'src/hooks';
import { TeamGroup } from 'src/types';

type UseSubscriptionPlansProps = {
  group?: TeamGroup;
};

export const useSubscriptionPlans = ({ group }: UseSubscriptionPlansProps) => {
  const { appUser, isEnterpriseTier } = useSession();

  const { data, isLoading, isFetching } = useGetSubscriptionPlansQuery(
    { user_id: appUser.user_id, group },
    { skip: isEnterpriseTier },
  );

  return useMemo(
    () => ({
      plans: data,
      isPlansLoading: isLoading || isFetching,
    }),
    [data, isLoading, isFetching],
  );
};
