import { ChangeEvent, ReactNode, useId } from 'react';
import classNames from 'classnames';
import { Circle, CheckCircle } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import styles from './RadioGroupSimple.module.scss';

export interface RadioOption<T> {
  value: T;
  label: ReactNode;
  isDisabled?: boolean;
}

interface RadioGroupSimpleProps<T> {
  options: RadioOption<T>[];
  selectedValue?: T;
  className?: string;
  disabledClassName?: string;
  isGroupDisabled?: boolean;
  onChange: (value: T) => void;
}

export const RadioGroupSimple = <T extends string>({
  options,
  selectedValue,
  className,
  disabledClassName,
  isGroupDisabled,
  onChange,
}: RadioGroupSimpleProps<T>) => {
  const groupId = useId();

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (isGroupDisabled) {
      return;
    }

    onChange(target.value as T);
  };

  return (
    <div
      className={classNames(
        styles.root,
        {
          [styles.disabled]: isGroupDisabled,
        },
        className,
      )}
    >
      {options.map(({ value, label, isDisabled }) => {
        const radioId = `${groupId}-${value}`;

        return (
          <div
            key={value}
            className={classNames(styles.radioItem, {
              [styles.disabled]: isDisabled,
              [disabledClassName || '']: isDisabled,
            })}
          >
            <input
              type="radio"
              id={radioId}
              value={value}
              disabled={isDisabled}
              checked={selectedValue === value}
              className={styles.input}
              onChange={handleChange}
            />
            <label htmlFor={radioId} className={styles.label}>
              {label}

              <span className={styles.icon}>
                {selectedValue === value ? (
                  <CheckCircle weight="fill" size={SVG_SIZE_M} />
                ) : (
                  <Circle size={SVG_SIZE_M} />
                )}
              </span>
            </label>
          </div>
        );
      })}
    </div>
  );
};
