/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CommonInputTypes {
  TEXT = 'text',
  EMAIL = 'email',
  SINGLE_SELECT = 'single_select',
  BOOLEAN = 'boolean',
}
