import { lightMode, darkMode } from 'src/constants';
import ReactSelect from 'react-select';
import { FieldValues } from 'react-hook-form';
import { getCustomStyles } from '../Select/selectHelper';
import { useColorTheme } from 'src/hooks';

interface SelectOption<T = unknown> {
  value: string | number | T;
  label: string | JSX.Element;
  customConfig?: {
    hasBorderBottom?: boolean;
  };
  isDisabled?: boolean;
}

interface SelectProps<T> extends FieldValues {
  options: SelectOption<T>[];
  value?: string | number | T;
  isSearchable?: boolean;
  isDisabled?: boolean;
  onChange: (data: T) => void;
  isShort?: boolean;
}

export const SelectSimple = <T,>({
  name,
  options,
  isSearchable = false,
  isDisabled = false,
  onChange,
  isShort = false,
  value,
  ...rest
}: SelectProps<T>) => {
  const { isDarkTheme } = useColorTheme();

  return (
    <ReactSelect
      defaultOptions
      options={options}
      theme={isDarkTheme ? darkMode : lightMode}
      isSearchable={isSearchable}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={(data: SelectOption) => {
        onChange(data.value as T);
      }}
      value={options.find((item) => item.value === value) || null}
      styles={getCustomStyles<SelectOption>({ isShort, isDarkTheme })}
      isDisabled={isDisabled}
      {...rest}
    />
  );
};
