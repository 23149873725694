/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum IDP {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
  LINKEDIN = 'linkedin',
  AMAZON = 'amazon',
  EMAIL = 'email',
  OPEN = 'open',
}
