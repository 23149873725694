import { ModalGalleryType, SearchWebPageReference } from 'src/types';
import { ResearchReference } from '../ResearchReference';

import { CollapsibleReferencesList } from 'src/components/CollapsibleReferencesList';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';

interface ThreadResearchReferencesProps {
  researchReferences?: SearchWebPageReference[];
}

export const ThreadResearchReferences = ({
  researchReferences,
}: ThreadResearchReferencesProps) => {
  const { onChangeModalGalleryData } = useContext(SessionContext);
  if (!researchReferences || researchReferences.length === 0) {
    return null;
  }

  const handleReferenceClick = (index: number) => {
    onChangeModalGalleryData(
      true,
      ModalGalleryType.REFERENCES,
      researchReferences,
      index,
    );
  };

  return (
    <CollapsibleReferencesList
      references={researchReferences}
      renderItem={(props) => (
        <ResearchReference
          {...props}
          key={props.index}
          onClick={handleReferenceClick}
        />
      )}
    />
  );
};
