// abTestingSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Variation {
  id: number;
  name?: string;
  isActive: boolean;
}

export interface Campaign {
  id: number;
  name?: string;
  status?: string;
  variations: { [variationId: string]: Variation };
  variationId?: string;
}

export interface CampaignsData {
  [campaignId: string]: Campaign;
}

interface ABTestingState {
  campaignData: CampaignsData | null;
  isABTestingEnabled: boolean | null;
}

const initialState: ABTestingState = {
  campaignData: null,
  isABTestingEnabled: null,
};

const abTestingSlice = createSlice({
  name: 'abTesting',
  initialState,
  reducers: {
    setCampaignData(state, action: PayloadAction<CampaignsData>) {
      state.campaignData = action.payload;
    },
    setIsABTestingEnabled(state, action: PayloadAction<boolean>) {
      state.isABTestingEnabled = action.payload;
    },
  },
});

export const { setCampaignData, setIsABTestingEnabled } = abTestingSlice.actions;

export default abTestingSlice.reducer;
