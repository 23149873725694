import { useContext, useEffect } from 'react';
import { Info } from '@phosphor-icons/react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { ChatMode } from 'src/types';
import {
  exitFullscreen,
  getDurationInMinutesAndSeconds,
  interruptMetahuman,
} from 'src/utils';
import { useSession } from 'src/hooks';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import {
  AVATAR_SESSION_NOTICE_SHOW,
  AVATAR_SESSION_NOTICE_WARNING,
} from 'src/constants';

const VIDEO_SESSION_ENDED = 'Video session ended.';

const SVG_SIZE = 24;

export const AvatarSessionNotice = () => {
  const {
    avatarTimeLeft,
    isAvatarSessionRunning,
    stopAvatarSession,
    clearShowAvatarQueue,
    isFullscreen,
    toggleTranscript,
    toggleFullscreen,
  } = useContext(AvatarModeContext);
  const { appUser, updateAvatarQueue, updateChatMode } = useSession();

  const handleCloseSession = () => {
    if (isFullscreen) {
      exitFullscreen();
      toggleTranscript(true);
      toggleFullscreen(false);
    }
    stopAvatarSession();
    updateAvatarQueue();
    clearShowAvatarQueue();
    updateChatMode(ChatMode.CHAT);
    interruptMetahuman(appUser.user_id);
    toast.info(VIDEO_SESSION_ENDED, {
      icon: <Info size={SVG_SIZE} weight="fill" />,
    });
  };

  useEffect(() => {
    if (isAvatarSessionRunning && avatarTimeLeft === 0) {
      handleCloseSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvatarSessionRunning, avatarTimeLeft]);

  const notice = getDurationInMinutesAndSeconds(avatarTimeLeft);

  const warning = avatarTimeLeft <= AVATAR_SESSION_NOTICE_WARNING;

  const visible =
    avatarTimeLeft > 0 && avatarTimeLeft <= AVATAR_SESSION_NOTICE_SHOW;

  return (
    <div
      className={classNames('nj-meta-human--session-notice', {
        visible,
        warning,
      })}
    >
      {notice} left
    </div>
  );
};
