/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ImageGenSettingsDimensionOptionTypes {
  SQUARE = 'square',
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  X = 'x',
  TIKTOK = 'tiktok',
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TV = 'tv',
  CINEMA = 'cinema',
}
