import { Fragment, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { VideoCamera } from '@phosphor-icons/react';
import classNames from 'classnames';
import { AvailableVideoFormats } from 'src/constants';
import { AvatarVideoPresetName } from 'src/types';
import { FormProvider } from 'src/components/FormProvider';
import { RadioButton } from 'src/components/RadioButton';
import { AvatarVideos } from 'src/types/models/AvatarVideos';
import { Button } from 'src/components/Button';

const ICON_SIZE = 24;

interface ResolutionFormData {
  resolution: AvatarVideoPresetName;
}

interface ResolutionFromProps {
  videoData: AvatarVideos;
  isLoading: boolean;
  onSubmit: (value: AvatarVideoPresetName) => void;
}

export const ResolutionForm = ({
  videoData,
  isLoading,
  onSubmit,
}: ResolutionFromProps) => {
  const methods = useForm<ResolutionFormData>();
  const { handleSubmit } = methods;

  const handleFormSubmit = ({ resolution }: ResolutionFormData) => {
    onSubmit(resolution);
  };

  const radioOptions = useMemo(
    () =>
      AvailableVideoFormats.filter(({ value }) =>
        videoData.urls?.find(({ name }) => value === name),
      ),
    [videoData],
  );

  return (
    <Fragment>
      <div className="nj-share--resolution-from-header">
        <VideoCamera size={ICON_SIZE} />

        <div>
          <p className="nj-share--resolution-from-title">
            {videoData.title || ''}
          </p>
          <p className="nj-share--resolution-from-date">
            Created on{' '}
            {videoData.created_at &&
              new Date(videoData.created_at).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
          </p>
        </div>
      </div>
      <hr className="divider" />

      <FormProvider<ResolutionFormData> methods={methods}>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className={classNames('nj-share--resolution-from', {
            disabled: isLoading,
          })}
        >
          {radioOptions.map(({ value, label, subTitle }) => (
            <RadioButton<AvatarVideoPresetName>
              className="nj-share--resolution-from-radio-button"
              name="resolution"
              required
              value={value}
              key={value}
              label={
                <span className="nj-share--resolution-from-label">
                  <span className="nj-share--resolution-from-label-title">
                    {label}
                  </span>
                  <span className="nj-share--resolution-from-label-subtitle">
                    {subTitle}
                  </span>
                </span>
              }
            />
          ))}

          <Button isSubmit className="nj-share--resolution-from-submit-button">
            Create public link
          </Button>
        </form>
      </FormProvider>
    </Fragment>
  );
};
