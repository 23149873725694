import { Button } from 'src/v2/commonComponents/Button';
import styles from './ExternalModelItem.module.scss';
import { Check, CheckSquare, Square } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { NinjaSubscriptionPlan } from 'src/types';
import { LabelPro } from 'src/components/LabelPro';
import { LabelUltra } from 'src/components/LabelUltra';
import LabelStandard from 'src/components/LabelStandard/LabelStandard';
import classNames from 'classnames';
import { useMemo } from 'react';

export interface ExternalModelItemProps {
  id: string;
  icon: JSX.Element;
  title: string;
  selected?: boolean;
  included?: boolean;
  availableIn?: NinjaSubscriptionPlan;
  onClick?: (id: string) => void;
}

export const ExternalModelItem = ({
  id,
  icon,
  title,
  selected,
  included,
  availableIn,
  onClick,
}: ExternalModelItemProps) => {
  const iconComponent = useMemo(() => {
    // TODO(olha): might be deprecated
    if (included) {
      return <Check size={SVG_SIZE_M} />;
    }

    if (!!availableIn) {
      return <></>;
    }

    if (selected) {
      return <CheckSquare weight="fill" size={SVG_SIZE_M} />;
    }

    return <Square color="currentColor" size={SVG_SIZE_M} />;
  }, [included, availableIn, selected]);

  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <Button
      disabled={!!availableIn}
      onClick={handleClick}
      className={classNames(styles.root, {
        [styles.withoutCursor]: included,
        [styles.selected]: selected,
      })}
      color="transparent"
    >
      {icon}

      <span className={styles.titleWrapper}>
        <span className={styles.title}>{title}</span>

        {availableIn === NinjaSubscriptionPlan.PRO && <LabelPro />}
        {availableIn === NinjaSubscriptionPlan.ULTRA && <LabelUltra />}
        {availableIn === NinjaSubscriptionPlan.STANDARD && <LabelStandard />}
      </span>

      {iconComponent}
    </Button>
  );
};
