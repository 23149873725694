export const COGNITO_FACEBOOK_EMAIL_NOT_SHARED_ERROR_MARKER =
  'PreSignUp failed with error Your Facebook account does not have primary email address';
export const COGNITO_PRE_SIGNUP_ERROR_PREFIX = 'PreSignUp failed with error ';
export const COGNITO_SIGN_IN_ERROR_INCORRECT_PASSWORD =
  'Incorrect username or password.';

export const COGNITO_PASSWORD_NUMBER_OF_CHARS_RULE = /^(?=.*[a-zA-Z]).{8,}$/;
export const COGNITO_PASSWORD_UPPER_AND_LOWER_CHARS_RULE =
  /^(?=.*[a-z])(?=.*[A-Z]).*$/;
export const COGNITO_PASSWORD_NUMBER_SPECIAL_CHARS_RULE =
  /^(?=.*\d)(?=.*[!@#$%^&*()_+=[\]{};':"\\|,.<>/?]).*$/;
