import { useMemo } from 'react';
import { useAppSelector, interfaceControlState } from 'src/store';

export const useRightPanelState = () => {
  const { isSettingsPanelExpanded } = useAppSelector(interfaceControlState);

  return useMemo(
    () => ({
      isSettingsPanelExpanded,
    }),
    [isSettingsPanelExpanded],
  );
};
