import { CSSTransition } from 'react-transition-group';
import { useContext, useRef, lazy, Suspense } from 'react';
import classNames from 'classnames';
import SessionContext from 'src/contexts/SessionContext';
import { useBreakpoint, useLeftPanelState, useTaskById } from 'src/hooks';
import { TaskScratchpad } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad';
import { TaskViewHeader } from './components/TaskViewHeader';
import { TaskViewProgress } from './components/TaskViewProgress';
import { TaskState } from 'src/types';
import { CheckCircle } from '@phosphor-icons/react';

const LazyReactConfetti = lazy(() => import('react-confetti'));

const OVERLAY_TIMEOUT = 360;
const MODAL_TIMEOUT = 300;

export const TaskView = () => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const { isVisibleTaskViewModal, onToggleVisibleTaskViewModal } =
    useContext(SessionContext);

  const { currentTaskData, removeCurrentTaskId } = useTaskById();
  const { isDesktopXLAndUp, isLaptopLAndUp } = useBreakpoint();
  const { isTasksListExpanded } = useLeftPanelState();

  const handleClose = () => {
    removeCurrentTaskId();
    onToggleVisibleTaskViewModal(false);
  };

  const positionByThreadWithLeftGap = isDesktopXLAndUp && isTasksListExpanded;

  const positionByThread = isLaptopLAndUp && !isTasksListExpanded;

  return (
    <CSSTransition
      in={isVisibleTaskViewModal}
      timeout={OVERLAY_TIMEOUT}
      classNames="nj-animate-fade"
      unmountOnExit
      nodeRef={containerRef}
    >
      <div ref={containerRef} className="nj-task-view--wrapper">
        <CSSTransition
          in={isVisibleTaskViewModal}
          timeout={MODAL_TIMEOUT}
          classNames="nj-animate-opacity-light"
          unmountOnExit
          nodeRef={buttonRef}
        >
          <div
            className="nj-task-view--background-overlay"
            onClick={handleClose}
            ref={buttonRef}
          />
        </CSSTransition>

        <div
          className={classNames('nj-task-view', {
            'position-by-thread-with-left-gap': positionByThreadWithLeftGap,
            'position-by-thread': positionByThread,
          })}
        >
          <div className="nj-task-view--container" ref={modalRef}>
            <TaskViewHeader onCloseTaskView={handleClose} />
            <TaskViewProgress />
            <TaskScratchpad />
            {currentTaskData?.state === TaskState.DONE && (
              <div className="nj-task-state" data-e2e="task-status-done">
                <CheckCircle size={24} weight="fill" />
                Task completed
              </div>
            )}
            {currentTaskData?.state === TaskState.DONE && (
              <Suspense>
                <LazyReactConfetti
                  width={modalRef?.current?.offsetWidth}
                  height={modalRef?.current?.offsetHeight}
                  numberOfPieces={500}
                  recycle={false}
                  initialVelocityY={{ min: -5, max: 3 }}
                />
              </Suspense>
            )}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
