import { isObject, isString, isNumber } from './general.guard';
import {
  SchedulingPayload,
  SchedulingRequest,
  Meeting,
  Negotiation,
} from '../api';
import { isGuest } from './api.guest.guard';
import log from 'src/utils/logger';

/**
 * isSchedulingRequest guards data for scheduling request type.
 * @param data unknown
 * @returns boolean
 */
export function isSchedulingRequest(data: unknown): data is SchedulingRequest {
  const request = data as SchedulingRequest;

  if (!isObject(request)) {
    log.debug(`isSchedulingRequest > not an object ${JSON.stringify(request)}`);
    return false;
  }

  if ('content' in request && !isString(request.content)) {
    log.debug(
      `isSchedulingRequest > invalid content ${
        request.content
      } in ${JSON.stringify(request)}`,
    );
    return false;
  }

  if (
    'guests' in request &&
    !(request.guests === undefined || Array.isArray(request.guests))
  ) {
    log.debug(
      `isSchedulingRequest > invalid guests ${JSON.stringify(request)}`,
    );
    return false;
  }

  if (request.guests) {
    for (const guest of request.guests) {
      if (!isGuest(guest)) {
        log.debug(
          `isSchedulingRequest > invalid guest ${JSON.stringify(
            guest,
          )} in ${JSON.stringify(request)}`,
        );
        return false;
      }
    }
  }

  if ('topic' in request && !isString(request.topic)) {
    log.debug(
      `isSchedulingRequest > invalid topic in ${JSON.stringify(request)}`,
    );
    return false;
  }

  if ('proposed_date' in request && !isString(request.proposed_date)) {
    log.debug(
      `isSchedulingRequest > invalid proposed_date in ${JSON.stringify(
        request,
      )}`,
    );
    return false;
  }

  if ('proposed_time' in request && !isString(request.proposed_time)) {
    log.debug(
      `isSchedulingRequest > invalid proposed_time in ${JSON.stringify(
        request,
      )}`,
    );
    return false;
  }

  if ('proposed_duration' in request && !isString(request.proposed_duration)) {
    log.debug(
      `isSchedulingRequest > invalid proposed_duration in ${JSON.stringify(
        request,
      )}`,
    );
    return false;
  }

  if ('request_timestamp' in request && !isString(request.request_timestamp)) {
    log.debug(
      `isSchedulingRequest > invalid request_timestamp in ${JSON.stringify(
        request,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isMeeting() tests data against Meeting data type.
 * @param data unknown
 * @returns boolean
 */
export function isMeeting(data: unknown): data is Meeting {
  const meeting = data as Meeting;

  if (!isObject(meeting)) {
    log.debug(`isMeeting > not an object ${JSON.stringify(meeting)}`);
    return false;
  }

  if (!('organizer' in meeting && isGuest(meeting.organizer))) {
    log.debug(`isMeeting > invalid organizer ${JSON.stringify(meeting)}`);
    return false;
  }

  if (!('guests' in meeting && Array.isArray(meeting.guests))) {
    log.debug(`isMeeting > invalid guests ${JSON.stringify(meeting)}`);
    return false;
  }

  for (const guest of meeting.guests) {
    if (!isGuest(guest)) {
      log.debug(
        `isMeeting > invalid guest ${JSON.stringify(guest)} in ${JSON.stringify(
          meeting,
        )}`,
      );
      return false;
    }
  }

  if (!('start_datetime' in meeting && isString(meeting.start_datetime))) {
    log.debug(`isMeeting > invalid start_datetime ${JSON.stringify(meeting)}`);
    return false;
  }

  if (!('end_datetime' in meeting && isString(meeting.end_datetime))) {
    log.debug(`isMeeting > invalid end_datetime ${JSON.stringify(meeting)}`);
    return false;
  }

  if (!('subject' in meeting && isString(meeting.subject))) {
    log.debug(`isMeeting > invalid subject ${JSON.stringify(meeting)}`);
    return false;
  }

  if ('location' in meeting && !isString(meeting.location)) {
    log.debug(`isMeeting > invalid location ${JSON.stringify(meeting)}`);
    return false;
  }

  if ('description' in meeting && !isString(meeting.description)) {
    log.debug(`isMeeting > invalid description ${JSON.stringify(meeting)}`);
    return false;
  }

  if (!('duration' in meeting && isNumber(meeting.duration))) {
    log.debug(`isMeeting > invalid duration ${JSON.stringify(meeting)}`);
    return false;
  }

  return true;
}

/**
 * isNegotiation() tests data against Negotiation data type.
 * @param data unknown
 * @returns boolean
 */
export function isNegotiation(data: unknown): data is Negotiation {
  const negotiation = data as Negotiation;

  if (!isObject(negotiation)) {
    log.debug(`isNegotiation > not an object ${JSON.stringify(negotiation)}`);
    return false;
  }

  if (
    !(
      'proposed_start_datetime' in negotiation &&
      isString(negotiation.proposed_start_datetime)
    )
  ) {
    log.debug(
      `isNegotiation > invalid proposed_start_datetime ${JSON.stringify(
        negotiation,
      )}`,
    );
    return false;
  }

  if (
    !(
      'proposed_end_datetime' in negotiation &&
      isString(negotiation.proposed_end_datetime)
    )
  ) {
    log.debug(
      `isNegotiation > invalid proposed_end_datetime ${JSON.stringify(
        negotiation,
      )}`,
    );
    return false;
  }

  if (
    !(
      'proposed_duration' in negotiation &&
      isNumber(negotiation.proposed_duration)
    )
  ) {
    log.debug(
      `isNegotiation > invalid proposed_duration ${JSON.stringify(
        negotiation,
      )}`,
    );
    return false;
  }

  if (!('guests' in negotiation && Array.isArray(negotiation.guests))) {
    log.debug(`isNegotiation > invalid guests ${JSON.stringify(negotiation)}`);
    return false;
  }

  for (const guest in negotiation.guests) {
    if (!isGuest(guest)) {
      log.debug(
        `isNegotiation > invalid guest ${JSON.stringify(
          guest,
        )} in ${JSON.stringify(negotiation)}`,
      );
      return false;
    }
  }

  if ('subject' in negotiation && !isString(negotiation.subject)) {
    log.debug(`isNegotiation > invalid subject ${JSON.stringify(negotiation)}`);
    return false;
  }

  return true;
}

/**
 * isSchedulingPayload() guards data to be of scheduling payload.
 * @param data unknown
 * @returns boolean
 */
export function isSchedulingPayload(data: unknown): data is SchedulingPayload {
  const payload = data as SchedulingPayload;

  if (!isObject(payload)) {
    log.debug(`isSchedulingPayload > not an object ${JSON.stringify(payload)}`);
    return false;
  }

  if ('payload_type' in payload && payload.payload_type !== 'scheduling') {
    return false;
  }

  if ('request' in payload && !isSchedulingRequest(payload.request)) {
    log.debug(
      `isSchedulingPayload > invalid request ${JSON.stringify(payload)}`,
    );
    return false;
  }

  if ('meeting' in payload && !isMeeting(payload.meeting)) {
    log.debug(
      `isSchedulingPayload > invalid meeting ${JSON.stringify(payload)}`,
    );
    return false;
  }

  if ('negotiation' in payload && !isNegotiation(payload.negotiation)) {
    log.debug(
      `isSchedulingPayload > invalid negotiation ${JSON.stringify(payload)}`,
    );
    return false;
  }

  return true;
}
