import styles from './PaymentPageTeamsTabs.module.scss';
import { TeamsTabs } from 'src/types';
import cn from 'classnames';

type PaymentPageTeamsTabsProps = {
  activeTab: TeamsTabs;
  onChange: (value: TeamsTabs) => void;
  tabsList: TeamsTabs[];
};

export const PaymentPageTeamsTabs = ({
  activeTab,
  onChange,
  tabsList,
}: PaymentPageTeamsTabsProps) => {
  return (
    <div className={styles.root}>
      {tabsList.map((item) => {
        const handleTabClick = () => {
          onChange(item);
        };
        return (
          <button
            type="button"
            key={item}
            className={cn(styles.tab, { [styles.active]: activeTab === item })}
            onClick={handleTabClick}
          >
            {item}
          </button>
        );
      })}
    </div>
  );
};
