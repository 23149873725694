import { SVGProps } from 'react';

export const XIcon = ({ width = 24, height = 24 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM5.19197 5.14286H9.72768L12.9487 9.71987L16.8571 5.14286H18.2857L13.5937 10.6362L19.3795 18.8571H14.8449L11.1071 13.5469L6.57142 18.8571H5.14285L10.462 12.6306L5.19197 5.14286ZM7.37946 6.28571L15.4408 17.7143H17.192L9.13058 6.28571H7.37946Z"
        fill="currentColor"
      />
    </svg>
  );
};
