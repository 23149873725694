import cn from 'classnames';
import styles from './ReferenceItem.module.scss';

type ReferenceItemProps = {
  onClick: () => void;
  isActive: boolean;
  title: string;
};

export const ReferenceItem = ({
  onClick,
  isActive,
  title,
}: ReferenceItemProps) => {
  return (
    <button
      className={cn(styles.root, { [styles.active]: isActive })}
      onClick={onClick}
    >
      <span className={cn(styles.title, 'ellipsis')}>{title}</span>
    </button>
  );
};
