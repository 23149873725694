import { List } from '@phosphor-icons/react';

const SVG_SIZE = 24;

type MobileListButtonProps = {
  onClick: () => void;
};

export const MobileListButton = ({ onClick }: MobileListButtonProps) => {
  return (
    <button
      type="button"
      className="nj-modal-gallery-activity-button"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <List size={SVG_SIZE} />
    </button>
  );
};
