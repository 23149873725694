import { useMemo, useEffect, ComponentType } from 'react';
import classNames from 'classnames';
import { SpeakerSimpleHigh, Waveform } from '@phosphor-icons/react';
import {
  useAvatarPreferences,
  useSession,
  useTextToSpeach,
  useColorTheme,
  useUserData,
} from 'src/hooks';
import { SelectOption } from 'src/components/Select';
import { Button } from 'src/components/Button';
import { darkMode, lightMode } from 'src/constants';
import { getCustomStyles } from 'src/components/Select/selectHelper';
import Select, { components, GroupBase, MultiValue } from 'react-select';
import { SingleValueProps } from 'react-select/dist/declarations/src/components/SingleValue';
import log from 'loglevel';
import { AvatarVoiceID } from 'src/types/models/AvatarVoiceID';

const SVG_SIZE = 24;

interface VoiceSelectorProps {
  selectedAvatar: string;
  selectedAvatarLanguage: string;
}

interface VoiceSelectOption extends SelectOption {
  isDefault: boolean;
}

export const VoiceSelector = ({
  selectedAvatar,
  selectedAvatarLanguage,
}: VoiceSelectorProps) => {
  const { isDarkTheme } = useColorTheme();
  const { updateUserData } = useUserData();
  const { synthesizerInProgress, synthesizeVoice, cancelSynthesizer } =
    useTextToSpeach();
  const {
    appUser: { settings },
  } = useSession();

  const {
    voiceOptions,
    avatarsListData,
    getTextSampleByLanguageCode,
    avatarVoiceID,
  } = useAvatarPreferences();

  const selectedAvatarGender = useMemo(
    () => avatarsListData.find(({ id }) => id === selectedAvatar)?.gender,
    [selectedAvatar, avatarsListData],
  );

  const filteredVoiceOptions: VoiceSelectOption[] = useMemo(
    () =>
      voiceOptions
        .filter(
          ({ gender, languageCode }) =>
            languageCode === selectedAvatarLanguage &&
            (selectedAvatarGender === 'both' ||
              gender === selectedAvatarGender),
        )
        .map(({ voiceID, dispalyVoiceName, isDefault }) => ({
          label: dispalyVoiceName,
          value: voiceID,
          isDefault,
        })),
    [selectedAvatarGender, selectedAvatarLanguage, voiceOptions],
  );

  useEffect(() => {
    if (avatarVoiceID) {
      cancelSynthesizer();
    }
  }, [avatarVoiceID, cancelSynthesizer]);

  const toggleListeningVoice = () => {
    if (synthesizerInProgress) {
      cancelSynthesizer();
    } else {
      const textSample = getTextSampleByLanguageCode(selectedAvatarLanguage);
      synthesizeVoice(avatarVoiceID, textSample);
    }
  };

  const customSingleValue: ComponentType<
    SingleValueProps<
      SelectOption | MultiValue<SelectOption>,
      boolean,
      GroupBase<SelectOption>
    >
  > = ({ children, ...props }): JSX.Element => {
    return components.SingleValue ? (
      <components.SingleValue {...props}>
        <div className="nj-avatar-voice-select-input-wrapper">
          <Waveform size={20} />
          {children}
        </div>
      </components.SingleValue>
    ) : (
      <div />
    );
  };

  const handleChangeAvatarVoice = async (value: AvatarVoiceID) => {
    try {
      await updateUserData({
        settings: {
          ...settings,
          video_language_setting: {
            ...settings?.video_language_setting,
            voice_id: value,
          },
        },
      });
    } catch (e) {
      log.error(e);
    }
  };

  const selectedVoiceValue = useMemo(() => {
    return (
      filteredVoiceOptions.find((item) => item.value === avatarVoiceID) || null
    );
  }, [filteredVoiceOptions, avatarVoiceID]);

  return (
    <div className="nj-avatar-recording--field">
      <div className="nj-avatar-recording--field-select">
        <Select
          options={filteredVoiceOptions}
          theme={isDarkTheme ? darkMode : lightMode}
          onChange={(data) => {
            handleChangeAvatarVoice(
              (data as SelectOption).value as AvatarVoiceID,
            );
          }}
          value={selectedVoiceValue}
          styles={getCustomStyles<SelectOption>({ isDarkTheme })}
          components={{ SingleValue: customSingleValue }}
          menuPlacement="top"
        />
        <Button
          type="button"
          className={classNames('nj-avatar-recording--play-button', {
            active: synthesizerInProgress,
          })}
          onClick={toggleListeningVoice}
        >
          <SpeakerSimpleHigh size={SVG_SIZE} />
        </Button>
      </div>
    </div>
  );
};
