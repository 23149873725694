import classNames from 'classnames';
import { Gender } from 'src/types';
import { Check } from '@phosphor-icons/react';
import { useAvatarPreferences, useSession, useUserData } from 'src/hooks';
import { changeMetahumanAvatar } from 'src/utils';
import {
  BETA_ENVIRONMENT,
  DEFAULT_AVATAR_MALE_VOICE_ID,
  DEFAULT_AVATAR_VOICE_ID,
} from 'src/constants';
import { AvatarID } from 'src/types/models/AvatarID';
import { Persona } from 'src/types/models/Persona';
import { useContext } from 'react';
import DebugContext from 'src/contexts/DebugContext';
import { VoiceSelector } from 'src/components/VoiceSelector';
import { AvatarVoiceID } from 'src/types/models/AvatarVoiceID';
import { env } from 'src/env';

const IMAGE_SIZE = 80;
const SVG_SIZE = 16;

export const PersonaSection = () => {
  const isBeta = env.REACT_APP_ENVIRONMENT === BETA_ENVIRONMENT;

  const { debugMode } = useContext(DebugContext);
  const { appUser } = useSession();
  const { updateUserData } = useUserData();
  const {
    selectedAvatarName,
    selectedAvatarGender,
    avatarVoiceID,
    avatarAudioLocale,
    voiceOptions,
    avatarsListData,
  } = useAvatarPreferences();

  const handleAgentChange = (agentName: string, newAgentGender: Gender) => {
    const voiceIDValue = voiceOptions.find(
      (item) =>
        item.languageCode === avatarAudioLocale &&
        item.gender === newAgentGender &&
        item.isDefault,
    )?.voiceID;

    const updatedVoiceId =
      newAgentGender !== selectedAvatarGender
        ? voiceIDValue ||
          (newAgentGender === 'female'
            ? DEFAULT_AVATAR_VOICE_ID
            : DEFAULT_AVATAR_MALE_VOICE_ID)
        : avatarVoiceID;

    changeMetahumanAvatar(appUser.user_id, agentName, debugMode);
    updateUserData(
      {
        persona: {
          ...appUser.persona,
          avatar_id: agentName as AvatarID,
        } as Persona,
        settings: {
          ...appUser.settings,
          video_language_setting: {
            ...appUser.settings?.video_language_setting,
            voice_id: updatedVoiceId as AvatarVoiceID,
          },
        },
      },
      true,
    );
  };

  return (
    <div>
      <div className="nj-avatar-select-tooltip-avatars-grid">
        {avatarsListData.map((item) => {
          const isSelected = selectedAvatarName === item.id;
          const imageSize = isSelected ? IMAGE_SIZE - 8 : IMAGE_SIZE;
          return (
            <button
              className={classNames('nj-avatar-select-tooltip-avatar-block', {
                selected: isSelected,
              })}
              onClick={() => handleAgentChange(item.id, item.gender as Gender)}
              key={`atlas_${item.id}`}
              disabled={
                !(
                  appUser?.settings?.features?.avatar_selector
                    ?.available_avatar_selectors || []
                ).includes(item.id)
              }
            >
              <img
                className="nj-avatar-select-tooltip-avatar-image"
                src={item.url}
                width={imageSize}
                height={imageSize}
                alt="Ninja"
              />
              {selectedAvatarName === item.id && (
                <div className="nj-avatar-select-tooltip-avatar-selected-icon">
                  <Check size={SVG_SIZE} />
                </div>
              )}
            </button>
          );
        })}
      </div>
      {isBeta && (
        <VoiceSelector
          selectedAvatar={selectedAvatarName}
          selectedAvatarLanguage={avatarAudioLocale}
        />
      )}
    </div>
  );
};
