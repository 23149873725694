interface ScheduleExpandButtonProps {
  isExpanded: boolean;
}

export const ScheduleExpandButton = ({
  isExpanded,
}: ScheduleExpandButtonProps) => {
  return (
    <button className="nj-thread-schedule-card--expand-button">
      {isExpanded ? 'View less' : 'View more'}
    </button>
  );
};
