import { useEffect, useContext } from 'react';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { exitFullscreen } from 'src/utils';

export const useFullScreen = () => {
  const { isFullscreen, toggleFullscreen, toggleTranscript } =
    useContext(AvatarModeContext);

  useEffect(() => {
    const handleFullscreenChange = () => {
      const isFullscreenEnabled = Boolean(document.fullscreenElement);
      if (!isFullscreenEnabled && isFullscreen) {
        exitFullscreen();
        toggleTranscript(true);
        toggleFullscreen(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [isFullscreen, toggleFullscreen, toggleTranscript]);
};
