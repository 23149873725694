import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppUser, TaskState } from 'src/types';
import { DEFAULT_AGENT } from 'src/constants';
import { RootState } from '../index';
import { PURGE } from 'reduxjs-toolkit-persist';

export enum SettingsTabs {
  GENERAL = 'General',
  PROFILE = 'Profile',
  VOICE_LANGUAGE = 'Voice & Language',
  // TODO(olha): check new mobile design and remove
  // PREFERENCES = 'Calendar',
  ACCESS_AND_DATA = 'Access & Data',
}

interface SettingsState {
  activeSettingsTab: SettingsTabs;
  agent: AppUser;
  threadTasksFilter?: TaskState;
}

// To keep all settings data here.
const initialState: SettingsState = {
  activeSettingsTab: SettingsTabs.GENERAL,
  agent: DEFAULT_AGENT,
};

/**
 * Keeps on a settings data.
 */
export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    resetSettings: (state) => {
      return initialState;
    },
    setActiveSettingsTab: (state, { payload }: PayloadAction<SettingsTabs>) => {
      state.activeSettingsTab = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const settingsState = (state: RootState) =>
  state.settings as SettingsState;

export const { resetSettings, setActiveSettingsTab } = settingsSlice.actions;

export default settingsSlice.reducer;
