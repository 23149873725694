import { Circle } from '@phosphor-icons/react';
import { useSession } from 'src/hooks';
import { VERSION_NUMBER } from 'src/constants';

const SVG_SIZE_TINY = 8;

export const SidePanelFooter = () => {
  const { isSocketConnected } = useSession();

  return (
    <div className="nj-section--footer">
      <div className="nj-section--footer--version">
        Version: {VERSION_NUMBER}
      </div>
      <div className="nj-section--footer--connection">
        <span
          className={isSocketConnected ? 'nj-color--green' : 'nj-color--red'}
        >
          <Circle size={SVG_SIZE_TINY} weight="fill" />
        </span>
        <span>{isSocketConnected ? `Connected` : `Disconnected`}</span>
      </div>
    </div>
  );
};
