import styles from './MeetNinjaSection.module.scss';
import { AWSLogo } from 'src/images/logos/aws-logo';
import classNames from 'classnames';

export const EnterpriseMeetNinjaSection = () => {
  return (
    <div className={styles.root}>
      <h1 className={styles.meetNinja}>Meet Ninja</h1>
      <div>
        <AWSLogo size={60} mainColor={'white'} />
      </div>
      <h4
        className={classNames(
          styles.productivity,
          styles.productivityEnterprise,
        )}
      >
        All-In-One AI Agent for Unlimited Productivity
      </h4>
      <h5 className={styles.poweredBy}>
        Powered by Next-Gen AWS Trainium 2 & Amazon Bedrock
      </h5>
    </div>
  );
};
