import { FormSwitch } from 'src/components/FormSwitch';
import { Icon } from 'src/components/Icon';

const SVG_SIZE = 24;

export const VideoSettings = () => {
  return (
    <div className="nj-avatar-recording--section-wrapper">
      <div className="nj-avatar-recording--video-format-field">
        <label className="nj-avatar-recording--video-format-label">
          <span className="nj-avatar-recording--video-format-label-icon">
            <Icon type="landscape" size={SVG_SIZE} />
          </span>

          <p className="nj-avatar-recording--video-format-label-text">
            <span className="nj-avatar-recording--video-format-label-title">
              Wide Landscape 16 : 9 4K
            </span>
            <span className="nj-avatar-recording--video-format-label-subtitle">
              YouTube
            </span>
          </p>
        </label>

        <FormSwitch name="_4k" />
      </div>

      <div className="nj-avatar-recording--video-format-field">
        <label className="nj-avatar-recording--video-format-label">
          <span className="nj-avatar-recording--video-format-label-icon">
            <Icon type="portrait" size={SVG_SIZE} />
          </span>

          <p className="nj-avatar-recording--video-format-label-text">
            <span className="nj-avatar-recording--video-format-label-title">
              Tall Portrait 9 : 16
            </span>
            <span className="nj-avatar-recording--video-format-label-subtitle">
              YouTube Short, TikTok, Instagram Reels
            </span>
          </p>
        </label>

        <FormSwitch name="portrait" />
      </div>

      <div className="nj-avatar-recording--video-format-field">
        <label className="nj-avatar-recording--video-format-label">
          <span className="nj-avatar-recording--video-format-label-icon">
            <Icon type="square" size={SVG_SIZE} />
          </span>

          <p className="nj-avatar-recording--video-format-label-text">
            <span className="nj-avatar-recording--video-format-label-title">
              Square 1 : 1
            </span>
            <span className="nj-avatar-recording--video-format-label-subtitle">
              YouTube Short, TikTok, Instagram Reels
            </span>
          </p>
        </label>

        <FormSwitch name="square" />
      </div>
    </div>
  );
};
