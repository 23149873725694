export interface AWSCognitoUserAttributes {
  email: string;
}

export interface AWSCognitoUser {
  attributes: AWSCognitoUserAttributes;
  username: string;
}

export enum SSOProvider {
  GOOGLE = 'Google',
  AMAZON = 'LoginWithAmazon',
  APPLE = 'SignInWithApple',
  FACEBOOK = 'Facebook',
  MICROSOFT = 'AzureAD',
  LINKEDIN = 'LinkedIn',
}
