import { useTaskById } from 'src/hooks';
import { TagsSection } from '../TaskViewHeader/components';
import { TaskQueryStatusSection } from 'src/components/FlatAppearance/components/TaskView/components/TaskViewProgress/components';

export const TaskViewProgress = () => {
  const { currentTaskData } = useTaskById();

  if (!currentTaskData || !currentTaskData?.task_id) {
    return null;
  }

  return (
    <div className="nj-task-view-header--wrapper">
      <div className="nj-task-view-header--content">
        <TagsSection currentTask={currentTaskData} />

        <TaskQueryStatusSection />
      </div>
    </div>
  );
};
