import dayjs from 'dayjs';
import { CaretDown } from '@phosphor-icons/react';
import { useTimeZone, useBreakpoint } from 'src/hooks';
import { DEFAULT_MINUTES_GAP } from '../../../../constants/defaultValues';
import { lazy, Suspense } from 'react';

const LazyTimePicker = lazy(() =>
  import('src/components/TimePicker').then((module) => ({
    default: module.TimePicker,
  })),
);

const LazyDateRangePicker = lazy(() =>
  import('src/components/DateRangePicker').then((module) => ({
    default: module.DateRangePicker,
  })),
);

const SVG_SIZE = 16;

interface DateTimePickerInputProps {
  date: string;
  hasError?: boolean;
  onDateChange: (date: string) => void;
}

export const DateTimePickerInput = ({
  date,
  hasError,
  onDateChange,
}: DateTimePickerInputProps) => {
  const { userTimeZoneShortName } = useTimeZone();
  const { isMobile } = useBreakpoint();
  const format = isMobile ? 'ddd, MMM D' : 'ddd, MMM D, YYYY';

  return (
    <div className="nj-create-meeting-card--meeting-window-picker-date-wrapper">
      <Suspense>
        <LazyDateRangePicker
          popperPlacement={isMobile ? 'top-end' : undefined}
          customInput={
            <p>
              {dayjs(date).format(format)}
              <span>
                <CaretDown size={SVG_SIZE} />
              </span>
            </p>
          }
          dateString={date}
          onDateChange={onDateChange}
        />
      </Suspense>

      <div className="nj-create-meeting-card--meeting-window-picker-time-wrapper">
        <Suspense>
          <LazyTimePicker
            dateString={date}
            onTimeChange={onDateChange}
            hasError={hasError}
            timeIntervals={DEFAULT_MINUTES_GAP}
          />
        </Suspense>
        <span>{userTimeZoneShortName}</span>
      </div>
    </div>
  );
};
