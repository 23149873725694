import { memo } from 'react';
import isEqual from 'lodash/isEqual';
import { Message } from 'src/types';
import { MessageAuthorPicture } from '../MessageAuthorPicture';
import { UserMessageBody } from './components/UserMessageBody';
import styles from './UserMessage.module.scss';

interface UserMessageProps {
  message: Message;
}

const UserMessageComponent = ({ message }: UserMessageProps) => {
  return (
    <div className={styles.root}>
      <MessageAuthorPicture message={message} />

      <UserMessageBody message={message} />
    </div>
  );
};

export const UserMessage = memo(
  UserMessageComponent,
  (prevProps, nextProps) => {
    return isEqual(prevProps.message, nextProps.message);
  },
);
