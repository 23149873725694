import { isGoogleEmailProvider } from 'src/utils/user';
import { useSession } from 'src/hooks/useSession';
import { useMemo } from 'react';

export const useCanConnectToGoogle = (): boolean => {
  const {
    appUser: { email_provider },
  } = useSession();

  return useMemo(() => isGoogleEmailProvider(email_provider), [email_provider]);
};
