/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserTierStatus {
  OK = 'ok',
  QUOTA_EXCEEDED = 'quota_exceeded',
  DELINQUENT = 'delinquent',
}
