import { Commands } from 'src/types';
import { escapeRegExp } from 'src/utils';

export const LIST_OF_COMMANDS = Object.values(Commands).map((command: string) =>
  command.replace(/^\//, ''),
);

const firstWordRegExp = new RegExp('^([a-zA-Z0-9]+)');

/**
 * replaceFirstWordWithCommands() replaces first word with command.
 * @param value string
 * @returns string
 */
export const replaceFirstWordWithCommands = (value: string): string => {
  const matchedValues = value.match(firstWordRegExp);

  if (
    matchedValues &&
    matchedValues.length > 0 &&
    LIST_OF_COMMANDS.includes(matchedValues[0].toLowerCase())
  ) {
    return value.replace(firstWordRegExp, `/${matchedValues[0].toLowerCase()}`);
  }
  return value;
};

/**
 * containsCommands() checks text for commands.
 * @param value string
 * @returns boolean
 */
export function containsCommand(value: string): boolean {
  for (const command of LIST_OF_COMMANDS) {
    if (value.includes(command)) {
      return true;
    }
  }
  return false;
}

/**
 * matchWithCommand() checks matching text with commands.
 * @param value string
 * @returns boolean
 */
export function matchWithCommand(value: string): boolean {
  const regExp = new RegExp(`^${escapeRegExp(value)}`, 'gi');
  return Object.values(Commands).some((command) => command.match(regExp));
}

/**
 * containsCommandAtTheEnd() returns command if it is found
 * at the end of the text.
 * @param value string
 * @returns boolean
 */
export function containsCommandAtTheEnd(value?: string): Commands | undefined {
  if (!value) return;
  for (const command of Object.values(Commands)) {
    const reg = new RegExp(`${command} $`);
    if (value.match(reg)) {
      return command;
    }
  }
  return;
}

/**
 * processUserCommands() converts textual emails to links & formats commands.
 * @param text string
 * @returns string
 */
export function processUserCommands(text: string): string {
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

  const emailProofed = text.replace(emailRegex, (match) => {
    return `<a href="mailto:${match}">${match}</a>`;
  });

  // att: removing conversion for now, since we
  // are replacing everything with markdown
  /*const formattedResult = emailProofed
    .replace(/\/calendar/gi, `<b>/calendar</b>`)
    .replace(/\/research-llm/gi, `<b>/research-llm</b>`)
    .replace(/\/research-code/gi, `<b>/research-code</b>`)
    .replace(/\/research-web/gi, `<b>/research-web</b>`)
    .replace(/\/research/gi, `<b>/research</b>`)
    .replace(/\n/gi, '<br>');
  return formattedResult;*/
  return emailProofed;
}
