import classNames from 'classnames';
import { MenuItem, MenuDivider } from '@szhsin/react-menu';
import { ChatsFilter } from 'src/types';
import type { FilterControlItem } from '../ConversationControl';

interface ControlMenuItemProps extends FilterControlItem {
  activeFilter?: ChatsFilter;
  svgSize?: number;
  onClick: (chatsFilter: ChatsFilter) => void;
}

export const ControlMenuItem = ({
  controlItem,
  activeFilter,
  Icon,
  Component,
  svgSize = 20,
  withDivider,
  isTitle,
  onClick,
}: ControlMenuItemProps) => {
  const handleClick = () => {
    onClick(controlItem);
  };

  return (
    <>
      <MenuItem
        onClick={handleClick}
        className={classNames('filtering-menu-item', {
          selected: activeFilter === controlItem,
        })}
      >
        {Icon && <Icon size={svgSize} />}
        {Component && Component}

        {isTitle ? (
          <h3 className="nj-multi-menu--headline">{controlItem}</h3>
        ) : (
          <span>{controlItem}</span>
        )}
      </MenuItem>

      {withDivider && <MenuDivider />}
    </>
  );
};
