import { createApi } from '@reduxjs/toolkit/query/react';
import { baseGuestUsersQuery } from './config';
import { ApiUser } from 'src/types';

export interface CreateGuestUserRequest {
  fingerprint: string;
}

export interface CreateGuestUserResponse {
  user: ApiUser;
  access_token: string;
}

export enum GuestUsersTags {
  GuestUsers = 'GuestUsers',
}

export const guestUsersApi = createApi({
  reducerPath: 'guestUsersApi',
  tagTypes: [GuestUsersTags.GuestUsers],
  baseQuery: baseGuestUsersQuery,
  endpoints: (builder) => ({
    createGuestUser: builder.mutation<
      CreateGuestUserResponse,
      CreateGuestUserRequest
    >({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useCreateGuestUserMutation } = guestUsersApi;
