import { Headset } from '@phosphor-icons/react';
import { CommonNavigationButton } from '../CommonNavigationButton';
import { useSession } from 'src/hooks';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';

interface SupportButtonProps {
  showIcon?: boolean;
}

export const SupportButton = ({ showIcon = true }: SupportButtonProps) => {
  const { appUser } = useSession();
  const { onChangeIsOpenSupportModal } = useContext(SessionContext);

  const handleClick = () => {
    onChangeIsOpenSupportModal(true);
  };
  return (
    <CommonNavigationButton
      IconComponent={showIcon ? Headset : undefined}
      title="Support"
      onClick={handleClick}
      disabled={!appUser?.settings?.features?.zendesk_support?.is_capable}
    />
  );
};
