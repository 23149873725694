/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TextRequestPayload = {
  payload_type?: TextRequestPayload.payload_type;
  content: string;
};

export namespace TextRequestPayload {
  export enum payload_type {
    TEXT = 'text',
  }
}
