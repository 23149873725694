import { lazy, Suspense, useRef } from 'react';
import cn from 'classnames';
import { DotsThreeOutlineVertical } from '@phosphor-icons/react';
import { useVisible, useOutsideClick } from 'src/hooks';
import {
  LanguageButton,
  CameraAngleButton,
  EnvironmentSelectorButton,
} from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons';
import styles from './AvatarSettingsMenu.module.scss';
import { SVG_SIZE_M, SVG_SIZE_S } from 'src/constants';

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

interface AvatarSettingsMenuProps {
  isSmall: boolean;
}

export const AvatarSettingsMenu = ({ isSmall }: AvatarSettingsMenuProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { isVisible, handleVisibilityRemove, handleVisibilityToggle } =
    useVisible();

  useOutsideClick(containerRef, handleVisibilityRemove);

  return (
    <div className={styles.root} ref={containerRef}>
      <button
        onClick={handleVisibilityToggle}
        id="avatar-settings-menu-trigger"
        className={cn(styles.triggerButton, {
          [styles.active]: isVisible,
          [styles.small]: isSmall,
        })}
      >
        <DotsThreeOutlineVertical size={isSmall ? SVG_SIZE_S : SVG_SIZE_M} />
      </button>

      <Suspense>
        <LazyReactTooltip
          anchorSelect="#avatar-settings-menu-trigger"
          clickable
          noArrow
          openOnClick
          disableStyleInjection
          className={styles.settingsTooltip}
          isOpen={isVisible}
          opacity={1}
          place={isSmall ? 'bottom-end' : 'top-start'}
          offset={isSmall ? 4 : -4}
        >
          <div className={styles.menu} onClick={handleVisibilityRemove}>
            <LanguageButton />
            <EnvironmentSelectorButton />
            <CameraAngleButton />
          </div>
        </LazyReactTooltip>
      </Suspense>
    </div>
  );
};
