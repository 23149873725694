import { datadogRum } from '@datadog/browser-rum';
import TagManager from 'react-gtm-module';
import { TRANSACTION_ID } from 'src/constants';
import {
  GTMVariables,
  GTMEvent,
  ApiTaskSelectable,
  TaskSkill,
  TaskState,
  GTMInitialVariables,
} from 'src/types';
import { UserTier } from 'src/types/models/UserTier';
import { env } from 'src/env';
import { isProdEnv } from 'src/utils';

type ActionMetadata = {
  [key: string]: string | number | boolean | undefined;
};

const DEFAULT_EVENTS: GTMVariables = {
  message_id: '',
  task_id: '',
  task_state: '',
  task_skill: '',
  transaction_id: '',
  subscription_plan: '',
  subscription_period: '',
  payment_method_type: '',
  page_path: '',
};

export const logAction = (action: string, metadata?: ActionMetadata) => {
  datadogRum.addAction(action, metadata);
};

export const initializeGTM = () => {
  if (!isProdEnv) {
    return;
  }

  const tagManagerArgs = {
    gtmId: env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
  };

  TagManager.initialize(tagManagerArgs);
};

const getTierGTMTitle = (tier?: UserTier) => {
  switch (tier) {
    case UserTier.PAID:
    case UserTier.PRO:
      return 'PRO';
    case UserTier.ULTRA:
      return 'Ultra';

    default:
      return tier?.toLocaleUpperCase() || '';
  }
};

export const addUserIdToGTMDataLayer = ({
  user_id,
  tier_id,
  login_type,
  user_email,
  user_first_name,
  user_last_name,
}: GTMInitialVariables) => {
  if (!isProdEnv) {
    return;
  }

  const tagManagerArgs = {
    dataLayer: {
      user_id,
      tier_id: getTierGTMTitle(tier_id),
      login_type,
      user_email,
      user_first_name,
      user_last_name,
      event: GTMEvent.INITIALIZATION_FROM_PORTAL,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const addSourcesToGTMDataLayer = ({
  calendar_access = false,
  contacts_access = false,
}: {
  calendar_access?: boolean;
  contacts_access?: boolean;
}) => {
  if (!isProdEnv) {
    return;
  }

  const tagManagerArgs = {
    dataLayer: {
      calendar_access,
      contacts_access,
      event: GTMEvent.INITIALIZATION_ACCESS_STATE,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const sendGTMEvent = (
  event: GTMEvent,
  variables: GTMVariables = {},
): void => {
  // send custom user action to Datadog
  datadogRum.addAction(event, {
    name: event,
    ...variables,
  });

  if (!isProdEnv) {
    return;
  }

  const tagManagerArgs = {
    dataLayer: {
      event,
      ...Object.fromEntries(
        Object.keys(DEFAULT_EVENTS).map((key: keyof GTMVariables) => [
          key,
          variables[key] ?? '',
        ]),
      ),
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

const getSkillGTMTitle = (skill?: TaskSkill) => {
  switch (skill) {
    case TaskSkill.CHITCHAT:
      return 'Writer';

    case TaskSkill.RESEARCH:
      return 'Researcher';

    case TaskSkill.CODING:
      return 'Coder';

    case TaskSkill.SCHEDULING:
      return 'Scheduler';

    case TaskSkill.IMAGE:
      return 'Image Generation';

    default:
      return '';
  }
};

export const taskGTMEvent = (task: Partial<ApiTaskSelectable>) => {
  const variables: GTMVariables = {
    task_id: task.task_id,
    task_state: task.state,
    task_skill: getSkillGTMTitle(task.skill),
  };

  if (task.skill === TaskSkill.CHITCHAT) {
    sendGTMEvent(GTMEvent.TASK_EVENT, {
      ...variables,
      task_state: TaskState.DONE,
    });
  }

  if (
    !task.state ||
    (task.state === TaskState.BLOCKED && !task.requires_attention) ||
    (task.state === TaskState.DONE && !task.requires_attention) ||
    !task.skill
  ) {
    return;
  }

  sendGTMEvent(GTMEvent.TASK_EVENT, variables);
};

export const sendPaymentGTMEvent = ({
  transaction_id,
  subscription_plan,
  subscription_period,
}: Pick<
  GTMVariables,
  'transaction_id' | 'subscription_plan' | 'subscription_period'
>) => {
  const variables: GTMVariables = {
    transaction_id:
      transaction_id || localStorage.getItem(TRANSACTION_ID) || undefined,
    subscription_plan,
    subscription_period,
  };

  localStorage.removeItem(TRANSACTION_ID);

  sendGTMEvent(GTMEvent.SUCCESSFUL_NEW_PRO_SUBSCRIPTION, variables);
};
