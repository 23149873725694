import { useCallback, useMemo } from 'react';
import {
  useGetTaskByIdQuery,
  useLazyGetTaskByIdQuery,
  useUpdateTaskFieldMutation,
} from 'src/store/services';
import {
  useAppSelector,
  sessionState,
  setCurrentTaskId,
  useAppDispatch,
} from 'src/store';
import log from 'src/utils/logger';
import { ApiTask, TaskState } from 'src/types';

export const useTaskById = () => {
  const {
    appUser: { user_id },
    currentTaskId,
  } = useAppSelector(sessionState);

  const dispatch = useAppDispatch();

  const { data: taskData } = useGetTaskByIdQuery(
    {
      user_id,
      task_id: currentTaskId || '',
    },
    { skip: !user_id || !currentTaskId },
  );

  const [getTaskByIdTrigger] = useLazyGetTaskByIdQuery();

  const handleGetTaskById = useCallback(
    async (task_id: string) => {
      await getTaskByIdTrigger({ user_id, task_id }, true);
    },
    [user_id, getTaskByIdTrigger],
  );

  const handleSetCurrentTaskId = useCallback(
    (task_id: string) => {
      dispatch(setCurrentTaskId(task_id));
    },
    [dispatch],
  );

  const handleRemoveCurrentTaskId = useCallback(() => {
    dispatch(setCurrentTaskId());
  }, [dispatch]);

  const [updateTaskField] = useUpdateTaskFieldMutation();

  const handleUpdateTaskField = useCallback(
    async ({ task_id, task }: { task_id: string; task: Partial<ApiTask> }) => {
      try {
        await updateTaskField({
          user_id,
          task_id,
          task,
        }).unwrap();
      } catch (error) {
        log.error(error);
      }
    },
    [updateTaskField, user_id],
  );

  const handleStopTask = useCallback(
    async (task_id: string) => {
      await handleUpdateTaskField({
        task_id,
        task: {
          state: TaskState.STOPPING,
        },
      });
    },
    [handleUpdateTaskField],
  );

  return useMemo(
    () => ({
      currentTaskId,
      currentTaskData: currentTaskId ? taskData : undefined,
      getTaskById: handleGetTaskById,
      setCurrentTaskId: handleSetCurrentTaskId,
      removeCurrentTaskId: handleRemoveCurrentTaskId,
      updateTaskField: handleUpdateTaskField,
      stopTask: handleStopTask,
    }),
    [
      currentTaskId,
      taskData,
      handleGetTaskById,
      handleSetCurrentTaskId,
      handleRemoveCurrentTaskId,
      handleUpdateTaskField,
      handleStopTask,
    ],
  );
};
