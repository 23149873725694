import { useCallback, useMemo, useState, useRef } from 'react';
import {
  SpeechConfig,
  SpeechSynthesizer,
  SpeakerAudioDestination,
  AudioConfig,
} from 'microsoft-cognitiveservices-speech-sdk';
import { useGetSpeechTokenQuery } from 'src/store/services';
import { useSession } from 'src/hooks';

const TOKEN_POLLING_INTERVAL = 1000 * 480; // 8 minutes

export const useTextToSpeach = () => {
  const { appUser } = useSession();
  const { data } = useGetSpeechTokenQuery(appUser.user_id, {
    pollingInterval: TOKEN_POLLING_INTERVAL,
  });
  const [synthesizerInProgress, setSynthesizerInProgress] =
    useState<boolean>(false);
  const player = useRef<SpeakerAudioDestination | null>(null);

  const speechConfig = useMemo(() => {
    if (!data) {
      return null;
    }
    return SpeechConfig.fromAuthorizationToken(data.token, data.region);
  }, [data]);

  const synthesizeVoice = useCallback(
    (voiceID: string, inputText: string) => {
      if (!speechConfig) {
        return;
      }
      if (player.current) {
        player.current?.pause();
        player.current = null;
      }

      player.current = new SpeakerAudioDestination();

      player.current.onAudioEnd = function () {
        setSynthesizerInProgress(false);
      };
      const audioConfig = AudioConfig.fromSpeakerOutput(player.current);

      speechConfig.speechSynthesisVoiceName = voiceID;

      let synthesizer: SpeechSynthesizer | null = new SpeechSynthesizer(
        speechConfig,
        audioConfig,
      );

      synthesizer.synthesisStarted = function () {
        setSynthesizerInProgress(true);
      };

      synthesizer?.speakTextAsync(
        inputText,
        function () {
          synthesizer?.close();
          synthesizer = null;
        },
        function () {
          synthesizer?.close();
          synthesizer = null;
        },
      );
    },
    [speechConfig],
  );

  const cancelSynthesizer = useCallback(() => {
    if (player.current) {
      player.current?.pause();
      player.current = null;
      setSynthesizerInProgress(false);
    }
  }, []);

  return { synthesizerInProgress, synthesizeVoice, cancelSynthesizer };
};
