import { Check } from '@phosphor-icons/react';
import classNames from 'classnames';
import { SVG_SIZE_S } from 'src/constants';
import styles from './PlanFeatureItem.module.scss';

interface PlanFeatureItemProps {
  title: string;
  isSecondary?: boolean;
}

export const PlanFeatureItem = ({
  title,
  isSecondary,
}: PlanFeatureItemProps) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.hidden]: !title,
        [styles.secondary]: isSecondary,
      })}
    >
      <Check size={SVG_SIZE_S} />

      <span>{title}</span>
    </div>
  );
};
