import styles from './ForgotPasswordSection.module.scss';
import { EmailSubmitForm } from 'src/v2/pages/auth/components/EmailSubmitForm';
import React, { ReactNode, useCallback, useState } from 'react';
import { resetPassword } from 'aws-amplify/auth';
import { ServiceError } from '@aws-amplify/core/src/types/errors';
import { EmailVerificationBlock } from 'src/pages/AuthPages/components/EmailVerificationBlock';
import log from 'src/utils/logger';
import { useLazyCheckEmailInfoQuery } from 'src/store/services';
import { AppRoutes } from 'src/types';
import { TextWithLinkSection } from 'src/v2/pages/auth/components/TextWithLinkSection';
import { ForgotPasswordEmailSent } from '../ForgotPasswordEmailSent';

export const ForgotPasswordSection = () => {
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | null>(
    null,
  );
  const [userEmail, setUserEmail] = useState<string | undefined>();
  const [isResetSent, setIsResetSent] = useState(false);
  const [checkEmailInfo] = useLazyCheckEmailInfoQuery();

  const onSubmit = useCallback(
    async (email: string) => {
      try {
        setUserEmail(email);
        setErrorMessage('');
        await resetPassword({
          username: email,
        });
        setIsResetSent(true);
      } catch (error) {
        if ((error as ServiceError).name === 'UserNotFoundException') {
          const { user_exists, user_email_verified } =
            await checkEmailInfo(email).unwrap();
          if (user_exists && !user_email_verified) {
            setErrorMessage(<EmailVerificationBlock email={email} />);
          } else {
            setErrorMessage('The account does not exist');
          }
        } else if (
          (error as ServiceError).name === 'NotAuthorizedException' &&
          (error as ServiceError).message ===
            'User password cannot be reset in the current state.'
        ) {
          setErrorMessage(
            'The email is registered via Single Sign-On (SSO). Please return to the login page to access your account.',
          );
        } else {
          log.error(error);
          setErrorMessage(error ? (error as ServiceError).message : 'error');
        }
      }
    },
    [checkEmailInfo],
  );
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h2 className={styles.signInHeader}>
          {isResetSent ? 'Check your email' : 'Forgot password?'}
        </h2>
        {isResetSent ? (
          <ForgotPasswordEmailSent email={userEmail} />
        ) : (
          <EmailSubmitForm
            onSubmit={onSubmit}
            errorMessage={errorMessage}
            buttonText="Send reset link"
          />
        )}
        <TextWithLinkSection
          text="Back to"
          linkText="Login"
          linkRoute={AppRoutes.LOGIN}
        />
      </div>
    </div>
  );
};
