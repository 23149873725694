export const convertBase64ToFile = (base64String: string) => {
  const byteString = atob(base64String.split(',')[1]);
  const mimeType = base64String.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: mimeType });
  const file = new File([blob], 'photo.jpg', { type: mimeType });

  return file;
};
