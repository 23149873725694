import { useContext, ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import SessionContext from 'src/contexts/SessionContext';
import { OverlayBackground } from 'src/components/OverlayBackground';
import { ANIMATION_TIMEOUT } from 'src/constants';

interface ConversationsControlPanelProps {
  children: ReactNode;
}

export const ConversationsControlPanel = ({
  children,
}: ConversationsControlPanelProps) => {
  const { isOpenTaskListControlsPanel, onCloseTaskListControlsPanel } =
    useContext(SessionContext);

  const overlayRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <OverlayBackground
        showOverlay={isOpenTaskListControlsPanel}
        zIndex={1150}
        onClick={onCloseTaskListControlsPanel}
        ref={overlayRef}
      />

      <CSSTransition
        in={isOpenTaskListControlsPanel}
        timeout={ANIMATION_TIMEOUT}
        classNames="nj-animate-vertical-appearance"
        unmountOnExit
        nodeRef={containerRef}
      >
        <div ref={containerRef} className="nj-mobile-action-panel">
          <hr
            onClick={onCloseTaskListControlsPanel}
            className="nj-mobile-action-panel--top-marker"
          />

          {children}
        </div>
      </CSSTransition>
    </>
  );
};
