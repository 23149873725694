import { AvatarImage } from 'src/components/AvatarImage';
import { SkillLabel } from 'src/components/SkillLabel';
import { ApiTaskSelectable } from 'src/types';

const AVATAR_SIZE = 24;

interface TagsSectionProps {
  currentTask: Partial<ApiTaskSelectable>;
}

export const TagsSection = ({ currentTask }: TagsSectionProps) => {
  return (
    <div className="nj-task-view-header--tags">
      <AvatarImage size={AVATAR_SIZE} />

      <SkillLabel skill={currentTask?.skill} size="medium" />
    </div>
  );
};
