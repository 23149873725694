import { isObject, isString, isBoolean } from './general.guard';
import { Guest } from '../api';
import log from 'src/utils/logger';

/**
 * isGuest() checks data against guest data type.
 * @param data unknown
 * @returns boolean
 */
export function isGuest(data: unknown): data is Guest {
  const guest = data as Guest;

  if (!isObject(guest)) {
    log.debug(`isGuest > not an object ${JSON.stringify(guest)}`);
    return false;
  }

  if ('email' in guest && !isString(guest.email)) {
    log.debug(
      `isGuest > invalid email ${guest.email} in ${JSON.stringify(guest)}`,
    );
    return false;
  }

  if ('name' in guest && !isString(guest.name)) {
    log.debug(
      `isGuest > invalid name ${guest.name} in ${JSON.stringify(guest)}`,
    );
    return false;
  }

  if ('role' in guest && !isString(guest.role)) {
    log.debug(
      `isGuest > invalid role ${guest.role} in ${JSON.stringify(guest)}`,
    );
    return false;
  }

  if ('accepted' in guest && !isBoolean(guest.accepted)) {
    log.debug(
      `isGuest > invalid accepted ${guest.accepted} in ${JSON.stringify(
        guest,
      )}`,
    );
    return false;
  }

  if ('internal' in guest && !isBoolean(guest.internal)) {
    log.debug(
      `isGuest > invalid internal ${guest.internal} in ${JSON.stringify(
        guest,
      )}`,
    );
    return false;
  }

  return true;
}
