import { useAvatarPreferences } from 'src/hooks';
import { CameraAngleButton } from './components/CameraAngleButton';

export const CameraAngleSelector = () => {
  const { cameraAngleOptions } = useAvatarPreferences();

  return (
    <div className="nj-section--main-container with-padding">
      <p className="nj-section--main-container-title">Steady shot</p>

      <div className="nj-section--thumbnail-list">
        {cameraAngleOptions
          .filter(({ cinematicMode }) => !cinematicMode)
          .map((angle) => {
            return <CameraAngleButton key={angle.id} {...angle} />;
          })}
      </div>

      <p className="nj-section--main-container-title">Cinematic mode</p>

      <div className="nj-section--thumbnail-list">
        {cameraAngleOptions
          .filter(({ cinematicMode }) => cinematicMode)
          .map((angle) => {
            return <CameraAngleButton key={angle.id} {...angle} />;
          })}
      </div>
    </div>
  );
};
