import { useContext } from 'react';
import { User } from '@phosphor-icons/react';
import cn from 'classnames';
import AuthContext from 'src/contexts/AuthContext';
import { SVG_SIZE_XL, SVG_SIZE_M } from 'src/constants';
import { useGetUserPhoto, useSession } from 'src/hooks';
import { getInitials } from 'src/utils';
import styles from './UserImage.module.scss';

interface UserImageProps {
  size?: number;
  iconSize?: number;
}

export const UserImage = ({
  size = SVG_SIZE_XL,
  iconSize = SVG_SIZE_M,
}: UserImageProps) => {
  const { photoUrl } = useGetUserPhoto();
  const { appUser } = useSession();

  const { isGuestAccess } = useContext(AuthContext);

  const alt = getInitials(appUser);

  if (photoUrl) {
    return (
      <span className={styles.root}>
        <img
          referrerPolicy="no-referrer"
          src={photoUrl}
          alt={alt}
          width={size}
          height={size}
        />
      </span>
    );
  }

  const style = {
    width: size,
    height: size,
  };

  if (isGuestAccess) {
    return (
      <span className={cn(styles.root, styles.withIcon)} style={style}>
        <User size={iconSize} />
      </span>
    );
  }

  return (
    <span className={cn(styles.root, styles.withInitials)} style={style}>
      <span data-testid="circle-icon" className={styles.initials}>
        {alt}
      </span>
    </span>
  );
};
