import { useEffect, RefObject } from 'react';
import {
  useCurrentConversationStatuses,
  useConversationParams,
} from 'src/hooks';

export const useScrollToBottomThread = (
  endOfMessagesRef: RefObject<HTMLDivElement> | null,
) => {
  const { isConversationSuccess, isCurrentConversationLoading } =
    useCurrentConversationStatuses();
  const { currentConversationId } = useConversationParams();

  useEffect(() => {
    if (
      isConversationSuccess &&
      !isCurrentConversationLoading &&
      endOfMessagesRef &&
      endOfMessagesRef.current
    ) {
      endOfMessagesRef.current.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentConversationId,
    isConversationSuccess,
    isCurrentConversationLoading,
    endOfMessagesRef,
  ]);
};
