import { createApi } from '@reduxjs/toolkit/query/react';
import { AppUser, LocationRequest } from 'src/types';
import { baseTasksQuery } from './config';

export interface UpdateUserRequest {
  id: string;
  body: Partial<AppUser>;
}

export interface UpdateUserLocationRequest {
  user_id: string;
  body: LocationRequest;
}

export enum UsersTags {
  Users = 'Users',
  ExternalModels = 'ExternalModels',
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: [UsersTags.Users, UsersTags.ExternalModels],
  baseQuery: baseTasksQuery,
  endpoints: (builder) => ({
    getUserById: builder.query<AppUser, string>({
      query: (id) => ({
        url: `/users/${id}`,
      }),
      providesTags: (result, error, id) => {
        return [{ type: UsersTags.Users, id }];
      },
    }),
    updateUser: builder.mutation<AppUser, UpdateUserRequest>({
      query: ({ id, body }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getUserById', id, (draft) => ({
            ...draft,
            ...patch.body,
          })),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateUserLocation: builder.mutation<void, UpdateUserLocationRequest>({
      query: ({ user_id, body }) => ({
        url: `/users/${user_id}/location`,
        method: 'POST',
        body,
      }),
    }),
    deleteUserAccount: builder.mutation<void, string>({
      query: (id) => ({
        url: `/users/self_deletion/${id}`,
        method: 'DELETE',
        headers: {
          accept: '*/*',
        },
      }),
    }),
    addFeedbackToUserDeletion: builder.mutation<
      void,
      {
        user_id: string;
        email: string;
        feedback_details: { reason: string; note: string };
      }
    >({
      query: ({ user_id, email, feedback_details }) => ({
        url: `/feedback/user_deletion_feedback`,
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: {
          user_id,
          email,
          feedback_details: {
            reason: feedback_details.reason,
            note: feedback_details.note,
          },
        },
      }),
    }),
  }),
});

export const {
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useUpdateUserMutation,
  useUpdateUserLocationMutation,
  useDeleteUserAccountMutation,
  useAddFeedbackToUserDeletionMutation,
} = usersApi;
