import { BasePublicPage } from 'src/v2/pages/auth/BasePublicPage';
import { ResetPasswordFailedSection } from 'src/v2/pages/auth/ResetPasswordFailedPage/components/ResetPasswordFailedSection';
import styles from './ResetPasswordFailedPage.module.scss';
import { AppRoutes } from 'src/types';
import { TextWithLinkSection } from 'src/v2/pages/auth/components/TextWithLinkSection';
import React from 'react';

export const ResetPasswordFailedPage = () => {
  return (
    <BasePublicPage alignContainer="center">
      <div className={styles.mainSection}>
        <ResetPasswordFailedSection />
        <TextWithLinkSection
          text="Back to"
          linkText="Login"
          linkRoute={AppRoutes.LOGIN}
        />
      </div>
    </BasePublicPage>
  );
};
