import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { LinkSimple, Check } from '@phosphor-icons/react';
import { toast } from 'react-toastify';
import { FacebookIcon } from 'src/images/icons/facebook';
import { LinkedinIcon } from 'src/images/icons/linkedin';
import { XIcon } from 'src/images/icons/twitter';
import { WhatsappIcon } from 'src/images/icons/whatsapp';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent } from 'src/types';
import { EnvelopeIcon } from 'src/images/icons/envelope';

const ICON_SIZE = 24;
const ICON_SIZE_SMALL = 20;

interface ShareLinksProps {
  link?: string;
}

export const ShareLinks = ({ link }: ShareLinksProps) => {
  const handleCopyButtonClick = () => {
    if (link) {
      navigator.clipboard.writeText(link);
    }

    toast(
      <div className="nj-copy-snackbar-content">
        <Check size={ICON_SIZE} />
        <span>Copied</span>
      </div>,
      {
        className: 'nj-copy-snackbar',
      },
    );
  };

  if (!link) {
    return null;
  }

  // (olha): it's a workaround to clear up gtm query params from shareable URL
  const handleClick = () => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  const handleGTMEventClick = (event: GTMEvent) => {
    sendGTMEvent(event);
  };

  return (
    <>
      <div className="nj-share--copy-block">
        <button className="nj-share--copy-field-link" onClick={handleClick}>
          {link.replace('https://', '')}
        </button>

        <button
          className="nj-share--copy-button"
          type="button"
          onClick={handleCopyButtonClick}
        >
          <LinkSimple size={ICON_SIZE_SMALL} /> Copy link
        </button>
      </div>

      <hr className="divider" />

      <div className="nj-share--buttons-block">
        <EmailShareButton type="button" url={link}>
          <EnvelopeIcon />
        </EmailShareButton>

        <FacebookShareButton
          type="button"
          url={link}
          onClick={() => {
            handleGTMEventClick(GTMEvent.SHARE_FACEBOOK);
          }}
        >
          <FacebookIcon />
        </FacebookShareButton>

        <LinkedinShareButton
          type="button"
          url={link}
          onClick={() => {
            handleGTMEventClick(GTMEvent.SHARE_LINKEDIN);
          }}
        >
          <LinkedinIcon />
        </LinkedinShareButton>

        <TwitterShareButton
          type="button"
          url={link}
          onClick={() => {
            handleGTMEventClick(GTMEvent.SHARE_X);
          }}
        >
          <XIcon />
        </TwitterShareButton>

        <WhatsappShareButton
          type="button"
          url={link}
          onClick={() => {
            handleGTMEventClick(GTMEvent.SHARE_WHATSAPP);
          }}
        >
          <WhatsappIcon />
        </WhatsappShareButton>
      </div>
    </>
  );
};
