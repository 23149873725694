import { useMemo, ReactNode } from 'react';
import { useCollapse } from 'react-collapsed';
import { DotsThree } from '@phosphor-icons/react';
import {
  useBreakpoint,
  useRightPanelState,
  useLeftPanelState,
} from 'src/hooks';
import styles from './CollapsibleReferencesList.module.scss';

interface CollapsibleReferencesListProps<T> {
  references: T[];
  renderItem: (item: T & { index: number }) => ReactNode;
  title?: string;
}

export const CollapsibleReferencesList = <T,>({
  references,
  renderItem,
  title = 'References',
}: CollapsibleReferencesListProps<T>) => {
  const { isMobile, isTablet, isDesktop } = useBreakpoint();
  const { isSettingsPanelExpanded } = useRightPanelState();
  const { isTasksListExpanded } = useLeftPanelState();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: true,
  });

  const defaultSourceLength = useMemo(() => {
    if (isMobile) {
      return 2;
    }
    if (isTablet) {
      return 3;
    }
    if ((isTasksListExpanded || isSettingsPanelExpanded) && isDesktop) {
      return 3;
    }
    return 4;
  }, [
    isMobile,
    isTablet,
    isTasksListExpanded,
    isDesktop,
    isSettingsPanelExpanded,
  ]);

  const isCollapsed = references.length > defaultSourceLength;

  return (
    <div className={styles.root}>
      <h6 className={styles.title}>
        {title} ({references.length})
      </h6>

      <div className={styles.list}>
        {references
          .slice(0, defaultSourceLength)
          .map((source, index) => renderItem({ ...source, index }))}

        {isCollapsed && !isExpanded && (
          <button {...getToggleProps()} className={styles.toggleButton}>
            <DotsThree size={20} />
          </button>
        )}
      </div>

      {isCollapsed && (
        <div {...getCollapseProps()}>
          <div className={styles.list}>
            {references
              .slice(defaultSourceLength)
              .map((source, index) =>
                renderItem({ ...source, index: index + defaultSourceLength }),
              )}

            <button {...getToggleProps()} className={styles.toggleButton}>
              View less
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
