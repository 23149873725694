import { Helmet } from 'react-helmet-async';

const MetaTags = () => {
  const isProd = window.location.hostname === 'myninja.ai';

  if (isProd) {
    return null;
  }

  return (
    <Helmet prioritizeSeoTags>
      <meta name="robots" content="noindex" />
    </Helmet>
  );
};

export default MetaTags;
