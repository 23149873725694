import { Theme } from 'react-select';

export const darkMode = (theme: Theme): Theme => ({
  ...theme,
  borderRadius: 8,
  colors: {
    ...theme.colors,
    primary: 'rgba(12, 197, 255, 0.12)', // selected
    primary25: 'rgba(12, 197, 255, 0.12)', // hover
    primary50: 'rgba(12, 197, 255, 0.12)', // active
    neutral0: '#161e22', // Customize the background color
    neutral5: '#161e22', // Customize the background color
    neutral10: '#161e22', // Customize the background color
    neutral80: '#bbbfc0', // Customize the text color
    neutral90: '#0CC5FF',
    danger: '#b33129',
    dangerLight: '#de6b64',
  },
});

export const lightMode = (theme: Theme): Theme => ({
  ...theme,
  borderRadius: 8,
  colors: {
    ...theme.colors,
    primary: 'rgba(10, 116, 255, 0.12)', // selected
    primary25: 'rgba(10, 116, 255, 0.12)', // hover
    primary50: 'rgba(10, 116, 255, 0.12)', // active
    neutral0: '#f6f7f7', // Customize the background color
    neutral80: '#1D282D', // Customize the text color
    neutral90: '#0A74FF',
    danger: '#b33129',
    dangerLight: '#de6b64',
  },
});

// preloading skeleton styles for messages
export const UIColors = {
  lightMode: {
    SKELETON_BACKGROUND: 'rgba(214, 216, 216, 1)',
    SKELETON_HIGHLIGHT: 'rgba(246, 247, 247, 0.5)',
  },
  darkMode: {
    SKELETON_BACKGROUND: 'rgb(52, 62, 66)',
    SKELETON_HIGHLIGHT: 'rgba(187, 191, 192, 0.2)',
  },
};
