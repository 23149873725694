import React, { useMemo } from 'react';
import GoogleLogo from 'src/images/logos/auth/google-logo.svg';
import FacebookLogo from 'src/images/logos/auth/facebook-logo.svg';
import MicrosoftLogo from 'src/images/logos/auth/microsoft-logo.svg';
import LinkedInLogo from 'src/images/logos/auth/LI-logo.svg';
import AppleLogo from 'src/images/logos/auth/apple-logo.svg';
import AmazonLogo from 'src/images/logos/auth/amazon-white-logo.svg';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent, SSOProvider } from 'src/types';
import { signInWithRedirect } from 'aws-amplify/auth';
import styles from './SSOButton.module.scss';

type SSOButtonProps = {
  ssoProvider: SSOProvider;
  showInProd?: boolean;
  isSignUp?: boolean;
  setAuthLoading: (value: boolean) => void;
  showFullButton?: boolean;
};

export const SSOButton = ({
  ssoProvider,
  showInProd = true,
  isSignUp = true,
  setAuthLoading,
  showFullButton = false,
}: SSOButtonProps) => {
  const showLoginButton =
    process.env.REACT_APP_ENVIRONMENT !== 'production' ? true : showInProd;

  const ssoData = useMemo(() => {
    switch (ssoProvider) {
      case SSOProvider.GOOGLE:
        return {
          icon: GoogleLogo,
          displayName: 'Google',
          gtmEvent: GTMEvent.GOOGLE_SSO_SIGNUP_CLICKS,
        };
      case SSOProvider.AMAZON:
        return {
          icon: AmazonLogo,
          displayName: 'Amazon',
          gtmEvent: GTMEvent.AMAZON_SSO_SIGNUP_CLICKS,
        };
      case SSOProvider.APPLE:
        return {
          icon: AppleLogo,
          displayName: 'Apple',
          gtmEvent: GTMEvent.APPLE_SSO_SIGNUP_CLICKS,
        };
      case SSOProvider.FACEBOOK:
        return {
          icon: FacebookLogo,
          displayName: 'Facebook',
          gtmEvent: GTMEvent.FACEBOOK_SSO_SIGNUP_CLICKS,
        };
      case SSOProvider.MICROSOFT:
        return {
          icon: MicrosoftLogo,
          displayName: 'Microsoft',
          gtmEvent: GTMEvent.MICROSOFT_SSO_SIGNUP_CLICKS,
        };
      case SSOProvider.LINKEDIN:
        return {
          icon: LinkedInLogo,
          displayName: 'LinkedIn',
          gtmEvent: GTMEvent.LINKED_IN_SSO_SIGNUP_CLICKS,
        };
      default:
        throw new Error('Unsupported SSOProvider');
    }
  }, [ssoProvider]);

  const handleLoginClick = async () => {
    sendGTMEvent(ssoData.gtmEvent);

    setAuthLoading(true);
    signInWithRedirect({
      provider: {
        custom: ssoProvider,
      },
    });
  };

  if (!showLoginButton) return <></>;

  return (
    <button
      className={showFullButton ? styles.full : styles.simple}
      onClick={handleLoginClick}
      data-e2e={`sso-button-${ssoProvider}`}
    >
      <img
        className={styles.inner}
        src={ssoData.icon}
        alt={ssoData.displayName}
      />
      {showFullButton && <span>Sign up with Google</span>}
    </button>
  );
};
