import styles from './ResetPasswordForm.module.scss';
import { PasswordFormInput } from 'src/v2/components/PasswordFormInput';
import { FormInput } from 'src/components/FormInput';
import { FormProvider } from 'src/components/FormProvider';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { confirmResetPassword } from 'aws-amplify/auth';
import { ServiceError } from '@aws-amplify/core/src/types/errors';
import { useQueryParams } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';
import { ErrorMessageBlock } from 'src/pages/AuthPages/components/ErrorMessageBlock';
import { PASSWORDS_DO_NOT_MATCH_MESSAGE } from 'src/constants';

enum Fields {
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirm_password',
}

interface FormData {
  [Fields.PASSWORD]: string;
  [Fields.CONFIRM_PASSWORD]: string;
}

export const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const { searchParams } = useQueryParams();
  const { username } = searchParams;
  const confirmationCode = searchParams.code;

  const methods = useForm<FormData>({
    defaultValues: {
      [Fields.PASSWORD]: '',
      [Fields.CONFIRM_PASSWORD]: '',
    },
    mode: 'onChange',
    delayError: 2000,
  });
  const {
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting, errors },
    getValues,
  } = methods;

  const confirmPasswordValidator = useCallback(
    (data: string) => {
      if (data !== getValues(Fields.PASSWORD)) {
        return PASSWORDS_DO_NOT_MATCH_MESSAGE;
      }
      return true;
    },
    [getValues],
  );

  const confirmPasswordValidationRules = useMemo(() => {
    return {
      validate: confirmPasswordValidator,
    };
  }, [confirmPasswordValidator]);

  const submitForm = async (data: FormData) => {
    try {
      if (!username || !confirmationCode) {
        return;
      }
      await confirmResetPassword({
        username,
        confirmationCode,
        newPassword: data[Fields.PASSWORD],
      });
      navigate(`${AppRoutes.LOGIN_WITH_EMAIL}?password-changed=true`);
    } catch (error) {
      switch ((error as ServiceError).name) {
        case 'ExpiredCodeException':
          navigate(`${AppRoutes.PASSWORD_RESET_FAILED}?username=${username}`);
          return;
        default:
          setError('root', {
            message: error ? (error as ServiceError).message : 'error',
          });
          return;
      }
    }
  };

  return (
    <FormProvider<FormData> methods={methods}>
      <form onSubmit={handleSubmit(submitForm)} className={styles.root}>
        <div className={styles.formRow}>
          <legend className={styles.legend}>Password</legend>

          <PasswordFormInput
            name={Fields.PASSWORD}
            className={styles.inputField}
            placeholder="Create password"
            errorRenderType="inline"
          />
        </div>
        <div className={styles.formRow}>
          <legend className={styles.legend}>Confirm password</legend>
          <FormInput
            name={Fields.CONFIRM_PASSWORD}
            type="password"
            className={styles.inputField}
            placeholder="Confirm password"
            required={true}
            rules={confirmPasswordValidationRules}
            errorRenderType="inline"
          />
        </div>
        <button
          className={styles.submitButton}
          type="submit"
          disabled={!isValid || isSubmitting}
        >
          <span>Reset password</span>
        </button>
        {!!errors.root && (
          <ErrorMessageBlock errorMessage={errors.root.message} />
        )}
      </form>
    </FormProvider>
  );
};
