import store, { persistor, resetStore } from 'src/store';
import { GUEST_ACCESS_TOKEN } from 'src/constants';
import { apiServices } from 'src/store/services';

export const resetSearchParamsByLocationHref = () => {
  const currentUrl = new URL(window.location.href);

  currentUrl.search = '';

  window.history.replaceState({}, '', currentUrl.toString());
};

export const resetAllStoredData = async () => {
  apiServices.forEach((api) => {
    store.dispatch(api.util.resetApiState());
  });

  localStorage.removeItem(GUEST_ACCESS_TOKEN);

  await persistor.purge();

  store.dispatch(resetStore());

  resetSearchParamsByLocationHref();
};
