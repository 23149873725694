import { useMemo } from 'react';
import { Warning } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import { SVG_SIZE_L } from 'src/constants';
import { useSession, useTimeZone } from 'src/hooks';
import { DATE_RANGE_STRING_FORMAT } from '../TaskScratchpad/components/CreateMeetingCard/constants/defaultValues';

interface UserRateLimitedBannerProps {
  isLimitedUntil?: boolean;
}

export const UserRateLimitedBanner = ({
  isLimitedUntil,
}: UserRateLimitedBannerProps) => {
  const { appUser } = useSession();
  const { userTimeZone } = useTimeZone();

  const limitedTime = useMemo(
    () =>
      dayjs(appUser.rate_limited_until)
        .tz(userTimeZone)
        .format(DATE_RANGE_STRING_FORMAT),
    [appUser.rate_limited_until, userTimeZone],
  );

  return (
    <div className="nj-common-banner">
      <div className="nj-common-banner--left-side-wrapper">
        <Warning size={SVG_SIZE_L} weight="fill" />
        <div className="nj-common-banner--left-side-wrapper--notification-wrapper">
          <h5 className="nj-common-banner--title">
            {isLimitedUntil
              ? `You have hit a rate limit until ${limitedTime}. Please try again later.`
              : 'You are currently rate limited due to excessive usage until your next billing cycle.'}
          </h5>
        </div>
      </div>
    </div>
  );
};
