import {
  SignOutButton,
  SupportButton,
} from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons';
import styles from './UserMenuTooltip.module.scss';
import { useSession } from 'src/hooks';
import { UserMenuMainInfo } from '../UserMenuMainInfo';
import { WalletSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { ThemeSelector } from '../ThemeSelector';
import { SettingsLink } from '../SettingsLink';
import { UpsellMessage } from 'src/components/UpsellMessage';

interface UserMenuTooltipProps {
  onClose: () => void;
}

export const UserMenuTooltip = ({ onClose }: UserMenuTooltipProps) => {
  const {
    isOpenTier,
    isSubscribedTier,
    isStandardTier,
    isProTier,
    isTeamsTier,
  } = useSession();

  return (
    <div className={styles.root}>
      <UserMenuMainInfo />

      <div onClick={onClose}>
        {!isSubscribedTier && !isTeamsTier && (
          <div className={styles.walletWrapper}>
            <WalletSection />
          </div>
        )}

        {(isStandardTier || isProTier) && (
          <UpsellMessage
            title="Unlimited external AI Models"
            description="Get answers from external models from companies like OpenAI, Google, and Anthropic"
            className={styles.upsellMessage}
            dataGTMNinjaTier="Settings-upgrade"
            dataGTMOpenTier="Settings-wallet-section-signup"
          />
        )}

        <SettingsLink />

        <ThemeSelector />

        <SupportButton />
      </div>

      {isOpenTier ? (
        <GuestAuthLinks
          dataGTM="Settings-auth-buttons-signup"
          className={styles.authLinks}
        />
      ) : (
        <SignOutButton />
      )}
    </div>
  );
};
