import { useContext } from 'react';
import { SquareHalf } from '@phosphor-icons/react';
import classNames from 'classnames';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { Button } from 'src/components/Button';

interface CCButtonProps {
  iconSize: number;
}

export const CCButton = ({ iconSize }: CCButtonProps) => {
  const { toggleTranscript, showTranscript } = useContext(AvatarModeContext);

  const handleClick = () => {
    toggleTranscript(!showTranscript);
  };

  return (
    <Button
      className={classNames('nj-meta-human--button-control', 'cc', {
        selected: showTranscript,
      })}
      onClick={handleClick}
    >
      <SquareHalf
        size={iconSize}
        weight={showTranscript ? 'fill' : 'duotone'}
      />
    </Button>
  );
};
