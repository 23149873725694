import { useRef, useState, useMemo } from 'react';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { FileObject } from 'src/types';
import { AttachedFileItem } from '../AttachedFileItem';
import styles from './AttachmentContainer.module.scss';
import { AttachmentCarouselArrow } from '../AttachmentCarouselArrow';

interface AttachmentContainerProps {
  files: {
    [key: string]: FileObject | null;
  };
}

export const AttachmentContainer = ({ files }: AttachmentContainerProps) => {
  const [isBeginning, setIsBeginning] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean | null>(null);

  const swiperRef = useRef<SwiperClass | null>(null);

  const handleResetArrowState = (swiper: SwiperClass) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const handleReachBeginning = (swiper: SwiperClass) => {
    setIsBeginning(true);
    setIsEnd(swiper.isEnd);
  };

  const handleReachEnd = (swiper: SwiperClass) => {
    setIsEnd(true);
    setIsBeginning(swiper.isBeginning);
  };

  const handleInit = (swiper: SwiperClass) => {
    swiperRef.current = swiper;

    if (!swiper.isLocked) {
      setIsEnd(false);
    } else {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  };

  const swiperKey = useMemo(() => Object.keys(files).join('-'), [files]);

  if (Object.keys(files).length === 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      <AttachmentCarouselArrow
        direction="prev"
        visible={!isBeginning}
        onClick={() => swiperRef.current?.slidePrev()}
      />

      <Swiper
        key={swiperKey}
        init={false}
        spaceBetween={4}
        slidesPerView="auto"
        onFromEdge={handleResetArrowState}
        onInit={handleInit}
        onReachEnd={handleReachEnd}
        onReachBeginning={handleReachBeginning}
      >
        {Object.keys(files).map((key) => {
          return (
            <SwiperSlide key={key}>
              <AttachedFileItem
                fileId={key}
                fileObject={files[key] as FileObject}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <AttachmentCarouselArrow
        direction="next"
        visible={isEnd === false}
        onClick={() => swiperRef.current?.slideNext()}
      />
    </div>
  );
};
