import { MessageSkill, RecommendationSkill } from 'src/types';
import { TaskSkill } from 'src/types';
import { useState, useEffect } from 'react';
import PillarsBlendData from 'src/data/skill-examples.json';
import type { Example } from '../PillarsBlendTile';
import recommendations from 'src/data/recommendations.json';
import { useQueryParams } from 'src/hooks';

type PillarBlend = {
  [key in RecommendationSkill | string]: Example[];
};

type PillarBlendSet = {
  [key in MessageSkill | string]: Example;
};

const RECOMMENDATIONS: PillarBlend = JSON.parse(
  JSON.stringify(recommendations),
);

const PILLARS_BLEND_DATA: PillarBlend = JSON.parse(
  JSON.stringify(PillarsBlendData),
);

const agentToTaskSkill = (key: string): TaskSkill => {
  switch (key) {
    case 'coder':
      return 'coding' as TaskSkill;
    case 'writer':
      return 'chitchat' as TaskSkill;
    case 'scheduler':
      return 'scheduling' as TaskSkill;
    case 'researcher':
      return 'research' as TaskSkill;
    case 'image':
      return 'image' as TaskSkill;
    default:
      throw new Error(`Unknown key: ${key}`);
  }
};

const createRandomPillarSet = () => {
  const randomSet = {} as PillarBlendSet;
  for (const [key, value] of Object.entries(PILLARS_BLEND_DATA)) {
    const randomValue = value[Math.floor(Math.random() * value.length)];
    randomValue.agent = agentToTaskSkill(key);
    randomSet[key as MessageSkill] = randomValue;
  }
  return randomSet;
};

// TODO(olha): move to separate file as a hook
export function useRecommendedSkills(skillsUpdateTrigger: number) {
  const { searchParams } = useQueryParams();
  const recommendation = searchParams.recommendation?.toUpperCase();

  const [randomPillarSet, setRandomPillarSet] = useState<PillarBlendSet>(
    createRandomPillarSet,
  );
  useEffect(() => {
    if (skillsUpdateTrigger) {
      setRandomPillarSet(createRandomPillarSet);
    }
  }, [skillsUpdateTrigger]);

  //if recommendation exists, and is recognized and valid use it, else default behavior
  if (recommendation && recommendation in RECOMMENDATIONS) {
    return RECOMMENDATIONS[recommendation];
  } else {
    return Object.values(randomPillarSet);
  }
}
