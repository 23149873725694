import styles from './PasswordFormInput.module.scss';
import { FormInput, InputProps } from 'src/components/FormInput';
import { useCallback, useMemo, useState } from 'react';
import {
  COGNITO_PASSWORD_NUMBER_OF_CHARS_RULE,
  COGNITO_PASSWORD_NUMBER_SPECIAL_CHARS_RULE,
  COGNITO_PASSWORD_UPPER_AND_LOWER_CHARS_RULE,
} from 'src/constants/cognito';
import classNames from 'classnames';
import { PASSWORD_DOES_NOT_MEET_REQUIREMENTS_MESSAGE } from 'src/constants';

export const PasswordFormInput = ({
  rules,
  onChangeHandler,
  ...rest
}: InputProps) => {
  const [passwordRulesState, setPasswordRulesState] = useState<
    Record<number, boolean>
  >({});

  const passwordRulesValidator = useCallback(
    (data: string) => {
      setPasswordRulesState({
        0: COGNITO_PASSWORD_NUMBER_OF_CHARS_RULE.test(data),
        1: COGNITO_PASSWORD_UPPER_AND_LOWER_CHARS_RULE.test(data),
        2: COGNITO_PASSWORD_NUMBER_SPECIAL_CHARS_RULE.test(data),
      });
      if (onChangeHandler) {
        onChangeHandler(data);
      }
    },
    [onChangeHandler],
  );

  const passwordValidator = useCallback((data: string) => {
    if (
      !COGNITO_PASSWORD_NUMBER_OF_CHARS_RULE.test(data) ||
      !COGNITO_PASSWORD_UPPER_AND_LOWER_CHARS_RULE.test(data) ||
      !COGNITO_PASSWORD_NUMBER_SPECIAL_CHARS_RULE.test(data)
    ) {
      return PASSWORD_DOES_NOT_MEET_REQUIREMENTS_MESSAGE;
    }
    return true;
  }, []);

  const passwordValidationRules = useMemo(() => {
    return {
      ...(rules || {}),
      validate: passwordValidator,
    };
  }, [rules, passwordValidator]);

  return (
    <div className={styles.root}>
      <ul className={styles.passwordRules}>
        <li
          className={classNames({
            [styles.passwordRulePassed]: passwordRulesState[0],
          })}
        >
          Minimum 8 characters
        </li>
        <li
          className={classNames({
            [styles.passwordRulePassed]: passwordRulesState[1],
          })}
        >
          Include uppercase and lowercase letters
        </li>
        <li
          className={classNames({
            [styles.passwordRulePassed]: passwordRulesState[2],
          })}
        >
          Include numbers and special characters
        </li>
      </ul>

      <FormInput
        type="password"
        autoComplete="new-password"
        required={true}
        {...rest}
        rules={passwordValidationRules}
        onChangeHandler={passwordRulesValidator}
      />
    </div>
  );
};
