import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvatarQueue, ChatMode } from 'src/types';
import { RootState } from '../index';

interface AvatarState {
  avatarQueue?: AvatarQueue;
  // chatMode we use only for checking if it's an avatar mode. It's why this variable is here.
  chatMode: ChatMode;
}

const initialState: AvatarState = {
  avatarQueue: undefined,
  chatMode: ChatMode.CHAT,
};

export const avatarSlice = createSlice({
  name: 'avatar',
  initialState,
  reducers: {
    setChatMode: (state, action: PayloadAction<ChatMode>) => {
      return { ...state, chatMode: action.payload };
    },
    setAvatarQueue: (state, action: PayloadAction<AvatarQueue | undefined>) => {
      const newQueue = {
        ...(state.avatarQueue || {}),
        ...(action.payload || {}),
      };
      state.avatarQueue = newQueue;
    },
    clearAvatarQueue: (state) => {
      state.avatarQueue = undefined;
    },
  },
});

export const avatarState = (state: RootState) => state.avatar as AvatarState;

export const { setChatMode, setAvatarQueue, clearAvatarQueue } =
  avatarSlice.actions;

export default avatarSlice.reducer;
