import { ListBullets } from '@phosphor-icons/react';
import { useMemo } from 'react';
import { TaskStatusIcon } from 'src/components/TaskStatusIcon';
import { SVG_SIZE_M } from 'src/constants';
import { Action, ActionStatue, TaskState } from 'src/types';
import styles from './ProgressStatus.module.scss';

interface ProgressStatusProps {
  actions?: Action[];
  taskState: TaskState;
}

export const ProgressStatus = ({
  actions = [],
  taskState,
}: ProgressStatusProps) => {
  const completedActionsLength = useMemo(
    () =>
      actions.filter((item) => item.status === ActionStatue.COMPLETED).length,
    [actions],
  );

  const progressPercent = useMemo(
    () =>
      completedActionsLength > 0 && actions.length > 0
        ? Math.ceil((completedActionsLength / actions.length) * 100)
        : 0,
    [completedActionsLength, actions.length],
  );

  if (taskState === TaskState.IN_PROGRESS) {
    return <span>{`${progressPercent}%`}</span>;
  }

  if (taskState === TaskState.DONE) {
    return <ListBullets size={SVG_SIZE_M} />;
  }

  return <TaskStatusIcon className={styles.icon} taskState={taskState} />;
};
