import { Tooltip, PlacesType } from 'react-tooltip';
import styles from './HintTooltip.module.scss';
import classNames from 'classnames';
import { useOutsideClick } from 'src/hooks';
import { useRef } from 'react';

interface HintTooltipProps {
  id: string;
  isOpen: boolean;
  title?: string;
  content: string;
  place?: PlacesType;
  offset?: number;
  sharpCorner?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  onClose: () => void;
  className?: string;
}

export const HintTooltip = ({
  id,
  isOpen,
  title,
  content,
  place = 'left-end',
  offset = 0,
  sharpCorner = 'bottom-right',
  onClose,
  className,
}: HintTooltipProps) => {
  const tooltipRef = useRef(null);

  const handleClose = () => {
    if (isOpen) {
      onClose();
    }
  };

  // TODO(olha): check if this ref doesn't work properly and we should move useOutsideClick to Parent component
  useOutsideClick(tooltipRef, handleClose);

  return (
    <div className={styles.root}>
      <Tooltip
        anchorSelect={`#${id}`}
        noArrow
        className={classNames(styles.tooltip, styles[sharpCorner], className)}
        isOpen={isOpen}
        imperativeModeOnly
        openOnClick
        place={place}
        offset={offset}
      >
        <div ref={tooltipRef} className={styles.content}>
          {title && <p className={styles.title}>{title}</p>}
          <p className={styles.description}>{content}</p>
        </div>
      </Tooltip>
    </div>
  );
};
