/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Avatar Videos status
 */
export enum AvatarVideosStatus {
  PROCESSING = 'processing',
  AVAILABLE = 'available',
  FAILURE = 'failure',
}
