import { createContext, useState } from 'react';
import { useSession, useUserData } from 'src/hooks';
import { CameraIndexId } from 'src/types/models/CameraIndexId';
import { useSessionTimer } from 'src/hooks';
import { AVATAR_SESSION_DURATION_DEFAULT } from 'src/constants';
import { BannerType } from 'src/types';
import { useBanner } from 'src/hooks/useBanner';

export interface MicrophoneTriggerHandlers {
  stopRecording: () => void;
  onToggleAction: () => void;
}

export type AvatarModeContextType = {
  isFullscreen: boolean;
  showTranscript: boolean;
  showAvatarQueueModal: boolean;
  shouldShowAvatarIframe: boolean;
  avatarIsBeingDragged: boolean;
  avatarTimeLeft: number;
  idleTimeLeft: string;
  isAvatarSessionRunning: boolean;
  toggleFullscreen: (shouldExpand?: boolean) => void;
  toggleTranscript: (showCC: boolean) => void;
  toggleCameraAngle: (cameraIndex: number) => void;
  setShowAvatarQueueModal: (showModal: boolean) => void;
  replaceAvatarQueueModalWithBanner: () => void;
  setShouldShowAvatarIframe: (showIframe: boolean) => void;
  setAvatarIsBeingDragged: (isBeingDragged: boolean) => void;
  clearShowAvatarQueue: () => void;
  startAvatarSession: (milliseconds: number) => void;
  setIdleTimeLeft: (remaining: string) => void;
  stopAvatarSession: () => void;
};

type AvatarModeContextProviderProps = {
  children: React.ReactNode;
};

/**
 * AvatarModeContext manages toggle for all avatar components.
 */
const AvatarModeContext = createContext<AvatarModeContextType>({
  isFullscreen: false,
  showTranscript: true,
  showAvatarQueueModal: false,
  shouldShowAvatarIframe: true,
  avatarIsBeingDragged: false,
  avatarTimeLeft: 0,
  idleTimeLeft: '',
  isAvatarSessionRunning: false,
  toggleFullscreen: () => undefined,
  toggleTranscript: () => undefined,
  toggleCameraAngle: () => undefined,
  setShowAvatarQueueModal: () => undefined,
  replaceAvatarQueueModalWithBanner: () => undefined,
  setShouldShowAvatarIframe: () => undefined,
  setAvatarIsBeingDragged: () => undefined,
  clearShowAvatarQueue: () => undefined,
  startAvatarSession: () => undefined,
  stopAvatarSession: () => undefined,
  setIdleTimeLeft: () => undefined,
});

const AvatarModeContextProvider = ({
  children,
}: AvatarModeContextProviderProps) => {
  const { updateUserData } = useUserData();
  const { appUser } = useSession();
  const { addBanner, removeBanner } = useBanner();
  const [isFullscreen, setFullscreen] = useState<boolean>(false);
  const [showTranscript, setShowTranscript] = useState<boolean>(true);
  const [showAvatarQueueModal, setShowAvatarQueueModal] =
    useState<boolean>(false);
  const [shouldShowAvatarIframe, setShouldShowAvatarIframe] =
    useState<boolean>(false);
  const [avatarIsBeingDragged, setAvatarIsBeingDragged] =
    useState<boolean>(false);
  const [idleTimeLeft, setIdleTimeLeft] = useState<string>('');

  const {
    remainingTime: avatarTimeLeft,
    startSession: startAvatarSession,
    stopSession: stopAvatarSession,
    isRunning: isAvatarSessionRunning,
  } = useSessionTimer(AVATAR_SESSION_DURATION_DEFAULT);

  const toggleFullscreen = (shouldExpand?: boolean) => {
    setFullscreen((state) =>
      shouldExpand !== undefined ? shouldExpand : !state,
    );
  };

  const toggleTranscript = (showCC: boolean) => {
    setShowTranscript(showCC);
    updateUserData(
      {
        settings: {
          ...appUser.settings,
          camera_angle_setting: {
            ...appUser.settings?.camera_angle_setting,
            show_cc: showCC,
          },
        },
      },
      false,
    );
  };

  const toggleCameraAngle = (cameraIndex: number) => {
    const newCameraIndex = cameraIndex > 4 ? 0 : cameraIndex;
    updateUserData(
      {
        settings: {
          ...appUser.settings,
          camera_angle_setting: {
            ...appUser.settings?.camera_angle_setting,
            camera_index: newCameraIndex.toString() as CameraIndexId,
          },
        },
      },
      false,
    );
  };

  const replaceAvatarQueueModalWithBanner = () => {
    setShowAvatarQueueModal(false);

    addBanner(BannerType.AVATAR_QUEUE);
  };

  const clearShowAvatarQueue = () => {
    setShowAvatarQueueModal(false);
    removeBanner(BannerType.AVATAR_QUEUE);
    setShouldShowAvatarIframe(false);
  };

  return (
    <AvatarModeContext.Provider
      value={{
        isFullscreen,
        showTranscript,
        showAvatarQueueModal,
        shouldShowAvatarIframe,
        avatarIsBeingDragged,
        avatarTimeLeft,
        idleTimeLeft,
        isAvatarSessionRunning,
        startAvatarSession,
        stopAvatarSession,
        toggleFullscreen,
        toggleTranscript,
        toggleCameraAngle,
        setShowAvatarQueueModal,
        replaceAvatarQueueModalWithBanner,
        setShouldShowAvatarIframe,
        setAvatarIsBeingDragged,
        clearShowAvatarQueue,
        setIdleTimeLeft,
      }}
    >
      {children}
    </AvatarModeContext.Provider>
  );
};

export { AvatarModeContextProvider };
export default AvatarModeContext;
