import { useRef } from 'react';
import { Camera } from '@phosphor-icons/react';
import classNames from 'classnames';
import { FadeTransition } from 'src/animations/FadeTransition';
import { SVG_SIZE_M } from 'src/constants';
import styles from './CaptureActions.module.scss';

interface CaptureActionsProps {
  isCaptureVisible: boolean;
  isConfirmActionsVisible: boolean;
  onCaptureShow: () => void;
  onConfirmActionsShow: () => void;
  onCaptureClick: () => void;
  onRetakeClick: () => void;
  onUploadClick: () => void;
}

export const CaptureActions = ({
  isCaptureVisible,
  isConfirmActionsVisible,
  onCaptureShow,
  onConfirmActionsShow,
  onCaptureClick,
  onRetakeClick,
  onUploadClick,
}: CaptureActionsProps) => {
  const captureButtonRef = useRef<HTMLButtonElement | null>(null);
  const captureConfirmActionsRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={styles.root}>
      <FadeTransition
        inProp={isCaptureVisible}
        nodeRef={captureButtonRef}
        onExited={onConfirmActionsShow}
      >
        <button
          onClick={onCaptureClick}
          className={styles.submitButton}
          ref={captureButtonRef}
        >
          <Camera size={SVG_SIZE_M} />
        </button>
      </FadeTransition>

      <FadeTransition
        inProp={isConfirmActionsVisible}
        nodeRef={captureConfirmActionsRef}
        onExited={onCaptureShow}
      >
        <div
          className={styles.appearingButtonsWrapper}
          ref={captureConfirmActionsRef}
        >
          <button onClick={onRetakeClick} className={styles.resetButton}>
            Retake
          </button>

          <button
            onClick={onUploadClick}
            className={classNames(styles.submitButton, styles.uploadButton)}
          >
            Upload
          </button>
        </div>
      </FadeTransition>
    </div>
  );
};
