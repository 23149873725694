import { createApi } from '@reduxjs/toolkit/query/react';
import { baseMatchmakerQuery } from './config';
import { MatchMakerQueryResponse, isAvatarQueue } from 'src/types';
import log from 'src/utils/logger';

export const matchmakerApi = createApi({
  reducerPath: 'matchmakerApi',
  baseQuery: baseMatchmakerQuery,
  endpoints: (builder) => ({
    getQueue: builder.query<
      MatchMakerQueryResponse,
      { userId: string; avatar_id?: string; environment?: string; camera_index?: string }
    >({
      query: ({ userId, avatar_id, environment, camera_index }) => {
        let url = `/signallingserver?userID=${userId}`;
        if (avatar_id !== undefined) {
          url += `&avatar_id=${avatar_id}`;
        }
        if (environment !== undefined) {
          url += `&environment=${environment}`;
        }
        if (camera_index !== undefined) {
          url += `&camera_index=${camera_index}`;
        }
        log.debug (url);
        return url;
      },
      transformResponse: (response: MatchMakerQueryResponse) => {
        // Typeguarding and converting the response to the right type
        log.debug('signallingserver');
        if (!isAvatarQueue(response)) {
          throw new Error(
            `Invalid matchmaker API response: ${JSON.stringify(response)}`,
          );
        }
        return response;
      },
    }),
  }),
});

export const { useLazyGetQueueQuery } = matchmakerApi;
