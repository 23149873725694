import { ReactNode, useContext } from 'react';
import {
  Check,
  PauseCircle,
  Prohibit,
  Warning,
  Icon,
} from '@phosphor-icons/react';
import SessionContext from 'src/contexts/SessionContext';
import { ChatsFilter } from 'src/types';
import { InProgressIcon } from 'src/components/InProgressIcon';
import { ConversationsControlMenu } from '../ConversationsControlMenu';
import { ConversationsControlPanel } from '../ConversationsControlPanel';
import { ControlMenuItem } from '../ControlMenuItem';
import { ControlPanelItem } from '../ControlPanelItem';

const SVG_SIZE = 20;

export interface FilterControlItem {
  controlItem: ChatsFilter;
  Icon?: Icon;
  Component?: ReactNode;
  withDivider?: boolean;
  isTitle?: boolean;
}

interface ConversationControlProps {
  type: 'panel' | 'menu';
}

export const ConversationControl = ({ type }: ConversationControlProps) => {
  const filterControls: FilterControlItem[] = [
    {
      controlItem: ChatsFilter.ALL,
      withDivider: true,
      isTitle: true,
    },
    {
      controlItem: ChatsFilter.BLOCKED,
      Icon: PauseCircle,
    },
    {
      controlItem: ChatsFilter.DONE,
      Icon: Check,
    },
    {
      controlItem: ChatsFilter.IN_PROGRESS,
      Component: <InProgressIcon size={SVG_SIZE} />,
    },
    {
      controlItem: ChatsFilter.FAILED,
      Icon: Warning,
    },
    {
      controlItem: ChatsFilter.STOPPED,
      Icon: Prohibit,
      withDivider: true,
    },
    {
      controlItem: ChatsFilter.ALL_AND_ARCHIVED,
    },
  ];

  const { chatsFilter, setChatsFilter } = useContext(SessionContext);

  const handleFilterClick = async (filter: ChatsFilter) => {
    setChatsFilter(filter);
  };

  if (type === 'menu') {
    return (
      <ConversationsControlMenu>
        {filterControls.map((item) => (
          <ControlMenuItem
            {...item}
            key={item.controlItem}
            svgSize={SVG_SIZE}
            activeFilter={chatsFilter}
            onClick={handleFilterClick}
          />
        ))}
      </ConversationsControlMenu>
    );
  }

  return (
    <ConversationsControlPanel>
      {filterControls.map((item) => (
        <ControlPanelItem
          key={item.controlItem}
          {...item}
          activeFilter={chatsFilter}
          svgSize={SVG_SIZE}
          onClick={handleFilterClick}
        />
      ))}
    </ConversationsControlPanel>
  );
};
