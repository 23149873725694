export const MetaLogo = ({ size = 20 }: { size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M2.59227 14.5128C2.59227 15.4293 2.79333 16.1328 3.05632 16.5584C3.40104 17.116 3.91515 17.3526 4.43932 17.3526C5.11528 17.3526 5.73374 17.1848 6.92553 15.5364C7.88057 14.2153 9.0054 12.3608 9.76239 11.1982L11.0444 9.22846C11.9349 7.86055 12.9656 6.33993 14.1474 5.30919C15.1125 4.46785 16.153 4.00049 17.2004 4.00049C18.959 4.00049 20.634 5.01956 21.916 6.93085C23.319 9.02399 24 11.6606 24 14.3813C24 15.9989 23.6812 17.1873 23.1387 18.1263C22.6146 19.0343 21.593 19.9416 19.8745 19.9416V17.3522C21.346 17.3522 21.7131 16.0001 21.7131 14.4527C21.7131 12.2477 21.199 9.80068 20.0664 8.05216C19.2628 6.81192 18.2212 6.05412 17.0753 6.05412C15.8358 6.05412 14.8385 6.98905 13.7176 8.65554C13.1216 9.54111 12.5099 10.6202 11.823 11.8379L11.0668 13.1775C9.54796 15.8712 9.16313 16.4844 8.40363 17.4966C7.07251 19.2691 5.93571 19.9412 4.43932 19.9412C2.66405 19.9412 1.54153 19.1724 0.846264 18.014C0.27877 17.0701 0 15.8315 0 14.4202L2.59227 14.5128Z"
          fill="#0081FB"
        />
        <path
          d="M2.04395 7.11312C3.23241 5.28116 4.94756 4 6.91474 4C8.05395 4 9.18672 4.33718 10.3692 5.30287C11.6628 6.35844 13.0416 8.09761 14.7617 10.9627L15.3785 11.9906C16.8674 14.4711 17.7145 15.7473 18.2103 16.349C18.8479 17.1218 19.2944 17.3522 19.8745 17.3522C21.346 17.3522 21.7131 16.0001 21.7131 14.4527L24 14.381C24 15.9985 23.6812 17.187 23.1386 18.1259C22.6146 19.0339 21.593 19.9412 19.8745 19.9412C18.8062 19.9412 17.8598 19.7092 16.8131 18.7218C16.0089 17.964 15.0679 16.6178 14.3443 15.4076L12.1919 11.8121C11.112 10.0077 10.121 8.66229 9.54793 8.05298C8.93107 7.39772 8.13819 6.60655 6.87292 6.60655C5.84892 6.60655 4.97923 7.32514 4.25149 8.42434L2.04395 7.11312Z"
          fill="url(#paint0_linear_7752_14788)"
        />
        <path
          d="M6.87295 6.60655C5.84895 6.60655 4.97926 7.32514 4.25153 8.42434C3.2224 9.97753 2.59278 12.291 2.59278 14.5128C2.59278 15.4292 2.79384 16.1327 3.05682 16.5583L0.846264 18.0139C0.27877 17.07 0 15.8314 0 14.4202C0 11.8537 0.704416 9.17882 2.04398 7.11312C3.23245 5.28116 4.9476 4 6.91477 4L6.87295 6.60655Z"
          fill="url(#paint1_linear_7752_14788)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_7752_14788"
          x1="5.20261"
          y1="11.5575"
          x2="21.7278"
          y2="12.3921"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0064E1" />
          <stop offset="0.4" stopColor="#0064E1" />
          <stop offset="0.83" stopColor="#0073EE" />
          <stop offset="1" stopColor="#0082FB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7752_14788"
          x1="3.45734"
          y1="15.6011"
          x2="3.45734"
          y2="9.50846"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0082FB" />
          <stop offset="1" stopColor="#0064E0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
