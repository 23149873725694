import styles from './PaypalPaymentInProgressPanel.module.scss';
import { PAYPAL_PAYMENT_IN_PROGRESS_MESSAGE } from 'src/constants';
import { SpinnerSimple } from 'src/v2/commonComponents/SpinnerSimple';

// TODO: stan - temporary fix
export const PaypalPaymentInProgressPanel = () => {
  return (
    <div className={styles.root}>
      <span className={styles.label}>{PAYPAL_PAYMENT_IN_PROGRESS_MESSAGE}</span>
      <SpinnerSimple size={20} />
    </div>
  );
};
