import { CheckSquare, Square } from '@phosphor-icons/react';
import classNames from 'classnames';

type CheckboxButtonProps = {
  selected?: boolean;
  size?: number;
};

const DEFAULT_SVG_SIZE = 24;

/**
 * @deprecated
 */
export const CheckboxChoiceButton = ({
  size = DEFAULT_SVG_SIZE,
  selected = false,
}: CheckboxButtonProps) => {
  return (
    <span className={classNames({ checked: selected })}>
      {selected ? (
        <CheckSquare size={size} weight="fill" />
      ) : (
        <Square size={size} />
      )}
    </span>
  );
};
