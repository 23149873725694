import { ChangeEvent, useId } from 'react';
import classNames from 'classnames';
import { Check, Icon as IconType } from '@phosphor-icons/react';
import styles from './SwitchSimple.module.scss';

interface SwitchSimpleProps {
  isChecked: boolean;
  isDisabled?: boolean;
  Icon?: IconType;
  className?: string;
  onChange: (value: boolean) => void;
}

export const SwitchSimple = ({
  isChecked,
  onChange,
  isDisabled = false,
  Icon = Check,
  className,
}: SwitchSimpleProps) => {
  const uniqueId = useId();

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!isDisabled) {
      onChange(target.checked);
    }
  };

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.switchOn]: isChecked,
        [styles.disabled]: isDisabled,
      })}
    >
      <input
        id={uniqueId}
        checked={isChecked}
        className={styles.input}
        type="checkbox"
        disabled={isDisabled}
        onChange={handleChange}
      />
      <label htmlFor={uniqueId} className={styles.label}>
        <span className={styles.slider}>
          {isChecked && <Icon weight="bold" size={14} />}
        </span>
      </label>
    </div>
  );
};
