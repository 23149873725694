import { useState } from 'react';
import { addUserIdToGTMDataLayer, getErrorMessage } from 'src/utils';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useSession, useUserLocation } from 'src/hooks';
import log from 'src/utils/logger';
import { LocationSource } from 'src/types';
import { useLazyGetUserByIdQuery } from 'src/store/services';

export const useAuthUser = () => {
  const { appUser } = useSession();
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [triggerGetUserById] = useLazyGetUserByIdQuery();

  const { setLocationInterval } = useUserLocation();

  const fetchUserInfo = async (userId: string) => {
    try {
      if (!appUser?.user_id) {
        setIsUserLoading(true);
      }

      const { data: userData } = await triggerGetUserById(userId, true);
      let tier_id = 'unknown';
      // set type to the resultAction
      if (userData?.location?.source === LocationSource.GPS) {
        setLocationInterval();
      }

      if (userData?.tier_id) {
        tier_id = userData.tier_id;
      }

      datadogLogs.logger.info('User logged in', {
        user_id: userId,
        tier_id: tier_id,
      });

      if (userData) {
        const { user_id, tier_id, idp, email, first_name, last_name } =
          userData;

        // Google Tag Manager add user_id, tier_id and login_type to dataLayer
        addUserIdToGTMDataLayer({
          user_id,
          tier_id,
          login_type: idp,
          user_email: email,
          user_first_name: first_name || '',
          user_last_name: last_name || '',
        });
      }

      // Set user data in session
      datadogRum.setUser({
        // Use the user ID from Cognito, it seems there is a delay
        // for appUser.user_id to be available
        id: userId,
        tier_id: tier_id,
        // name: getFullName(appUser),
        // email: appUser?.email
      });

      setIsUserLoading(false);
    } catch (e: unknown) {
      datadogLogs.logger.error(getErrorMessage(e), {
        user_id: appUser?.user_id,
      });
      log.error(e);
      setIsUserLoading(false);
    }
  };

  return {
    fetchUserInfo,
    isUserLoading,
  };
};
