import classNames from 'classnames';
import { ApiContact, Attendee } from 'src/types';
import { getInitials } from 'src/utils';
import { Icon } from 'src/components/Icon';
import { useQuickPickerControls } from 'src/hooks';

const SVG_SIZE = 32;

interface AttendeePickerProps {
  isOpen: boolean;
  query?: string;
  contacts: ApiContact[];
  shouldDisablePickerSubmit: boolean;
  onClose: () => void;
  onSelect: (attendee: Attendee) => void;
}

export const AttendeePicker = ({
  query = '',
  isOpen,
  contacts,
  shouldDisablePickerSubmit,
  onClose,
  onSelect,
}: AttendeePickerProps) => {
  const handleContactClick = (index: number) => {
    if (shouldDisablePickerSubmit) {
      return;
    }

    const contact = contacts[index];
    const { email, first_name, last_name } = contact;
    const newAttendee: Attendee = {
      email: email,
      name: `${first_name} ${last_name || ''}`.trim(),
    };
    onSelect(newAttendee);
  };

  const { selectedIndex } = useQuickPickerControls({
    options: contacts,
    isOpen,
    onClose,
    onItemClick: handleContactClick,
  });

  if (!isOpen || !query || contacts.length === 0) {
    return null;
  }

  return (
    <div className="nj-thread-input-box--quick-picker contacts" role="menu">
      {contacts.map((contact, index) => (
        <div
          key={index}
          className={classNames('nj-thread-input-box--quick-picker-item', {
            selected: index === selectedIndex,
          })}
          onMouseDown={(e) => {
            e.preventDefault();
            handleContactClick(index);
          }}
          data-testid="thread-input-box-quick-picker-item"
        >
          <Icon
            alt={getInitials(contact)}
            size={SVG_SIZE}
            // TODO(olha): There is a bug with changing color while searching. should fix later
            masterColor={contact.color}
          />

          <div>
            <p className="nj-thread-input-box--quick-picker-title">{`${contact.first_name} ${contact.last_name}`}</p>
            <p className="nj-thread-input-box--quick-picker-subtitle">
              {contact.email}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
