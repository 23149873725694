import classNames from 'classnames';

type AudioWaveProps = {
  interactive?: boolean;
};

export const AudioWave = ({ interactive = false }: AudioWaveProps) => {
  const audioWaveClasses = classNames('nj-audio-wave', {
    'nj-audio-wave--interactive': interactive,
    'nj-audio-wave--static': !interactive,
  });

  return (
    <div className={audioWaveClasses}>
      <span className="nj-audio-wave--bar"></span>
      <span className="nj-audio-wave--bar"></span>
      <span className="nj-audio-wave--bar"></span>
      <span className="nj-audio-wave--bar"></span>
    </div>
  );
};
