import { ThumbsDown, ThumbsUp } from '@phosphor-icons/react';
import classNames from 'classnames';
import { MessageType } from 'src/types';
import { useCurrentMessage, useFeedbackThumbs } from 'src/hooks';
import { useCollapse } from 'react-collapsed';
import { MouseEvent } from 'react';

type GetToggleProps = ReturnType<typeof useCollapse>['getToggleProps'];

const SVG_SIZE = 20;

interface MessageThumbsButtonsProps {
  messageId?: string;
  conversationId?: string;
  withTitle?: boolean;
  disabled?: boolean;
  messageType?: MessageType;
  onToggleNegativeFeedbackForm: GetToggleProps;
  onCloseRedoSkillActions: () => void;
  onCloseNegativeFeedbackForm: () => void;
  isShowNegativeFeedbackForm?: boolean;
}

export const MessageThumbsButtons = ({
  messageId,
  conversationId,
  withTitle,
  disabled,
  onToggleNegativeFeedbackForm,
  onCloseRedoSkillActions,
  onCloseNegativeFeedbackForm,
  isShowNegativeFeedbackForm = false,
}: MessageThumbsButtonsProps) => {
  const { onThumbUpClick, onThumbDownClick } = useFeedbackThumbs(messageId);
  const { currentMessage } = useCurrentMessage(messageId);

  const isThumbUpSelected =
    currentMessage?.feedback?.feedback_v2_data?.thumbs_up_down?.thumbs_up;

  const handleNegativeThumbClick = (e: MouseEvent) => {
    onCloseRedoSkillActions();
    onThumbDownClick();
    if (
      isThumbUpSelected !== false ||
      (isThumbUpSelected === false && isShowNegativeFeedbackForm)
    ) {
      onToggleNegativeFeedbackForm().onClick(e);
    }
  };

  const handlePositiveThumbClick = (e: MouseEvent) => {
    onCloseRedoSkillActions();
    onCloseNegativeFeedbackForm();

    onThumbUpClick();

    if (isShowNegativeFeedbackForm) {
      onToggleNegativeFeedbackForm().onClick(e);
    }
  };

  if (!messageId || !conversationId) {
    return null;
  }

  return (
    <div
      data-testid="thumb-buttons"
      className={classNames('nj-feedback-thumb-buttons nondebug-mode', {
        'in-column': withTitle,
      })}
    >
      <button
        onClick={handlePositiveThumbClick}
        aria-label="Click on the thumb up"
        disabled={disabled}
        className={classNames('nj-feedback-thumb-buttons--button', {
          selected: isThumbUpSelected,
        })}
      >
        <ThumbsUp
          weight={isThumbUpSelected ? 'fill' : 'regular'}
          size={SVG_SIZE}
          color="currentColor"
        />
        {withTitle && <span>Good response</span>}
      </button>

      {/* TODO(olha): was moved from other component, includes workaround and needs refactoring */}
      <div>
        <button
          onClick={handleNegativeThumbClick}
          aria-label="Click on the thumb down"
          disabled={disabled}
          className={classNames('nj-feedback-thumb-buttons--button', {
            selected: isThumbUpSelected === false,
          })}
        >
          <ThumbsDown
            weight={isThumbUpSelected === false ? 'fill' : 'regular'}
            size={SVG_SIZE}
            color="currentColor"
          />
          {withTitle && <span>Bad response</span>}
        </button>
      </div>
    </div>
  );
};
