import { useMemo } from 'react';
import { useTimeZone } from 'src/hooks';
import { Span } from 'src/types';
import { formatTimestampToDateAndTime } from 'src/utils';
import { ProposalTimeSlotLabel } from '../ProposalTimeSlotLabel';
import { ProposalTimeSlotStaticValue } from '../ProposalTimeSlotStaticValue';

type CompletedTimeSlotFieldsListProps = {
  timestamp?: string;
  fields: Span[];
};

export const CompletedTimeSlotFieldsList = ({
  timestamp,
  fields,
}: CompletedTimeSlotFieldsListProps) => {
  const { userTimeZone } = useTimeZone();

  const submissionTime = useMemo(
    () => formatTimestampToDateAndTime(timestamp, userTimeZone),
    [timestamp, userTimeZone],
  );

  return (
    <div className="nj-task-view--form completed">
      <div className="nj-task-view--form--block">
        <div className="nj-task-view--form--field">
          <ProposalTimeSlotLabel
            label="Time slots"
            sublabel="Ninja will propose the time slot you provided to guests"
          />
          <div className="nj-task-view--form--block">
            {fields.map((proposal, index) => (
              <ProposalTimeSlotStaticValue
                key={`nj-timeslot-${timestamp}-${index}`}
                proposal={proposal}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="nj-task-view--form--footer center">
        <i
          data-e2e="proposal-confirmation-card-submission-time"
          className="nj-task-view--form--submitted"
        >
          Submitted on {submissionTime}
        </i>
      </div>
    </div>
  );
};
