import { createSlice } from '@reduxjs/toolkit';

/**
 * Creating a logout slice for the whole app.
 */
const logoutSlice = createSlice({
  name: 'logout',
  initialState: null as null | undefined,
  reducers: {
    resetStore: () => undefined,
  },
});

export const { resetStore } = logoutSlice.actions;

export default logoutSlice.reducer;
