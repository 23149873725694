import { useCallback, useMemo } from 'react';
import {
  useUpdateConversationByIdMutation,
  useArchiveAllConversationsMutation,
} from 'src/store/services';
import { useAppSelector, sessionState } from 'src/store';
import { ConversationUpdateRequest } from 'src/types';
import log from 'src/utils/logger';

export const useConversationActions = () => {
  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);

  const [updateConversationById] = useUpdateConversationByIdMutation();

  const handleUpdateConversationById = useCallback(
    async (conversation: Omit<ConversationUpdateRequest, 'user_id'>) => {
      try {
        await updateConversationById({ user_id, ...conversation }).unwrap();
      } catch (error) {
        log.error(error);
      }
    },
    [updateConversationById, user_id],
  );

  const [archiveAllConversations, { isLoading: isArchiveAllLoading }] =
    useArchiveAllConversationsMutation();

  const handleArchiveAllConversations = useCallback(async () => {
    await archiveAllConversations({ user_id }).unwrap();
  }, [user_id, archiveAllConversations]);

  return useMemo(
    () => ({
      isArchiveAllLoading,
      updateConversationById: handleUpdateConversationById,
      archiveAllConversations: handleArchiveAllConversations,
    }),
    [
      isArchiveAllLoading,
      handleUpdateConversationById,
      handleArchiveAllConversations,
    ],
  );
};
