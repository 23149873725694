/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ImageGenSettingsDimensionGroupTypes {
  PRIMARY = 'primary',
  SOCIAL_MEDIA = 'social_media',
  DEVICES = 'devices',
  FILM = 'film',
}
