import { isString, isNumber, isObject } from './general.guard';
import { ReservationDetails } from '../api';
import log from 'src/utils/logger';

/**
 * isReservationDetails() tests data against resevration details.
 * @param data unknown
 * @returns boolean
 */
export function isReservationDetails(
  data: unknown,
): data is ReservationDetails {
  const reservation = data as ReservationDetails;

  if (!isObject(reservation)) {
    log.debug(
      `isReservationDetails > not an object ${JSON.stringify(reservation)}`,
    );
    return false;
  }

  if (
    !(
      'payload_type' in reservation &&
      reservation.payload_type === 'reservation'
    )
  ) {
    return false;
  }

  if (
    !(
      'time_range_minutes' in reservation &&
      isNumber(reservation.time_range_minutes)
    )
  ) {
    log.debug(
      `isReservationDetails > invalid time_range_minutes ${JSON.stringify(
        reservation,
      )}`,
    );
    return false;
  }

  if (!('phone_number' in reservation && isString(reservation.phone_number))) {
    log.debug(
      `isReservationDetails > invalid phone_number ${JSON.stringify(
        reservation,
      )}`,
    );
    return false;
  }

  if (
    !(
      'number_of_people' in reservation &&
      isNumber(reservation.number_of_people)
    )
  ) {
    log.debug(
      `isReservationDetails > invalid number_of_people ${JSON.stringify(
        reservation,
      )}`,
    );
    return false;
  }

  if ('start_time' in reservation && !isString(reservation.start_time)) {
    log.debug(
      `isReservationDetails > invalid start_time ${JSON.stringify(
        reservation,
      )}`,
    );
    return false;
  }

  if (
    'extra_requirements' in reservation &&
    !isString(reservation.extra_requirements)
  ) {
    log.debug(
      `isReservationDetails > invalid extra_requirements ${JSON.stringify(
        reservation,
      )}`,
    );
    return false;
  }

  return true;
}
