import { SubscriptionPlan } from 'src/types';
import { getRoundedPriceWithCurrency } from 'src/utils';
import styles from './SummaryHeader.module.scss';

interface SummaryHeaderProps {
  originalPrice?: number;
  dueToday?: number;
  selectedPlan?: SubscriptionPlan;
  additionalDescription?: string;
}

export const SummaryHeader = ({
  originalPrice = 0,
  dueToday = 0,
  selectedPlan,
  additionalDescription,
}: SummaryHeaderProps) => {
  return (
    <div className={styles.header}>
      <h4 className={styles.topTitle}>Due today</h4>
      <h3 className={styles.title}>{getRoundedPriceWithCurrency(dueToday)}</h3>
      <p className={styles.subtitle}>
        Then {getRoundedPriceWithCurrency(originalPrice)} per{' '}
        {selectedPlan?.period === 'monthly' ? 'month' : 'year'}
        {additionalDescription}
      </p>
    </div>
  );
};
