import { RefObject, useEffect, useMemo, useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  useIntersectionObserver,
  useCurrentConversationTasks,
  useReadMessage,
} from 'src/hooks';

export const useReadMessageByScrolling = (
  anchorRef: RefObject<HTMLDivElement>,
  taskId: string,
) => {
  const { getTaskFromConversationById } = useCurrentConversationTasks();
  const { readMessage } = useReadMessage();
  const { currentScrollingTaskId, setCurrentScrollingTaskId } =
    useContext(SessionContext);

  const isVisible = useIntersectionObserver(anchorRef, { threshold: 1.0 });

  const isTaskRequiresAttention = useMemo(
    () => getTaskFromConversationById(taskId)?.requires_attention || false,
    [taskId, getTaskFromConversationById],
  );

  useEffect(() => {
    if (!isTaskRequiresAttention || !isVisible) {
      return;
    }

    if (!!currentScrollingTaskId && currentScrollingTaskId === taskId) {
      setCurrentScrollingTaskId(null);
      return;
    }

    if (!currentScrollingTaskId) {
      readMessage(taskId);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, isTaskRequiresAttention, taskId, currentScrollingTaskId]);

  return { isTaskRequiresAttention };
};
