import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { MessageTabRegistry } from 'src/types';

export interface MessageTabRegistryPayload {
  messageId: string;
  tabName: string;
  isRead: boolean | null;
}

export interface ThreadState {
  messageTabRegistry: MessageTabRegistry;
}

const initialState = {
  messageTabRegistry: {},
} as ThreadState;

export const threadPersistSlice = createSlice({
  name: 'threadPersist',
  initialState,
  reducers: {
    setMessageTabRegistry: (
      state,
      { payload }: PayloadAction<MessageTabRegistryPayload>,
    ) => {
      state.messageTabRegistry = {
        ...state.messageTabRegistry,
        [payload.messageId]: {
          ...state.messageTabRegistry?.[payload.messageId],
          [payload.tabName]: {
            isRead: payload.isRead,
          },
        },
      };
    },
  },
});

export const messageTabRegistryState = (state: RootState) =>
  state.threadPersist.messageTabRegistry as MessageTabRegistry;

export const { setMessageTabRegistry } = threadPersistSlice.actions;

export default threadPersistSlice.reducer;
