import { useContext } from 'react';
import cn from 'classnames';
import { useBreakpoint } from 'src/hooks';
import { AvatarSelectorTooltip } from './components/AvatarSelectorTooltip';
import SessionContext from 'src/contexts/SessionContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { AvatarImage } from 'src/components/AvatarImage';
import { SVG_SIZE_XL } from 'src/constants';
import { UserGear } from '@phosphor-icons/react';

interface AvatarSelectorButtonProps {
  inVideoSettings?: boolean;
  iconSize?: number;
}

/**
 * @deprecated
 */
export const AvatarSelectorButton = ({
  inVideoSettings,
  iconSize,
}: AvatarSelectorButtonProps) => {
  const { isMobileOrTablet } = useBreakpoint();

  const { onToggleAvatarSelect } = useContext(SessionContext);
  const { avatarsSelectorButtonRef, avatarsSelectorVideoButtonRef } =
    useContext(ForwardRefContext);

  const handleOpenSelector = () => {
    onToggleAvatarSelect();
  };

  return (
    <div>
      <button
        className={cn({
          'nj-meta-human--button-control': inVideoSettings,
        })}
        onClick={handleOpenSelector}
        id="avatar-select"
        ref={
          inVideoSettings
            ? avatarsSelectorVideoButtonRef
            : avatarsSelectorButtonRef
        }
      >
        {inVideoSettings ? (
          <UserGear size={iconSize || SVG_SIZE_XL} />
        ) : (
          <AvatarImage size={iconSize || SVG_SIZE_XL} />
        )}
      </button>

      {!isMobileOrTablet && <AvatarSelectorTooltip />}
    </div>
  );
};
