import { UserImage } from 'src/components/UserImage';
import {
  PAYPAL_PAYMENT_IN_PROGRESS,
  SVG_SIZE_XXL,
  TIER_DATA,
} from 'src/constants';
import { useSession } from 'src/hooks';
import { getFullName } from 'src/utils';
import styles from './UserMenuMainInfo.module.scss';
import { PaypalPaymentInProgressPanel } from 'src/components/PaypalPaymentInProgressPanel/PaypalPaymentInProgressPanel';
import { getWithExpiry } from 'src/utils/localStorage';

export const UserMenuMainInfo = () => {
  const { appUser, isOpenTier, tierId } = useSession();
  const userName = getFullName(appUser);

  const paypalPaymentInProgress =
    getWithExpiry(PAYPAL_PAYMENT_IN_PROGRESS) === 'true';

  return (
    <div className={styles.root}>
      <UserImage size={SVG_SIZE_XXL} />

      <div className={styles.content}>
        <p className={styles.name}>{isOpenTier ? 'Guest' : userName}</p>
        {!isOpenTier && appUser.email && (
          <p className={styles.email}>{appUser.email}</p>
        )}
      </div>

      {TIER_DATA[tierId].labelLong}
      {paypalPaymentInProgress && <PaypalPaymentInProgressPanel />}
    </div>
  );
};
