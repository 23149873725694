import { AppNav, Footer } from 'src/components/AppNav';
import { ConversationsList } from '../ConversationsList/ConversationsList';
import { FilteringChip } from '../FilteringChip';

export const ConversationListMainContent = () => {
  return (
    <div className="nj-manage-tasks" data-testid="first-panel">
      <div className="nj-manage-tasks--content-wrapper nj-beta">
        <AppNav />

        <FilteringChip />

        <ConversationsList />

        <Footer />
      </div>
    </div>
  );
};
