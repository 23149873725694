import { ListBullets } from '@phosphor-icons/react';
import styles from './ToggleManageAccountNavigation.module.scss';

const SVG_SIZE = 20;

interface ToggleManageAccountNavigationProps {
  onClick: () => void;
}

export const ToggleManageAccountNavigation = ({
  onClick,
}: ToggleManageAccountNavigationProps) => {
  return (
    <button onClick={onClick} className={styles.root}>
      <ListBullets size={SVG_SIZE} />
    </button>
  );
};
