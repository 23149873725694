import { Fragment } from 'react';
import { ImageGenStyles } from 'src/types';
import { Prohibit, CaretDown, Circle } from '@phosphor-icons/react';
import { useSession, useBreakpoint, useVisible } from 'src/hooks';
import { imageStyleOptions, SVG_SIZE_M } from 'src/constants';
import { SelectSimple } from 'src/components/SelectSimple/SelectSimple';
import { BottomDrawer } from 'src/components/BottomDrawer';
import styles from './ImageStyleSelect.module.scss';
import classNames from 'classnames';
import { LabelPro } from 'src/components/LabelPro';

interface ImageStyleSelectProps {
  selectedValue?: ImageGenStyles;
  onChange: (value: ImageGenStyles) => void;
  maxMenuHeight?: number;
}

export const ImageStyleSelect = ({
  selectedValue = ImageGenStyles.NONE,
  onChange,
  maxMenuHeight,
}: ImageStyleSelectProps) => {
  const { isOpenTier, isFreeTier } = useSession();
  const { isMobileOrTablet } = useBreakpoint();
  const { isVisible, handleVisibilityRemove, handleVisibilityToggle } =
    useVisible();

  const isDisabled = isOpenTier || isFreeTier;

  const selectedStyle = imageStyleOptions.find(
    (option) => option.value === selectedValue,
  );

  const selectedTitle =
    selectedStyle?.value === ImageGenStyles.NONE ? (
      <>
        <Prohibit size={SVG_SIZE_M} />
        <span>{selectedStyle?.label}</span>
      </>
    ) : (
      selectedStyle?.label
    );

  const handleChange = (newValue: ImageGenStyles) => {
    if (isMobileOrTablet) {
      handleVisibilityToggle();
    }
    if (newValue) {
      onChange(newValue);
    }
  };

  return (
    <div className={classNames(styles.root, { [styles.disabled]: isDisabled })}>
      <label className={styles.label}>
        <span>Style</span>
        {isDisabled && <LabelPro />}
      </label>

      {isMobileOrTablet ? (
        <>
          <button
            className={styles.selectorButton}
            onClick={handleVisibilityToggle}
            disabled={isDisabled}
          >
            <span className={styles.buttonTitle}>{selectedTitle}</span>

            <CaretDown size={SVG_SIZE_M} />
          </button>

          <BottomDrawer isOpen={isVisible} onClose={handleVisibilityRemove}>
            <div>
              {imageStyleOptions.map(({ value, label }) => (
                <Fragment key={value}>
                  <button
                    onClick={() => handleChange(value as ImageGenStyles)}
                    className={classNames(styles.selectorOption, {
                      [styles.active]: value === selectedValue,
                    })}
                  >
                    <span className={styles.optionTitle}>
                      {value === ImageGenStyles.NONE && (
                        <Prohibit size={SVG_SIZE_M} />
                      )}

                      {label}
                    </span>

                    {value === selectedValue && (
                      <Circle size={8} weight="fill" />
                    )}
                  </button>

                  {value === ImageGenStyles.NONE && <hr className="divider" />}
                </Fragment>
              ))}
            </div>
          </BottomDrawer>
        </>
      ) : (
        <SelectSimple<ImageGenStyles>
          options={imageStyleOptions}
          maxMenuHeight={maxMenuHeight}
          value={selectedStyle?.value}
          onChange={handleChange}
          isDisabled={isDisabled}
        />
      )}
    </div>
  );
};
