import dayjs from 'dayjs';
import { FieldValues } from 'react-hook-form';
import { Plus } from '@phosphor-icons/react';
import { Span } from 'src/types';
import { roundToNearestQuarterHour } from 'src/utils';
import { useMeetingCardFieldsWatch } from '../../../../hooks/useMeetingCardFieldsWatch';
import { DEFAULT_MINUTES_GAP } from '../../../../constants/defaultValues';

const SVG_SIZE = 20;

interface AddTimeframeButtonProps {
  onAppend: (dateRange: Span) => void;
}

export const AddTimeframeButton = <T extends FieldValues>({
  onAppend,
}: AddTimeframeButtonProps) => {
  const { minutes, executed } = useMeetingCardFieldsWatch<T>();

  const handleAddTimeframe = () => {
    const startTime = dayjs().add(DEFAULT_MINUTES_GAP, 'minute');

    const start = roundToNearestQuarterHour(startTime).toISOString();

    const end = roundToNearestQuarterHour(
      dayjs(start).add(minutes || DEFAULT_MINUTES_GAP, 'minute'),
    ).toISOString();

    onAppend({ start, end });
  };

  if (executed) {
    return null;
  }

  return (
    <button
      type="button"
      onClick={handleAddTimeframe}
      className="nj-button nj-create-meeting-card--meeting-window-add-button"
    >
      <Plus size={SVG_SIZE} />
      Add timeframe
    </button>
  );
};
