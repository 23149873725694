import { SVGProps } from 'react';

export const FacebookIcon = ({
  width = 24,
  height = 24,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M21.1429 0H2.85714C1.28 0 0 1.28 0 2.85714V21.1429C0 22.72 1.28 24 2.85714 24H21.1429C22.72 24 24 22.72 24 21.1429V2.85714C24 1.28 22.72 0 21.1429 0ZM18.8571 8.57143H17.7143C16.4914 8.57143 16 8.85714 16 9.71429V11.4286H18.8571L18.2857 14.2857H16V22.8571H13.1429V14.2857H10.8571V11.4286H13.1429V9.71429C13.1429 7.42857 14.2857 5.71429 16.5714 5.71429C18.2286 5.71429 18.8571 6.28571 18.8571 6.28571V8.57143Z"
        fill="currentColor"
      />
    </svg>
  );
};
