/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ErrorCode } from './ErrorCode';
import type { TaskSkill } from './TaskSkill';

export type ErrorPayload = {
  payload_type?: ErrorPayload.payload_type;
  error_message?: string;
  error_code: ErrorCode;
  unsupported_skills?: Array<TaskSkill>;
  supported_skills?: Array<TaskSkill>;
};

export namespace ErrorPayload {
  export enum payload_type {
    ERROR_MESSAGE = 'error-message',
  }
}
