import dayjs from 'dayjs';
import { NinjaSubscriptionPlan, WelcomeUpsellModalType } from 'src/types';
import { UserTier } from 'src/types/models/UserTier';

/**
 * @param amount in cents
 * @param discount in dollars
 * @returns price with discount
 */
export const getNewAmountWithDiscount = (amount: number, discount: number) => {
  return amount / 100 - discount;
};

export const getCalculatedSale = (amount: number, discount: number) => {
  return Math.ceil((discount * 100) / (amount / 100));
};

export const getNextUpsellModalTimestamp = (delay: number) => {
  return dayjs().valueOf() + delay;
};

/**
 * @deprecated
 */
export const getUpsellModalType = (
  userTier?: UserTier,
): WelcomeUpsellModalType | null => {
  // if (userTier === UserTier.OPEN) {
  //   return 'open-tier-upsell-modal';
  // }
  // if (userTier === UserTier.FREE) {
  //   return 'free-tier-upsell-modal';
  // }
  // if (userTier === UserTier.PRO_TRIAL) {
  //   return 'pro-trial-upsell-modal';
  // }

  return null;
};

export const defineIsUpgrade = (
  currentTier: UserTier,
  newTier: NinjaSubscriptionPlan,
) => {
  if (currentTier === UserTier.ULTRA) {
    return false;
  }
  if (currentTier === UserTier.STANDARD) {
    return true;
  }
  if (currentTier === UserTier.PAID || currentTier === UserTier.PRO) {
    return newTier === NinjaSubscriptionPlan.ULTRA;
  }
};
