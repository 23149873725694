import { Image } from '@phosphor-icons/react';
import { ChatModeSidePanel } from 'src/types';
import { CommonNavigationButton } from '../CommonNavigationButton';

export const EnvironmentSelectorButton = () => {
  return (
    <CommonNavigationButton
      title="Theme"
      sidePanelType={ChatModeSidePanel.ENVIRONMENT}
      IconComponent={Image}
    />
  );
};
