import { useMemo } from 'react';
import { useGetConversationByIdQuery } from 'src/store/services';
import { useAppSelector, sessionState } from 'src/store';
import { useConversationParams } from './useConversationParams';
import { Message } from 'src/types';

export const useCurrentConversationMessages = () => {
  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);

  const { currentConversationId, shouldSkipGetConversationById } =
    useConversationParams();

  const { messages: conversationMessages } = useGetConversationByIdQuery(
    {
      user_id,
      conversation_id: currentConversationId,
    },
    {
      skip: shouldSkipGetConversationById,
      selectFromResult: (result) => {
        return { messages: result.data?.messages || [] };
      },
    },
  );

  const conversationMessagesMap = useMemo(
    () =>
      conversationMessages.reduce(
        (acc, item) => {
          acc[item.message_id || ''] = item;
          return acc;
        },
        {} as Record<string, Message>,
      ),
    [conversationMessages],
  );

  return useMemo(
    () => ({
      conversationMessages,
      conversationMessagesMap,
    }),
    [conversationMessages, conversationMessagesMap],
  );
};
