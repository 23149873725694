import styles from './MainEmailSigninSection.module.scss';
import { AppRoutes } from 'src/types';
import { EmailSigninForm } from 'src/v2/pages/auth/components/EmailSigninForm';
import { TextWithLinkSection } from 'src/v2/pages/auth/components/TextWithLinkSection';
import React from 'react';

export const MainEmailSigninSection = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div>
          <h2 className={styles.signInHeader}>Welcome back!</h2>
          <div className={styles.signInSubHeader}>Login to your account</div>
        </div>
        <EmailSigninForm />
        <TextWithLinkSection
          text="Don’t have an account?"
          linkText="Sign Up"
          linkRoute={AppRoutes.SIGN_UP}
        />
        <TextWithLinkSection
          linkText="Forgot password?"
          linkRoute={AppRoutes.FORGOT_PASSWORD}
        />
      </div>
    </div>
  );
};
