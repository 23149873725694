import styles from './MainEmailSignupSection.module.scss';
import { AppRoutes } from 'src/types';
import { TermsSection } from 'src/v2/pages/auth/components/TermsSection/TermsSection';
import { EmailSignupForm } from 'src/v2/pages/auth/components/EmailSignupForm';
import { TextWithLinkSection } from 'src/v2/pages/auth/components/TextWithLinkSection';
import React from 'react';

export const MainEmailSignupSection = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div>
          <h2 className={styles.signUpHeader}>Sign up</h2>
          <div className={styles.signUpSubHeader}>Setup your account</div>
        </div>
        <EmailSignupForm />
        <TextWithLinkSection
          text="Already have an account?"
          linkText="Log in"
          linkRoute={AppRoutes.LOGIN}
        />
        <TermsSection />
      </div>
    </div>
  );
};
