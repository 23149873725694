import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { VideoScript } from '../VideoScript';
import { AvatarSettings } from '../AvatarSettings';
import { VideoSettings } from '../VideoSettings';
import type { MovieStudioFormData } from 'src/types';
import {
  getVideoScriptSubtitle,
  getAvatarSettingsSubtitle,
  getVideoFormatSubtitle,
} from 'src/utils';
import { AccordionItemType, Accordion } from 'src/components/Accordion';

/**
 * @deprecated
 */
export const ScriptForm = () => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<MovieStudioFormData>();

  const { emotion, _4k, portrait, square, script } = useWatch({
    control,
  });

  const items: AccordionItemType[] = useMemo(
    () => [
      {
        key: 'videoScript',
        title: 'Video Script',
        subtitle: getVideoScriptSubtitle(script) || '',
        content: <VideoScript />,
        isDraggableContent: true,
      },
      {
        key: 'avatarSettings',
        title: 'Avatar Settings',
        subtitle: getAvatarSettingsSubtitle(emotion),
        shouldHideSubtitle: true,
        content: <AvatarSettings />,
      },
      {
        key: 'videoSettings',
        title: 'Video Settings',
        subtitle: getVideoFormatSubtitle(_4k, portrait, square) || '',
        shouldHideSubtitle: true,
        content: <VideoSettings />,
      },
    ],
    [script, emotion, _4k, portrait, square],
  );

  return (
    <div className="nj-avatar-recording--script-record">
      <div className="nj-avatar-recording--script-record-content">
        <div className="nj-avatar-recording--title-wrapper">
          <p className="nj-avatar-recording--field-label">Video name</p>
          <div className="nj-avatar-recording--input-wrapper">
            <TextareaAutosize
              minRows={1}
              cacheMeasurements
              className={classNames('nj-avatar-recording--input', {
                isRequired: errors.title,
              })}
              {...register('title', {
                required: true,
              })}
            />
            {errors.title && (
              <span className="nj-avatar-recording--error-label">Required</span>
            )}
          </div>
        </div>

        <hr className="nj-section--side-settings-panel-divider" />

        <Accordion
          className="nj-avatar-recording--accordion"
          items={items}
          strictActiveKey={!!errors.script ? 'videoScript' : null}
        />
      </div>
    </div>
  );
};
