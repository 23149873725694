import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { UpsellVideoModal } from 'src/components/UpsellVideoModal';
import { useGetVideoLinksJSONQuery } from 'src/store/services';

export const AvatarUpsellVideoModal = () => {
  const { isVisibleUpsellAvatarModal, onToggleUpsellAvatarModal } =
    useContext(SessionContext);

  const { data: videoLinks } = useGetVideoLinksJSONQuery();

  const handleClose = () => {
    onToggleUpsellAvatarModal(false);
  };

  return (
    <UpsellVideoModal
      videoUrl={videoLinks?.avatar || ''}
      title="Sign up to access video chat!"
      description="Video chat using Ninja! Discover unique avatars and enhance your conversations."
      isOpen={isVisibleUpsellAvatarModal}
      onClose={handleClose}
    />
  );
};
