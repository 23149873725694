import { ReactNode } from 'react';

export interface TabPanelProps<T extends string> {
  tab: T;
  activeTab?: T;
  children: ReactNode;
  className?: string;
}

export const TabPanel = <T extends string>({
  tab,
  activeTab,
  children,
  className,
}: TabPanelProps<T>) => {
  if (activeTab !== tab) {
    return null;
  }

  return <div className={className}>{children}</div>;
};

TabPanel.displayName = 'TabPanel';
