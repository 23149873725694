import { Button } from 'src/components/Button';
import { isNumber } from 'src/types';
import { getQueuePosition } from 'src/utils';

type QueuePositionViewProps = {
  position?: number;
  waitingTime?: number;
  onCancel?: () => void;
};

export const QueuePositionView = ({
  position = 1,
  waitingTime,
  onCancel,
}: QueuePositionViewProps) => {
  const handleCancel = (): void => {
    onCancel && onCancel();
  };

  const positionInQueue = getQueuePosition(position);

  const estimatedMinutes = isNumber(waitingTime)
    ? `${Math.floor(waitingTime / 60)}m`
    : '';

  const estimatedSeconds = isNumber(waitingTime) ? `${waitingTime % 60}s` : '';

  return (
    <>
      <h5>Ninja is currently busy at the moment...</h5>
      {positionInQueue && (
        <>
          <p className="nj-avatar-joining-queue--number">{positionInQueue}</p>
          <p className="nj-avatar-joining-queue--position">Queue position</p>
        </>
      )}
      <p>
        {waitingTime && waitingTime > 0 ? (
          <span>
            Estimated waiting time: {estimatedMinutes} {estimatedSeconds}
          </span>
        ) : (
          <span>...</span>
        )}
      </p>
      <div className="nj-avatar-joining-queue--buttons">
        <Button
          className="nj-button nj-button--outline nj-avatar-joining-queue--button"
          onClick={handleCancel}
        >
          Leave the queue
        </Button>
        <Button
          className="nj-button nj-button--primary nj-avatar-queue--button"
          disabled
        >
          In the queue...
        </Button>
      </div>
    </>
  );
};
