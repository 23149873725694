import { AvatarVideos } from 'src/types/models/AvatarVideos';
import { AvatarVideoPresetName } from 'src/types';
import { Modal } from 'src/components/Modal';
import { LoadingContent } from '../LoadingContent';
import { ResolutionForm } from '../ResolutionForm';
import { ShareLinks } from '../ShareLinks';

interface ShareModalProps {
  isOpen: boolean;
  isLoading: boolean;
  shareLink?: string;
  hasResolutionForm?: boolean;
  videoData?: AvatarVideos;
  onClose: () => void;
  onSetResolution?: (value: AvatarVideoPresetName) => void;
}

export const ShareModal = ({
  isOpen,
  isLoading,
  shareLink,
  hasResolutionForm,
  videoData,
  onClose,
  onSetResolution,
}: ShareModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose} className="nj-share--modal">
      <h5 className="nj-share--title">
        {hasResolutionForm && !shareLink
          ? 'Select the format to create a public link'
          : 'Public link to share'}
      </h5>

      {hasResolutionForm && videoData && onSetResolution && !shareLink && (
        <ResolutionForm
          videoData={videoData}
          onSubmit={onSetResolution}
          isLoading={isLoading}
        />
      )}

      {isLoading && <LoadingContent />}

      {!!shareLink && <ShareLinks link={shareLink} />}
    </Modal>
  );
};
