import { createApi } from '@reduxjs/toolkit/query/react';
import { baseMsftQuery } from './config';

type TokenData = {
  token: string;
  region: string;
};

export const msftApi = createApi({
  reducerPath: 'msftApi',
  baseQuery: baseMsftQuery,
  endpoints: (builder) => ({
    getSpeechToken: builder.query<TokenData, string>({
      query: (userId) => `/get-speech-token?user_id=${userId}`,
    }),
  }),
});

export const { useGetSpeechTokenQuery, useLazyGetSpeechTokenQuery } = msftApi;
