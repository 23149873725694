import { useBreakpoint, useSession, useSubscriptionInfo } from 'src/hooks';
import { AppRoutes, BillingSettingsNestedRoutes } from 'src/types';
import { useLazyGetBillingPortalUrlQuery } from 'src/store/services';
import { ManageAccountContentPage } from '../ManageAccountContentPage/ManageAccountContentPage';
import './SubscriptionsBilling.scss';

export const SubscriptionsBilling = () => {
  const { isDesktopAndUp } = useBreakpoint();

  const { appUser } = useSession();
  const [trigger, { isLoading }] = useLazyGetBillingPortalUrlQuery();

  const { subscriptionInfo } = useSubscriptionInfo();

  // TODO(olha): add error handling
  // TODO(olha): it's a temporary solution.
  const handleClick = async () => {
    if (subscriptionInfo?.source === 'paypal') {
      window.location.href = 'https://www.paypal.com';
      return;
    }

    const { data } = await trigger({
      user_id: appUser.user_id,
      return_url: `${AppRoutes.MANAGE_ACCOUNT}/${BillingSettingsNestedRoutes.BILLING}`,
    });
    const redirectUrl = data?.url;

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  return (
    <ManageAccountContentPage title="Billing">
      <div className="nj-subscriptions-billing">
        <p className="nj-subscriptions-billing--header">
          <span className="nj-subscriptions-billing--title">
            Payment & billing information
          </span>

          <span className="nj-subscriptions-billing--subtitle">
            Manage all your payment information, payment history and billing
            information via Stripe.
          </span>
        </p>

        <button
          onClick={handleClick}
          className="nj-subscriptions-billing--button"
          disabled={isLoading}
        >
          Manage billing information
        </button>

        {!isDesktopAndUp && <hr className="divider" />}
      </div>
    </ManageAccountContentPage>
  );
};
