import { Modal } from 'react-responsive-modal';
import { X } from '@phosphor-icons/react';
import { AccessModalContent } from 'src/pages/AuthPages/components/AccessModalContent';
import React from 'react';

type AccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SVG_SIZE = 24;

export const AccessModal = ({ isOpen, onClose }: AccessModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      container={document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: 'nj-modal--access nj-auth-access-modal',
        closeIcon: 'nj-video-avatar-modal-closeButton',
      }}
      closeIcon={<X size={SVG_SIZE} />}
      center
    >
      <AccessModalContent onCloseModal={onClose} />
    </Modal>
  );
};
