import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { Modal } from 'src/components/Modal';
import { AvatarSelectorContent } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorContent';

export const AvatarSelectorModal = () => {
  const { isShowAvatarSelect, onToggleAvatarSelect } =
    useContext(SessionContext);

  const handleClose = () => {
    onToggleAvatarSelect(false);
  };

  return (
    <Modal
      blockScroll={false}
      className="nj-avatar-select-modal"
      open={isShowAvatarSelect}
      onClose={handleClose}
    >
      <AvatarSelectorContent />
    </Modal>
  );
};
