import { useRef } from 'react';
import cn from 'classnames';
import { DotsThreeOutlineVertical } from '@phosphor-icons/react';
import { useVisible, useOutsideClick, useBreakpoint } from 'src/hooks';
import { SVG_SIZE_M } from 'src/constants';
import { UserImage } from 'src/components/UserImage';
import styles from './UserMenu.module.scss';
import { UserMenuTooltip } from '../UserMenuTooltip';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export const UserMenu = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { isMobileOrTablet } = useBreakpoint();

  const { isVisible, handleVisibilityRemove, handleVisibilityToggle } =
    useVisible();

  useOutsideClick(containerRef, handleVisibilityRemove);

  return (
    <div className={styles.root} ref={containerRef}>
      <button
        onClick={handleVisibilityToggle}
        id="mobile-settings-trigger"
        className={cn(styles.triggerButton, { [styles.active]: isVisible })}
      >
        {isMobileOrTablet ? (
          <DotsThreeOutlineVertical size={SVG_SIZE_M} />
        ) : (
          <UserImage />
        )}
      </button>

      <ReactTooltip
        anchorSelect="#mobile-settings-trigger"
        clickable
        noArrow
        openOnClick
        disableStyleInjection
        className={styles.settingsTooltip}
        isOpen={isVisible}
        opacity={1}
        place="bottom-end"
        offset={10}
      >
        <UserMenuTooltip onClose={handleVisibilityRemove} />
      </ReactTooltip>
    </div>
  );
};
