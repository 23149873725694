import { Choices, Choice } from 'src/types';
import { ChoiceOption } from '../ChoiceOption';

type OptionsListProps = {
  list?: Choices;
  onClick?: ({
    select_key,
    select_value,
    selected,
    multi_select,
  }: {
    select_key: string;
    select_value: string;
    selected: boolean;
    multi_select: boolean;
  }) => void;
};

export const OptionsList = ({ list, onClick }: OptionsListProps) => {
  const {
    multi_select = false,
    select_key = '',
    choice_list = [],
  } = list as Choices;

  return choice_list.length > 0 ? (
    <div className="nj-options-card--column" data-key={select_key}>
      {choice_list.map((subOption: Choice) => {
        return (
          <ChoiceOption
            key={`select-key--${select_key}--${subOption.select_value}`}
            option={subOption}
            multiSelect={multi_select}
            selectKey={select_key}
            onClick={onClick}
          />
        );
      })}
    </div>
  ) : (
    <></>
  );
};
