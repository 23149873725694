import { useCallback, useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import classNames from 'classnames';
import { FormProvider } from 'src/components/FormProvider';
import TextareaAutosize from 'react-textarea-autosize';
import { Button } from 'src/components/Button';
import { X } from '@phosphor-icons/react';
import { useBreakpoint, useSession } from 'src/hooks';
import { Select } from 'src/components/Select';
import { toast } from 'react-toastify';
import { SupportTicketType, SupportTicketRequest } from 'src/types';
import { useSendSupportZendeskFormMutation } from 'src/store/services/zendeskApi';
import { useMobileOrientation } from 'react-device-detect';
import log from 'src/utils/logger';
import { sanitizeOutgoingContent } from 'src/utils';

type SubjectOption = {
  label: string;
  value: SupportTicketType;
};

const subjectOptions: SubjectOption[] = [
  {
    label: 'Customer question',
    value: SupportTicketType.CUSTOMER_QUESTION,
  },
  {
    label: 'Sales',
    value: SupportTicketType.SALES,
  },
  {
    label: 'Bug or technical issue',
    value: SupportTicketType.BUG_OR_TECHNICAL_ISSUE,
  },
  {
    label: 'Product questions and feedback',
    value: SupportTicketType.PRODUCT_QUESTIONS_AND_FEEDBACK,
  },
  {
    label: 'Billing and subscription',
    value: SupportTicketType.BILLING_AND_SUBSCRIPTION,
  },
  {
    label: 'Technical support',
    value: SupportTicketType.TECHNICAL_SUPPORT,
  },
  {
    label: 'Other',
    value: SupportTicketType.OTHER,
  },
];

type ZendeskSupportData = {
  requester_name: string;
  requester_email: string;
  subject: SupportTicketType;
  subjectRequired: string;
  comment: string;
  commentRequired: string;
};

type AutoresizeRow = {
  minRows: number;
  maxRows: number;
};

type AutoresizeRows = {
  [key: string]: AutoresizeRow;
};

const AUTORESIZE_ROWS: AutoresizeRows = {
  defaults: { minRows: 8, maxRows: 12 },
};

const SVG_SIZE = 24;
const MENU_HEIGHT = 205;

interface TicketFormProps {
  messageId?: string;
  taskId?: string;
  tags?: string[];
  onClose: () => void;
}

const requiredFieldErrorMessage = 'This field is required';

export const ZendeskTicketForm = ({
  messageId = '',
  taskId = '',
  tags = [],
  onClose,
}: TicketFormProps) => {
  const { appUser } = useSession();
  const { isMobile } = useBreakpoint();
  const { isPortrait } = useMobileOrientation();
  const [rows, setRows] = useState<AutoresizeRow>(AUTORESIZE_ROWS.defaults);

  useEffect(() => {
    const handleOrientationChange = () => {
      if (isMobile) {
        const rows = Math.floor((window.innerHeight - 300) / 25);
        setRows({ minRows: rows, maxRows: isPortrait ? rows + 4 : rows });
        return;
      }
      setRows(AUTORESIZE_ROWS.defaults);
    };

    handleOrientationChange();

    window.addEventListener('resize', handleOrientationChange);
    return () => window.removeEventListener('resize', handleOrientationChange);
  }, [isPortrait, isMobile]);

  const [sendZendeskForm] = useSendSupportZendeskFormMutation();

  const methods = useForm<ZendeskSupportData>({
    defaultValues: {
      requester_name: `${appUser.first_name} ${appUser.last_name}`,
      requester_email: appUser.email,
    },
  });

  const {
    register,
    reset,
    formState: { errors },
    control,
    handleSubmit,
  } = methods;

  const onSubmit: SubmitHandler<ZendeskSupportData> = async (data) => {
    const label =
      subjectOptions.find(
        (entry: SubjectOption) => entry.value === data.subject,
      )?.label || 'Reporting an issue';

    const requestData: SupportTicketRequest = {
      subject: label,
      comment: sanitizeOutgoingContent(data.comment),
      requester_name: data.requester_name,
      requester_email: data.requester_email,
      ticket_type: data.subject as SupportTicketType,
      task_id: taskId,
      message_id: messageId,
      tags,
    };

    try {
      await sendZendeskForm({
        userId: appUser.user_id,
        supportRequest: requestData,
      }).unwrap();

      onClose();
      toast.success('The ticket was submitted successfully.');
    } catch (e: unknown) {
      toast.error('The ticket was not submitted.');
      log.error(e);
    }
  };

  const handleCancel = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  return (
    <FormProvider<ZendeskSupportData> methods={methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="nj-form">
        <header className="nj-form--header">
          <h2 className="title">Get help</h2>
          <Button type="reset" className="close-button" onClick={handleCancel} data-e2e="report-task-zendesk-close-button">
            <X size={SVG_SIZE} color="currentColor" />
          </Button>
        </header>
        <div className="nj-form--block">
          <div className="nj-form--field">
            <Select
              control={control}
              name="subject"
              options={subjectOptions}
              required={requiredFieldErrorMessage}
              errors={errors}
              menuPlacement="bottom"
              menuShouldScrollIntoView={false}
              maxMenuHeight={MENU_HEIGHT}
              defaultValues
            />
            {errors.subject && (
              <span className="nj-field-error">{errors.subject.message}</span>
            )}
          </div>
          <div className="nj-form--field">
            <legend
              className={classNames('nj-form--legend', {
                isRequired: errors.commentRequired,
              })}
            >
              Message
            </legend>
            <TextareaAutosize
              className="nj-form--textarea"
              placeholder="Enter message"
              {...rows}
              {...register('comment', { required: requiredFieldErrorMessage })}
            />
            {errors.comment && (
              <span className="nj-field-error">{errors.comment.message}</span>
            )}
          </div>
        </div>

        <footer className="nj-form--footer">
          <Button
            type="reset"
            className="nj-button nj-common-button--secondary"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="nj-common-button nj-common-button--primary"
            disabled={!appUser?.settings?.features?.zendesk_support?.is_capable}
            data-e2e="report-task-zendesk-submit-button"
          >
            Submit
          </Button>
        </footer>
      </form>
    </FormProvider>
  );
};
