import { Button } from 'src/components/Button';

type QuotaExceededViewProps = {
  onCancel?: () => void;
};

export const QuotaExceededView = ({ onCancel }: QuotaExceededViewProps) => {
  const handleCancel = (): void => {
    onCancel && onCancel();
  };

  return (
    <>
      <h5>Quota exceeded...</h5>
      <p className="nj-avatar-joining-queue--waiting">
        You have exhausted your free minutes with avatar. Please try again
        later.
      </p>
      <Button className="nj-button nj-button--outline" onClick={handleCancel}>
        Cancel
      </Button>
    </>
  );
};
