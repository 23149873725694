import styles from './PeriodSwitch.module.scss';
import { PaymentPlanInterval, NinjaSubscriptionPlan } from 'src/types';
import cn from 'classnames';
import { useSubscriptionPlans } from 'src/hooks';

type PeriodSwitchProps = {
  selectedPeriod: PaymentPlanInterval;
  onChangePeriod: (value: PaymentPlanInterval) => void;
  isTeams?: boolean;
};

export type PaymentPeriod = 'Monthly' | 'Annually';

const periods: Record<PaymentPlanInterval, PaymentPeriod> = {
  [PaymentPlanInterval.MONTHLY]: 'Monthly',
  [PaymentPlanInterval.ANNUALLY]: 'Annually',
};

export const PeriodSwitch = ({
  selectedPeriod,
  onChangePeriod,
  isTeams,
}: PeriodSwitchProps) => {
  const { plans, isPlansLoading } = useSubscriptionPlans({
    group: isTeams ? 'teams' : 'individual',
  });

  const { recurring_discount_percentage } =
    plans?.find(
      ({ period, plan_tier }) =>
        period === PaymentPlanInterval.ANNUALLY &&
        (plan_tier === NinjaSubscriptionPlan.ULTRA ||
          plan_tier === NinjaSubscriptionPlan.TEAMS_ULTRA),
    ) || {};

  return (
    <div className={styles.root}>
      {Object.values(PaymentPlanInterval).map((item: PaymentPlanInterval) => {
        return (
          <button
            className={cn(styles.periodButton, {
              [styles.active]: item === selectedPeriod,
            })}
            onClick={() => onChangePeriod(item)}
            key={item}
          >
            {periods[item]}
          </button>
        );
      })}
      {!isPlansLoading && recurring_discount_percentage && (
        <div className={styles.saleLabel}>
          {recurring_discount_percentage}% OFF
        </div>
      )}
    </div>
  );
};
