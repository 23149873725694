import Skeleton from 'react-loading-skeleton';
import { useColorTheme } from 'src/hooks';
import classNames from 'classnames';

export type Color = { darkTheme: string; lightTheme: string };

export type ColorScheme = {
  baseColor?: Color;
  highlightColor?: Color;
};

type ProgressBarProps = ColorScheme & {
  className?: string;
  count?: number;
};

export const ProgressBar = ({
  className,
  count = 1,
  baseColor,
  highlightColor,
}: ProgressBarProps) => {
  const { isDarkTheme } = useColorTheme();

  const { darkTheme: baseDark, lightTheme: baseLight } = baseColor || {
    darkTheme: '#1d282d',
    lightTheme: '#e8eaea',
  };

  const { darkTheme: highlightDark, lightTheme: highlightLight } =
    highlightColor || {
      darkTheme: '#11181b',
      lightTheme: '#ffffff',
    };

  return (
    <div
      className={classNames('nj-thread-combined-card--progress-bar', className)}
    >
      <Skeleton
        baseColor={isDarkTheme ? baseDark : baseLight}
        highlightColor={isDarkTheme ? highlightDark : highlightLight}
        className="nj-thread-combined-card--progress-bar-skeleton"
        duration={3}
        width="100%"
        count={count}
      />
    </div>
  );
};
