import { baseUsersV2Query } from './config';
import {
  Conversation,
  ConversationList,
  ConversationRequestArgs,
  ConversationUpdateRequest,
  ConversationsByPageRequest,
  ChatsTasksFilter,
} from '../../types';
import { createApi } from '@reduxjs/toolkit/query/react';
import log from 'src/utils/logger';
import { DEFAULT_PAGE_LIMIT, DEFAULT_SORT_ORDER } from 'src/constants';
import {
  updateQueryGetConversationById,
  updateQueryGetConversations,
  updateQueryGetConversationsWithArchived,
  updateQueryGetFilteredTasks,
} from '../updateQueries';
import { tasksApi } from './tasksApi';

export enum ConversationsTags {
  Conversations = 'Conversations',
  ConversationById = 'ConversationById',
}

export const conversationApi = createApi({
  reducerPath: 'conversationApi',
  tagTypes: [
    ConversationsTags.Conversations,
    ConversationsTags.ConversationById,
  ],
  baseQuery: baseUsersV2Query,
  endpoints: (builder) => ({
    getConversations: builder.query<
      ConversationList,
      ConversationsByPageRequest
    >({
      query: ({ user_id, pageToken = '', includeArchived = false }) => ({
        url: `${user_id}/top_conversations`,
        params: {
          sort_order: DEFAULT_SORT_ORDER,
          limit: DEFAULT_PAGE_LIMIT,
          include_archived: includeArchived,
          page_token: pageToken,
        },
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { includeArchived } = queryArgs;
        return { includeArchived };
      },
      merge: (currentCache, newData) => {
        currentCache?.data?.push(...newData.data);
        currentCache.page_token = newData.page_token;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          !!currentArg?.pageToken &&
          currentArg?.pageToken !== previousArg?.pageToken
        );
      },
      providesTags: [ConversationsTags.Conversations],
    }),

    archiveAllConversations: builder.mutation<void, { user_id: string }>({
      query: ({ user_id }) => ({
        url: `${user_id}/archive_all`,
        method: 'POST',
      }),
      async onQueryStarted({ user_id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          log.error(error);
        } finally {
          dispatch(conversationApi.util.resetApiState());
          dispatch(tasksApi.util.resetApiState());

          dispatch(
            conversationApi.endpoints.getConversations.initiate({
              user_id,
              pageToken: '',
              includeArchived: false,
            }),
          );
        }
      },
    }),

    getConversationById: builder.query<Conversation, ConversationRequestArgs>({
      query: ({ user_id, conversation_id }) => ({
        url: `${user_id}/conversations/${conversation_id}?show_tasks=true&show_messages=true`,
      }),
      providesTags: (_, __, arg) => {
        return [
          { type: ConversationsTags.ConversationById, id: arg.conversation_id },
        ];
      },
    }),

    updateConversationById: builder.mutation<
      Conversation,
      ConversationUpdateRequest
    >({
      query: ({ user_id, conversation_id, conversation_hash, state }) => ({
        url: `${user_id}/conversations/${conversation_id}`,
        method: 'PATCH',
        body: {
          user_id,
          conversation_id,
          conversation_hash,
          state,
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const conversationByIdResult = dispatch(
          updateQueryGetConversationById(arg),
        );
        const conversationsResult = dispatch(updateQueryGetConversations(arg));
        const conversationsWithArchivedResult = dispatch(
          updateQueryGetConversationsWithArchived(arg),
        );

        const filteredTasksResults = Object.values(ChatsTasksFilter).map(
          (filter) => dispatch(updateQueryGetFilteredTasks(arg, filter)),
        );

        try {
          await queryFulfilled;
        } catch {
          conversationByIdResult.undo();
          conversationsResult.undo();
          conversationsWithArchivedResult.undo();

          filteredTasksResults.forEach((result) => result.undo());
        }
      },
    }),
  }),
});

export const {
  useGetConversationsQuery,
  useGetConversationByIdQuery,
  useUpdateConversationByIdMutation,
  useArchiveAllConversationsMutation,
} = conversationApi;
