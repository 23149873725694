import { useCallback, useContext } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import {
  CONVERTED_PASTED_TEXT_TO_FILE_NAME,
  CONVERTED_TEXT_TO_FILE_EXTENSION,
} from 'src/constants';
import { useAttachFile } from '../useAttachFile';

export const useConvertTextToFile = () => {
  const { convertedFromTextFilesLength } = useContext(ThreadInputBoxContext);

  const { handleUpTextToFiles } = useAttachFile();

  const handleConvertTextToFile = useCallback(
    async (pastedText: string) => {
      const fileName = `${CONVERTED_PASTED_TEXT_TO_FILE_NAME}${convertedFromTextFilesLength || ''}${CONVERTED_TEXT_TO_FILE_EXTENSION}`;

      const newFile = new File([pastedText], fileName, {
        type: 'text/plain',
      });

      await handleUpTextToFiles(newFile);
    },
    [handleUpTextToFiles, convertedFromTextFilesLength],
  );

  return { onConvertTextToFile: handleConvertTextToFile };
};
