/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum MessageType {
  CONVERSATION = 'CONVERSATION',
  TASK_CREATED = 'TASK_CREATED',
  SYSTEM = 'SYSTEM',
  SYSTEM_UPDATES = 'SYSTEM_UPDATES',
  PROGRESS_UPDATE = 'PROGRESS_UPDATE',
  CODE_CARD = 'CODE_CARD',
  EMAIL_CARD = 'EMAIL_CARD',
  CHOICES_CARD = 'CHOICES_CARD',
  CALENDAR_CARD = 'CALENDAR_CARD',
  RESEARCH_CARD = 'RESEARCH_CARD',
  RESEARCH = 'RESEARCH',
  RESEARCH_CODE = 'RESEARCH_CODE',
  CHAT_CARD = 'CHAT_CARD',
  RESEARCH_LLM = 'RESEARCH_LLM',
  SUMMARY = 'SUMMARY',
  RESEARCH_TASK_CREATION_CARD = 'RESEARCH_TASK_CREATION_CARD',
  CODE_TASK_CREATION_CARD = 'CODE_TASK_CREATION_CARD',
  SCHEDULER_TASK_CREATION_CARD = 'SCHEDULER_TASK_CREATION_CARD',
  CREATE_MEETING_CARD = 'CREATE_MEETING_CARD',
  DATE_RANGE_CARD = 'DATE_RANGE_CARD',
  TEXT_INPUT_CARD = 'TEXT_INPUT_CARD',
  PROPOSAL_CONFIRMATION_CARD = 'PROPOSAL_CONFIRMATION_CARD',
  ERROR_MESSAGE = 'ERROR_MESSAGE',
  IMAGE_CARD = 'IMAGE_CARD',
  COMPOSITE_CARD = 'COMPOSITE_CARD',
}
