import { Button } from 'src/v2/commonComponents/Button';
import styles from './SubmitActions.module.scss';

interface SubmitActionsProps {
  isSubmitDisabled: boolean;
  isResetDisabled?: boolean;
  onReset: () => void;
  onSubmit: () => void;
}

export const SubmitActions = ({
  isSubmitDisabled,
  isResetDisabled,
  onReset,
  onSubmit,
}: SubmitActionsProps) => {
  return (
    <div className={styles.root}>
      <Button
        disabled={isResetDisabled}
        color="secondary"
        className={styles.button}
        onClick={onReset}
      >
        Reset
      </Button>

      <Button
        disabled={isSubmitDisabled}
        className={styles.button}
        onClick={onSubmit}
      >
        Save
      </Button>
    </div>
  );
};
