import styles from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection/components/CreditsInfoSection/CreditsInfoSection.module.scss';
import dayjs from 'dayjs';
import { useSession } from 'src/hooks';
import classNames from 'classnames';

export const TrialDaysSection = () => {
  const { appUser } = useSession();

  const diff = Math.ceil(
    dayjs(appUser?.tier_expiration_date).diff(dayjs(), 'day', true),
  );

  return (
    <div className={styles.root}>
      <div>
        <h4 className={styles.title}>Trial period</h4>
        <span className={styles.description}>
          Ends on {dayjs(appUser?.tier_expiration_date).format('MMM D, YYYY')}
        </span>
      </div>
      <span
        className={classNames(styles.value, diff <= 0 ? styles.error : null)}
      >
        {diff <= 0 ? '0 days' : `${diff} day${diff === 1 ? '' : 's'}`}
      </span>
    </div>
  );
};
