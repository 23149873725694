import styles from './ConfirmInvitedUsersStep.module.scss';
import { Button } from 'src/v2/commonComponents/Button';
import { CaretLeft } from '@phosphor-icons/react';
import { SVG_SIZE_L } from 'src/constants';
import { useState } from 'react';
import { useSession, useTeamInfo } from 'src/hooks';
import { CheckboxSimple } from 'src/v2/commonComponents/CheckboxSimple';
import log from 'src/utils/logger';
import { useInviteUsersMutation } from 'src/store/services';
import { SeatRole } from 'src/types/models/SeatRole';
import { toast } from 'react-toastify';

type ConfirmInvitedUsersStepProps = {
  onGoBack: () => void;
  onClose: () => void;
  listOfEmails: string[];
};

export const ConfirmInvitedUsersStep = ({
  onGoBack,
  onClose,
  listOfEmails,
}: ConfirmInvitedUsersStepProps) => {
  const { appUser } = useSession();
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

  const { teamInfo } = useTeamInfo();

  const [inviteUsers, { isLoading }] = useInviteUsersMutation();

  const availableNumberOfSeats =
    (teamInfo?.num_seats_total || 0) - (teamInfo?.num_seats_assigned || 0);

  const isMoreSelectedThenPossible =
    selectedEmails.length > availableNumberOfSeats;

  const isSendButtonDisabled =
    selectedEmails.length === 0 || isMoreSelectedThenPossible;

  const handleSendInvite = async () => {
    try {
      await inviteUsers({
        user_id: appUser.user_id,
        team_id: teamInfo?.team_id || '',
        users: selectedEmails.map((item) => ({
          email: item,
          role: SeatRole.MEMBER,
        })),
      }).unwrap();
      onClose();
      toast.success(
        "We've sent out the invitations for your \n" +
          'team to join the workspace.',
      );
    } catch (e) {
      // todo handle errors
      log.error('error', e);
      onClose();
      toast.error('Something went wrong, users were not invited.');
    }
  };

  const handleChangeSelectAll = () => {
    if (selectedEmails.length === listOfEmails.length) {
      setSelectedEmails([]);
    } else {
      setSelectedEmails(listOfEmails);
    }
  };

  const handleChangeCheckboxValue = (item: string) => {
    if (selectedEmails.includes(item)) {
      setSelectedEmails(selectedEmails.filter((email) => email !== item));
    } else {
      setSelectedEmails([...selectedEmails, item]);
    }
  };

  const handleOpenBuySeats = () => {
    // todo open buy seats panel
  };

  return (
    <div className={styles.root}>
      <div className={styles.titleRow}>
        <Button color="transparent" onClick={onGoBack}>
          <CaretLeft size={SVG_SIZE_L} />
        </Button>
        <span className={styles.title}>Confirm user details</span>
      </div>
      <span className={styles.description}>
        Seats available in workspace: {availableNumberOfSeats}
      </span>
      <div className={styles.emailsListWrapper}>
        <div className={styles.listHeader}>
          <span>Users ({selectedEmails.length})</span>
          <CheckboxSimple
            isChecked={selectedEmails.length === listOfEmails.length}
            onChange={handleChangeSelectAll}
            className={styles.checkbox}
          />
        </div>
        <div className={styles.listBody}>
          {listOfEmails.map((item, index) => (
            <div className={styles.listRowWrapper} key={`${item}_${index}`}>
              <div className={styles.listRow}>
                <span className={styles.listItemEmail}>{item}</span>
                <CheckboxSimple
                  isChecked={selectedEmails.includes(item)}
                  onChange={() => handleChangeCheckboxValue(item)}
                  className={styles.checkbox}
                />
              </div>
              {index + 1 !== listOfEmails.length && (
                <hr className={styles.divider} />
              )}
            </div>
          ))}
        </div>
      </div>
      {isMoreSelectedThenPossible && (
        <div className={styles.errorRow}>
          <span className={styles.errorText}>
            Seat limit exceeded. Unselect users to meet current seat count or
            add seats to continue.
          </span>
          <Button onClick={handleOpenBuySeats} color="transparent">
            Add seats
          </Button>
        </div>
      )}
      <div className={styles.buttonRow}>
        <Button
          color="transparent"
          onClick={onClose}
          className={styles.cancelButton}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSendInvite}
          disabled={isSendButtonDisabled || isLoading}
        >
          Send invite
        </Button>
      </div>
    </div>
  );
};
