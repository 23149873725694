import { useRef } from 'react';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import styles from './AttachmentCarouselArrow.module.scss';
import classNames from 'classnames';
import { FadeTransition } from 'src/animations/FadeTransition';

interface AttachmentCarouselArrowProps {
  direction: 'prev' | 'next';
  visible: boolean;
  onClick: () => void;
}

export const AttachmentCarouselArrow = ({
  direction,
  visible,
  onClick,
}: AttachmentCarouselArrowProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const isPrev = direction === 'prev';

  return (
    <FadeTransition nodeRef={buttonRef} inProp={visible}>
      <button
        ref={buttonRef}
        className={classNames(styles.root, {
          [styles.prev]: isPrev,
          [styles.next]: !isPrev,
        })}
        onClick={onClick}
      >
        {isPrev ? (
          <CaretLeft size={SVG_SIZE_M} />
        ) : (
          <CaretRight size={SVG_SIZE_M} />
        )}
      </button>
    </FadeTransition>
  );
};
