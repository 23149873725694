import { useState, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useGetContactsQuery } from 'src/store/services';
import { useSession } from 'src/hooks';

const DEBOUNCE_DELAY = 500;

export const useSearchContacts = () => {
  const { appUser } = useSession();

  const [searchTerm, setSearchTerm] = useState<string>('');

  const debouncedOnChange = useDebouncedCallback((query: string) => {
    setSearchTerm(query);
  }, DEBOUNCE_DELAY);

  const { data = [] } = useGetContactsQuery(
    { query: searchTerm || '', user_id: appUser.user_id },
    {
      skip: !searchTerm.trim(),
    },
  );

  const contacts = useMemo(
    () =>
      data
        ?.slice()
        .sort((a, b) => a.first_name.localeCompare(b.first_name))
        .slice(0, 5) || [],
    [data],
  );

  return {
    contacts,
    onSearchContacts: debouncedOnChange,
  };
};
