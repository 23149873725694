import { useEffect } from 'react';
import { isMetaHumanEvent, MetaHumanEvent } from 'src/types';

/**
 * This hook helps to watch out for the message events and handle those that are coming
 * from the avatar iFrame.
 */
export const useMetaHumanEvent = (
  callback: (metaHumanEvent: MetaHumanEvent) => void,
) => {
  useEffect(() => {
    const handler = (ev: MessageEvent<MetaHumanEvent>) => {
      if (!isMetaHumanEvent(ev.data)) {
        return;
      }

      callback(ev.data as MetaHumanEvent);
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [callback]);
};
