import { MeetNinjaSection } from 'src/v2/pages/auth/components/MeetNinjaSection/MeetNinjaSection';
import { MainSigninSection } from 'src/v2/pages/auth/components/MainSigninSection';
import { isEnterprise } from 'src/utils';
import { MainEmailSigninSection } from '../components/MainEmailSigninSection';
import { EnterpriseMeetNinjaSection } from 'src/v2/pages/auth/components/MeetNinjaSection';
import { useQueryParams } from 'src/hooks';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { BasePublicPage } from 'src/v2/pages/auth/BasePublicPage';
import { SEO_LOGIN_DESCRIPTION, SEO_LOGIN_TITLE } from 'src/constants';

export const SigninPage = () => {
  const {
    searchParams: { email_verified },
    removeSearchParam,
  } = useQueryParams();

  useEffect(() => {
    if (email_verified) {
      toast.success('Email verification successful');
      removeSearchParam('email_verified');
    }
  }, [email_verified, removeSearchParam]);

  return (
    <BasePublicPage
      seoTitle={SEO_LOGIN_TITLE}
      seoDescription={SEO_LOGIN_DESCRIPTION}
    >
      {isEnterprise ? (
        <>
          <EnterpriseMeetNinjaSection />
          <MainEmailSigninSection />
        </>
      ) : (
        <>
          <MeetNinjaSection isSignup={false} />
          <MainSigninSection />
        </>
      )}
    </BasePublicPage>
  );
};
