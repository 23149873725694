import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { SUPER_AGENT } from 'src/constants/externalLinks';
import { SwitchSimple } from 'src/v2/commonComponents/SwitchSimple';
import { useSession, useUserData } from 'src/hooks';
import { sendGTMEvent } from 'src/utils';
import {
  GTMEvent,
  SuperAgentSettings,
  SuperAgentModels,
  NinjaSubscriptionPlan,
} from 'src/types';
import {
  RadioGroupSimple,
  RadioOption,
} from 'src/v2/commonComponents/RadioGroupSimple';
import log from 'src/utils/logger';
import { superAgentModelSet } from 'src/constants';
import styles from './SuperAgentSection.module.scss';
import { LabelPro } from 'src/components/LabelPro';
import { LabelUltra } from 'src/components/LabelUltra';

export const SuperAgentSection = () => {
  const {
    appUser: { settings },
  } = useSession();
  const { updateUserSettings } = useUserData();

  const options: RadioOption<SuperAgentModels>[] = useMemo(
    () =>
      settings?.super_agent?.available_models?.map(({ model, is_capable }) => ({
        value: model,
        label: (
          <span className={styles.radioLabel}>
            <span className={styles.content}>
              <span className={styles.subTitle}>
                {superAgentModelSet[model].title}
              </span>

              <span className={styles.caption}>
                {superAgentModelSet[model].description}
              </span>
            </span>

            {/* TODO(olha): we need to create one component with label render logic */}
            {!is_capable &&
              superAgentModelSet[model].isCapableFrom ===
                NinjaSubscriptionPlan.PRO && <LabelPro />}

            {!is_capable &&
              superAgentModelSet[model].isCapableFrom ===
                NinjaSubscriptionPlan.ULTRA && <LabelUltra />}
          </span>
        ),
        isDisabled: !is_capable,
      })) || [],
    [settings?.super_agent?.available_models],
  );

  const handleSuperAgentChange = async (enabled: boolean) => {
    const super_agent: SuperAgentSettings = {
      ...settings?.super_agent,
      enabled,
    };

    try {
      await updateUserSettings({ super_agent });

      if (enabled) {
        sendGTMEvent(GTMEvent.SUPERAGENT_TURN_ON_CLICK);
      } else {
        sendGTMEvent(GTMEvent.SUPERAGENT_TURN_OFF_CLICK);
      }
    } catch (e) {
      log.error(e);
    }
  };

  const handleModelChange = async (value: SuperAgentModels) => {
    const super_agent: SuperAgentSettings = {
      ...settings?.super_agent,
      selected: value,
    };

    try {
      await updateUserSettings({ super_agent });

      if (value === SuperAgentModels.TURBO) {
        sendGTMEvent(GTMEvent.SUPERAGENT_SELECT_TURBO_CLICK);
      }

      if (value === SuperAgentModels.NEXUS) {
        sendGTMEvent(GTMEvent.SUPERAGENT_SELECT_NEXUS_CLICK);
      }

      if (value === SuperAgentModels.APEX) {
        sendGTMEvent(GTMEvent.SUPERAGENT_SELECT_APEX_CLICK);
      }
    } catch (e) {
      log.error(e);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.superAgentContainer}>
        <div className={styles.content}>
          <span className={styles.title}>SuperAgent</span>

          <span className={styles.caption}>
            Combines the power of multiple AI models to produce the best answer
            possible.
          </span>

          <Link to={SUPER_AGENT} target="_blank" className={styles.link}>
            Learn more
          </Link>
        </div>

        <SwitchSimple
          isDisabled={!settings?.super_agent?.is_capable}
          isChecked={!!settings?.super_agent?.enabled}
          onChange={handleSuperAgentChange}
        />
      </div>

      <RadioGroupSimple<SuperAgentModels>
        options={options}
        selectedValue={settings?.super_agent?.selected}
        onChange={handleModelChange}
        className={styles.radioGroup}
        disabledClassName={styles.disabledRadio}
        isGroupDisabled={!settings?.super_agent?.enabled}
      />
    </div>
  );
};
