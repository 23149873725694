import { SingleSelectOption } from 'src/types';
import { NO_VIDEO_CALL_VALUE } from '../../constants/defaultValues';
import { useMeetingCardFieldsWatch } from '../../hooks/useMeetingCardFieldsWatch';
import { getLabelFromOptionValue } from '../../utils/selectOptions';
import { MeetingCardFormData } from '../../CreateMeetingCard';

const DEFAULT_EMPTY_LOCATION_MESSAGE = 'No location';
const DEFAULT_EMPTY_VIDEO_MESSAGE = 'No video call option';
const DEFAULT_EMPTY_MESSAGE = 'No location & video call option';

interface EventInfoDefaultVaueProps {
  conferenceOptions: Array<SingleSelectOption>;
}

export const EventInfoDefaultVaue = ({
  conferenceOptions,
}: EventInfoDefaultVaueProps) => {
  const { event_title, location, conference } =
    useMeetingCardFieldsWatch<MeetingCardFormData>();

  const isEmptyVideo = conference === NO_VIDEO_CALL_VALUE;
  const isEmptyLocationAndVideo = !location && isEmptyVideo;

  const conferenceValue = isEmptyVideo
    ? DEFAULT_EMPTY_VIDEO_MESSAGE
    : getLabelFromOptionValue(conferenceOptions, conference || '');

  return (
    <>
      <span className="nj-create-meeting-card--title-field-title">
        {event_title}
      </span>

      {isEmptyLocationAndVideo ? (
        <span>{DEFAULT_EMPTY_MESSAGE}</span>
      ) : (
        <>
          <span>{location || DEFAULT_EMPTY_LOCATION_MESSAGE}</span>

          <span>{conferenceValue}</span>
        </>
      )}
    </>
  );
};
