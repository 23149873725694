import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatModeSidePanel } from 'src/types';
import { RootState } from '../index';
import { BREAKPOINT_DESKTOP } from 'src/constants';

interface InterfaceControl {
  chatModeSidePanel: ChatModeSidePanel;
  isSettingsPanelExpanded: boolean;
  isTasksListExpanded: boolean;
}

const initialState: InterfaceControl = {
  chatModeSidePanel: ChatModeSidePanel.NONE,
  isSettingsPanelExpanded: false,
  isTasksListExpanded: window.innerWidth >= BREAKPOINT_DESKTOP ? true : false,
};

export const interfaceControlSlice = createSlice({
  name: 'interfaceControl',
  initialState,
  reducers: {
    setChatModeSidePanel: (
      state,
      { payload }: PayloadAction<ChatModeSidePanel>,
    ) => {
      state.chatModeSidePanel = payload;
    },
    setIsSettingsPanelExpanded: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      state.isSettingsPanelExpanded = payload ?? !state.isSettingsPanelExpanded;
    },
    setIsTasksListExpanded: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      state.isTasksListExpanded = payload ?? !state.isTasksListExpanded;
    },
  },
});

export const interfaceControlState = (state: RootState) =>
  state.interfaceControl as InterfaceControl;

export const {
  setChatModeSidePanel,
  setIsSettingsPanelExpanded,
  setIsTasksListExpanded,
} = interfaceControlSlice.actions;

export default interfaceControlSlice.reducer;
