import { SingleSelectOption } from 'src/types';
import GoogleMeetIcon from 'src/images/logos/google-meet.svg';
import {
  NO_VIDEO_CALL_LABEL,
  NO_VIDEO_CALL_VALUE,
} from '../constants/defaultValues';

export type ConferenceOption = Omit<SingleSelectOption, 'label'> & {
  label: JSX.Element | string;
};

export const prepareConferenceOptions = (
  conferenceOptions: Array<SingleSelectOption>,
): Array<ConferenceOption> => {
  const options = [
    { label: NO_VIDEO_CALL_LABEL, value: NO_VIDEO_CALL_VALUE },
    ...conferenceOptions,
  ]
    // temporary remove zoom from options
    .filter((item) => item.value !== 'zoom')
    .map((item) =>
      item.value === 'google_meet'
        ? {
            ...item,
            label: (
              <span className="nj-create-meeting-card--conference-label">
                <img src={GoogleMeetIcon} alt="Google meet" />
                {item.label}
              </span>
            ),
          }
        : item,
    );

  return options;
};

export const getDefaultValueFromOptions = (
  options: Array<SingleSelectOption>,
) => {
  return options.find((item) => !!item.selected)?.value;
};

export const getLabelFromOptionValue = (
  options: Array<SingleSelectOption>,
  value: JSX.Element | string,
) => {
  return (
    prepareConferenceOptions(options).find((item) => item.value === value)
      ?.label || ''
  );
};
