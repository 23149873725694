import { X } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import styles from './PanelHeader.module.scss';

interface PanelHeaderProps {
  onClose: () => void;
}

export const PanelHeader = ({ onClose }: PanelHeaderProps) => {
  return (
    <div className={styles.mobileBackButtonRow}>
      <button
        type="button"
        onClick={onClose}
        className={styles.mobileBackButton}
      >
        <span>Checkout</span>
        <X size={SVG_SIZE_M} />
      </button>
    </div>
  );
};
