/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum LocationSource {
  IP = 'ip',
  GPS = 'gps',
  CLOUDFRONT = 'cloudfront',
  MANUAL = 'manual',
  UNKNOWN = 'unknown',
}
