import styles from './LeaveWorkspaceSection.module.scss';
import { Button } from 'src/v2/commonComponents/Button';
import log from 'src/utils/logger';
import { useLeaveWorkspaceMutation } from 'src/store/services';
import { useSession, useTeamInfo } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';

export const LeaveWorkspaceSection = () => {
  const { appUser } = useSession();
  const { teamInfo } = useTeamInfo();
  const navigate = useNavigate();
  const [leaveWorkspace] = useLeaveWorkspaceMutation();

  const handleLeaveWorkspaceClick = async () => {
    try {
      await leaveWorkspace({
        user_id: appUser.user_id,
        team_id: teamInfo?.team_id || '',
      }).unwrap();
      navigate(AppRoutes.HOME);
    } catch (e) {
      log.error('error', e);
    }
  };

  return (
    <div className={styles.root}>
      <h4 className={styles.title}>Leave workspace</h4>
      <span className={styles.description}>
        Your account will immediately be downgraded to a Basic account and you
        will loose access to all subscription and workspace specific features.
      </span>
      <Button
        className={styles.button}
        onClick={handleLeaveWorkspaceClick}
        color="secondary"
      >
        Leave workspace
      </Button>
    </div>
  );
};
