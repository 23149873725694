import { Modal } from 'react-responsive-modal';
import { Icon, X } from '@phosphor-icons/react';
import React, { useContext } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { useBreakpoint } from 'src/hooks';
import { useGetVideoLinksJSONQuery } from 'src/store/services';
import { LazyReactPlayer } from 'src/components/LazyReactPlayer';
import { isEnterprise } from 'src/utils';

type QuickTourModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SVG_SIZE = 24;

export type QuickTourVideoInfoBlock = {
  id: string;
  title: string;
  description: string;
  url: string;
  isNew: boolean;
  icon: Icon;
  thumbnailUrl: string;
};

export const QuickTourModal = ({ isOpen, onClose }: QuickTourModalProps) => {
  const { rootContainerRef } = useContext(ForwardRefContext);
  const { isMobile } = useBreakpoint();
  const { data: videoLinks } = useGetVideoLinksJSONQuery();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      container={rootContainerRef?.current || document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: 'nj-modal--access nj-quick-tour-modal',
        closeIcon: 'nj-video-avatar-modal-closeButton',
      }}
      closeIcon={<X size={SVG_SIZE} />}
      center
    >
      <div className="nj-quick-tour-modal-content">
        <h3 className="nj-quick-tour-modal-title">Welcome to Ninja AI</h3>

        <div className="nj-quick-tour-modal-video-wrapper">
          <LazyReactPlayer
            url={
              isEnterprise
                ? videoLinks?.enterpriseWelcome || ''
                : videoLinks?.welcome || ''
            }
            controls
            width="100%"
            height={isMobile ? '320px' : '450px'}
            playing
          />
        </div>
      </div>
    </Modal>
  );
};
