import { MeetNinjaSection } from 'src/v2/pages/auth/components/MeetNinjaSection/MeetNinjaSection';
import { MainEmailSigninSection } from '../components/MainEmailSigninSection';
import { useQueryParams } from 'src/hooks';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { BasePublicPage } from 'src/v2/pages/auth/BasePublicPage';
import { SEO_LOGIN_DESCRIPTION, SEO_LOGIN_TITLE } from 'src/constants';

export const SigninWithEmailPage = () => {
  const {
    searchParams: { email_verified },
    removeSearchParam,
  } = useQueryParams();

  useEffect(() => {
    if (email_verified) {
      toast.success('Email verification successful');
      removeSearchParam('email_verified');
    }
  }, [email_verified, removeSearchParam]);

  return (
    <BasePublicPage
      seoTitle={SEO_LOGIN_TITLE}
      seoDescription={SEO_LOGIN_DESCRIPTION}
    >
      <>
        <MeetNinjaSection isSignup={false} />
        <MainEmailSigninSection />
      </>
    </BasePublicPage>
  );
};
