/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Meeting } from './Meeting';
import type { Negotiation } from './Negotiation';
import type { SchedulingRequest } from './SchedulingRequest';

export type SchedulingPayload = {
  payload_type?: SchedulingPayload.payload_type;
  request: SchedulingRequest;
  meeting?: Meeting;
  negotiation?: Negotiation;
};

export namespace SchedulingPayload {
  export enum payload_type {
    SCHEDULING = 'scheduling',
  }
}
