import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { toast } from 'react-toastify';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import AudioContext from 'src/contexts/AudioContext';
import { useBanner } from './useBanner';
import { useConditionalInterval } from './useConditionalInterval';
import { BannerType, ChatMode } from 'src/types';
import { exitFullscreen } from 'src/utils';
import { useSession } from './useSession';

const VIDEO_CHAT_ENDED_MESSAGE = 'Video chat ended.';
const IDLE_COUNTER_INTERVAL = 1000;

// TODO(olha): handlers was moved from legacy Avatar banners. Logic needs refactoring
// (olha): attn: this hook can be called only from one place and only once
export const useAvatarIdleTimer = () => {
  const { removeBanner, bannersSet, addBanner } = useBanner();

  const { updateAvatarQueue, updateChatMode, avatarQueue, chatMode } =
    useSession();
  const {
    clearShowAvatarQueue,
    avatarIsBeingDragged,
    isFullscreen,
    toggleTranscript,
    toggleFullscreen,
    setIdleTimeLeft,
  } = useContext(AvatarModeContext);

  const { metaHumanTalking, recordInProgress } = useContext(AudioContext);

  const isAvatarMode = useMemo(
    () => chatMode === ChatMode.AVATAR && avatarQueue?.ready,
    [chatMode, avatarQueue?.ready],
  );

  const handleDismissOnIdleAvatar = () => {
    if (isFullscreen) {
      exitFullscreen();
      toggleTranscript(true);
      toggleFullscreen(false);
    }

    updateAvatarQueue();
    clearShowAvatarQueue();
    updateChatMode(ChatMode.CHAT);
    toast(VIDEO_CHAT_ENDED_MESSAGE);
  };

  // when idle state has been reached,
  // check whether banner was shown,
  // if not show the banner, and
  // kickstart the timer
  const onIdle = () => {
    if (!isAvatarMode) {
      return;
    }

    if (!bannersSet.includes(BannerType.AVATAR_IDLE)) {
      activate();
      addBanner(BannerType.AVATAR_IDLE);
    } else {
      removeBanner(BannerType.AVATAR_IDLE);
      handleDismissOnIdleAvatar();
    }
  };

  // when the user is prompted to interact
  // with the avatar expand this banner.
  const onPrompt = () => {
    if (!isAvatarMode) {
      return;
    }

    addBanner(BannerType.AVATAR_IDLE);
  };

  // when the user interacts with the avatar
  // close the banner.
  const onActive = () => {
    if (!isAvatarMode) {
      return;
    }

    removeBanner(BannerType.AVATAR_IDLE);
  };

  const onAction = () => {
    if (!isAvatarMode) {
      return;
    }

    removeBanner(BannerType.AVATAR_IDLE);
  };

  const { getRemainingTime, activate, pause, resume, reset, start } =
    useIdleTimer({
      onIdle,
      onPrompt,
      onActive,
      onAction,
      startOnMount: false,
      timeout: 90000,
      promptBeforeIdle: 60000,
      throttle: 50,
    });

  const getIdleRemainingTime = useCallback((remaining: number) => {
    const roundedRemaining = Math.round(remaining);

    if (roundedRemaining === 0) {
      return '2:00';
    }
    const minutes = Math.floor(roundedRemaining / 60);
    const seconds = roundedRemaining % 60;

    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }, []);

  const handleUpdateIdleCounter = useCallback(() => {
    const remainingTime = getRemainingTime();
    const idleRemainingTime = getIdleRemainingTime(remainingTime / 1000);
    setIdleTimeLeft(idleRemainingTime);
  }, [getRemainingTime, getIdleRemainingTime, setIdleTimeLeft]);

  const { startInterval: startIdleInterval, stopInterval: stopIdleInterval } =
    useConditionalInterval(handleUpdateIdleCounter, IDLE_COUNTER_INTERVAL);

  useEffect(() => {
    if (isAvatarMode) {
      start();
      startIdleInterval();
    } else {
      reset();
      stopIdleInterval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvatarMode]);

  useEffect(() => {
    if (metaHumanTalking || recordInProgress || avatarIsBeingDragged) {
      pause();
    } else {
      resume();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaHumanTalking, recordInProgress, avatarIsBeingDragged]);
};
