import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { RenameTypeProps, RenameType } from 'src/types';
import { Button } from 'src/components/Button';
import { InputError } from 'src/components/Tooltip';
import { sanitizeOutgoingContent } from 'src/utils';

type RenameFormProps = {
  itemId: string;
  itemName: string;
  itemType: RenameType;
  onRenameSubmit: (values: RenameTypeProps) => void;
};

type RenameFormData = {
  hashId: string;
  hashTitle: string;
  hashTitleRequired: string;
  hashType: RenameType;
};

export const RenameForm = ({
  itemId,
  itemName,
  itemType,
  onRenameSubmit,
}: RenameFormProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    reset: resetForm,
  } = useForm<RenameFormData>({
    defaultValues: { hashTitle: itemName },
  });

  const handleCancelRename = () => {
    resetForm();
    setValue('hashTitle', itemName);
  };

  // submit data to be updated as conversation or task
  const onSubmit: SubmitHandler<RenameFormData> = ({
    hashId,
    hashTitle,
    hashType,
  }: RenameFormData) => {
    if (!hashTitle) {
      handleCancelRename();
      return;
    }

    if (onRenameSubmit) {
      onRenameSubmit({
        itemId: hashId,
        itemType: hashType,
        itemName: sanitizeOutgoingContent(hashTitle),
      } as unknown as RenameTypeProps);
    }

    resetForm();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const cleanedValue = value.replace(/\s+/g, '-').replace(/[^\w\s-]/g, '');
    e.target.value = cleanedValue;
  };

  return (
    <form
      className="nj-form nj-modal--rename-form--form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <input defaultValue={itemId} {...register('hashId')} type="hidden" />
      <input defaultValue={itemType} {...register('hashType')} type="hidden" />

      <div className="nj-form--header">
        <h2>{itemType === RenameType.TASK ? 'Rename task' : 'Rename chat'}</h2>
      </div>

      <div className="nj-form--block">
        <div className="nj-form--field">
          <input
            className="nj-form--input"
            {...register('hashTitle', {
              required: `Please provide hyphen-separated alpha-numeric title`,
            })}
            onInput={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />

          {isFocused && (
            <InputError title="Names can't contain special characters" />
          )}
        </div>
      </div>

      {errors.hashTitle && (
        <span className="nj-field-error">This field is required.</span>
      )}

      <footer className="nj-form--footer">
        <Button type="submit" className="nj-button nj-button--primary">
          Submit
        </Button>
      </footer>
    </form>
  );
};
