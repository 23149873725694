import ninjaCover from 'src/images/loader/cover.svg';
import ninjaGlowingEyes from 'src/images/loader/eyes-glow.svg';
import ninjaEyes from 'src/images/loader/eyes.svg';
import ninjaHeadBase from 'src/images/loader/head-base.svg';

import ninjaCoverLight from 'src/images/loaderLight/cover.svg';
import ninjaGlowingEyesLight from 'src/images/loaderLight/eyes-glow.svg';
import ninjaEyesLight from 'src/images/loaderLight/eyes.svg';
import ninjaHeadBaseLight from 'src/images/loaderLight/head-base.svg';
import classNames from 'classnames';

type LoadingProps = {
  className?: string;
  isLightBackground?: boolean;
};

/**
 * Loading/preloading screen.
 */
export const Loading = ({
  className,
  isLightBackground = false,
}: LoadingProps) => {
  return (
    <div
      className={classNames(
        'nj-loading',
        { lightBackground: isLightBackground },
        className,
      )}
    >
      <div className="nj-loading--ninja">
        <div className="nj-loading--ninja-eyes">
          <img
            src={isLightBackground ? ninjaEyesLight : ninjaEyes}
            alt="Glowing Ninja Eyes"
          />
        </div>
        <div className="nj-loading--ninja-cover">
          <img
            src={isLightBackground ? ninjaCoverLight : ninjaCover}
            alt="Ninja Cover"
          />
        </div>
        <div className="nj-loading--ninja-head">
          <img
            src={isLightBackground ? ninjaHeadBaseLight : ninjaHeadBase}
            alt="Ninja Head Base"
          />
        </div>
        {
          <div className="nj-loading--ninja-glowing-eyes">
            <img
              src={isLightBackground ? ninjaGlowingEyesLight : ninjaGlowingEyes}
              alt="Glowing Ninja Eyes"
            />
          </div>
        }
      </div>
    </div>
  );
};
