import { useCallback, useContext } from 'react';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { useSession } from './useSession';
import { BannerType, ChatMode } from 'src/types';
import { useBanner } from './useBanner';

// TODO(olha): handlers was moved from legacy Avatar banners. Logic needs refactoring
export const useAvatarBanner = () => {
  const { clearShowAvatarQueue, shouldShowAvatarIframe } =
    useContext(AvatarModeContext);

  const { avatarQueue, updateAvatarQueue, updateChatMode } = useSession();

  const { removeBanner } = useBanner();

  const handleCancelAvatarQueue = useCallback(() => {
    updateAvatarQueue();
    clearShowAvatarQueue();
    updateChatMode(ChatMode.CHAT);
  }, [updateAvatarQueue, clearShowAvatarQueue, updateChatMode]);

  const handleMoveToAvatarMode = useCallback(() => {
    removeBanner(BannerType.AVATAR_QUEUE);
    updateChatMode(ChatMode.AVATAR);
  }, [removeBanner, updateChatMode]);

  const handleCloseAvatarQueueBanner = useCallback(() => {
    if (shouldShowAvatarIframe) {
      handleMoveToAvatarMode();
      return;
    }

    if (avatarQueue?.joinedAvatarQueue) {
      handleCancelAvatarQueue();
    }
  }, [
    shouldShowAvatarIframe,
    avatarQueue,
    handleMoveToAvatarMode,
    handleCancelAvatarQueue,
  ]);

  return {
    onCancelAvatarQueue: handleCancelAvatarQueue,
    onCloseAvatarQueueBanner: handleCloseAvatarQueueBanner,
  };
};
