import { ExternalModelSettings, ImageGenSettingsOption } from 'src/types';

// TODO(olha): expand function with others icons
export const getDefaultModelIconName = (modelId: string) => {
  const provider = modelId.split('/')[0];

  switch (provider) {
    case 'bedrock':
      if (modelId.includes('nova-')) {
        return 'nova';
      }
      return 'awsLogo';

    case 'openai':
      return 'chatGPTLogo';

    case 'gemini':
      return 'googleGeminiProLogo';

    default:
      return null;
  }
};

export const getTextModelDisplayName = (
  settings: ExternalModelSettings,
  targetModel: string,
): string | undefined => {
  return Object.values(settings)
    .flatMap((value) => (Array.isArray(value) ? value : []))
    .find((option) => option?.model_name === targetModel)?.display_name;
};

export const getImageModelDisplayName = (
  settings: ImageGenSettingsOption[],
  targetModel: string,
): string | undefined => {
  return settings.find((option) => option?.model === targetModel)?.display_name;
};
