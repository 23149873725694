import { TaskState } from 'src/types';
import { useBreakpoint } from 'src/hooks';
import { StopTaskButton } from 'src/components/StopTaskButton';

interface ThreadMessageActionsProps {
  taskState: TaskState;
  taskId?: string;
  taskHash?: string;
}

export const ThreadMessageActions = ({
  taskState,
  taskId = '',
  taskHash,
}: ThreadMessageActionsProps) => {
  const { isMobile } = useBreakpoint();

  return (
    <StopTaskButton
      taskId={taskId}
      taskState={taskState}
      taskHash={taskHash}
      className="nj-thread-message--action-round-button"
      buttonName={!isMobile ? 'Stop' : ''}
    />
  );
};
