import { createApi } from '@reduxjs/toolkit/query/react';
import {
  SearchResults,
  ApiContact,
  isApiContact,
  MyContactResult,
} from 'src/types';
import { getCircleColor } from 'src/utils';
import { baseContactsQuery } from './config';
import { X_NINJA_USER_ID_HEADER } from 'src/constants/api';

export interface ContactsRequest {
  query: string;
  user_id: string;
}

export interface MyContactRequest {
  user_id: string;
}

export enum ContactsTags {
  Contacts = 'Contacts',
}

export const contactsApi = createApi({
  reducerPath: 'contactsApi',
  baseQuery: baseContactsQuery,
  tagTypes: [ContactsTags.Contacts],
  endpoints: (builder) => ({
    getContacts: builder.query<ApiContact[], ContactsRequest>({
      query: ({ query, user_id }) => ({
        url: `/v2/search?query=${query}`,
        method: 'GET',
        headers: {
          [X_NINJA_USER_ID_HEADER]: user_id,
        },
      }),
      providesTags: [ContactsTags.Contacts],
      transformResponse: (response: SearchResults): ApiContact[] => {
        const searchResults = response.results;
        const contacts: ApiContact[] = searchResults.map(({ person }) => {
          const emails = [];

          if (person.emailAddresses) {
            for (const emailAddress of person.emailAddresses) {
              const { metadata, value = '' } = emailAddress;
              if (metadata && metadata.primary) {
                emails.push(value);
              }
            }
          }
          const email =
            emails.length > 0
              ? emails.at(0)
              : person.emailAddresses?.at(0)?.value;

          const names = [];
          if (person.names && person.names.length > 0) {
            for (const personName of person.names) {
              const { metadata, givenName = '', familyName = '' } = personName;
              if (metadata && metadata.primary) {
                names.push({
                  first_name: givenName,
                  last_name: familyName,
                });
              }
            }
          }

          const name =
            names.length > 0
              ? names.at(0)
              : {
                  first_name: person.names.at(0)?.givenName,
                  last_name: person.names.at(0)?.familyName,
                };

          return { ...name, email, color: getCircleColor() } as ApiContact;
        });

        const filteredContacts = contacts.filter((contact) =>
          isApiContact(contact),
        );

        return filteredContacts;
      },
    }),
    getMyContactPhoto: builder.query<string | null, MyContactRequest>({
      query: ({ user_id }) => ({
        url: `/v1/people/me?personFields=photos`,
        method: 'GET',
        headers: {
          [X_NINJA_USER_ID_HEADER]: user_id,
        },
      }),
      providesTags: [ContactsTags.Contacts],
      transformResponse: async (response: MyContactResult) => {
        let result = null;
        const url =
          (response.photos || []).find((item) => item.metadata.primary)?.url ||
          response.photos[0]?.url ||
          null;

        if (url) {
          result = url;
        }

        return result;
      },
    }),
  }),
});

export const { useGetContactsQuery, useGetMyContactPhotoQuery } = contactsApi;
