import { createContext, useRef, RefObject } from 'react';

export type ThreadMessageListHandlers = {
  onScrollToBottomMessageList: (delay?: number) => void;
};

// TODO(olha): check all variables, delete unused
export type ForwardRefContextType = {
  rootContainerRef: RefObject<HTMLDivElement> | null;
  navButtonsRef: RefObject<HTMLDivElement> | null;
  avatarSettingsButtonRef: RefObject<HTMLButtonElement> | null;
  avatarsSelectorButtonRef: RefObject<HTMLButtonElement> | null;
  avatarsSelectorVideoButtonRef: RefObject<HTMLButtonElement> | null;
  avatarsSelectorRef: RefObject<HTMLDivElement> | null;
  avatarBackgroundSelectorRef: RefObject<HTMLDivElement> | null;
  avatarBackgroundSelectorButtonRef: RefObject<HTMLButtonElement> | null;
  avatarCameraSelectorRef: RefObject<HTMLDivElement> | null;
  avatarCameraSelectorButtonRef: RefObject<HTMLButtonElement> | null;
  avatarFrameRef: RefObject<HTMLDivElement> | null;
  iFrameRef: RefObject<HTMLIFrameElement> | null;
  avatarAnchorRef: RefObject<HTMLDivElement> | null;
  threadMessageListRef: RefObject<HTMLDivElement> | null;
  threadMessageListAnchorRef: RefObject<ThreadMessageListHandlers> | null;
  threadInputBoxRef: RefObject<HTMLElement> | null;
  endOfMessagesRef: RefObject<HTMLDivElement> | null;
};

type ForwardRefProviderProps = {
  children: React.ReactNode;
};

/**
 * ForwardRefContext is workaround for pass refs to components without using ForwardRef.
 */
const ForwardRefContext = createContext<ForwardRefContextType>({
  rootContainerRef: null,
  avatarSettingsButtonRef: null,
  navButtonsRef: null,
  avatarsSelectorButtonRef: null,
  avatarsSelectorVideoButtonRef: null,
  avatarsSelectorRef: null,
  avatarBackgroundSelectorRef: null,
  avatarBackgroundSelectorButtonRef: null,
  avatarCameraSelectorRef: null,
  avatarCameraSelectorButtonRef: null,
  avatarFrameRef: null,
  iFrameRef: null,
  avatarAnchorRef: null,
  threadMessageListRef: null,
  threadMessageListAnchorRef: null,
  threadInputBoxRef: null,
  endOfMessagesRef: null,
});

const ForwardRefContextProvider = ({ children }: ForwardRefProviderProps) => {
  const rootContainerRef = useRef<HTMLDivElement>(null);
  const avatarSettingsButtonRef = useRef<HTMLButtonElement>(null);
  const navButtonsRef = useRef<HTMLDivElement>(null);
  const avatarsSelectorButtonRef = useRef<HTMLButtonElement>(null);
  const avatarsSelectorVideoButtonRef = useRef<HTMLButtonElement>(null);
  const avatarsSelectorRef = useRef<HTMLDivElement>(null);
  const avatarBackgroundSelectorRef = useRef<HTMLDivElement>(null);
  const avatarBackgroundSelectorButtonRef = useRef<HTMLButtonElement>(null);
  const avatarCameraSelectorRef = useRef<HTMLDivElement>(null);
  const avatarCameraSelectorButtonRef = useRef<HTMLButtonElement>(null);
  const avatarFrameRef = useRef<HTMLDivElement>(null);
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const avatarAnchorRef = useRef<HTMLDivElement>(null);
  const threadMessageListRef = useRef<HTMLDivElement>(null);
  const threadMessageListAnchorRef = useRef<ThreadMessageListHandlers>(null);
  const threadInputBoxRef = useRef<HTMLTextAreaElement>(null);
  const endOfMessagesRef = useRef<HTMLDivElement>(null);

  return (
    <ForwardRefContext.Provider
      value={{
        rootContainerRef,
        avatarSettingsButtonRef,
        navButtonsRef,
        avatarsSelectorButtonRef,
        avatarsSelectorVideoButtonRef,
        avatarsSelectorRef,
        avatarBackgroundSelectorRef,
        avatarBackgroundSelectorButtonRef,
        avatarCameraSelectorRef,
        avatarCameraSelectorButtonRef,
        avatarFrameRef,
        iFrameRef,
        avatarAnchorRef,
        threadMessageListAnchorRef,
        threadMessageListRef,
        threadInputBoxRef,
        endOfMessagesRef,
      }}
    >
      {children}
    </ForwardRefContext.Provider>
  );
};

export { ForwardRefContextProvider };
export default ForwardRefContext;
