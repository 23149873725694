import { NinjaSubscriptionPlan, TaskSkill } from './api';

export type PlanSectionType =
  | 'Standard'
  | 'Pro'
  | 'Ultra'
  | 'Teams_Standard'
  | 'Teams_Pro'
  | 'Teams_Ultra';

export interface PaymentPlanOption {
  lookup_key: string;
  amount: number;
  isDefault?: boolean;
  tasksCount?: number;
  isAnnual?: boolean;
  promotion_code?: string;
}

export interface PaymentPlanOptionWithSale extends PaymentPlanOption {
  newAmount: number;
  sale: number;
  couponAmount: number;
}

export interface PaymentsActivity {
  skill: TaskSkill;
  task_hash: string;
  timestamp: string;
  cost: number;
  status: string;
  models: PaymentsActivityModel[];
  components: PaymentsActivityComponent[];
}

export interface PaymentsActivityModel {
  platform: string;
  provider: string;
  model_name: string;
}

export interface PaymentsActivityComponent {
  component: string;
}

export interface PaymentsActivityColumn {
  header: string;
  accessor: keyof PaymentsActivity;
  render?: (activity: PaymentsActivity) => React.ReactNode;
  format?: (value: number | string) => string;
}

export enum PaymentPlanInterval {
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
}

export enum TeamsTabs {
  INDIVIDUAL = 'individual',
  BUSINESS = 'business',
}

export interface PaymentsSubscriptionInfo {
  subscription_id: string;
  inception_timestamp: string;
  is_cancelled: boolean;
  curr_period_start_timestamp: string;
  curr_period_end_timestamp: string;
  interval: PaymentPlanInterval;
  price_info: {
    amount: number;
    currency: string;
    lookup_key: string;
    credit: number;
  };
  source: 'stripe' | 'paypal';
  has_scheduled_downgrade: boolean;
  has_scheduled_plan_change: boolean;
  processing_plan_change: boolean;
  scheduled_plan_name: string | null;
}

export interface PlanOption {
  title: string;
  withBorder?: boolean;
  link?: { href: string; label: string };
  isExternalModels?: boolean;
}

export type WelcomeUpsellModalType =
  | 'open-tier-welcome-modal'
  | 'free-tier-welcome-modal'
  | 'standard-welcome-modal'
  | 'pro-welcome-modal'
  | 'ultra-welcome-modal'
  | 'enterprise-tier-welcome-modal';

export interface WelcomeUpsellData {
  title: string;
  description: string;
  continueButtonTitle: string;
  upgradeButtonTitle: string;
  isContinueButtonBlue?: boolean;
  upgradeButtonId: string;
  isUpgradeButtonBlue?: boolean;
  reverseButtonsOrder?: boolean;
  duration: number;
}

export type WelcomeUpsellContent = Record<
  WelcomeUpsellModalType,
  WelcomeUpsellData
>;

export interface CreateSubscriptionRequest {
  user_id: string;
  plan_id: string;
  seat_count?: number;
}

export type TeamGroup = 'individual' | 'teams';

export interface GetSubscriptionPlansRequest {
  user_id: string;
  group?: TeamGroup;
}

export interface SubscriptionIntent {
  subscription_id: string;
  client_secret: string;
}

export interface UpgradeDowngradeSubscriptionRequest {
  user_id: string;
  product_key: string;
  price_timestamp: number;
}

export interface SubscriptionPlanMetadata {
  paypal: {
    plan_id: string;
  };

  stripe: {
    lookup_key: string;
  };

  [key: string]: string | unknown;
}

// TODO(olha): generate types when BE will be ready
export interface SubscriptionPlan {
  currency: string;
  discounted_price: number;
  one_time_discount_amount: number;
  one_time_discount_percentage: number;
  original_price: number;
  period: PaymentPlanInterval;
  plan_id: string; // should be enum
  plan_tier: NinjaSubscriptionPlan;
  recurring_discount_amount: number;
  recurring_discount_percentage: number;
  status: string; // should be enum
  total_discount_amount: number;
  total_recurring_amount: number;
  total_due_amount: number;
  total_original_amount: number;
  metadata?: SubscriptionPlanMetadata;
  timestamp: number;
  prorated_discount_amount: number;
}

export interface CreatePaypalSubscriptionRequest {
  subscription_id: string;
  user_id: string;
}
