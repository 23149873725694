import { ArrowClockwise } from '@phosphor-icons/react';
interface InProgressIconProps {
  size?: number;
}

export const InProgressIcon = ({ size = 20 }: InProgressIconProps) => {
  return (
    <span data-e2e="in-progress-icon" className="nj-inprogress-icon">
      <span
        data-testid="arrows-clock-wise-icon"
        className="nj-inprogress-icon--animation"
        style={{ width: size, height: size }}
      >
        <ArrowClockwise size={size} />
      </span>
    </span>
  );
};
