import { Select } from 'src/components/Select';
import { SingleSelectOption } from 'src/types';
import { prepareConferenceOptions } from '../../utils/selectOptions';

interface ConferenceFieldProps {
  conferenceOptions: Array<SingleSelectOption>;
}

export const ConferenceField = ({
  conferenceOptions,
}: ConferenceFieldProps) => {
  return (
    <div className="nj-create-meeting-card--conference-select-wrapper">
      <Select<string>
        name="conference"
        isShort
        options={prepareConferenceOptions(conferenceOptions)}
      />
    </div>
  );
};
