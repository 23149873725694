import { useRef, useContext, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  useThemeActions,
  useBreakpoint,
  useOutsideClick,
  useRightPanelState,
} from 'src/hooks';
import { SideSettingsPanel } from '../SidePanel/components/SideSettingsPanel';
import ForwardRefContext from 'src/contexts/ForwardRefContext';

/* TODO(olha): rename to RightSidePanel */
export const MobileSidePanel = () => {
  const { avatarFrameRef } = useContext(ForwardRefContext);

  const settingsRef = useRef<HTMLDivElement>(null);

  const { isMobile, isDesktopAndUp, isTablet } = useBreakpoint();

  const { toggleSettingsPanelExpanded } = useThemeActions();
  const { isSettingsPanelExpanded } = useRightPanelState();

  const handleOverlayClick = () => {
    if (isDesktopAndUp || isMobile) {
      return;
    }
    if (isSettingsPanelExpanded) {
      toggleSettingsPanelExpanded(false);
    }
  };

  useOutsideClick(settingsRef, handleOverlayClick, avatarFrameRef);

  const animationClassNames = useMemo(() => {
    if (isMobile) {
      return 'nj-animate-fade';
    }
    if (isTablet) {
      return 'nj-animate-horizontal-slow-appearance';
    }
    return 'nj-animate-right-panel-width-reduce';
  }, [isMobile, isTablet]);

  return (
    <CSSTransition
      in={isSettingsPanelExpanded}
      timeout={450}
      classNames={animationClassNames}
      unmountOnExit
      nodeRef={settingsRef}
    >
      <SideSettingsPanel ref={settingsRef} />
    </CSSTransition>
  );
};
