import cn from 'classnames';
import { useThemeActions } from 'src/hooks';
import styles from './MainNavToggleButton.module.scss';
import { SVG_SIZE_M } from 'src/constants';
import { SidebarSimple } from '@phosphor-icons/react';

interface MainNavToggleButtonProps {
  className?: string;
}

/**
 * MainNavToggleButton for opening tasks list.
 */
export const MainNavToggleButton = ({
  className,
}: MainNavToggleButtonProps) => {
  const { toggleTasksListExpanded } = useThemeActions();

  const handleClick = () => {
    toggleTasksListExpanded();
  };

  return (
    <button
      className={cn(styles.root, {
        className,
      })}
      onClick={handleClick}
      data-tooltip-id="avatar-onboarding-tooltip"
      data-tooltip-place="bottom-end"
    >
      <SidebarSimple size={SVG_SIZE_M} />
    </button>
  );
};
