import { useEffect, useContext } from 'react';
import { Button } from 'src/components/Button';
import { useTimer } from 'react-timer-hook';
import AvatarModeContext, {
  AvatarModeContextType,
} from 'src/contexts/AvatarModeContext';
import { AUTOCLOSE_TIMER_DURATION } from 'src/constants';

type DefaulQueueViewProps = {
  onAutoClose?: () => void;
  onCancel?: () => void;
};

export const DefaulQueueView = ({
  onAutoClose,
  onCancel,
}: DefaulQueueViewProps) => {
  const { showAvatarQueueModal } =
    useContext<AvatarModeContextType>(AvatarModeContext);

  // set up timer if auto-close is requested by parent modal
  const { restart } = useTimer({
    expiryTimestamp: new Date(Date.now() + AUTOCLOSE_TIMER_DURATION),
    autoStart: false,
    onExpire: () => {
      onAutoClose && onAutoClose();
    },
  });

  // close the modal after the timer expires
  useEffect(() => {
    if (onAutoClose && showAvatarQueueModal) {
      restart(new Date(Date.now() + AUTOCLOSE_TIMER_DURATION));
    }
  }, [onAutoClose, showAvatarQueueModal, restart]);

  const handleCancel = (): void => {
    onCancel && onCancel();
  };

  return (
    <>
      <h5>Joining video chat...</h5>
      <Button className="nj-button nj-button--outline" onClick={handleCancel}>
        Cancel
      </Button>
    </>
  );
};
