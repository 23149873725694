import { Navigate, Route, Routes } from 'react-router-dom';
import { SignupPage } from 'src/v2/pages/auth/SignupPage';
import { SigninPage } from 'src/v2/pages/auth/SigninPage';
import { IS_SIGN_UP_REDIRECT, isEnterprise } from 'src/utils';
import { ManageTasksChatPage } from 'src/pages/ManageTasksChatPage';
import { AppRoutes } from 'src/types';
import { useMemo } from 'react';
import { VerificationPage } from 'src/v2/pages/auth/VerificationPage';
import { VerificationFailedPage } from 'src/v2/pages/auth/VerificationFailedPage';
import { ForgotPasswordPage } from 'src/v2/pages/auth/ForgotPasswordPage';
import { ResetPasswordPage } from 'src/v2/pages/auth/ResetPasswordPage';
import { ResetPasswordFailedPage } from 'src/v2/pages/auth/ResetPasswordFailedPage';
import { SignupWithEmailPage } from 'src/v2/pages/auth/SignupWithEmailPage';
import { SigninWithEmailPage } from 'src/v2/pages/auth/SigninWithEmailPage';
import { WelcomeEnterprisePage } from 'src/v2/pages/auth/WelcomeEnterprisePage';
// import { CompleteAccountSetupPage } from 'src/pages/AuthPages/CompleteAccountSetupPage';

const defaultRoutes = [
  {
    path: AppRoutes.LOGIN,
    component: <SigninPage />,
  },
  {
    path: AppRoutes.LOGIN_WITH_EMAIL,
    component: <SigninWithEmailPage />,
  },
  {
    path: AppRoutes.SIGN_UP,
    component: <SignupPage />,
  },
  {
    path: AppRoutes.SIGN_UP_WITH_EMAIL,
    component: <SignupWithEmailPage />,
  },
  {
    path: AppRoutes.FORGOT_PASSWORD,
    component: <ForgotPasswordPage />,
  },
  {
    path: AppRoutes.RESET_PASSWORD,
    component: <ResetPasswordPage />,
  },
  {
    path: AppRoutes.VERIFICATION_FAILED,
    component: <VerificationFailedPage />,
  },
  {
    path: AppRoutes.VERIFIED,
    component: <VerificationPage />,
  },
  {
    path: AppRoutes.PASSWORD_RESET_FAILED,
    component: <ResetPasswordFailedPage />,
  },
  {
    path: AppRoutes.HOME,
    component: <ManageTasksChatPage />,
  },
];

// no HOME for Enterprise
const ENTERPRISE_ROUTES = [
  AppRoutes.LOGIN,
  AppRoutes.SIGN_UP,
  AppRoutes.FORGOT_PASSWORD,
  AppRoutes.RESET_PASSWORD,
  AppRoutes.VERIFICATION_FAILED,
  AppRoutes.VERIFIED,
  AppRoutes.PASSWORD_RESET_FAILED,
];

export const PublicContent = () => {
  const isOnboardingRedirect = localStorage.getItem(IS_SIGN_UP_REDIRECT);

  const routes = useMemo(() => {
    if (isEnterprise) {
      return defaultRoutes.filter((route) =>
        ENTERPRISE_ROUTES.includes(route.path),
      );
    } else {
      return defaultRoutes;
    }
  }, []);

  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.path} path={route.path} element={route.component} />
      ))}

      {isEnterprise && (
        <Route path={AppRoutes.WELCOME} element={<WelcomeEnterprisePage />} />
      )}
      {/* for Enterprise, we redirect to Welcome page instead of Home page */}
      <Route
        path={AppRoutes.WILDCARD}
        element={
          <Navigate
            to={
              isOnboardingRedirect
                ? AppRoutes.SIGN_UP
                : isEnterprise
                  ? AppRoutes.WELCOME
                  : AppRoutes.HOME
            }
            replace
          />
        }
      />
    </Routes>
  );
};
