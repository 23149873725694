import styles from './TermsSection.module.scss';
import {
  DEFAULT_PRIVACY_POLICY_LINK,
  DEFAULT_TERMS_OF_SERVICE_LINK,
} from 'src/constants/externalLinks';

interface TermsSectionProps {
  onlyLinks?: boolean;
}

export const TermsSection = ({ onlyLinks }: TermsSectionProps) => {
  return (
    <div className={styles.root}>
      {!onlyLinks && 'By signing up, you agree to our'}&nbsp;
      <span className={styles.underlined}>
        <a
          href={DEFAULT_TERMS_OF_SERVICE_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
      </span>
      &nbsp;&amp;&nbsp;
      <span className={styles.underlined}>
        <a href={DEFAULT_PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      </span>
      &nbsp;
    </div>
  );
};
