import { createApi } from '@reduxjs/toolkit/query/react';
import { AvatarVideoPresetName, CreateMessageSharedLink } from 'src/types';
import { baseTasksQuery } from './config';

export const sharingApi = createApi({
  reducerPath: 'sharingApi',
  baseQuery: baseTasksQuery,
  endpoints: (builder) => ({
    createMessageSharedLink: builder.query<string, CreateMessageSharedLink>({
      query: (payload) => ({
        url: `/users/${payload.userId}/shared_content/${payload.messageId}`,
      }),
    }),
    createVideoSharedLink: builder.query<
      string,
      { videoId: string; videoResolution: AvatarVideoPresetName }
    >({
      query: ({ videoId, videoResolution }) => ({
        url: `/avatar/shared_video/${videoId}/${videoResolution}`,
      }),
    }),
  }),
});

export const {
  useLazyCreateMessageSharedLinkQuery,
  useLazyCreateVideoSharedLinkQuery,
} = sharingApi;
