import {
  ProposalConfirmationCard,
  Duration,
  MeetingInputTypes,
  DatetimeRangeList,
  Span,
} from '../api';
import { isObject, isString, isBoolean, isNumber } from './general.guard';
import log from 'src/utils/logger';

/**
 * isDuration() checks data against Duration data type.
 * @param data unknown
 * @returns boolean
 */
export function isDuration(data: unknown): data is Duration {
  const duration = data as Duration;

  if (!isObject(duration)) {
    log.debug(`isDuration > not an object ${JSON.stringify(duration)}`);
    return false;
  }

  if (
    'type' in duration &&
    !(
      isString(duration.type) &&
      Object.values(MeetingInputTypes).includes(
        duration.type as MeetingInputTypes,
      )
    )
  ) {
    log.debug(
      `isDuration > type not of MeetingInputTypes ${JSON.stringify(duration)}`,
    );
    return false;
  }

  if ('minutes' in duration && !isNumber(duration.minutes)) {
    log.debug(`isDuration > minutes not a number ${JSON.stringify(duration)}`);
    return false;
  }

  if ('all_day' in duration && !isBoolean(duration.all_day)) {
    log.debug(`isDuration > all_day not a boolean ${JSON.stringify(duration)}`);
    return false;
  }

  return true;
}

/**
 * isSpan() checks data against Span data type.
 * @param data unknown
 * @returns boolean
 */
export function isSpan(data: unknown): data is Span {
  const span = data as Span;

  if (!isObject(span)) {
    log.debug(`isSpan > not an object ${JSON.stringify(span)}`);
    return false;
  }

  if ('start' in span && !isString(span.start)) {
    log.debug(`isSpan > start is not a string ${JSON.stringify(span)}`);
    return false;
  }

  if ('end' in span && !isString(span.end)) {
    log.debug(`isSpan > end is not a string ${JSON.stringify(span)}`);
    return false;
  }

  return true;
}

/**
 * isDatetimeRangeList() checks data against DatetimeRangeList data type.
 * @param data unknown
 * @returns boolean
 */
export function isDatetimeRangeList(data: unknown): data is DatetimeRangeList {
  const datetimeRangeList = data as DatetimeRangeList;

  if (!isObject(datetimeRangeList)) {
    log.debug(
      `isDatetimeRangeList > not an object ${JSON.stringify(
        datetimeRangeList,
      )}`,
    );
    return false;
  }

  if (
    'type' in datetimeRangeList &&
    !(
      isString(datetimeRangeList.type) &&
      Object.values(MeetingInputTypes).includes(
        datetimeRangeList.type as MeetingInputTypes,
      )
    )
  ) {
    log.debug(
      `isDatetimeRangeList > type is not of MeetingInputTypes ${JSON.stringify(
        datetimeRangeList,
      )}`,
    );
    return false;
  }

  if ('values' in datetimeRangeList) {
    if (!Array.isArray(datetimeRangeList.values)) {
      log.debug(
        `isDatetimeRangeList > values not an array ${JSON.stringify(
          datetimeRangeList,
        )}`,
      );
      return false;
    }

    for (const value of datetimeRangeList.values.entries()) {
      if (!isSpan(value)) {
        log.debug(
          `isDatetimeRangeList > not a span ${JSON.stringify(
            datetimeRangeList,
          )}`,
        );
        return false;
      }
    }
  }
  return true;
}

/**
 * isProposalConfirmationCard() checks data against ProposalConfirmationCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isProposalConfirmationCard(
  data: unknown,
): data is ProposalConfirmationCard {
  const proposalConfirmationCard = data as ProposalConfirmationCard;

  if (!isObject(proposalConfirmationCard)) {
    log.debug(
      `isProposalConfirmationCard > not an object ${JSON.stringify(
        proposalConfirmationCard,
      )}`,
    );
    return false;
  }

  if (
    'executed' in proposalConfirmationCard &&
    !isBoolean(proposalConfirmationCard.executed)
  ) {
    log.debug(
      `isProposalConfirmationCard > executed not a boolean ${JSON.stringify(
        proposalConfirmationCard,
      )}`,
    );
    return false;
  }

  if (
    'input_id' in proposalConfirmationCard &&
    !isString(proposalConfirmationCard.input_id)
  ) {
    log.debug(
      `isProposalConfirmationCard > input_id not a string ${JSON.stringify(
        proposalConfirmationCard,
      )}`,
    );
    return false;
  }

  if (
    'payload_type' in proposalConfirmationCard &&
    proposalConfirmationCard.payload_type !== 'proposal-confirmation-card'
  ) {
    return false;
  }

  if (
    'link' in proposalConfirmationCard &&
    !isString(proposalConfirmationCard.link)
  ) {
    log.debug(
      `isProposalConfirmationCard > link not a string ${JSON.stringify(
        proposalConfirmationCard,
      )}`,
    );
    return false;
  }

  if (
    'description' in proposalConfirmationCard &&
    !isString(proposalConfirmationCard.description)
  ) {
    log.debug(
      `isProposalConfirmationCard > description not a string ${JSON.stringify(
        proposalConfirmationCard,
      )}`,
    );
    return false;
  }

  if (
    'duration' in proposalConfirmationCard &&
    !isDuration(proposalConfirmationCard.duration)
  ) {
    log.debug(
      `isProposalConfirmationCard > duration not valid ${JSON.stringify(
        proposalConfirmationCard,
      )}`,
    );
    return false;
  }

  if (
    'proposals' in proposalConfirmationCard &&
    !isDatetimeRangeList(proposalConfirmationCard.proposals)
  ) {
    log.debug(
      `isProposalConfirmationCard > proposals not valid ${JSON.stringify(
        proposalConfirmationCard,
      )}`,
    );
    return false;
  }

  return true;
}
