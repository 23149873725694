import { SVGProps } from 'react';

export const CheckMarkIcon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <path
        d="M14.3537 4.85378L6.35372 12.8538C6.30729 12.9003 6.25214 12.9372 6.19144 12.9623C6.13074 12.9875 6.06568 13.0004 5.99997 13.0004C5.93427 13.0004 5.8692 12.9875 5.8085 12.9623C5.7478 12.9372 5.69266 12.9003 5.64622 12.8538L2.14622 9.35378C2.0524 9.25996 1.99969 9.13272 1.99969 9.00003C1.99969 8.86735 2.0524 8.7401 2.14622 8.64628C2.24004 8.55246 2.36729 8.49976 2.49997 8.49976C2.63265 8.49976 2.7599 8.55246 2.85372 8.64628L5.99997 11.7932L13.6462 4.14628C13.74 4.05246 13.8673 3.99976 14 3.99976C14.1327 3.99976 14.2599 4.05246 14.3537 4.14628C14.4475 4.2401 14.5003 4.36735 14.5003 4.50003C14.5003 4.63272 14.4475 4.75996 14.3537 4.85378Z"
        fill={'currentColor'}
      />
    </svg>
  );
};
