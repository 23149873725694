import { useMemo } from 'react';
import { ResearchCard, MessageTabs, MessageTabData, GTMEvent } from 'src/types';
import { Tabs, TabPanel } from 'src/v2/commonComponents/Tabs';
import { prepareExternalModelsTabContent, sendGTMEvent } from 'src/utils';
import { renderMessageTabList } from '../MessageTabList';
import styles from './ThreadResearchCard.module.scss';

// TODO(olha): needs refactoring
import { ThreadMessageContent } from 'src/components/FlatAppearance/components/ThreadMessageContent';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';
import { ThreadResearchReferences } from 'src/components/FlatAppearance/components/ThreadResearchCard/components/ThreadResearchReferences';
import { ThreadResearchMedia } from 'src/components/FlatAppearance/components/ThreadResearchCard/components/ThreadResearchMedia';
import { SuperAgentUpsell } from '../SuperAgentUpsell';
import { useMessageTab, useSession, useExternalModels } from 'src/hooks';
import { FeedbackRating } from 'src/v2/components/FeedbackRating';

interface ThreadResearchCardProps {
  messageId?: string;
  researchCard?: ResearchCard;
  isStreaming?: boolean;
}

export const ThreadResearchCard = ({
  messageId,
  researchCard,
  isStreaming,
}: ThreadResearchCardProps) => {
  const {
    appUser: { settings },
  } = useSession();
  const { textExternalModels } = useExternalModels();

  const { search_result, deep_research_result, generative_ai_result } =
    researchCard?.data || {};

  const { referenced_model_summary } = generative_ai_result || {};
  const { references: external_models_result } = referenced_model_summary || {};
  const { web_page_references, image_references, video_references } =
    search_result || {};

  const withSuperAgent = useMemo(
    () => !!deep_research_result,
    [deep_research_result],
  );

  const tabsData: MessageTabData[] = useMemo(
    () => [
      ...(search_result
        ? [
            {
              tabName: MessageTabs.SEARCH_RESULT,
              iconName: 'ninjaOutlined',
              tabTitle: 'Ninja 405B',
              content: search_result.summary || '',
              references: web_page_references,
            },
          ]
        : []),
      ...(withSuperAgent || !settings?.super_agent?.is_capable
        ? [
            {
              tabName: MessageTabs.DEEP_RESEARCH,
              iconName: 'ninjaPixProLogo',
              tabTitle: 'Ninja SuperAgent',
              content: deep_research_result?.summary || '',
              references: deep_research_result?.web_page_references,
            },
          ]
        : []),
      ...(external_models_result
        ? prepareExternalModelsTabContent(external_models_result).map(
            ({ tabName, tabTitle, iconName, content }) => ({
              tabName,
              iconName,
              tabTitle,
              content,
            }),
          )
        : []),
    ],
    [
      search_result,
      web_page_references,
      deep_research_result,
      external_models_result,
      withSuperAgent,
      settings?.super_agent?.is_capable,
    ],
  );

  const { getTabSRegistry, onTabClick } = useMessageTab({
    messageId,
    tabsData,
  });

  const handleTabClick = (tabName: string) => {
    onTabClick(tabName);

    if (
      tabName === MessageTabs.DEEP_RESEARCH &&
      !settings?.super_agent?.is_capable
    ) {
      sendGTMEvent(GTMEvent.SUPERAGENT_UPSELL_TAB_CLICK);
      return;
    }

    if (withSuperAgent) {
      deep_research_result?.summary
        ? sendGTMEvent(GTMEvent.SUPERAGENT_COMPLETE_CLICK)
        : sendGTMEvent(GTMEvent.SUPERAGENT_IN_PROGRESS_CLICK);
    }
  };

  if (!researchCard) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Tabs<string>
        defaultActiveTab={MessageTabs.SEARCH_RESULT}
        onTabClick={handleTabClick}
      >
        {renderMessageTabList({
          data: tabsData,
          withSuperAgent,
          tabRegistry: getTabSRegistry(),
          externalModels: textExternalModels,
        })}

        {tabsData.map(({ tabName, content, references }) => (
          <TabPanel key={tabName} tab={tabName}>
            {content.length === 0 ? (
              <>
                {(tabName !== MessageTabs.DEEP_RESEARCH ||
                  !!deep_research_result) && <ProgressBar count={3} />}

                {tabName === MessageTabs.DEEP_RESEARCH && (
                  <SuperAgentUpsell description="An advanced research agent that dives deep into your questions, leveraging real-time web data and relevant sources to deliver more accurate and richer answers." />
                )}
              </>
            ) : (
              <div className={styles.container}>
                <ThreadMessageContent
                  content={content}
                  isStreaming={isStreaming}
                  references={references}
                />

                {references && (
                  <ThreadResearchReferences researchReferences={references} />
                )}

                {tabName === MessageTabs.SEARCH_RESULT && (
                  <ThreadResearchMedia
                    images={image_references || []}
                    videos={video_references || []}
                  />
                )}

                {tabName === MessageTabs.DEEP_RESEARCH && !isStreaming && (
                  <FeedbackRating messageId={messageId} />
                )}
              </div>
            )}
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};
