import { Message } from 'src/types';
import { ChoicesLayoutCard } from 'src/pages/ManageTasksChatPage/components/ChatMessage/components/ChoicesLayoutCard';

interface ChoicesCardProps {
  taskId?: string;
  message: Message;
}

export const ChoicesCard = ({ taskId, message }: ChoicesCardProps) => {
  if (!taskId) {
    return null;
  }
  return (
    <ChoicesLayoutCard
      className="nj-task-scratchpad--section nj-task-scratchpad--choices-card"
      message={message}
      taskId={taskId}
    />
  );
};
