import {
  performanceMarkerEnd,
  performanceMarkerStart,
} from './performanceMarkers';

import { get_access_token } from 'src/utils';
import { tokenBaseUrl } from 'src/store/constants';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';

/* eslint-disable @typescript-eslint/no-explicit-any */
const translationBaseUrl =
  'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0';

const TRANSLATION_TOKEN_KEY = 'translation_token';

export const getTranslationToken = async (): Promise<string> => {
  const translationToken = localStorage.getItem(TRANSLATION_TOKEN_KEY);
  // if the item doesn't exist, return null
  if (translationToken) {
    const decodedTranslationToken = jwtDecode(translationToken);
    if (dayjs.unix(Number(decodedTranslationToken.exp)).isAfter(dayjs())) {
      return translationToken;
    }
  }

  const usersToken = await get_access_token();
  const decodedUsersToken = jwtDecode(usersToken);
  const user_id = (decodedUsersToken as Record<string, string>)[
    'custom:ninja_user_id'
  ];
  const response = await fetch(
    `${tokenBaseUrl}/msft/get-translation-token?user_id=${user_id}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${usersToken}`,
        'Content-Type': 'application/json',
      }),
    },
  );
  const parsedResponse = await response.json();
  localStorage.setItem(TRANSLATION_TOKEN_KEY, parsedResponse.token || '');
  return parsedResponse.token;
};

/**
 * Map to cache translations.
 */
export const translations: Map<string, Map<string, string>> = new Map();

const cacheTranslation = (
  originalText: string,
  translatedText: string,
  originalLanguage: string,
  translatedLanguage: string,
) => {
  const originalTextMap =
    translations.get(originalText) || new Map<string, string>();

  originalTextMap.set(translatedLanguage.split('-')[0], translatedText);

  translations.set(originalText, originalTextMap);

  // If we have original language, we can cache the reverse translation
  if (originalLanguage) {
    const translatedTextMap =
      translations.get(translatedText) || new Map<string, string>();

    translatedTextMap.set(originalLanguage, originalText);

    translations.set(translatedText, translatedTextMap);
  }
};

export const translateText = async (
  text: string,
  toLanguages: string[],
  originLanguage?: string,
) => {
  performanceMarkerStart('translate_text');
  const token = await getTranslationToken();
  const languagesToRequestTranslations: string[] = [];

  /**
   * For each passed in language check the cache to se if we already have the translation.
   * If we don't have translation for exact text to exact language, we will have to make a request
   * to get the translation.
   */
  toLanguages.forEach((toLanguage) => {
    const cachedTranslationText = translations.get(text)?.get(toLanguage);
    if (
      !cachedTranslationText &&
      toLanguage === 'en' &&
      originLanguage === 'en-US'
    ) {
      cacheTranslation(text, text, toLanguage, toLanguage);
    } else if (!cachedTranslationText) {
      languagesToRequestTranslations.push(toLanguage);
    } else {
      cacheTranslation(text, cachedTranslationText, '', toLanguage);
    }
  });

  if (languagesToRequestTranslations.length > 0) {
    const response = await fetch(
      `${translationBaseUrl}&to=${languagesToRequestTranslations.join(',')}`,
      {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify([
          {
            text,
          },
        ]),
      },
    );

    const parsedResponse = await response.json();

    parsedResponse?.[0]?.translations?.forEach((translation: any) => {
      cacheTranslation(
        text,
        translation.text,
        parsedResponse?.[0].detectedLanguage.language,
        translation.to,
      );
    });
  }

  performanceMarkerEnd('translate_text');

  return translations;
};

export const translateTextToSpecificLanguage = async (
  text: string,
  toLanguage: string,
): Promise<string> => {
  const token = await getTranslationToken();
  const response = await fetch(`${translationBaseUrl}&to=${toLanguage}`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify([
      {
        text,
      },
    ]),
  });

  const parsedResponse = await response.json();

  return parsedResponse[0]?.translations[0]?.text || '';
};
