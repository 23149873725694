import { useContext } from 'react';
import { Microphone } from '@phosphor-icons/react';
import classNames from 'classnames';
import AudioContext from 'src/contexts/AudioContext';
import { Button } from 'src/components/Button';

interface MicrophoneButtonProps {
  iconSize: number;
}

export const MicrophoneButton = ({ iconSize }: MicrophoneButtonProps) => {
  const { microphoneTriggerRef, recordInProgress, metaHumanTalking } =
    useContext(AudioContext);

  const handleMicClick = () => {
    microphoneTriggerRef?.current?.onToggleAction();
  };

  return (
    <Button
      className={classNames('nj-meta-human--button-control', 'mic', {
        selected: recordInProgress,
        disabled: metaHumanTalking,
      })}
      onClick={handleMicClick}
    >
      <Microphone
        size={iconSize}
        weight={recordInProgress ? 'fill' : 'regular'}
      />
    </Button>
  );
};
