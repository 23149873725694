import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  GTMEvent,
  Message,
  TaskState,
  isMessageWithCreationTaskId,
} from 'src/types';
import { useCurrentConversationTasks, useTaskById } from 'src/hooks';
import { sendGTMEvent } from 'src/utils';
import styles from './BlockedTaskMessage.module.scss';

interface BlockedTaskMessageProps {
  message: Message;
}

export const BlockedTaskMessage = ({ message }: BlockedTaskMessageProps) => {
  const { getTaskFromConversationById } = useCurrentConversationTasks();
  const { setCurrentTaskId } = useTaskById();
  const { onToggleVisibleTaskViewModal } = useContext(SessionContext);

  if (!isMessageWithCreationTaskId(message)) {
    return null;
  }

  const { task_id } = message.payload || {};

  const taskState =
    getTaskFromConversationById(task_id)?.state || TaskState.IN_PROGRESS;

  if (taskState !== TaskState.BLOCKED) {
    return null;
  }

  const handleClick = () => {
    setCurrentTaskId(task_id);
    onToggleVisibleTaskViewModal(true);
    sendGTMEvent(GTMEvent.TASK_VIEW_OPENING, {
      task_id,
      task_state: taskState,
    });
  };

  return (
    <div className={styles.root}>
      <span>We need you to confirm the detail.</span>
      <button onClick={handleClick} className={styles.openTaskButton}>
        Click here
      </button>
    </div>
  );
};
