import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent } from 'src/types';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const eventVariables = {
      page_path: location.pathname,
    };

    sendGTMEvent(GTMEvent.PAGE_VIEW, eventVariables);
  }, [location.pathname]);
};
