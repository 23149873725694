import { createApi } from '@reduxjs/toolkit/query/react';
import { baseAuthQuery } from './config';
import { EmailInfoResponse } from 'src/types';

export enum AuthTags {
  Email = 'Email',
}

export const publicAuthApi = createApi({
  reducerPath: 'publicAuthApi',
  tagTypes: [AuthTags.Email],
  baseQuery: baseAuthQuery,
  endpoints: (builder) => ({
    checkEmailInfo: builder.query<EmailInfoResponse, string>({
      query: (email) => `/email-info?email=${email}`,
      providesTags: () => [AuthTags.Email],
    }),
    resendVerificationEmail: builder.mutation<void, string>({
      query: (email) => ({
        url: `/resend-verification-email?email=${email}`,
        method: `POST`,
      }),
    }),
  }),
});

export const {
  useLazyCheckEmailInfoQuery,
  useResendVerificationEmailMutation,
} = publicAuthApi;
