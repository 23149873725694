import { SEO_LOGIN_DESCRIPTION, SEO_LOGIN_TITLE } from 'src/constants';
import { BasePublicPage } from '../BasePublicPage';
import { EnterpriseMeetNinjaSection } from '../components/MeetNinjaSection';
import { Button } from 'src/v2/commonComponents/Button';
import { TermsSection } from '../components/TermsSection';
import styles from './WelcomeEnterprisePage.module.scss';
import { AppRoutes } from 'src/types';
import { useNavigate } from 'react-router-dom';

export const WelcomeEnterprisePage = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate(AppRoutes.LOGIN);
  };

  const handleSignupClick = () => {
    navigate(AppRoutes.SIGN_UP);
  };

  return (
    <BasePublicPage
      seoTitle={SEO_LOGIN_TITLE}
      seoDescription={SEO_LOGIN_DESCRIPTION}
    >
      <EnterpriseMeetNinjaSection />

      <div className={styles.root}>
        <h3 className={styles.title}>Get started</h3>
        <p className={styles.description}>
          <span>Ninja is available for all Amazon employees.</span>
          <span>Signup for free using your @amazon.com email.</span>
        </p>

        <div className={styles.actions}>
          <Button onClick={handleLoginClick} className={styles.button}>
            Log in
          </Button>

          <Button onClick={handleSignupClick} className={styles.button}>
            Sign up
          </Button>
        </div>

        <TermsSection onlyLinks />
      </div>
    </BasePublicPage>
  );
};
