import { useEffect, useState } from 'react';
import { DEFAULT_CHAT_ID } from 'src/constants';
import { handleScrollToMessageByTask } from 'src/utils';
import { useCurrentConversationStatuses } from 'src/hooks';
import { useThreadRegistry } from './threadHooks/useThreadRegistry';
import { useConversationParams } from './conversationHooks/useConversationParams';

export const useScrollToTask = () => {
  const { isConversationSuccess, isCurrentConversationLoading } =
    useCurrentConversationStatuses();
  const { currentConversationId } = useConversationParams();

  const { isSubmitHappened } = useThreadRegistry();

  const [activeThreadAnchor, setActiveThreadAnchor] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (currentConversationId === DEFAULT_CHAT_ID) {
      setActiveThreadAnchor(null);
    }
  }, [currentConversationId]);

  useEffect(() => {
    if (isSubmitHappened) {
      setActiveThreadAnchor(null);
    }
  }, [isSubmitHappened]);

  useEffect(() => {
    if (
      activeThreadAnchor &&
      isConversationSuccess &&
      !isCurrentConversationLoading
    ) {
      setTimeout(() => {
        // (olha): this handler should be called after useScrollToBottomThread
        handleScrollToMessageByTask(activeThreadAnchor);
      }, 0);
    }
  }, [activeThreadAnchor, isConversationSuccess, isCurrentConversationLoading]);

  return { activeThreadAnchor, setActiveThreadAnchor };
};
