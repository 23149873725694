import { useFormContext } from 'react-hook-form';
import { MapPin } from '@phosphor-icons/react';
import { MeetingCardField } from '../MeetingCardField';
import TextareaAutosize from 'react-textarea-autosize';
import type { MeetingCardFormData } from '../../CreateMeetingCard';
import { ConferenceField } from '../ConferenceField';
import { SingleSelectOption } from 'src/types';

const SVG_SIZE = 20;

interface LocationVideoFieldProps {
  conferenceOptions: Array<SingleSelectOption>;
}

export const LocationVideoField = ({
  conferenceOptions,
}: LocationVideoFieldProps) => {
  const { register } = useFormContext<MeetingCardFormData>();

  return (
    <MeetingCardField label="Location & Video call">
      <div className="nj-create-meeting-card--form-field-wrapper nj-create-meeting-card--location-field">
        <div className="nj-create-meeting-card--form-field-wrapper">
          <TextareaAutosize
            minRows={1}
            className="nj-create-meeting-card--input nj-create-meeting-card--location-input"
            {...register('location')}
          />

          <MapPin
            className="nj-create-meeting-card--location-input-icon"
            size={SVG_SIZE}
          />
        </div>

        <ConferenceField conferenceOptions={conferenceOptions} />
      </div>
    </MeetingCardField>
  );
};
