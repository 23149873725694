import styles from './ResetPasswordFailedSection.module.scss';
import { Button } from 'src/v2/commonComponents/Button';
import { useQueryParams } from 'src/hooks';
import { resetPassword } from 'aws-amplify/auth';
import { toast } from 'react-toastify';
import { ServiceError } from '@aws-amplify/core/src/types/errors';

export const ResetPasswordFailedSection = () => {
  const { searchParams } = useQueryParams();
  const { username } = searchParams;

  const handleResendLink = async () => {
    try {
      if (username) {
        await resetPassword({
          username,
        });
        toast('Password reset link resent');
      }
    } catch (error) {
      toast.error(
        error
          ? (error as ServiceError).message
          : 'Something went wrong, try again later',
      );
    }
  };

  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>Password reset failed</h2>
      <p className={styles.verifiedText}>
        The reset link is either invalid or has expired
      </p>
      <Button onClick={handleResendLink}>Resend password reset link</Button>
    </div>
  );
};
