interface AvatarSelectorMobileItemProps {
  title: string;
  subtitle?: string;
  content: React.ReactNode;
}

export const AvatarSelectorMobileItem = ({
  title,
  subtitle,
  content,
}: AvatarSelectorMobileItemProps) => {
  return (
    <div>
      <div className="nj-accordion--label">
        <div className="nj-accordion--label-content">
          <p className="nj-accordion--title">{title}</p>
          {!!subtitle && <p className="nj-accordion--subtitle">{subtitle}</p>}
        </div>
      </div>

      <div className="nj-accordion--content-wrapper">{content}</div>
    </div>
  );
};
