import { MeetNinjaSection } from 'src/v2/pages/auth/components/MeetNinjaSection/MeetNinjaSection';
import { BasePublicPage } from 'src/v2/pages/auth/BasePublicPage/BasePublicPage';
import { SEO_SIGN_UP_DESCRIPTION, SEO_SIGN_UP_TITLE } from 'src/constants';
import { MainEmailSignupSection } from 'src/v2/pages/auth/components/MainEmailSignupSection';

export const SignupWithEmailPage = () => {
  return (
    <BasePublicPage
      seoTitle={SEO_SIGN_UP_TITLE}
      seoDescription={SEO_SIGN_UP_DESCRIPTION}
    >
      <>
        <MeetNinjaSection isSignup />
        <MainEmailSignupSection />
      </>
    </BasePublicPage>
  );
};
