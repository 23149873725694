import { Fragment, ReactNode, useContext } from 'react';
import DebugContext from 'src/contexts/DebugContext';
import { Message, ChatMode } from 'src/types';
import { useSession } from 'src/hooks';
import { DebugInfo } from '../DebugInfo';

interface DebugMessageWrapperProps {
  message: Message;
  children: ReactNode;
}

export const DebugMessageWrapper = ({
  message,
  children,
}: DebugMessageWrapperProps) => {
  const { debugMode } = useContext(DebugContext);
  const { chatMode } = useSession();

  return (
    <Fragment>
      {debugMode && chatMode !== ChatMode.AVATAR && (
        <DebugInfo message={message} />
      )}

      {children}
    </Fragment>
  );
};
