type ProgressBarProps = {
  progress: number;
};
export const ProgressBar = ({ progress }: ProgressBarProps) => {
  return (
    <div className="nj-progress-bar-empty">
      <div
        className="nj-progress-bar-filled"
        style={{
          left: progress > 100 ? 0 : `${progress - 100}%`,
        }}
      />
    </div>
  );
};
