import { useContext, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLineLeft } from '@phosphor-icons/react';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { useThemeActions, useColorTheme } from 'src/hooks';
import styles from './AppNav.module.scss';
import { SVG_SIZE_M } from 'src/constants';
import logoHorizontalLight from 'src/images/logos/logo-light.svg';
import logoHorizontalDark from 'src/images/logos/logo-dark.svg';

export const AppNav = () => {
  const { toggleTasksListExpanded } = useThemeActions();
  const { isDarkTheme } = useColorTheme();

  const { isFullscreen } = useContext(AvatarModeContext);

  const onCloseTasksList = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    toggleTasksListExpanded();
  };

  if (isFullscreen) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Link to="/" className={styles.logo}>
        <img
          src={isDarkTheme ? logoHorizontalLight : logoHorizontalDark}
          alt="Logo"
        />
      </Link>

      <button onClick={onCloseTasksList} className={styles.toggleButton}>
        <ArrowLineLeft size={SVG_SIZE_M} />
      </button>
    </div>
  );
};
