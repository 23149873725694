import { useMemo } from 'react';
import { useCollapse } from 'react-collapsed';
import { Markdown } from 'src/components/Markdown';
import {
  EnvelopeSimpleOpen,
  EnvelopeSimple,
  CaretDown,
  CaretUp,
  ArrowRight,
} from '@phosphor-icons/react';
import {
  EmailCard as EmailCardType,
  EmailCollaborator,
  Message,
} from 'src/types';
import { formatTimestampToHoursMinutes } from 'src/utils';
import { useTimeZone } from 'src/hooks';

interface EmailCardProps {
  message: Message;
}

const SVG_SIZE = 24;
const ARROW_SIZE = 20;

// TODO(olha): current EmailLayoutCard component doesn't work properly. Needs refactoring
export const EmailCard = ({ message }: EmailCardProps) => {
  const { userTimeZone } = useTimeZone();

  const { payload, timestamp } = message;
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const { sender, to, cc, subject, body } = payload as EmailCardType;

  const emailRecipients = useMemo(
    () =>
      to
        .map(({ email_address }: EmailCollaborator) => email_address)
        .join(', '),
    [to],
  );

  const emailCC = useMemo(
    () =>
      cc
        .map(({ email_address }: EmailCollaborator) => email_address)
        .join(', '),
    [cc],
  );

  const emailSender = useMemo(
    () => `${sender.display_name} (${sender.email_address})`,
    [sender],
  );
  const messageTime = useMemo(
    () => formatTimestampToHoursMinutes(timestamp, userTimeZone),
    [timestamp, userTimeZone],
  );

  if (!payload) {
    return null;
  }

  return (
    <div className="nj-task-scratchpad--section nj-task-scratchpad--email-card">
      <div className="nj-task-scratchpad--email-header">
        <span className="nj-task-scratchpad--email-card-envelope">
          {isExpanded ? (
            <EnvelopeSimpleOpen size={SVG_SIZE} />
          ) : (
            <EnvelopeSimple size={SVG_SIZE} />
          )}
        </span>
        <span className="nj-task-scratchpad--message--time">{messageTime}</span>
      </div>
      <button
        className="nj-task-scratchpad--email-card-header"
        {...getToggleProps()}
      >
        <div className="nj-task-scratchpad--email-card-header-content">
          <span className="nj-task-scratchpad--email-card-title">
            {sender.email_address}
          </span>

          <ArrowRight size={SVG_SIZE} />

          <span className="nj-task-scratchpad--email-card-title">
            {emailRecipients}
          </span>
        </div>
        {isExpanded ? (
          <CaretUp size={ARROW_SIZE} />
        ) : (
          <CaretDown size={ARROW_SIZE} />
        )}
      </button>

      <div {...getCollapseProps()}>
        <div className="nj-task-scratchpad--email-card-container">
          <h6 className="nj-task-scratchpad--email-card-subject">{subject}</h6>

          <div className="nj-task-scratchpad--email-card-container-headers">
            <p>
              <b>From:</b>&nbsp; {emailSender}
            </p>

            <p>
              <b>To:</b>&nbsp; {emailRecipients}
            </p>

            {emailCC && (
              <p>
                <b>CC:</b> {emailCC}
              </p>
            )}
          </div>

          <div className="nj-task-scratchpad--email-card-body">
            <Markdown>{body}</Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};
