import { Button } from 'src/components/Button';

type ErrorViewProps = {
  message?: string;
  onCancel?: () => void;
};

export const ErrorView = ({ message = '', onCancel }: ErrorViewProps) => {
  const handleCancel = (): void => {
    onCancel && onCancel();
  };

  return (
    <>
      <h5>Invalid Request</h5>
      {message && <p>{message}</p>}
      <div className="nj-avatar-joining-queue--buttons">
        <Button className="nj-button nj-button--outline" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};
