import { ArrowSquareOut } from '@phosphor-icons/react';
import './ViewInBrowserButton.scss';
import { useBreakpoint } from 'src/hooks';

const SVG_SIZE = 20;
const SVG_SIZE_MOBILE = 24;

type ViewInBrowserButtonProps = {
  url: string;
};

export const ViewInBrowserButton = ({ url }: ViewInBrowserButtonProps) => {
  const { isMobileOrTablet } = useBreakpoint();
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="nj-view-in-browser-button"
      onClick={(e) => e.stopPropagation()}
    >
      <ArrowSquareOut size={isMobileOrTablet ? SVG_SIZE_MOBILE : SVG_SIZE} />
      {!isMobileOrTablet && <span>View in browser</span>}
    </a>
  );
};
