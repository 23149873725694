import { AppRoutes, GTMEvent } from 'src/types';
import { sendGTMEvent } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import { useSubscriptionInfo } from 'src/hooks';
import { toast } from 'react-toastify';
import { SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE } from 'src/constants';

type UpgradeButtonProps = {
  onClick?: () => void;
  title?: string;
  dataGTM: string;
};

export const UpgradeButton = ({
  onClick,
  title = 'Upgrade',
  dataGTM,
}: UpgradeButtonProps) => {
  const navigate = useNavigate();
  const { subscriptionInfo, isSubscriptionInfoLoading } = useSubscriptionInfo();

  const handleUpgradeClick = () => {
    if (
      !!subscriptionInfo?.is_cancelled ||
      !!subscriptionInfo?.has_scheduled_downgrade ||
      !!subscriptionInfo?.has_scheduled_plan_change ||
      !!subscriptionInfo?.processing_plan_change
    ) {
      toast.error(SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE);
    } else {
      navigate(AppRoutes.PAYMENT);
    }
    if (onClick) {
      onClick();
    }
    sendGTMEvent(GTMEvent.UPGRADE_BUTTON_CLICKS);
  };

  return (
    <button
      id={dataGTM}
      className="nj-upgrade-button"
      onClick={handleUpgradeClick}
      disabled={isSubscriptionInfoLoading}
    >
      {title}
    </button>
  );
};
