import { useResendVerificationEmailMutation } from 'src/store/services';
import { toast } from 'react-toastify';
import { TokenApiHttpError } from 'src/types';
import styles from './EmailVerificationHint.module.scss';

type EmailVerificationHintProps = {
  email?: string;
};

export const EmailVerificationHint = ({
  email,
}: EmailVerificationHintProps) => {
  const [resendVerificationEmail] = useResendVerificationEmailMutation();

  const handleResendLink = async () => {
    try {
      if (email) {
        await resendVerificationEmail(email).unwrap();
        toast('Verification email was resent');
      }
    } catch (error) {
      toast.error(
        (error as TokenApiHttpError)?.data?.detail
          ? (error as TokenApiHttpError).data.detail
          : 'Something went wrong, try again later',
      );
    }
  };

  return (
    <div>
      <p className={styles.verifiedText}>
        Account is already registered.&nbsp;
        <button className={styles.verifiedButton} onClick={handleResendLink}>
          Resend the verification email
        </button>
      </p>
    </div>
  );
};
