import { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { useTimer } from 'react-timer-hook';
import { VideoCamera } from '@phosphor-icons/react';
import {
  useSession,
  useBreakpoint,
  useBanner,
  useAvatarBanner,
} from 'src/hooks';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { AvatarQueue, QueueMessageType, BannerType } from 'src/types';
import { Button } from 'src/components/Button';
import { getQueuePosition } from 'src/utils';
import { QUEUE_HOLDING_DURATION } from 'src/constants';

const SVG_SIZE = 24;

// TODO(olha): component needs deep refactoring .was moving from legacy AvatarBanner component
export const AvatarQueueBanner = () => {
  const { isTabletAndUp, isDesktopAndUp } = useBreakpoint();
  const { onCancelAvatarQueue } = useAvatarBanner();
  const { removeBanner, bannersSet } = useBanner();

  const { avatarQueue } = useSession();
  const { setShowAvatarQueueModal } = useContext(AvatarModeContext);

  const { positionInQueue, message, messageType } = (avatarQueue ||
    {}) as AvatarQueue;

  const { minutes, seconds, restart, pause } = useTimer({
    expiryTimestamp: new Date(Date.now() + QUEUE_HOLDING_DURATION),
    autoStart: false,
    onExpire: () => {
      handleCancel();
    },
  });

  const handleCancel = () => {
    pause();
    onCancelAvatarQueue();
  };

  const handleStartVideoChat = () => {
    pause();
    removeBanner(BannerType.AVATAR_QUEUE);
    setShowAvatarQueueModal(true);
  };

  useEffect(() => {
    // if avatar video stream is ready, close the banner
    if (
      messageType === QueueMessageType.AVAILABLE &&
      bannersSet.includes(BannerType.AVATAR_QUEUE)
    ) {
      restart(new Date(Date.now() + QUEUE_HOLDING_DURATION));
    }
  }, [messageType, bannersSet, restart]);

  const bannerNotification = () => {
    switch (messageType) {
      case QueueMessageType.AVAILABLE:
        // TODO: add expectedWaitingTime to the message + shared useTimer
        return (
          <>
            <div className="nj-banner--notification">
              <h5>
                {isTabletAndUp ? "It's your turn now!" : "Ninja's ready!"}
              </h5>
              {isTabletAndUp && (
                <p>
                  {isDesktopAndUp ? 'Video chat is available. ' : ''}We will
                  hold you spot for the next{' '}
                  <strong>
                    {`${minutes}m`}{' '}
                    {seconds < 10 ? `0${seconds}s` : `${seconds}s`}
                  </strong>
                  .
                </p>
              )}
            </div>
            <div className="nj-banner--buttons">
              {isTabletAndUp && (
                <Button
                  className="nj-button nj-button--basic nj-banner--button"
                  onClick={handleCancel}
                >
                  Leave the queue...
                </Button>
              )}
              <Button
                className="nj-button nj-button--outline nj-banner--button"
                onClick={handleStartVideoChat}
              >
                Start video{isTabletAndUp ? ' chat' : ''}
              </Button>
            </div>
          </>
        );
      case QueueMessageType.WAITING_ROOM:
        return (
          <>
            <div className="nj-banner--notification">
              <h5>Your place in the queue is saved</h5>
              {isTabletAndUp && positionInQueue && (
                <p>
                  You are currently in the {getQueuePosition(positionInQueue)}{' '}
                  position.
                </p>
              )}
            </div>
            {isTabletAndUp && (
              <div className="nj-banner--buttons">
                <Button
                  className="nj-button nj-button--outline nj-banner--button"
                  onClick={handleCancel}
                >
                  Leave the queue...
                </Button>
              </div>
            )}
          </>
        );
      case QueueMessageType.QUOTA_EXCEEDED:
        return (
          <>
            <div className="nj-banner--notification">
              <h5>Quota exceeded</h5>
              <p>
                {isTabletAndUp
                  ? 'Your quota for using avatar has exceeded maximum allowed time per day.'
                  : 'Exceeded your session time'}
              </p>
            </div>
          </>
        );
      case QueueMessageType.INVALID_REQUEST:
        return (
          <>
            <div className="nj-banner--notification">
              <h5>Invalid request</h5>
              <p>
                {isDesktopAndUp
                  ? 'Provided user session is not valid for loading avatar video streaming, re-login to fix this issue.'
                  : 'Re-login to fix this issue.'}
              </p>
            </div>
          </>
        );
      case QueueMessageType.UNAVAILABLE:
        return (
          <>
            <div className="nj-banner--notification">
              <h5>Unavailable</h5>
              <p>
                {isTabletAndUp
                  ? 'Avatar is currently unavailable. Try again later.'
                  : 'Try again later.'}
              </p>
            </div>
          </>
        );
      // todo: add case unavailable
      default:
        return (
          <>
            <div className="nj-banner--notification">
              <h5>Video chat with Ninja</h5>
              <p>Joining video chat... {isDesktopAndUp && message}</p>
            </div>
          </>
        );
    }
  };

  return (
    <div
      className={classNames(
        'nj-common-banner',
        'nj-banner',
        'nj-banner--queue',
      )}
    >
      <div className="nj-banner--text-wrapper">
        <div className="nj-banner--icon">
          <VideoCamera size={SVG_SIZE} weight="fill" />
        </div>
        {bannerNotification()}
      </div>
    </div>
  );
};
