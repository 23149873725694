/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SuperAgentModels {
  TURBO = 'turbo',
  NEXUS = 'nexus',
  APEX = 'apex',
}
