import {
  createContext,
  useState,
  useRef,
  RefObject,
  Dispatch,
  SetStateAction,
} from 'react';

export interface MicrophoneTriggerHandlers {
  stopRecording: (skipInterrupt?: boolean) => void;
  onToggleAction: () => void;
}

export interface StopResponseTriggerHandlers {
  stopResponse: () => void;
}

export type AudioContextType = {
  detectedLanguage?: string | undefined;
  microphoneClasses: string; // deprecated
  recordInProgress: boolean;
  setDetectedLanguage: (value: string) => void;
  setMicrophoneClasses: Dispatch<SetStateAction<string>>; // deprecated
  setRecordInProgress: Dispatch<SetStateAction<boolean>>;
  microphoneTriggerRef: RefObject<MicrophoneTriggerHandlers> | null;
  stopResponseTriggerRef: RefObject<StopResponseTriggerHandlers> | null;
  metaHumanTalking: boolean;
  setMetaHumanTalking: (value: boolean) => void;
};

type AudioContextProviderProps = {
  children: React.ReactNode;
};

const AudioContext = createContext<AudioContextType>({
  microphoneClasses: '',
  recordInProgress: false,
  setDetectedLanguage: () => undefined,
  setMicrophoneClasses: () => undefined,
  setRecordInProgress: () => undefined,
  microphoneTriggerRef: null,
  stopResponseTriggerRef: null,
  metaHumanTalking: false,
  setMetaHumanTalking: () => undefined,
});

const AudioContextProvider = ({ children }: AudioContextProviderProps) => {
  const [detectedLanguage, setDetectedLanguage] = useState<string>();

  const [recordInProgress, setRecordInProgress] = useState<boolean>(false);
  const [microphoneClasses, setMicrophoneClasses] = useState<string>('');
  const [metaHumanTalking, setMetaHumanTalking] = useState<boolean>(false);

  const microphoneTriggerRef = useRef<MicrophoneTriggerHandlers>(null);
  const stopResponseTriggerRef = useRef<StopResponseTriggerHandlers>(null);

  return (
    <AudioContext.Provider
      value={{
        detectedLanguage,
        microphoneClasses,
        recordInProgress,
        setDetectedLanguage,
        setMicrophoneClasses,
        setRecordInProgress,
        microphoneTriggerRef,
        stopResponseTriggerRef,
        metaHumanTalking,
        setMetaHumanTalking,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};

export { AudioContextProvider };
export default AudioContext;
