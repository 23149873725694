import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Gear } from '@phosphor-icons/react';
import { AppRoutes, UserSettingsNestedRoutes } from 'src/types';
import { SVG_SIZE_M } from 'src/constants';
import styles from './SettingsLink.module.scss';
import { useSession } from 'src/hooks';

export const SettingsLink = () => {
  const { isOpenTier } = useSession();
  return (
    <Link
      to={`${AppRoutes.MANAGE_ACCOUNT}/${UserSettingsNestedRoutes.PROFILE}`}
      className={cn(styles.root, { [styles.disabled]: isOpenTier })}
      onClick={(e) => {
        isOpenTier && e.preventDefault();
      }}
    >
      <Gear size={SVG_SIZE_M} />
      Manage account & settings
    </Link>
  );
};
