import { useRef } from 'react';
import { AppearingFromRight } from 'src/animations/AppearingFromRight';
import styles from './UpgradeDowngradeSection.module.scss';
import { SubscriptionPlan } from 'src/types';
import { PanelHeader } from '../PanelHeader';
import { UpgradeDowngradeSummarySection } from '../UpgradeDowngradeSummarySection';
import { UpgradeDowngradeButton } from '../UpgradeDowngradeButton';

interface UpgradeDowngradeSectionProps {
  isOpen: boolean;
  onClose: () => void;
  selectedPlan?: SubscriptionPlan;
}

export const UpgradeDowngradeSection = ({
  isOpen,
  onClose,
  selectedPlan,
}: UpgradeDowngradeSectionProps) => {
  const checkoutContentRef = useRef<HTMLDivElement>(null);

  return (
    <AppearingFromRight inProp={isOpen} nodeRef={checkoutContentRef}>
      <div className={styles.root} ref={checkoutContentRef}>
        <PanelHeader onClose={onClose} />

        <div className={styles.mainContainer}>
          <UpgradeDowngradeSummarySection selectedPlan={selectedPlan} />

          <div className={styles.paymentContainer}>
            <UpgradeDowngradeButton selectedPlan={selectedPlan} />
          </div>
        </div>
      </div>
    </AppearingFromRight>
  );
};
