import styles from './InviteUsersModalStep.module.scss';
import { UploadSimple } from '@phosphor-icons/react';
import { InviteUsersFileInputId, SVG_SIZE_L } from 'src/constants';
import TextareaAutosize from 'react-textarea-autosize';
import { Button } from 'src/v2/commonComponents/Button';
import { ChangeEvent } from 'react';

type InviteUsersModalStepProps = {
  onClose: () => void;
  onFileSelect: (event: ChangeEvent) => void;
  onUploadFile: () => void;
  inputValue: string;
  onInputChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onInviteInputEmails: () => void;
};

export const InviteUsersModalStep = ({
  onClose,
  onFileSelect,
  onUploadFile,
  inputValue,
  onInputChange,
  onInviteInputEmails,
}: InviteUsersModalStepProps) => {
  return (
    <div className={styles.root}>
      <h4 className={styles.title}>Invite users</h4>
      <div className={styles.uploadFileRow} onClick={onUploadFile}>
        <div className={styles.uploadFileInfo}>
          <h5 className={styles.uploadFileTitle}>Import users from CSV</h5>
          <p className={styles.uploadFileDescription}>
            This file must include list of email addresses:{' '}
          </p>
          <span className={styles.uploadFileExamples}>Email1, Email2 ...</span>
        </div>
        <UploadSimple size={SVG_SIZE_L} />
        <input
          id={InviteUsersFileInputId}
          type="file"
          accept=".csv"
          onChange={onFileSelect}
          className={styles.attachInput}
        />
      </div>
      <hr className={styles.divider} />

      <div className={styles.inputSection}>
        <h5 className={styles.inputTitle}>Enter email address</h5>
        <TextareaAutosize
          className={styles.input}
          minRows={3}
          maxRows={3}
          placeholder="Type an email, separate each by a comma..."
          value={inputValue}
          onChange={onInputChange}
        />
      </div>

      <div className={styles.buttonRow}>
        <Button
          color="transparent"
          onClick={onClose}
          className={styles.cancelButton}
        >
          Cancel
        </Button>
        <Button
          onClick={onInviteInputEmails}
          disabled={inputValue.length === 0}
        >
          Invite users
        </Button>
      </div>
    </div>
  );
};
