import { KeyboardEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { KeyCodes } from 'src/types';
import { useMeetingCardFieldsWatch } from '../../../../hooks/useMeetingCardFieldsWatch';
import type { MeetingCardFormData } from '../../../../CreateMeetingCard';
import { REGEX_EMAIL_PATTERN } from 'src/constants';
import { useSession } from 'src/hooks';

interface AttendeeInputProps {
  isError: boolean;
  shouldDisableInputSubmit: boolean;
  onSearchContacts: (query: string) => void;
  onAttendeeSubmit: () => void;
}

export const AttendeeInput = ({
  isError,
  shouldDisableInputSubmit,
  onSearchContacts,
  onAttendeeSubmit,
}: AttendeeInputProps) => {
  const { register, trigger } = useFormContext<MeetingCardFormData>();
  const { executed, attendees } =
    useMeetingCardFieldsWatch<MeetingCardFormData>();
  const { appUser } = useSession();
  const maxAttendeesLength = appUser?.settings?.scheduler?.num_of_invitees || 0;

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== KeyCodes.ENTER) {
      return;
    }

    e.preventDefault();

    if (!shouldDisableInputSubmit) {
      onAttendeeSubmit();
    }
  };

  if (executed) {
    return null;
  }

  return (
    <input
      disabled={attendees && attendees?.length >= maxAttendeesLength}
      placeholder="Add guest by name or email address"
      className={classNames('nj-create-meeting-card--input', {
        warning: isError,
      })}
      onKeyDown={handleKeyDown}
      {...register('new_attendee', {
        onChange: (e) => {
          trigger('new_attendee');
          onSearchContacts(e.target.value);
        },
        pattern: REGEX_EMAIL_PATTERN,
      })}
    />
  );
};
