import { ChangeEvent, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { CheckSquare, Square } from '@phosphor-icons/react';

const SVG_SIZE = 24;

interface CheckboxProps {
  name: string;
  label: string | JSX.Element;
  disabled?: boolean;
  className?: string;
  onChangeHandler?: (value: boolean) => void;
}

export const Checkbox = ({
  name,
  label,
  disabled,
  className,
  onChangeHandler,
}: CheckboxProps) => {
  const uniqueId = useMemo(() => uuidv4(), []);

  const { register, control } = useFormContext();
  const fieldValues = useWatch({ control });

  const isChecked = !!fieldValues[name];

  return (
    <div
      data-e2e={`checkbox-${isChecked ? 'on' : 'off'}`}
      className={classNames('nj-checkbox', className, { disabled })}
    >
      <input
        disabled={disabled}
        className="nj-checkbox--input"
        id={uniqueId}
        type="checkbox"
        {...register(name)}
        onChange={(data: ChangeEvent<HTMLInputElement>) => {
          register(name).onChange(data);
          if (onChangeHandler) {
            onChangeHandler(data.target.checked);
          }
        }}
      />
      <label className="nj-checkbox--label" htmlFor={uniqueId}>
        {label}

        <div style={{ width: SVG_SIZE, height: SVG_SIZE }}>
          {isChecked ? (
            <span
              className="nj-checkbox--label-icon"
              style={{ width: SVG_SIZE, height: SVG_SIZE }}
            >
              <CheckSquare weight="fill" size={SVG_SIZE} color="currentColor" />
            </span>
          ) : (
            <Square color="currentColor" size={SVG_SIZE} />
          )}
        </div>
      </label>
    </div>
  );
};
