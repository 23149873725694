import { FormSwitch } from 'src/components/FormSwitch';

export const GuestAvailabilityField = () => {
  return (
    <div className="nj-create-meeting-card--field">
      <div className="nj-create-meeting-card--guest-availability-label">
        <p className="nj-create-meeting-card--label">
          Confirm guest availability
        </p>
        <span className="nj-create-meeting-card--sublabel">
          Ninja will reach out to guests for availability
        </span>
      </div>

      <div>
        <FormSwitch isDisabled name="confirm_guest_availability" />
      </div>
    </div>
  );
};
